import React from 'react'
import {
  Button,
  Dialog,
  Div,
  Flex,
  HStack,
  LoadingSpinner,
  Text,
  VStack,
} from '@sendoutcards/quantum-design-ui'
import {
  useActions,
  useEffect,
  useQueries,
  useSelector,
  useState,
} from 'src/hooks'
import {
  useCheckout,
  useCheckoutPayment,
  useCompleteCheckout,
  useCustomerAttach,
  useDeleteCheckoutLines,
  useUpdateCheckoutBillingAddress,
} from 'src/react_query/queries/hooks'
import { PriceBreakdown } from '../price_breakdown/PriceBreakdown'
import { ProductSummary } from '../product_summary/ProductSummary'
import { CheckoutAccountWrapper } from '../account/checkoutModal/CheckoutAccountWrapper'
import { CreditCardManager } from 'src/payments/components'
import { useStripe } from '@stripe/react-stripe-js'
import { AnimatePresence } from 'framer-motion'
import { ToasterNotification } from 'src/editor/components/MobileEditorToolbar/components/ToasterNotification'
import { MarketingParagraphBanner } from 'src/chrome/MarketingParagraphBanner/MarketingParagraphBanner'
import { supportedAffiliateCountries } from 'src/pricing_page/components/singlePlanTile/SinglePlanTile'
import {
  GS_AFFILIATE_ID,
  GS_SALEOR_MONTHLY_AFFILIATE_ID,
} from 'src/marketing/plans/constants'
import {
  BannerType,
  CollapsibleBanner,
} from '../collapsable_banner/CollapsableBanner'
import { UpDownBanner } from '../up_down_banner/UpDownBanner'
import getCountryCodeFromPostalName from 'src/helpers/getCountryCodeFromPostalName'
import { getDetailedCountries } from 'src/legacy_graphql'
import { CCBillingAddress } from 'src/payments/components/CreditCardForm/CreditCardBillingAddressForm'
import {
  mapStripeSourceAddress,
  mapToCheckoutAddress,
} from 'src/pricing_page/utils'
import { executeSaleorAuth } from 'src/saleor/utils/auth'
import {
  AccountFragment,
  StripeSourceFragment,
} from 'src/graphql/generated/graphql'

const MP_FOR_INELIGIBLE_AFFILIATE_COUNTRY = '161'

type BannerMsg = {
  type: BannerType
  title: string
  content: React.ReactNode
}

export const SubscriptionCheckout = (props: {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  shouldSkipRedirect?: boolean
}) => {
  const actions = useActions()
  const { mutate: deleteCheckoutLines } = useDeleteCheckoutLines()
  const { mutateAsync: attachCustomer } = useCustomerAttach()
  const { data: checkout, isLoading: isLoadingCheckout } = useCheckout()
  const { mutateAsync: completeCheckout } = useCompleteCheckout()
  const { mutateAsync: checkoutPayment } = useCheckoutPayment()
  const { mutateAsync: updateCheckoutBillingAddress } =
    useUpdateCheckoutBillingAddress()
  const stripe = useStripe()
  const [detailedCountries] = useQueries(getDetailedCountries())

  const { width } = useSelector(state => state.window)
  const account = useSelector(state => state.user.account)
  const accCountry = useSelector(
    state => state.user.account?.shippingAddress?.country,
  )

  const [bannerMsgId, setBannerMsgId] = useState<string | undefined>()
  const [subBannerMsg, setSubBannerMsg] = useState<BannerMsg | undefined>()
  const [isSubBannerOpen, setIsSubBannerOpen] = useState(false)
  const [changingSub, setChangingSub] = useState<
    'Upgrade' | 'Downgrade' | undefined
  >()
  const [isSponsorExpanded, setIsSponsorExpanded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [orderError, setOrderError] = useState<string | undefined>(undefined)

  const isMobile = width <= 950

  const affiliatePlan = checkout?.lines.find(
    line => line.variant.sku === GS_SALEOR_MONTHLY_AFFILIATE_ID,
  )

  const handleIsSponsorExpanded = (isExp: boolean) => {
    setIsSponsorExpanded(isExp)
  }

  const hasResponseError = <
    TData extends { errors: Array<unknown> },
    TError extends Error | unknown,
  >(
    data?: TData | null,
    error?: TError,
  ): boolean => {
    if ((data?.errors && data?.errors.length > 0) || error) {
      return true
    }
    return false
  }

  const confirmOrder = async () => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    let mutableHasCheckoutError = false

    try {
      setIsLoading(true)
      setOrderError(undefined)

      await checkoutPayment(undefined, {
        onSettled: (data, error) =>
          (mutableHasCheckoutError = hasResponseError(data, error)),
      })
      const cpChecKout = await completeCheckout(
        {},
        {
          onSettled: (data, error) =>
            (mutableHasCheckoutError = hasResponseError(data, error)),
        },
      )

      if (!cpChecKout?.confirmationNeeded) {
        await completeCheckout(
          {},
          {
            onSettled: (data, error) => {
              mutableHasCheckoutError = hasResponseError(data, error)

              if (data?.order && !props.shouldSkipRedirect) {
                window.open(`/order-confirmation/${data.order.id}`, '_self')
              }
            },
          },
        )
        actions.needsRefreshedAccount()
      }

      if (cpChecKout?.confirmationData && cpChecKout.confirmationNeeded) {
        const parsed = JSON.parse(cpChecKout.confirmationData)
        const searchParams = new URLSearchParams(window.location.search)

        const res = await stripe?.confirmPayment({
          clientSecret: parsed.client_secret,
          confirmParams: {
            return_url: `${window.location.protocol}//${
              window.location.host
            }/confirm-payment?checkout=${searchParams.get('checkout')}`,
          },
        })
        if (res?.error) {
          console.error(res.error)
          throw new Error('Payment could not be processed')
        }
        await completeCheckout(
          {},
          {
            onSettled: (data, error) =>
              (mutableHasCheckoutError = hasResponseError(data, error)),
          },
        )
        actions.needsRefreshedAccount()
      }
      if (mutableHasCheckoutError) {
        throw Error(
          'There was a problem creating your order, if the issue persist please contact customer support',
        )
      }
    } catch (err) {
      setOrderError((err as Error).message)
      console.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  const handleUpdatedBillingAddress = (billingAddress: CCBillingAddress) => {
    try {
      updateCheckoutBillingAddress({
        billingAddress: {
          streetAddress1: billingAddress.line1,
          streetAddress2: billingAddress.line2,
          city: billingAddress.city,
          countryArea: billingAddress.state,
          postalCode: billingAddress.postalCode,
          country: getCountryCodeFromPostalName(
            billingAddress.country,
            detailedCountries,
          ),
        },
      })
    } catch (err) {
      setOrderError((err as Error).message)
      console.error(err)
    }
  }

  const handleLoginOrCreateAccSuccess = async (account: AccountFragment) => {
    try {
      setIsLoading(true)
      const auth = await executeSaleorAuth()
      await attachCustomer({ token: auth.access_token })
      if (
        account.stripeSource?.__typename === 'CreditCard' &&
        account.stripeSource.billingAddress
      ) {
        const stripeSourceAddress = mapStripeSourceAddress(
          account.stripeSource.billingAddress,
        )
        const billingAddress = stripeSourceAddress
          ? mapToCheckoutAddress(stripeSourceAddress, detailedCountries)
          : account?.shippingAddress
            ? mapToCheckoutAddress(account.shippingAddress, detailedCountries)
            : undefined
        if (billingAddress) {
          updateCheckoutBillingAddress({
            billingAddress,
          })
        }
      }
    } catch (err) {
      setOrderError('There was a problem updating your order')
    } finally {
      setIsLoading(false)
    }
  }

  const handleAddedCard = (stripeSource?: StripeSourceFragment) => {
    if (
      stripeSource?.__typename === 'CreditCard' &&
      stripeSource.billingAddress
    ) {
      try {
        setIsLoading(true)
        const stripeSourceAddress = mapStripeSourceAddress(
          stripeSource.billingAddress,
        )
        const billingAddress = stripeSourceAddress
          ? mapToCheckoutAddress(stripeSourceAddress, detailedCountries)
          : account?.shippingAddress
            ? mapToCheckoutAddress(account.shippingAddress, detailedCountries)
            : undefined
        if (billingAddress) {
          updateCheckoutBillingAddress({
            billingAddress,
          })
        }
      } catch (err) {
        setOrderError('There was a problem updating your order')
      } finally {
        setIsLoading(false)
      }
    }
  }

  useEffect(() => {
    if (orderError) {
      setTimeout(() => {
        setOrderError(undefined)
      }, 5000)
    }
  }, [orderError, setOrderError])

  useEffect(() => {
    const planDuos = [
      ['15494', '14547'],
      ['15495', '15394'],
      ['15493', '15395'],
      ['13705', '15506'],
    ]

    const subscriptionProductType = 'UHJvZHVjdFR5cGU6NDQ='

    const hasSub = account && account.plan.id !== '1'

    const subscriptionItemsInCart =
      (!account || !hasSub) && checkout
        ? checkout.lines.filter(
            line =>
              line.variant.product.productType.id === subscriptionProductType,
          )
        : undefined

    const replaceSubscriptionItemInCart =
      subscriptionItemsInCart && subscriptionItemsInCart.length > 1
        ? subscriptionItemsInCart[0]
        : undefined

    const sameSub = checkout?.lines.find(line => {
      const duoPlan = planDuos.find(pd => pd.some(p => p === line.variant.sku))
      return (
        account && duoPlan && duoPlan.some(p => p === account.plan.stripeId)
      )
    })

    const isChangingPlan = checkout?.lines.find(line => {
      return (
        line.variant.product.productType.id === subscriptionProductType &&
        hasSub &&
        sameSub === undefined
      )
    })

    const gsaSub = account?.activePlansAndAddons.find(pa =>
      [GS_AFFILIATE_ID, GS_SALEOR_MONTHLY_AFFILIATE_ID].some(
        gs => gs === pa?.stripeId,
      ),
    )
    const alreadyGSA = checkout?.lines.find(
      line => gsaSub && line.variant.sku === GS_SALEOR_MONTHLY_AFFILIATE_ID,
    )

    const itemsToDelete = [
      ...(alreadyGSA ? [alreadyGSA.id] : []),
      ...(sameSub ? [sameSub.id] : []),
      ...(isChangingPlan ? [isChangingPlan.id] : []),
      ...(replaceSubscriptionItemInCart
        ? [replaceSubscriptionItemInCart.id]
        : []),
    ]

    if (sameSub) {
      setIsSubBannerOpen(true)
      setSubBannerMsg({
        type: 'warning',
        title: 'We made changes to your shopping cart.',
        content: (
          <Flex flexDirection="column">
            <Text type="caption">
              You've already subscribed to{' '}
              <Text
                type="caption"
                weight="bold"
                style={{ display: 'inline-block' }}
              >
                "{sameSub.variant.name}"
              </Text>
              . So we took it out from your shopping cart.
            </Text>
            <Text type="caption">
              Our subscriptions are meant to be purchased just once with a
              monthly fee, to an specific user use only.
            </Text>
          </Flex>
        ),
      })
    } else if (alreadyGSA) {
      setIsSubBannerOpen(true)
      setSubBannerMsg({
        type: 'warning',
        title: 'We made changes to your shopping cart.',
        content: (
          <Flex flexDirection="column">
            <Text type="caption">
              You've already subscribed to{' '}
              <Text
                type="caption"
                weight="bold"
                style={{ display: 'inline-block' }}
              >
                "{alreadyGSA.variant.name}"
              </Text>
              . So we took it out from your shopping cart.
            </Text>
            <Text type="caption">
              Our subscriptions are meant to be purchased just once with a
              monthly fee, to an specific user use only.
            </Text>
          </Flex>
        ),
      })
    } else if (isChangingPlan && account) {
      const variantPrice = isChangingPlan.variant.pricing?.price
      const isUpgrade =
        variantPrice && variantPrice.gross.amount >= account.plan.price.amount
      setChangingSub(isUpgrade ? 'Upgrade' : 'Downgrade')
    }

    if (itemsToDelete.length > 0) {
      deleteCheckoutLines({ linesIds: itemsToDelete })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkout, account])

  useEffect(() => {
    if (
      !supportedAffiliateCountries.some(
        country => country.name === accCountry,
      ) &&
      affiliatePlan &&
      account &&
      account.shippingAddress
    ) {
      setBannerMsgId(MP_FOR_INELIGIBLE_AFFILIATE_COUNTRY)
      // remove from line
      deleteCheckoutLines({ linesIds: affiliatePlan.id })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkout, accCountry])

  useEffect(() => {
    if (
      account &&
      account.email &&
      checkout &&
      (!checkout.user || checkout.user.email !== account.email)
    ) {
      ;(async () => {
        // eslint-disable-next-line promise/prefer-await-to-then
        executeSaleorAuth().then(auth => {
          if (auth.success) {
            attachCustomer({ token: auth.access_token })
          }
        })
      })()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkout, account?.email])

  return (
    <Dialog
      isOpen={props.isOpen}
      onClose={() => props.setIsOpen(false)}
      maxWidth={'1200px'}
      maxHeight={isMobile ? '100dvh' : undefined}
      insetOverride={isMobile ? 'x2' : 'x4'}
    >
      <Text outset={{ bottom: 'x2' }} type="title">
        Order Checkout
      </Text>
      {bannerMsgId && (
        <Div outset={{ bottom: 'x3' }}>
          <MarketingParagraphBanner id={bannerMsgId} />
        </Div>
      )}
      {subBannerMsg && isSubBannerOpen && (
        <CollapsibleBanner
          title={subBannerMsg.title}
          type="prompt"
          childrenWrapperType="relative"
          onClose={() => setIsSubBannerOpen(false)}
        >
          {subBannerMsg.content}
        </CollapsibleBanner>
      )}
      {changingSub && account && <UpDownBanner changeType={changingSub} />}
      <Flex
        style={{ gap: '16px' }}
        flexDirection={isMobile ? 'column' : 'row'}
        overflow={isMobile ? 'auto' : undefined}
      >
        {/** Account */}
        <Flex
          width="100%"
          justifyContent={isMobile ? 'center' : undefined}
          flexDirection={isMobile ? 'column' : 'row'}
          rowGap="x4"
        >
          <CheckoutAccountWrapper
            handleLoginOrCreateAccSuccess={handleLoginOrCreateAccSuccess}
            isSponsorExpanded={isSponsorExpanded}
            handleIsSponsorExpanded={handleIsSponsorExpanded}
            shouldShowAffiliateCountriesOnly={!!affiliatePlan}
            onAddedCardSuccess={handleAddedCard}
            handleUpdateBillingAddressSuccess={address =>
              handleUpdatedBillingAddress(address)
            }
          >
            <CreditCardManager
              shouldMinimizeCard={true}
              onAddedCardSuccess={handleAddedCard}
              onUpdateCreditCardBillingAddressSuccess={address =>
                handleUpdatedBillingAddress(address)
              }
            />
          </CheckoutAccountWrapper>
        </Flex>
        {/** Order */}
        <VStack
          gap="x6"
          justifyContent={'center'}
          width={isMobile ? '100%' : '420px'}
        >
          <Div
            display="flex"
            id="checkout-find-my-sponsor-container"
            style={{ width: '100%' }}
          />
          {isLoadingCheckout && (
            <Div>
              <LoadingSpinner size="large" />
            </Div>
          )}
          {!isLoadingCheckout && !isSponsorExpanded && (
            <Div>
              {checkout && checkout.lines.length > 0 && (
                <ProductSummary products={checkout.lines} />
              )}
              {checkout && checkout.totalPrice && (
                <PriceBreakdown totalPrice={checkout.totalPrice} />
              )}
            </Div>
          )}
          {isLoading ? (
            <LoadingSpinner size="large" />
          ) : (
            <HStack gap={isMobile ? 'x1' : 'x4'} justify="center">
              <Button
                outlined={true}
                backgroundColorOverride="#000000"
                onClick={() => props.setIsOpen(false)}
              >
                Continue Shopping
              </Button>
              <Button disabled={!account?.stripeSource} onClick={confirmOrder}>
                Pay Order
              </Button>
            </HStack>
          )}
        </VStack>
      </Flex>
      <AnimatePresence>
        {orderError && (
          <ToasterNotification
            backgroundColor={{
              swatch: 'danger',
              shade: '_500',
            }}
            icon={{
              size: 'xSmall',
              name: 'information',
              primaryColor: 'inverseHeadingText',
              iconContainerColor: { swatch: 'success', shade: '_400' },
            }}
            label={{
              color: 'inverseHeading',
              type: 'footnote',
              content: orderError,
            }}
          />
        )}
      </AnimatePresence>
    </Dialog>
  )
}
