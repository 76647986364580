import { Flex, Text } from '@sendoutcards/quantum-design-ui'
import React, { FC } from 'react'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
import { IconType } from 'src/design_system/atoms/icons/types'
import { useSelector } from 'src/hooks'

export type TabProps = {
  title: string
  onClick: () => void
  icon?: IconType
  isActive?: boolean
  notificationCount?: number
  radius?: string
  activeTabBackgroundColor?: string
  activeTabTextColor?: string
  tabBarBackground?: string
  shouldHideText?: boolean
}

export const Tab: FC<TabProps> = ({
  title,
  isActive,
  icon,
  radius = '10px',
  onClick,
  activeTabBackgroundColor = '#FFF',
  activeTabTextColor = '#404040',
  tabBarBackground = '#f5f6f7',
  shouldHideText = false,
}) => {
  const tabTextColor = isActive ? activeTabTextColor : '#616161'
  const isSmallMobile = useSelector(state => state.window.width < 400)

  return (
    <Flex
      boxShadow={isActive ? 'mediumLight' : ''}
      position="relative"
      inset={{
        vertical: 'x1',
        horizontal: isActive
          ? isSmallMobile
            ? 'x2'
            : 'x2_5'
          : isSmallMobile
            ? '10px'
            : 'x1_5',
      }}
      borderRadius={radius}
      backgroundColor={isActive ? activeTabBackgroundColor : tabBarBackground}
      onClick={onClick}
      cursor={'pointer'}
      justifyContent="center"
      alignItems="center"
      style={{ boxShadow: isActive ? '0px 4px 8px 0px rgb(0 0 0 / 7%)' : '' }}
    >
      <Flex alignItems="center" columnGap="x_75">
        {icon && <Icon name={icon} size={20} color={tabTextColor} />}
        {!shouldHideText && (
          <Text
            type="caption"
            weight={isActive ? 'bold' : 'regular'}
            content={title}
            color={isActive ? 'primaryHeading' : 'primaryBody'}
            style={{
              lineHeight: 'initial',
              color: tabTextColor,
            }}
          />
        )}
      </Flex>
    </Flex>
  )
}
