import { Spacer, Text } from '@sendoutcards/quantum-design-ui'
import React, { FC } from 'react'

import { Address } from 'src/chrome'
import { maskField } from 'src/helpers'

import { AffiliateFormInfo } from 'src/marketing/plans/components/AffiliateForm/AffiliateForm'
import { AFFILIATE_BRANDING } from 'src/app/constants'

import styles from './affiliateContext.module.scss'

type AffiliateContextProps = {
  affiliate: AffiliateFormInfo
}

const AffiliateContext: FC<AffiliateContextProps> = props => {
  const { affiliate } = props
  return (
    <div className={styles.purchaseReview}>
      <Text type="largeBody">{AFFILIATE_BRANDING.capitalized}</Text>
      <Text type="body">$99 one-time signup fee</Text>
      <Text type="body">$79 annual recurring fee</Text>
      <Spacer space="x4" />
      <Text type="body">Personal Information:</Text>
      <Address address={{ ...affiliate.user }} />

      <div className={styles.idBlock}>
        <Text type="body">Identification</Text>
        <Text type="caption">Gov Id #1 -- {maskField(affiliate.govId1)}</Text>
        {affiliate.govId2 && (
          <Text type="caption">Gov Id #2 -- {maskField(affiliate.govId2)}</Text>
        )}
      </div>
    </div>
  )
}

export default AffiliateContext
