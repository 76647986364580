import { Flex } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { CardPanelView } from 'src/orders/components'
import { CardFragment } from 'src/graphql/generated/graphql'

export const CardPreviewCustomCardView = (props: {
  card: CardFragment
  size?: string
}) => {
  const { card, size = '12rem' } = props
  return (
    <Flex flexDirection="column" alignItems={'center'} width={'100%'}>
      <div
        style={{
          position: 'relative',
          marginBottom: '2rem',
        }}
      >
        <CardPanelView card={card} width={size} maxHeight={size} />
      </div>
    </Flex>
  )
}
