import React from 'react'

type RightArrowIconProps = { height?: number; width?: number }

export const RightArrowIcon: React.FC<RightArrowIconProps> = ({
  height = 25,
  width = 24,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.7071 12.1171C20.0976 12.5076 20.0976 13.1408 19.7071 13.5313L13.7071 19.5313C13.3166 19.9219 12.6834 19.9219 12.2929 19.5313C11.9024 19.1408 11.9024 18.5076 12.2929 18.1171L16.5858 13.8242L5 13.8242C4.44772 13.8242 4 13.3765 4 12.8242C4 12.2719 4.44772 11.8242 5 11.8242L16.5858 11.8242L12.2929 7.53133C11.9024 7.1408 11.9024 6.50764 12.2929 6.11711C12.6834 5.72659 13.3166 5.72659 13.7071 6.11711L19.7071 12.1171Z"
        fill="white"
      />
    </svg>
  )
}
