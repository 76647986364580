import Checkbox from '@material-ui/core/Checkbox'
import FavoriteIcon from '@material-ui/icons/Favorite'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import { useMutations, useState } from 'src/hooks'

export const FavoriteButton = (props: {
  cardId: string
  isFavorite: boolean
  style?: React.CSSProperties | undefined
}) => {
  const { cardId, isFavorite, style } = props
  const mutations = useMutations()
  const [isSetFavorite, setIsSetFavorite] = useState<boolean>(isFavorite)

  const handleToggleFavoriteCard = () => {
    try {
      if (isSetFavorite) {
        mutations.unfavoriteCard({ id: cardId })
      } else {
        mutations.favoriteCard({ id: cardId })
      }
      setIsSetFavorite(!isSetFavorite)
    } catch (error) {
      console.error('Failed to favorite/unfavorite card.')
    }
  }

  return (
    <Checkbox
      icon={
        <FavoriteBorderIcon
          style={{
            fill: '#f36CC2',
            position: 'absolute',
          }}
          fontSize="default"
        />
      }
      checkedIcon={
        <FavoriteIcon
          style={{
            fill: '#f36CC2',
            position: 'absolute',
          }}
          fontSize="default"
        />
      }
      checked={isSetFavorite}
      onClick={event => {
        handleToggleFavoriteCard()
      }}
      style={style}
    />
  )
}
