import React, { CSSProperties } from 'react'
import { Set } from 'immutable'
import Checkbox from '@material-ui/core/Checkbox'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import CircularProgress from '@material-ui/core/CircularProgress'
import FavoriteIcon from '@material-ui/icons/Favorite'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
// @src imports
import { useEffect, useRef, useScroll } from 'src/hooks'
import { Icon } from 'src/chrome'
import {
  CollectionFragment,
  CustomCardFragment,
  MinimalSendableCardFragment,
} from 'src/legacy_graphql'
// relative imports
import { FilterButton as FilterButtonType } from '../../types'
import FilterButton from '../FilterButtons/FilterButtons'

import styles from './catalogRibbon.module.scss'
import { Div, Text } from '@sendoutcards/quantum-design-ui'
import PremiumBadge from 'src/chrome/PremiumBadge/PremiumBadge'

type Item =
  | MinimalSendableCardFragment
  | FilterButtonType
  | CollectionFragment
  | CustomCardFragment

interface Props {
  category?: string
  canFavorite?: boolean
  isSearchResultEmpty?: boolean
  isWrapContentEnabled?: boolean
  handleCategory?: () => void
  handleCheckbox?: (cardId: string) => void
  handleFavoriteCard?: (id: string, isFavorite: boolean) => void
  handleSearchAll?: () => void
  hasMore?: boolean
  isLoadingMore?: boolean
  isScrollBarEnabled?: boolean
  isCategory?: boolean
  items: Item[]
  loadMore?: () => void
  onClick?: (
    id: string,
    typename: 'SendableCard' | 'Card' | 'Collection',
  ) => void
  onClose?: () => void
  search?: string
  selectedSet?: Set<string>
  type?: string
  title?: string
}

const CatalogRibbon: React.FC<Props> = props => {
  const {
    category,
    canFavorite,
    isSearchResultEmpty,
    isWrapContentEnabled,
    handleCategory,
    handleCheckbox,
    handleFavoriteCard,
    handleSearchAll,
    hasMore,
    isScrollBarEnabled = true,
    isLoadingMore,
    isCategory,
    items,
    loadMore,
    onClick,
    onClose,
    search,
    selectedSet,
    type,
    title,
  } = props

  const triggerElement = useRef<HTMLDivElement>(null)
  const handleScroll = useScroll({
    isLoadingMore,
    hasMore,
    loadMore,
    triggerElement,
  })

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  })

  // Trigger it once when the trigger element or hasMore has loaded
  useEffect(() => {
    handleScroll()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerElement.current, hasMore])

  return (
    <div
      className={`${styles.catalogRibbon} ${
        isWrapContentEnabled && styles.gridPadding
      }`}
    >
      <div className={styles.ribbonInfo}>
        {title && (
          <div onClick={() => handleCategory?.()} style={{ cursor: 'pointer' }}>
            <Text type="subtitle" color="primaryBody">{`${title}`}</Text>
          </div>
        )}
        {!isWrapContentEnabled && isCategory && (
          <div className={styles.more} onClick={() => handleCategory?.()}>
            <Text type="body">SEE ALL</Text>
            <Icon icon={'CARROTRIGHT'} size={12} color={''} />
          </div>
        )}
        {isWrapContentEnabled && onClose && (
          <h6 className={styles.closeCollection} onClick={() => onClose()}>
            <Icon icon={'CLOSECIRCLE'} size={18} />
            <Div display="inline-block">
              <Text
                type="caption"
                color="primaryHeading"
                outset={{ left: 'x_5' }}
              >
                Close
              </Text>
            </Div>
          </h6>
        )}
      </div>
      <div
        className={styles.ribbonCards}
        style={{
          ...(isWrapContentEnabled && {
            flexWrap: 'wrap',
            justifyContent: 'center',
          }),
          ...(!isScrollBarEnabled && { overflowX: 'hidden' }),
        }}
      >
        {items.map((item, index) => {
          const isPremium =
            item.__typename === 'SendableCard' ? item.isPremium : false

          switch (item.__typename) {
            case 'SendableCard':
            case 'Card':
              const cardType = !item.isHorizontal
                ? styles.portrait
                : item.isHorizontal
                  ? styles.landscape
                  : styles.gift
              const isFavorite =
                item.__typename === 'SendableCard' && item.isFavorite
              const image =
                item.__typename === 'SendableCard'
                  ? item.frontImage.smallThumb
                  : item.frontPreviewUrl
              const iconStyle: CSSProperties = {
                fill: '#f36CC2',
                position: 'absolute',
              }
              const checkboxStyle: CSSProperties = {
                display: 'flex',
                width: 35,
                height: 35,
                position: 'absolute',
                top: 10,
                borderRadius: 100,
                background: '#FFFFFFA3',
                border: '1px solid #fff',
                boxShadow:
                  '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)',
              }
              return (
                <div
                  id={`${type}_${index}`}
                  className={`${styles.ribbonItem} ${cardType}`}
                  style={{
                    backgroundImage: `url(${image})`,
                  }}
                  key={item.id}
                  onClick={() => onClick && onClick(item.id, item.__typename)}
                >
                  {isPremium && <PremiumBadge />}
                  {type !== 'drafts' && canFavorite && (
                    <Checkbox
                      checkedIcon={
                        <FavoriteIcon style={iconStyle} fontSize="default" />
                      }
                      icon={
                        <FavoriteBorderIcon
                          style={iconStyle}
                          fontSize="default"
                        />
                      }
                      onClick={event => {
                        event.stopPropagation()
                        handleFavoriteCard &&
                          handleFavoriteCard(item.id, isFavorite)
                      }}
                      checked={isFavorite}
                      value={item.id}
                      style={{ ...checkboxStyle, right: 10 }}
                    />
                  )}
                  {!!handleCheckbox && (
                    <div
                      className={styles.selectCheckbox}
                      style={
                        selectedSet && selectedSet.size > 0
                          ? { display: 'initial' }
                          : {}
                      }
                    >
                      <Checkbox
                        checked={selectedSet?.has(item.id)}
                        onClick={ev => {
                          ev.stopPropagation()
                          handleCheckbox?.(item.id)
                        }}
                        checkedIcon={
                          <CheckCircleIcon
                            style={iconStyle}
                            fontSize="default"
                          />
                        }
                        icon={
                          <RadioButtonUncheckedIcon
                            style={iconStyle}
                            fontSize="default"
                          />
                        }
                        style={{ ...checkboxStyle, left: 10 }}
                      />
                    </div>
                  )}
                </div>
              )
            case 'Collection':
              const backgroundImage = {
                backgroundImage: `url(${item.backgroundImageUrl})`,
              }
              const backgroundType = item.backgroundImageUrl
                ? backgroundImage
                : {
                    backgroundImage: `linear-gradient(115deg, ${item.backgroundStartColor}, ${item.backgroundEndColor})`,
                  }
              return (
                <div
                  id={`${type}_${index}`}
                  className={styles.tile}
                  style={backgroundType}
                  key={item.id}
                  onClick={() => onClick?.(item.id, 'Collection')}
                >
                  <span style={{ color: item.textColor }}>{item.title}</span>
                </div>
              )
            case 'FilterButton':
              return (
                <FilterButton
                  id={item.id}
                  key={index}
                  filter={item}
                  onClick={item.onClick}
                />
              )
            default:
              return null
          }
        })}
        {isSearchResultEmpty && (
          <div className={styles.badSearch}>
            <Text type="caption" alignment="center">
              Hmmm.. We could not find any cards using your search.
            </Text>
            <Text type="body" alignment="center">
              {category && `${category}: `}
            </Text>
            <Text type="largeBody" alignment="center">
              {search}
            </Text>
            {category && (
              <>
                <Div display="inline-block">
                  <Text type="caption" alignment="center">
                    Try selecting a different category or
                  </Text>
                </Div>
                <Div
                  onClick={() => handleSearchAll?.()}
                  display="inline-block"
                  outset={{ horizontal: 'x1' }}
                >
                  <Text type="caption" alignment="center" color="anchorBlue">
                    search all
                  </Text>
                </Div>
                <Div display="inline-block">
                  <Text type="caption" alignment="center">
                    categories for {`${search}`}
                  </Text>
                </Div>
              </>
            )}
          </div>
        )}
        {(!!loadMore || !!hasMore) && (
          <div
            ref={triggerElement}
            style={{ background: 'transparent', boxShadow: 'none' }}
          >
            {hasMore && <CircularProgress />}
          </div>
        )}
        <div className={styles.overScroll} />
      </div>
    </div>
  )
}

export default CatalogRibbon
