import * as mutations from 'src/legacy_graphql/mutations'
import {
  BlockingCreateMutation,
  BlockingUpdateMutation,
  OptimisticCreateMutation,
  OptimisticDeleteMutation,
  OptimisticUpdateMutation,
} from 'src/legacy_graphql/Mutation'
import { compact } from 'src/helpers'
import { RelatedContactFragment } from '.'

// DEPRECATED! Do NOT add anymore mutations declarations
// See the new Networking documentation

// WRITE DECLARATIONS IN ALPHABETICAL ORDER

export const cancelPendingCards = BlockingUpdateMutation<
  mutations.CancelPendingCardsData,
  mutations.CancelPendingCardsVariables
>({
  operation: mutations.CancelPendingCards,
  transitionMessage: 'Cancelling pending cards...',
  failureMessage:
    'Sorry, could not cancel pending cards, please try again later.',
})

export const createCampaign = BlockingCreateMutation<
  mutations.CreateCampaignData,
  mutations.CreateCampaignVariables
>({
  operation: mutations.CreateCampaign,
  transitionMessage: 'Creating Campaign...',
  failureMessage: 'Failed to create campaign.',
})

export const createCampaignOrder = BlockingCreateMutation<
  mutations.CreateCampaignOrderData,
  mutations.CreateCampaignOrderVariables
>({
  operation: mutations.CreateCampaignOrder,
  transitionMessage: 'Creating Campaign Order...',
  failureMessage:
    'Sorry, could not create the campaign order, please try again later',
})

export const createContact = OptimisticCreateMutation<
  mutations.CreateOrUpdateContactData,
  mutations.CreateOrUpdateContactVariables
>({
  operation: mutations.CreateOrUpdateContact,
  createdResources: ({ contact }) => [
    {
      __typename: 'Contact' as 'Contact',
      id: contact.id,
      firstName: contact.firstName ?? '',
      lastName: contact.lastName ?? '',
      company: contact.companyName ?? '',
      address1: contact.address1 ?? '',
      address2: contact.address2 ?? '',
      city: contact.city ?? '',
      state: contact.state ?? '',
      postalCode: contact.postalCode ?? '',
      country: contact.country ?? null,
      homePhone: contact.homePhone ?? null,
      cellPhone: contact.cellPhone ?? contact.cellNumber ?? null,
      workPhone: contact.workPhone ?? null,
      faxNumber: contact.faxNumber ?? null,
      emailAddress: contact.emailAddress ?? null,
      website:
        contact.website ??
        (contact.websites && contact.websites.length > 0
          ? contact.websites[0]
          : ''),
      notes: [],
      isPrimary: true,
      parent: contact.parent
        ? ({ id: contact.parent } as RelatedContactFragment)
        : null,
      spouse: contact.spouse
        ? ({ id: contact.spouse } as RelatedContactFragment)
        : null,
      children: [],
      groups: contact.groups?.map(id => ({ __typename: 'Group', id })) ?? [],
      anniversary: contact.anniversary
        ? {
            ...contact.anniversary,
            year: contact.anniversary.year ?? null,
            __typename: 'OptionalYearDate',
          }
        : null,
      birthday: contact.birthday
        ? {
            ...contact.birthday,
            year: contact.birthday.year ?? null,
            __typename: 'OptionalYearDate',
          }
        : null,
      invite: null,
      contactRequest: null,
    },
  ],
  failureMessage: 'Failed to create contact.',
})

export const createContactWithSpouseAndChildren = BlockingCreateMutation<
  mutations.CreateContactWithSpouseChildrenData,
  mutations.CreateContactWithSpouseChildrenVariables
>({
  operation: mutations.CreateContactWithSpouseChildren,
  transitionMessage: 'Creating Contacts...',
  failureMessage: 'Failed to create contacts.',
})

export const createContacts = BlockingCreateMutation<
  mutations.CreateContactsData,
  mutations.CreateContactsVariables
>({
  operation: mutations.CreateContacts,
  transitionMessage: 'Creating Contacts...',
  failureMessage: 'Failed to create contacts.',
})

export const createContactWithInvite = BlockingCreateMutation<
  mutations.CreateContactWithInviteData,
  mutations.CreateContactWithInviteVariables
>({
  operation: mutations.CreateContactWithInvite,
  transitionMessage: 'Creating Contact...',
  failureMessage: 'Failed to create contact.',
})

export const createContactsWithInvites = BlockingCreateMutation<
  mutations.CreateContactsWithInvitesData,
  mutations.CreateContactsWithInvitesVariables
>({
  operation: mutations.CreateContactsWithInvites,
  transitionMessage: 'Creating Contacts...',
  failureMessage: 'Failed to create contacts.',
})

export const createContactstWithSocProContacts = BlockingCreateMutation<
  mutations.CreateContactsWithSocProContactsData,
  mutations.CreateContactsWithSocProContactsVariables
>({
  operation: mutations.CreateContactsWithSocProContacts,
  transitionMessage: 'Creating Contacts...',
  failureMessage: 'Failed to create contacts.',
})

export const createContactWithSocProContact = BlockingCreateMutation<
  mutations.CreateContactWithSocProContactData,
  mutations.CreateContactWithSocProContactVariables
>({
  operation: mutations.CreateContactWithSocProContact,
  transitionMessage: 'Creating Contact...',
  failureMessage: 'Failed to create contact.',
})

export const createGroup = BlockingCreateMutation<
  mutations.CreateGroupData,
  mutations.CreateGroupVariables
>({
  operation: mutations.CreateGroup,
  transitionMessage: 'Creating Group...',
  failureMessage: 'Failed to create group.',
})

export const createImageWithURL = BlockingCreateMutation<
  mutations.CreateImageWithURLData,
  mutations.CreateImageWithURLVariables
>({
  operation: mutations.CreateImageWithURL,
  transitionMessage: 'Creating Image...',
  failureMessage: 'Failed to create image.',
})

export const createOrder = BlockingUpdateMutation<
  mutations.CreateOrderData,
  mutations.CreateOrderVariables
>({
  operation: mutations.CreateOrder,
  transitionMessage: 'Creating Order...',
  failureMessage: 'Sorry, could not create order, please try again later',
})

export const createPurchase = BlockingUpdateMutation<
  mutations.CreatePurchaseData,
  mutations.CreatePurchaseVariables
>({
  operation: mutations.CreatePurchase,
  transitionMessage: 'Creating purchase...',
  failureMessage: 'Sorry, could not create purchase, please try again later',
})

export const commitPurchase = BlockingUpdateMutation<
  mutations.CommitPurchaseData,
  mutations.CommitPurchaseVariables
>({
  operation: mutations.CommitPurchase,
  transitionMessage: 'Commiting purchase...',
  failureMessage: 'Sorry, could not commit purchase, please try again later',
})

export const createBackPanel = BlockingCreateMutation<
  mutations.CreateOrUpdateBackPanelData,
  mutations.CreateOrUpdateBackPanelVariables
>({
  operation: mutations.CreateOrUpdateBackPanel,
  transitionMessage: 'Saving Layout...',
  failureMessage: 'Failed to save layout.',
})

export const createOrUpdateLayout = BlockingUpdateMutation<
  mutations.CreateOrUpdateBackPanelData,
  mutations.CreateOrUpdateBackPanelVariables
>({
  operation: mutations.CreateOrUpdateBackPanel,
  transitionMessage: 'Saving Layout...',
  failureMessage: 'Failed to save layout.',
})

export const createStripeOrder = BlockingCreateMutation<
  mutations.CreateStripeOrderData,
  mutations.CreateStripeOrderVariables
>({
  operation: mutations.CreateStripeOrder,
  transitionMessage: 'Creating Order...',
  failureMessage: 'Sorry, could not create order, please try again later',
})

export const deleteCampaign = OptimisticDeleteMutation<
  mutations.DeleteCampaignData,
  mutations.DeleteCampaignVariables
>({
  operation: mutations.DeleteCampaign,
  resourceTypename: 'Campaign',
  resourceIds: ({ id }) => [id],
  failureMessage: 'Failed to delete campaign.',
})

export const deleteCards = OptimisticDeleteMutation<
  mutations.BulkDeleteCardsData,
  mutations.BulkDeleteCardsVariables
>({
  operation: mutations.BulkDeleteCards,
  resourceTypename: 'Card',
  resourceIds: ({ cards }) => cards,
  failureMessage: 'Failed to delete cards.',
})

export const deleteContact = OptimisticDeleteMutation<
  mutations.DeleteContactData,
  mutations.DeleteContactVariables
>({
  operation: mutations.DeleteContact,
  resourceTypename: 'Contact',
  resourceIds: ({ id }) => [id],
  failureMessage: 'Failed to delete contact.',
})

export const deleteContacts = OptimisticDeleteMutation<
  mutations.BulkDeleteContactsData,
  mutations.BulkDeleteContactsVariables
>({
  operation: mutations.BulkDeleteContacts,
  resourceTypename: 'Contact',
  resourceIds: ({ contacts }) => contacts,
  failureMessage: 'Failed to delete contacts.',
})

export const deleteGroup = OptimisticDeleteMutation<
  mutations.DeleteGroupData,
  mutations.DeleteGroupVariables
>({
  operation: mutations.DeleteGroup,
  resourceTypename: 'Group',
  resourceIds: ({ id }) => [id],
  failureMessage: 'Failed to delete group.',
})

export const deleteImage = OptimisticDeleteMutation<
  mutations.DeleteImageData,
  mutations.DeleteImageVariables
>({
  operation: mutations.DeleteImage,
  resourceTypename: 'Image',
  resourceIds: ({ id }) => [id],
  failureMessage: 'Failed to delete image.',
})

export const deleteOrders = OptimisticDeleteMutation<
  mutations.BulkDeleteOrdersData,
  mutations.BulkDeleteOrdersVariables
>({
  operation: mutations.BulkDeleteOrders,
  resourceTypename: 'Order',
  resourceIds: ({ orders }) => orders,
  failureMessage: 'Failed to delete orders.',
})

export const favoriteCard = BlockingCreateMutation<
  mutations.FavoriteCardData,
  mutations.FavoriteCardVariables
>({
  operation: mutations.FavoriteCard,
  transitionMessage: 'Favoriting card...',
  failureMessage: 'Failed to favorite card.',
})

export const getOrCreateImageForStickerUrl = BlockingCreateMutation<
  mutations.GetOrCreateImageForStickerUrlData,
  mutations.GetOrCreateImageForStickerUrlVariables
>({
  operation: mutations.GetOrCreateImageForStickerUrl,
  failureMessage: 'Failed to create Sticker.',
  transitionMessage: 'Loading Sticker...',
})

export const requestEmailVerificationCode = BlockingCreateMutation<
  mutations.RequestEmailVerificationCodeData,
  mutations.RequestEmailVerificationCodeVariables
>({
  operation: mutations.RequestEmailVerificationCode,
  transitionMessage: 'Requesting Email Verification Code',
  failureMessage: 'Could not request email verificaiton code',
})

export const requestPhoneNumberVerification = BlockingCreateMutation<
  mutations.RequestPhoneNumberVerificationData,
  mutations.RequestPhoneNumberVerificationVariables
>({
  operation: mutations.RequestPhoneNumberVerification,
  transitionMessage: 'Requesting Phone Number Verification...',
  failureMessage: 'Failed to request phone number verification.',
})

export const revertBulkOrder = BlockingUpdateMutation<
  mutations.RevertBulkOrderData,
  mutations.RevertBulkOrderVariables
>({
  operation: mutations.RevertBulkOrder,
  transitionMessage: 'Reverting Bulk Order...',
  failureMessage: 'Failed to revert bulk order.',
})

export const sendPausedRecipients = BlockingUpdateMutation<
  mutations.SendPausedRecipientsData,
  mutations.SendPausedRecipientsVariables
>({
  operation: mutations.SendPausedRecipients,
  transitionMessage: 'Sending paused recipients',
  failureMessage: 'Failed to send paused recipients',
})

export const shareCampaign = BlockingCreateMutation<
  mutations.ShareCampaignData,
  mutations.ShareCampaignVariables
>({
  operation: mutations.ShareCampaign,
  transitionMessage: 'Sharing campaign...',
  failureMessage: 'Failed to share campaign.',
})

export const shareContact = BlockingCreateMutation<
  mutations.ShareContactData,
  mutations.ShareContactVariables
>({
  operation: mutations.ShareContact,
  transitionMessage: 'Sharing contact...',
  failureMessage: 'Failed to share contact.',
})

export const shouldDisplayUpsaleTrigger = BlockingCreateMutation<
  mutations.ShouldDisplayUpsaleTriggerData,
  {}
>({
  operation: variables => ({
    ...mutations.ShouldDisplayUpsaleTrigger(),
    variables,
  }),
  transitionMessage: '...',
  failureMessage: 'Failed to display upsale.',
})

export const sendOrder = BlockingCreateMutation<
  mutations.SendOrderData,
  mutations.SendOrderVariables
>({
  operation: mutations.SendOrder,
  transitionMessage: 'Sending Order...',
  failureMessage: 'Failed to send order.',
})

export const separateContact = BlockingUpdateMutation<
  mutations.SeparateContactData,
  mutations.SeparateContactVariables
>({
  operation: mutations.SeparateContact,
  transitionMessage: 'Separating Contacts...',
  failureMessage: 'Failed to separate contacts.',
})

export const updateCampaign = BlockingUpdateMutation<
  mutations.UpdateCampaignData,
  mutations.UpdateCampaignVariables
>({
  operation: mutations.UpdateCampaign,
  transitionMessage: 'Saving campaign...',
  failureMessage: 'Failed to save campagin.',
})

export const updateSourceBillingAddress = BlockingUpdateMutation<
  mutations.UpdateSourceBillingAddressData,
  mutations.UpdateSourceBillingAddressVariables
>({
  operation: mutations.UpdateSourceBillingAddress,
  transitionMessage: 'Saving billing adress...',
  failureMessage: 'Failed to save billing address.',
})

export const updateCoachPublicDisplayInfo = BlockingUpdateMutation<
  mutations.UpdateCoachPublicInfoDisplayData,
  mutations.UpdateCoachPublicInfoDisplayVariables
>({
  operation: mutations.UpdateCoachPublicInfoDisplay,
  transitionMessage: 'Updating your preferences...',
  failureMessage: 'Failed to update your preferences.',
})

export const updateContact = OptimisticUpdateMutation<
  mutations.UpdateContactData,
  mutations.UpdateContactVariables
>({
  operation: mutations.CreateOrUpdateContact,
  updatedResources: ({ contact }) =>
    compact({
      __typename: 'Contact',
      ...contact,
      company: contact.companyName,
      parent:
        contact.parent !== undefined && contact.parent !== null
          ? ({ id: contact.parent } as RelatedContactFragment)
          : contact.parent,
      spouse:
        contact.spouse !== undefined && contact.spouse !== null
          ? ({ id: contact.spouse } as RelatedContactFragment)
          : contact.spouse,
      anniversary:
        contact.anniversary !== undefined && contact.anniversary !== null
          ? {
              ...contact.anniversary,
              year: contact.anniversary.year || null,
              __typename: 'OptionalYearDate',
            }
          : contact.anniversary,
      birthday:
        contact.birthday !== undefined && contact.birthday !== null
          ? {
              ...contact.birthday,
              year: contact.birthday.year || null,
              __typename: 'OptionalYearDate',
            }
          : contact.birthday,
      groups: contact.groups?.map(id => ({ __typename: 'Group', id })),
      notes: contact.notes?.map(note => ({
        ...note,
        date: note.date || null,
        __typename: 'Note',
      })),
    }),
  failureMessage: 'Failed to update contact.',
})

export const updateInvite = BlockingUpdateMutation<
  mutations.UpdateInviteData,
  mutations.UpdateInviteVariables
>({
  operation: mutations.UpdateInvite,
  transitionMessage: 'Saving invite...',
  failureMessage: 'Failed to save invite.',
})

export const updateOrder = BlockingUpdateMutation<
  mutations.UpdateOrderData,
  mutations.UpdateOrderVariables
>({
  operation: mutations.UpdateOrder,
  transitionMessage: 'Saving order...',
  failureMessage: 'Failed to save order.',
})

export const updateRecipient = BlockingUpdateMutation<
  mutations.UpdateRecipientData,
  mutations.UpdateRecipientVariables
>({
  operation: mutations.UpdateRecipient,
  transitionMessage: 'Updating recipient...',
  failureMessage: 'Failed to update recipient.',
})

export const updateStorefrontMugshot = BlockingCreateMutation<
  mutations.UpdateStorefrontMugshotData,
  { image_file: File; tags: string[] }
>({
  operation: variables => ({
    ...mutations.UpdateStorefrontMugshot(),
    variables,
  }),
  transitionMessage: 'Uploading Image...',
  failureMessage: 'Failed to upload image.',
  isFormData: true,
})

export const uploadImage = BlockingCreateMutation<
  mutations.UploadImageData,
  { image_file: File; tags: string[] }
>({
  operation: variables => ({
    ...mutations.UploadImage(),
    variables,
  }),
  transitionMessage: 'Uploading Image...',
  failureMessage: 'Failed to upload image.',
  isFormData: true,
})

export const unfavoriteCard = BlockingCreateMutation<
  mutations.UnfavoriteCardData,
  mutations.UnfavoriteCardVariables
>({
  operation: mutations.UnfavoriteCard,
  transitionMessage: 'Unfavoriting card...',
  failureMessage: 'Failed to unfavorite card.',
})

export const updateGroup = OptimisticUpdateMutation<
  mutations.UpdateGroupData,
  mutations.UpdateGroupVariables
>({
  operation: mutations.UpdateGroup,
  updatedResources: ({ group }) => [
    {
      __typename: 'Group',
      ...group,
      members: group.members?.concat(group.newMembers ?? [])?.map(id => ({
        id,
        __typename: 'Contact',
      })),
    },
  ],
  failureMessage: 'Failed to update group.',
})

export const validateEmail = BlockingCreateMutation<
  mutations.ValidateEmailData,
  mutations.ValidateEmailVariables
>({
  operation: mutations.ValidateEmail,
  transitionMessage: 'Validating Email...',
  failureMessage: 'Failed to validate email.',
})

export const validateUsername = BlockingCreateMutation<
  mutations.ValidateUsernameData,
  mutations.ValidateUsernameVariables
>({
  operation: mutations.ValidateUsername,
  transitionMessage: 'Validating Username...',
  failureMessage: 'Failed to validate username.',
})

export const validatePhoneNumber = BlockingCreateMutation<
  mutations.ValidatePhoneNumberData,
  mutations.ValidatePhoneNumberVariables
>({
  operation: mutations.ValidatePhoneNumber,
  transitionMessage: 'Validating Phone Number...',
  failureMessage: 'Failed to validate phone number.',
})

// WRITE DECLARATIONS IN ALPHABETICAL ORDER
