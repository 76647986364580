import { Operation } from '../../../__generated__/globalTypes'

import getFairUseMarketingContentString from '../GetFairUseMarketingContent.graphql'

export type GetFairUseMarketingContentData = {
  fairUsePolicyLink: {
    __typename: 'MarketingURL'
    url: string
  }
}

export type GetFairUseMarketingContent =
  Operation<GetFairUseMarketingContentData>

export const GetFairUseMarketingContent = (): GetFairUseMarketingContent => ({
  query: getFairUseMarketingContentString,
  variables: undefined,
})
