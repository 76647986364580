import React from 'react'
import styles from './dialogHeader.module.scss'
import { Icon, Text } from '@sendoutcards/quantum-design-ui'

type DialogHeaderProps = {
  onBack: () => void
}

const DialogHeader: React.FC<DialogHeaderProps> = props => {
  const { onBack } = props

  return (
    <div style={{ display: 'flex' }}>
      <div className={styles.backButton} onClick={onBack}>
        <Icon name="leftChevron" size="xSmall" primaryColor="primaryBodyText" />
        <Text type="caption" weight="bold" content="Back" />
      </div>
    </div>
  )
}

export default DialogHeader
