import React, { FC } from 'react'
import { SVGProps } from './types'

export const Share: FC<SVGProps> = ({
  width = 17,
  height = 16,
  onClick,
  color = '#7E22CE',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick && (() => onClick())}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5835 10.667C3.5835 10.3908 3.80735 10.167 4.0835 10.167C4.35964 10.167 4.5835 10.3908 4.5835 10.667V11.3337C4.5835 11.7939 4.95659 12.167 5.41683 12.167H12.0835C12.5437 12.167 12.9168 11.7939 12.9168 11.3337V10.667C12.9168 10.3908 13.1407 10.167 13.4168 10.167C13.693 10.167 13.9168 10.3908 13.9168 10.667V12.0003C13.9168 12.3224 13.804 12.5941 13.5736 12.8245C13.3433 13.0548 13.0716 13.1674 12.7502 13.167H4.75016C4.42809 13.167 4.15637 13.0542 3.92601 12.8238C3.69567 12.5935 3.58311 12.3218 3.5835 12.0003V10.667Z"
        fill={color}
      />

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.39623 2.77826C8.59146 2.58303 8.90811 2.58303 9.10334 2.77826L11.9318 5.60668C12.127 5.80191 12.127 6.11856 11.9318 6.31379C11.7365 6.50902 11.4199 6.50902 11.2247 6.31379L9.24992 4.33905L9.24992 9.92066C9.24992 10.1962 9.02596 10.4202 8.75042 10.4202C8.47337 10.4202 8.24989 10.1956 8.25028 9.91999L8.24956 4.33914L6.27491 6.31379C6.07969 6.50902 5.76303 6.50902 5.56781 6.31379C5.37258 6.11856 5.37258 5.80191 5.56781 5.60668L8.39623 2.77826Z"
        fill={color}
      />
    </svg>
  )
}
