import React from 'react'
import {
  BottomNav,
  CATALOG_ITEM,
  RELATIONSHIPS_ITEM,
} from 'src/chrome/BottomNav/BottomNav'
import {
  ORDER_DETAIL_RECIPIENTS,
  ORDER_DETAIL_RETURN_ADDRESS_ID,
} from 'src/orders/constants'
import { useAccount, useActions } from 'src/hooks'
import { OrderWithPartialLines } from 'src/redux/reducers/orders'
import { AddCardToGiftRoute } from 'src/orders/routes/AddCardToGiftRoute'
import styles from './orderFooter.module.scss'
import { Button, ButtonProps } from '@sendoutcards/quantum-design-ui'
import HoverTooltip from 'src/chrome/HoverTooltip/HoverTooltip'
import { OrderTotal } from 'src/design_system/molecules/OrderTotal/OrderTotal'
import { getGrandTotal } from 'src/orders/helpers'

interface Props {
  isSaving: boolean
  order: OrderWithPartialLines
  isLoadingCredits: boolean
  didFlattenFail: boolean
  onSendOrder: () => void
  addRecipients: () => void
  toggleAddressBlinker: (toggle: boolean) => void
}

const OrderFooter: React.FC<Props> = props => {
  const {
    isSaving,
    order,
    isLoadingCredits,
    didFlattenFail,
    onSendOrder,
    addRecipients,
    toggleAddressBlinker,
  } = props

  const hasFufilledAllLineItemRequirements = order.lines.every(
    line => line.card,
  )

  const isInvalidCampaign =
    !order.returnAddress ||
    !order.lines.length ||
    !hasFufilledAllLineItemRequirements ||
    (!order.isBulk && order.invalidSendDelayContacts.length > 0) ||
    (order.isBulk && !order.bulkUpgradeShippingAddress)

  const isInvalidOrder =
    isInvalidCampaign ||
    (!order.isBulk && !order.contacts.length && !order.groups.length) ||
    didFlattenFail ||
    order.invalidContacts.length > 0

  const actions = useActions()
  const account = useAccount()

  const isSparse = !account.username

  const getFocusElement = (elementId: string) => {
    toggleAddressBlinker(true)

    const element = document.querySelector(`#${elementId}`)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }
  const getLineMissingCardIndex = () => {
    const firstLineMissingCard = order.lines.find(line => !line.card)
    return order.lines.length >= 1 && firstLineMissingCard
      ? order.lines.indexOf(firstLineMissingCard).toString()
      : '0'
  }

  const getMainActionDetails = (): ButtonProps => {
    if (isInvalidOrder) {
      if (didFlattenFail) {
        return {
          id: 'card_error_did_flatten_fail',
          title: 'Card Error',
          onClick: () => {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
          },
        }
      } else if (!hasFufilledAllLineItemRequirements) {
        return {
          id: 'order_footer_add_card',
          title: 'Add Card',
          onClick: () =>
            actions.openOrder(
              order.id,
              AddCardToGiftRoute(getLineMissingCardIndex()),
            ),
        }
      } else if (
        order?.returnAddress &&
        !order?.isBulk &&
        order?.contacts.length === 0
      ) {
        return {
          id: 'add_recipients_btn',
          title: 'Add Recipients',
          onClick: addRecipients,
        }
      } else if (order?.invalidContacts.length > 0) {
        return {
          id: 'recipient_error',
          title: 'Recipient Error',
          onClick: () => getFocusElement(ORDER_DETAIL_RECIPIENTS),
        }
      } else {
        return {
          title: `Add ${order?.isBulk ? 'Shipping' : 'Return'} Address`,
          onClick: () => getFocusElement(ORDER_DETAIL_RETURN_ADDRESS_ID),
        }
      }
    } else {
      return {
        id: 'ready_to_send_btn',
        title: isLoadingCredits
          ? 'Loading Credits'
          : isSaving
            ? 'Saving...'
            : order.isSent
              ? 'This order has been sent!'
              : 'Checkout',
        onClick: onSendOrder,
        disabled:
          isLoadingCredits || isSaving || order.isSent || !order.isSendable,
      }
    }
  }

  const total = getGrandTotal(order)
  const isMissingRecipient = order.contacts.length === 0

  const getHoverTooltipProps = () => {
    if (
      getMainActionDetails().id === 'order_footer_add_card' &&
      isInvalidCampaign
    ) {
      return {
        title:
          'In order to complete your order please add card(s) to every card item(s)', // IF you change the length of this tooltip title you will need to adjust the left positioning so it doesn't break off screen
        shouldDisplayTooltip: true,
        positioning: { left: '-210%', top: '15%' }, // TODO - Make tooltip positioning dynamic to content length
      }
    } else if (getMainActionDetails().id === 'card_error_did_flatten_fail') {
      return {
        title:
          'We encountered an error while preparing your card due to an issue with the pictures or images used.',
        shouldDisplayTooltip: true,
        positioning: { left: '-280%', top: '15%' },
      }
    } else {
      return {
        title: '',
        shouldDisplayTooltip: false,
        positioning: undefined,
      }
    }
  }

  return (
    <footer
      className={styles.orderFooter}
      style={{
        zIndex: 115,
        left: isSparse ? 0 : '65px',
        width: isSparse ? '100%' : 'calc(100% - 65px)',
      }}
    >
      <div className={styles.desktop} style={{ padding: '0 5.5rem' }}>
        <HoverTooltip
          title="Add Recipient(s) to calculate total."
          shouldDisplayTooltip={isMissingRecipient}
          isFullWidth={true}
          background="light"
          tooltipDirection="bottom"
        >
          <OrderTotal total={total} />
        </HoverTooltip>
        <div className={`${styles.orderInfo} `}>
          <HoverTooltip
            {...getHoverTooltipProps()}
            isFullWidth={true}
            background="dark"
            tooltipDirection="right"
          >
            <Button
              {...getMainActionDetails()}
              size="small"
              type={isInvalidOrder ? 'primary' : 'success'}
              backgroundColorOverride={
                didFlattenFail
                  ? 'rgb(231, 81, 93)'
                  : isInvalidOrder
                    ? '#323334'
                    : ''
              }
              textColorOverride={isInvalidOrder ? '#FFFFFF' : ''}
            />
          </HoverTooltip>
        </div>
      </div>

      <div className={styles.mobile}>
        <BottomNav items={[CATALOG_ITEM, RELATIONSHIPS_ITEM]} />
      </div>
    </footer>
  )
}

export default OrderFooter
