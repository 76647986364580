import React from 'react'
import { Flex, Text } from '@sendoutcards/quantum-design-ui'
import { SendType } from 'src/catalog/components/CardOptions/CardOptions'

type SendTypeToggleProps = {
  onToggle: (sendType: SendType) => void
  sendType: SendType
}

export const SendTypeToggle = (props: SendTypeToggleProps) => {
  const { sendType, onToggle } = props

  const isInstant = sendType === SendType.Digital
  const isPrint = sendType === SendType.Print

  const handleToggle = () => {
    onToggle(isInstant ? SendType.Print : SendType.Digital)
  }

  return (
    <Flex
      backgroundColor="#d0d0d029"
      justifyContent="space-between"
      alignItems="center"
      borderRadius="circle"
      inset="x_5"
      onClick={handleToggle}
    >
      <Flex
        justifyContent="center"
        alignItems="center"
        backgroundColor={isInstant ? '#fff' : 'transparent'}
        inset={{ vertical: 'x_75', horizontal: 'x1_5' }}
        borderRadius="circle"
        cursor="pointer"
        opacity={isInstant ? 1 : 0.5}
        style={{
          transition: 'background-color 0.3s ease',
          backgroundColor: isInstant ? '#fff' : 'transparent',
        }}
      >
        <Text
          type="footnote"
          content="Digital Card"
          weight="semiBold"
          style={{ fontFamily: 'Montserrat, san-seriff' }}
        />
      </Flex>
      <Flex
        justifyContent="center"
        alignItems="center"
        backgroundColor={isPrint ? '#fff' : 'transparent'}
        inset={{ vertical: 'x_75', horizontal: 'x1_5' }}
        borderRadius="circle"
        cursor="pointer"
        opacity={isPrint ? 1 : 0.5}
        style={{
          transition: 'background-color 0.3s ease',
          backgroundColor: isPrint ? '#fff' : 'transparent',
        }}
      >
        <Text
          type="footnote"
          content="Printed Card"
          weight="semiBold"
          style={{ fontFamily: 'Montserrat, san-seriff' }}
        />
      </Flex>
    </Flex>
  )
}
