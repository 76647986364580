import React from 'react'
import {
  Anchor,
  Button,
  Checkbox,
  ConfirmDialog,
  Div,
  Flex,
  IconLink,
  OrderCard,
  Separator,
  Spacer,
  Text,
  Transition,
} from '@sendoutcards/quantum-design-ui'
// @src imports
import {
  useActions,
  useDebounce,
  useEffect,
  useMutations,
  usePersistedUserData,
  useQueries,
  useQueryParams,
  useRoute,
  useSelector,
  useState,
  useVertical,
} from 'src/hooks'

import { FlexRow } from 'src/styled'
import styles from './loginForm.module.scss'
import LoginInput from './components/LoginInput'
import ForgotPasswordForm from './components/ForgotPasswordForm'
import { getMarketingContent } from 'src/legacy_graphql'
import {
  AccountFragment,
  AccountInput,
  UserType,
} from 'src/graphql/generated/graphql'
import {
  useCreateAffiliate,
  useCreateFreeAffiliate,
  useLoginUser,
  useUpgradeToGreenerStillAffiliate,
} from 'src/react_query'
import { createPhoneNumberInput } from 'src/helpers/createPhoneNumberInput'
import { getFormattedNumber } from 'src/onboarding/components/PhoneNumberVerification'
import Result from 'src/utils/Result'
import AffiliateAddress from 'src/dashboard/components/AffiliateJoin/AffiliateAddress'
import PaymentMethod from 'src/dashboard/components/AffiliateJoin/PaymentMethod'
import { AffiliateFormInfo } from 'src/marketing/plans/components/AffiliateForm/AffiliateForm'
import IdenfiticationNumber from 'src/dashboard/components/AffiliateJoin/IdentificationNumber'
import { GiftStoreRoute } from 'src/gift_store/routes/GiftStoreRoute'
import { GiftRoute } from 'src/gift_store/routes/GiftRoute'
import { CatalogRoute } from 'src/catalog/routes/CatalogRoute'
import { SendableCardRoute } from 'src/catalog/routes/SendableCardRoute'
import AffiliateJoinSuccess from 'src/dashboard/components/AffiliateJoin/AffiliateJoinSuccess'
import usePhoneNumberCountries from 'src/hooks/usePhoneNumberCountries'
import { getMachineCountryCode } from 'src/helpers'
import { parseError } from 'src/utils/parseError'
import { DashboardRoute } from 'src/dashboard/routes/DashboardRoute'
import { PricingRoute } from 'src/marketing/plans/routes/PricingRoute'
import { AFFILIATE_BRANDING } from 'src/app/constants'

interface Props {
  didForgetLoginInfo: boolean
  setForgotPassword: (value: boolean) => void
  onCreateAccount?: () => void
  isAffiliateJoining?: boolean
  onComplete?: () => void
  title?: string
  hasFreeCardFlow?: boolean
}

interface LoginFormState {
  username: string
  password: string
  phoneNumber: string
}

type AffiliateJoinSteps =
  | 'loginAccount'
  | 'affiliateAddress'
  | 'identificationNumber'
  | 'paymentMethod'

const LoginForm: React.FC<Props> = props => {
  const {
    didForgetLoginInfo,
    setForgotPassword,
    onCreateAccount,
    isAffiliateJoining,
    onComplete,
    title,
    hasFreeCardFlow = true,
  } = props
  const shouldShowLoginWithPhoneAnchor = true
  const shouldShowRememberMeCheckbox = true
  const route = useRoute()
  const actions = useActions()
  const mutations = useMutations()
  const { mutateAsync: login } = useLoginUser()
  const { mutateAsync: createAffiliateMutation } = useCreateAffiliate()
  const { mutateAsync: createFreeAffiliateMutation } = useCreateFreeAffiliate()
  const { mutateAsync: upgradeToGreenerStillAffiliateMutation } =
    useUpgradeToGreenerStillAffiliate()
  const {
    account: persistedAccount,
    controlData,
    phoneVerification,
  } = usePersistedUserData()
  const queryParams = useQueryParams()
  const vertical = useVertical()
  const loginPhoneNumber = !controlData.isLoggedIn
    ? phoneVerification.loginPhoneNumber
    : undefined
  const [marketingCopy] = useQueries(getMarketingContent())
  const phoneNumberCountries = usePhoneNumberCountries()
  const [isWelcomeMessageOpen, setIsWelcomeMessageOpen] = useState(false)
  const [createdAccount, setCreatedAccount] = useState<AccountFragment>()
  const [isLoggingIn, setIsLoggingIn] = useState(false)
  const [loginErrorMessage, setLoginErrorMessage] = useState<
    string | undefined
  >()
  const [values, setValues] = useState<LoginFormState>({
    username: '',
    password: '',
    phoneNumber: loginPhoneNumber?.nationalNumber ?? '',
  })
  const userTypesEligibleForAffiliateUpgrade: UserType[] = [
    UserType.Fgsa,
    UserType.D,
    UserType.Id,
    UserType.Md,
    UserType.Pbd,
    UserType.Mbd,
    UserType.Bbd,
  ]

  const isMobile = useSelector(state => state.window.width <= 474)

  const isNewProAffiliate =
    queryParams?.package === 'pro' &&
    persistedAccount &&
    !userTypesEligibleForAffiliateUpgrade.includes(persistedAccount.type)
  const isNewFreeAffiliate = queryParams?.package === 'free'

  const hasCompleteAddress = (account: AccountFragment): boolean => {
    return (
      account.firstName !== '' &&
      account.lastName !== '' &&
      account.shippingAddress !== null
    )
  }

  const [affiliateStep, setAffiliateStep] = useState<AffiliateJoinSteps>(
    isAffiliateJoining && persistedAccount
      ? !hasCompleteAddress(persistedAccount)
        ? 'affiliateAddress'
        : (persistedAccount.isEligibleForFreeGsAffiliateAccount ||
              persistedAccount.isTrialAffiliate) &&
            !isNewProAffiliate
          ? 'identificationNumber'
          : userTypesEligibleForAffiliateUpgrade.includes(
                persistedAccount.type,
              ) || isNewProAffiliate
            ? 'paymentMethod'
            : 'affiliateAddress'
      : 'loginAccount',
  )
  const { username, password, phoneNumber } = values
  const debouncedPhoneNumber = useDebounce(values.phoneNumber, 1000)
  const [selectedCountryIndex, setSelectedCountryIndex] = useState<number>(
    phoneNumberCountries.findIndex(country => {
      if (loginPhoneNumber) {
        return country.isoCode === loginPhoneNumber.countryIsoCode
      }
      return country.isoCode === 'US'
    }),
  )
  const [isCountrySelectedManually, setIsCountrySelectedManually] =
    useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isRememberMeChecked, setIsRememberMeChecked] = useState(true)
  const [shouldLoginWithPhone, setShouldLoginWithPhone] =
    useState(!!loginPhoneNumber)
  const [formattedNumber, setFormattedNumber] = useState<string>('')
  const [loginWithPhonenumberError, setLoginWithPhonenumberError] =
    useState<string>()
  const [isPhoneNumberChanging, setIsPhoneNumberChanging] = useState(false)
  const [shouldShowUnverifiedDialog, setShouldShowUnverifiedDialog] = useState(
    phoneVerification.unverifiedAccountsFound ?? false,
  )
  const [shouldShowPhoneNotFoundDialog, setShouldShowPhoneNotFoundDialog] =
    useState(phoneVerification.noPhoneFound ?? false)
  const phoneNumberInput = createPhoneNumberInput(
    debouncedPhoneNumber,
    phoneNumberCountries[selectedCountryIndex],
  )
  const [affiliateFormInfo, setAffiliateFormInfo] = useState<AffiliateFormInfo>(
    isAffiliateJoining &&
      persistedAccount &&
      hasCompleteAddress(persistedAccount)
      ? {
          user: {
            firstName: persistedAccount.firstName,
            lastName: persistedAccount.lastName,
            company: '',
            address1: persistedAccount.shippingAddress?.address1 ?? '',
            address2: persistedAccount.shippingAddress?.address2 ?? '',
            city: persistedAccount.shippingAddress?.city ?? '',
            state: persistedAccount.shippingAddress?.state ?? '',
            country: persistedAccount.shippingAddress?.country ?? '',
            postalCode: persistedAccount.shippingAddress?.postalCode ?? '',
          },
          govId1: '',
          govId2: '',
          phoneNumber: persistedAccount.phoneNumber,
          accepted: false,
          purchased: false,
        }
      : {
          user: {
            firstName: '',
            lastName: '',
            company: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            country: '',
            postalCode: '',
          },
          govId1: '',
          govId2: '',
          phoneNumber: '',
          accepted: false,
          purchased: false,
        },
  )
  const [failureMessage, setFailureMessage] = useState<string>()

  const [details, setDetails] = useState({
    email: persistedAccount?.email ?? '',
    username: persistedAccount?.username ?? '',
    password: '',
  })

  const hasGiftStoreDirectLink =
    route.subroute.path === GiftStoreRoute.path &&
    route.subroute.subroute !== undefined &&
    route.subroute.subroute.path === GiftRoute.path &&
    route.subroute.subroute.giftId !== ''

  const hasCardPreviewDirectLink =
    route.subroute.path === CatalogRoute.path &&
    route.subroute.subroute !== undefined &&
    route.subroute.subroute.path === SendableCardRoute.path &&
    route.subroute.subroute.sendableCardId !== ''

  useEffect(() => {
    if (debouncedPhoneNumber !== phoneNumber) {
      setIsPhoneNumberChanging(true)
      setLoginWithPhonenumberError(undefined)
    } else {
      setIsPhoneNumberChanging(false)
    }
  }, [
    debouncedPhoneNumber,
    phoneNumber,
    setIsPhoneNumberChanging,
    setLoginWithPhonenumberError,
  ])

  useEffect(() => {
    const updateCountryCode = async () => {
      for await (const countryCode of getMachineCountryCode()) {
        const index = phoneNumberCountries.findIndex(country => {
          return country.isoCode === countryCode
        })
        if (index !== -1 && !loginPhoneNumber && !isCountrySelectedManually) {
          setSelectedCountryIndex(index)
        }
      }
    }
    if (!loginPhoneNumber && !isCountrySelectedManually) {
      updateCountryCode()
    }
  }, [phoneNumberCountries, loginPhoneNumber, isCountrySelectedManually])

  const handleChange = (name: keyof LoginFormState, value: string) => {
    setValues({ ...values, [name]: value })
  }
  const loginEvent: React.ReactEventHandler<HTMLFormElement> = event => {
    event.preventDefault()
    loginUser()
  }

  const loginUser = async () => {
    setIsLoggingIn(true)
    setLoginErrorMessage(undefined)
    const {
      loginUser: { account, message },
    } = await login({
      username,
      password,
      verticalId: vertical.graphqlVerticalID,
      queryParams: {
        redirect_uri: queryParams.redirect_uri,
      },
    })

    if (account) {
      if (details) {
        setDetails(x => ({ ...x, password: '' }))
      }
      if (isAffiliateJoining) {
        actions.loadedUser(Result(account))
        if (!hasCompleteAddress(account)) {
          setAffiliateStep('affiliateAddress')
        } else if (
          (account.isEligibleForFreeGsAffiliateAccount ||
            account.isTrialAffiliate) &&
          !isNewProAffiliate
        ) {
          setAffiliateStep('identificationNumber')
        } else if (
          userTypesEligibleForAffiliateUpgrade.includes(account.type) ||
          isNewProAffiliate
        ) {
          setAffiliateStep('paymentMethod')
        } else {
          setAffiliateStep('affiliateAddress')
        }
      } else if (queryParams.isJoiningPromo || queryParams.postcardPromo) {
        actions.loginDone(Result(account))
      } else {
        actions.saveToken(account.token)
        actions.loginDone(Result(account), isRememberMeChecked)
        if (
          queryParams.goToPricing ||
          (route.subroute.path === DashboardRoute.path &&
            route.subroute.subroute.path === PricingRoute.path)
        ) {
          actions.openPricing()
        } else if (onComplete) {
          onComplete()
        } else if (!hasGiftStoreDirectLink && !hasCardPreviewDirectLink) {
          actions.openAccount()
        }
      }
      window._cio.identify({
        id: account.id,
        first_name: account.firstName,
        last_name: account.lastName,
      })
    } else {
      setLoginErrorMessage(
        message === 'Invalid Credentials'
          ? 'Wrong username or password'
          : message
            ? message
            : 'Unable to login.',
      )
    }
    setIsLoggingIn(false)
  }

  const errorMessage =
    !didForgetLoginInfo && !shouldLoginWithPhone && loginErrorMessage
      ? loginErrorMessage
      : !isPhoneNumberChanging &&
          debouncedPhoneNumber !== '' &&
          !phoneNumberInput.nationalNumber
        ? 'Please enter a valid phone number'
        : (loginWithPhonenumberError ?? '')

  const handleLoginWithPhonenumber = async () => {
    try {
      setFormattedNumber(
        getFormattedNumber(
          phoneNumberInput.countryIsoCode,
          phoneNumberInput.nationalNumber,
        ),
      )
      setIsLoading(true)
      const { requestPhoneNumberVerification: result } =
        await mutations.requestPhoneNumberVerification({
          phoneNumber: phoneNumberInput,
          doNotShowDefaultTransition: true,
        })
      if (result.__typename === 'RequestPhoneNumberVerificationSuccess') {
        actions.openPhoneNumberVerification(
          phoneNumberInput,
          undefined,
          true,
          false,
          isRememberMeChecked,
        )
        setLoginWithPhonenumberError(undefined)
      } else {
        setLoginWithPhonenumberError(
          `Failed to send code to: ${formattedNumber}`,
        )
      }
    } catch (error) {
      setLoginWithPhonenumberError(
        error?.toString() ?? 'Failed to request phone number verification.',
      )
    } finally {
      setIsLoading(false)
    }
  }

  const hasAllRequiredFields = (info: AffiliateFormInfo): boolean => {
    return (
      info.user.firstName !== '' &&
      info.user.lastName !== '' &&
      info.user.address1 !== '' &&
      info.user.postalCode !== '' &&
      info.user.city !== '' &&
      info.user.state !== '' &&
      info.user.country !== '' &&
      info.govId1 !== '' &&
      info.accepted
      // (!!info.stripeToken || !!persistedAccount?.stripeSource) // disabling it for first release, afterwards this should depend on type of join (free or pro)
    )
  }

  const hasAllUpgradeRequiredFields = (info: AffiliateFormInfo): boolean => {
    return !!info.stripeToken || !!persistedAccount?.stripeSource
  }

  // createaffiliate
  const createAffiliate = async (info: AffiliateFormInfo) => {
    const accountInputForPro: AccountInput = {
      ...details,
      firstName: info.user.firstName,
      lastName: info.user.lastName,
      shippingAddress: { ...info.user },
      stripeToken: info.stripeToken?.id,
      profileImage: info.user.profileImage,
    }

    try {
      const { govId1, govId2 } = info
      const {
        createDistributor: { account, error },
      } = await createAffiliateMutation({
        account: {
          ...accountInputForPro,
        },
        govId1,
        govId2,
        verticalId: vertical.graphqlVerticalID,
        queryParams: {
          redirect_uri: queryParams.redirect_uri,
        },
      })
      if (account) {
        setCreatedAccount(account)
        actions.saveToken(account.token)
        setIsWelcomeMessageOpen(true)
      } else {
        setLoginErrorMessage(
          error ?? `Failed to create ${AFFILIATE_BRANDING.lowerCase} account.`,
        )
      }
    } catch (error) {
      if (error instanceof Error && error.message === '0') {
        setLoginErrorMessage(
          `The SSN value ${info.govId1} already exists in our system. Please go back and log in to your existing ${AFFILIATE_BRANDING.lowerCase} account. If you need further assistance, please contact support.`,
        )
      } else {
        setLoginErrorMessage(parseError(error))
      }
    }
  }
  // createfreeaffiliate
  const createFreeAffiliate = async (info: AffiliateFormInfo) => {
    const accountInput: AccountInput = {
      firstName: info.user.firstName,
      lastName: info.user.lastName,
      shippingAddress: { ...info.user },
    }

    try {
      const { govId1, govId2 } = info
      const {
        createFreeAffiliate: { account, error },
      } = await createFreeAffiliateMutation({
        account: {
          ...accountInput,
        },
        govId1,
        govId2,
        verticalId: vertical.graphqlVerticalID,
        queryParams,
      })
      if (account) {
        actions.saveToken(account.token)
        actions.loginDone(Result(account), isRememberMeChecked)
        setCreatedAccount(account)
        setIsWelcomeMessageOpen(true)
      } else {
        setFailureMessage(
          error ?? `Failed to create ${AFFILIATE_BRANDING.lowerCase} account.`,
        )
      }
    } catch (error) {
      setFailureMessage(
        parseError(
          error,
          `Failed to create ${AFFILIATE_BRANDING.lowerCase} account.`,
        ),
      )
    }
  }

  // upgradeaffiliate
  const upgradeToGreenerStillAffiliate = async (info: AffiliateFormInfo) => {
    const { govId1, govId2 } = info
    try {
      const {
        upgradeToGreenerStillAffiliate: { account },
      } = await upgradeToGreenerStillAffiliateMutation(
        persistedAccount?.isTrialAffiliate || isNewProAffiliate
          ? {
              govId1,
              govId2,
            }
          : {},
      )
      if (account) {
        setCreatedAccount(account)
        setIsWelcomeMessageOpen(true)
      }
    } catch (error) {
      setFailureMessage(
        parseError(
          error,
          `Failed to upgrade ${AFFILIATE_BRANDING.lowerCase} account.`,
        ),
      )
    }
  }

  const onSubmitAffiliateJoin = async (info: AffiliateFormInfo) => {
    setAffiliateFormInfo(info)

    switch (affiliateStep) {
      case 'affiliateAddress':
        setAffiliateStep(
          isNewProAffiliate ? 'paymentMethod' : 'identificationNumber',
        )
        return
      case 'identificationNumber':
        setAffiliateStep('paymentMethod')
        return
      case 'paymentMethod':
        if (
          (persistedAccount &&
            persistedAccount.isEligibleForFreeGsAffiliateAccount &&
            !hasAllRequiredFields(info)) ||
          (persistedAccount &&
            userTypesEligibleForAffiliateUpgrade.includes(
              persistedAccount.type,
            ) &&
            !hasAllUpgradeRequiredFields(info))
        ) {
          return
        }

        if (persistedAccount && isNewProAffiliate) {
          createAffiliate(info)
        } else if (
          persistedAccount?.isEligibleForFreeGsAffiliateAccount &&
          isNewFreeAffiliate
        ) {
          createFreeAffiliate(info)
        } else if (
          persistedAccount &&
          (userTypesEligibleForAffiliateUpgrade.includes(
            persistedAccount.type,
          ) ||
            isNewProAffiliate ||
            persistedAccount.isTrialAffiliate)
        ) {
          upgradeToGreenerStillAffiliate(info)
        }
        return
      default:
        break
    }
  }

  if (isLoading) {
    return (
      <Transition
        isLoading={isLoading}
        title="Sending Code To:"
        subtitle={formattedNumber}
      />
    )
  }

  const isJoiningAffiliate = queryParams.isJoiningAffiliate

  return didForgetLoginInfo ? (
    <ForgotPasswordForm
      toggleForgotPassword={() => setForgotPassword(!didForgetLoginInfo)}
    />
  ) : isWelcomeMessageOpen && createdAccount ? (
    <AffiliateJoinSuccess
      createdAccount={createdAccount}
      isFreeAffiliate={createdAccount.type === UserType.Fgsa}
    />
  ) : (
    <>
      {affiliateStep === 'loginAccount' && (
        <form className={styles.userForm} onSubmit={loginEvent}>
          <Div width="100%">
            {vertical.id === 'soc' && title && (
              <Text
                type="title"
                weight="semiBold"
                alignment={isMobile ? 'center' : 'left'}
                content={title}
              />
            )}
            {queryParams.isJoiningAffiliate && (
              <Text
                type="largeBody"
                color="primaryHeading"
                weight="semiBold"
                alignment={isMobile ? 'center' : 'left'}
              >
                Become an {AFFILIATE_BRANDING.capitalized}
              </Text>
            )}
            <Spacer space="x2" />
            <Text
              outset={{ bottom: 'x2', horizontal: isMobile ? 'x1' : undefined }}
              alignment={isMobile ? 'center' : 'left'}
              type="caption"
              data-mktcontent={
                isJoiningAffiliate
                  ? 'affiliateLoginMessage'
                  : queryParams.bulkOrderPromo
                    ? 'bulkPromoJoinMessage'
                    : 'loginWelcomeMessage'
              }
            >
              {isJoiningAffiliate
                ? marketingCopy.affiliateLoginMessage.content
                : queryParams.bulkOrderPromo
                  ? marketingCopy.bulkPromoJoinMessage.content
                  : marketingCopy.loginWelcomeMessage.content}
            </Text>
          </Div>
          <Spacer space="x1" />
          <LoginInput
            selectedCountryIndex={selectedCountryIndex}
            setSelectedCountryIndex={index => {
              setIsCountrySelectedManually(true)
              setSelectedCountryIndex(index)
            }}
            phoneNumberCountries={phoneNumberCountries}
            shouldLoginWithPhone={shouldLoginWithPhone}
            onChange={handleChange}
            username={username}
            password={password}
            phoneNumber={phoneNumber}
            errorMessage={errorMessage}
          />
          <Flex
            outset={{ top: shouldLoginWithPhone ? '-8px' : 'x2' }}
            width="100%"
            justifyContent="space-between"
          >
            <div style={{ marginLeft: '-13px' }}>
              {shouldShowRememberMeCheckbox && (
                <Checkbox
                  onChange={setIsRememberMeChecked}
                  isChecked={isRememberMeChecked}
                  label="Remember Me"
                  type="primary"
                />
              )}
            </div>
            <div style={{ marginLeft: '5px' }}>
              <Anchor
                size="xSmall"
                title="Forgot password?"
                color="anchorBlue"
                isDecorated={true}
                onClick={() => {
                  setValues({ ...values })
                  setForgotPassword(!didForgetLoginInfo)
                }}
              />
            </div>
          </Flex>
          <Spacer space="x4" />
          <Div id="login_btn" width="100%">
            <Button
              hover={true}
              onClick={
                shouldLoginWithPhone
                  ? () => handleLoginWithPhonenumber()
                  : 'submitForm'
              }
              type={'primary'}
              fullWidth={true}
              title={!isLoggingIn ? 'Log In' : 'Logging in...'}
              disabled={
                shouldLoginWithPhone
                  ? phoneNumberInput.nationalNumber === undefined
                  : !username || !password || isLoggingIn
              }
            />
          </Div>
          <Spacer space="x1" />
          <Div style={{ width: '100%' }}>
            <FlexRow>
              <Div style={{ cursor: 'pointer' }}>
                {shouldShowLoginWithPhoneAnchor && (
                  <IconLink
                    size="xSmall"
                    title={`Login with ${
                      shouldLoginWithPhone ? 'Username' : 'Phone Number'
                    }`}
                    icon="information"
                    onClick={() =>
                      setShouldLoginWithPhone(!shouldLoginWithPhone)
                    }
                  />
                )}
              </Div>
              <Div />
            </FlexRow>
          </Div>
          {(hasFreeCardFlow || onCreateAccount) && (
            <>
              <Spacer space="x4" />
              <Separator orientation="horizontal" dashed={true} caption="Or" />
              <Spacer space="x4" />
            </>
          )}
          {hasFreeCardFlow && (
            <>
              {vertical.id === 'soc' &&
                !queryParams.isJoiningPromo &&
                !queryParams.bulkOrderPromo && (
                  <>
                    <Div width="100%">
                      <Button
                        hover={true}
                        fullWidth={true}
                        title="Send Your First Card Free"
                        onClick={() => {
                          actions.openPhoneNumberVerification()
                        }}
                        type="secondary"
                      />
                    </Div>
                    <Spacer space="x2" />
                  </>
                )}
            </>
          )}
          {onCreateAccount && (
            <Button
              hover={true}
              fullWidth={true}
              title="Create an Account"
              outlined={true}
              onClick={() => onCreateAccount()}
              type="secondary"
            />
          )}
          <ConfirmDialog
            isOpen={shouldShowUnverifiedDialog}
            title={marketingCopy.unverifiedAccountsFoundCopy.title}
            description={marketingCopy.unverifiedAccountsFoundCopy.content}
            accept={{
              title: 'Got it',
              onClick: () => setShouldShowUnverifiedDialog(false),
            }}
            primaryAction={'accept'}
            onClose={() => setShouldShowUnverifiedDialog(false)}
            data-mktcontent="unverifiedAccountsFoundCopy"
          />
          <ConfirmDialog
            isOpen={shouldShowPhoneNotFoundDialog}
            title={marketingCopy.phoneNotFoundCopy.title}
            description={marketingCopy.phoneNotFoundCopy.content}
            accept={{
              title: 'Got it',
              onClick: () => setShouldShowPhoneNotFoundDialog(false),
            }}
            primaryAction={'accept'}
            onClose={() => setShouldShowPhoneNotFoundDialog(false)}
          />
        </form>
      )}
      {affiliateStep === 'affiliateAddress' && (
        <AffiliateAddress
          affiliateInfo={affiliateFormInfo}
          onFinishAccount={affiliateInfo => {
            onSubmitAffiliateJoin(affiliateInfo)
          }}
          onBack={() => {
            setAffiliateStep('loginAccount')
            actions.unloadUser()
          }}
        />
      )}
      {affiliateStep === 'identificationNumber' && (
        <>
          {isNewFreeAffiliate && (
            <>
              <OrderCard
                price={'$0.00/month'}
                thumbnail={{
                  image:
                    'https://images.unsplash.com/photo-1521685468847-de0a1a3c41a8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=3300&q=80',
                  orientation: 'portrait',
                }}
                title={`Free ${AFFILIATE_BRANDING.capitalized}`}
              />
              <Spacer space="x1" />
            </>
          )}
          <IdenfiticationNumber
            affiliateInfo={affiliateFormInfo}
            onFinishAccount={affiliateInfo =>
              onSubmitAffiliateJoin(affiliateInfo)
            }
            onBack={info => {
              setAffiliateFormInfo(info)
              setAffiliateStep('affiliateAddress')
            }}
          />
        </>
      )}
      {affiliateStep === 'paymentMethod' && (
        <PaymentMethod
          affiliateInfo={affiliateFormInfo}
          onFinishAccount={affiliateInfo => {
            onSubmitAffiliateJoin(affiliateInfo)
          }}
          onBack={info => {
            setAffiliateFormInfo(info)
            setAffiliateStep(
              isNewProAffiliate ? 'affiliateAddress' : 'identificationNumber',
            )
          }}
          isFreeAffiliateSignup={false}
          isNewProAffiliate={isNewProAffiliate}
        />
      )}
      <ConfirmDialog
        zIndex={1}
        isOpen={!!failureMessage}
        title="Failed to Join"
        description={failureMessage ?? ''}
        accept={{
          title: 'OK',
          onClick: () => setFailureMessage(undefined),
        }}
      />
    </>
  )
}

export default LoginForm
