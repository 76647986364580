import Action from 'src/redux/action'
import * as actions from 'src/redux/actions/act'

import { DrawerState } from '../types/act'

export type ACTState = {
  duvetState: DrawerState
  isRecipientDrawerOpen: boolean
}

const initialState: ACTState = {
  duvetState: 'navigation',
  isRecipientDrawerOpen: true,
}

const act = (state: ACTState = initialState, action: Action): ACTState => {
  switch (action.type) {
    case actions.setDuvetState.type:
      return {
        ...state,
        duvetState: action.duvetState,
      }
    case actions.setRecipientDrawerOpen.type:
      return {
        ...state,
        isRecipientDrawerOpen: !state.isRecipientDrawerOpen,
      }
    default:
      return state
  }
}

export default act
