import { Flex, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'

type SendTypeScreenProps = {
  title: string
  onClick: () => void
  cardView: JSX.Element
  isSelected: boolean
}

export const SendTypeScreen = (props: SendTypeScreenProps) => {
  const { title, onClick, cardView, isSelected } = props
  const primaryFont = 'Montserrat, san serif'

  const handleOnClick = () => {
    onClick()
  }

  return (
    <Flex
      position="relative"
      cursor="pointer"
      alignItems="center"
      justifyContent="center"
      inset="1rem"
      rowGap="x1_5"
      backgroundColor={'background'}
      flexDirection="column"
      style={{
        borderRadius: '.5rem',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.08)',
        border: isSelected
          ? '3px solid #8fc3ff'
          : '2px solid rgba(233, 233, 233, 0.70)',
      }}
      onClick={handleOnClick}
    >
      {cardView}
      <Text
        content={title}
        type="body"
        whiteSpace="pre"
        alignment="center"
        lineHeight={1.8}
        style={{
          fontFamily: primaryFont,
          fontSize: '.625rem',
          fontWeight: isSelected ? 700 : 500,
          color: '#404040',
        }}
      />
    </Flex>
  )
}
