import React from 'react'
import { motion } from 'framer-motion'
// @src imports
import FontFace from 'src/editor/components/FontFace/FontFace'
import { Button, Transition } from 'src/chrome'
import { Footer } from 'src/styled'
import {
  CardType,
  FullAccessFeatureType,
  LayoutCategory,
  LayoutDimensions,
  Owner,
} from 'src/graphql/generated/graphql'
import { getImages } from 'src/legacy_graphql'
import { GlobalEditor, GlobalEditorProvider } from '@sendoutcards/editor'
import * as SendDelayOptions from 'src/orders/sendDelayOptions'
// relative imports

import EditorToolBar from '../../components/EditorToolBar/EditorToolBar'
import EditorTabs from '../EditorTabs/EditorTabs'
import EditorDrawer from '../EditorDrawer/EditorDrawer'
import ContextBar from '../../components/ContextBar/ContextBar'
import EditorFooterContents from '../../components/EditorFooterContents/EditorFooterContents'
import styles from './styles'
import { Props, Steps } from '../../types'
import apiFactory from '../../api'
import {
  useAccount,
  useActions,
  useCallback,
  useDeviceInfo,
  useEffect,
  useMutations,
  useQueries,
  useSelector,
  useRef,
  useState,
} from 'src/hooks'
import range from 'lodash/range'
import {
  editorFullBleed,
  newEditorCard,
  newEditorColor,
  newEditorLayout,
  stickerFragmentToImageType,
  userImageFragmentToImageType,
} from '../../utils'
import {
  Button as QDButton,
  ConfirmDialog,
  Dialog,
  Div,
  Flex,
  Icon,
  Spacer,
  Text,
  Image,
} from '@sendoutcards/quantum-design-ui'
import { getCardDimensions, isNotNullOrUndefined } from 'src/helpers'
import { selectedVariationId as getSelectedVariationId } from '../../../redux/selectors/editor'
import { Card } from '@sendoutcards/editor/dist/types/card'
import { MobileContextBar } from './components/MobileContextBar'
import { ToasterNotification } from '../../components/MobileEditorToolbar/components/ToasterNotification'
import { AnimatePresence } from 'framer-motion'
import { Portal } from 'src/portal/portal'
import { countLineItems } from 'src/orders/helpers'
import { MiniCartProps } from 'src/orders/components/MiniCart/MiniCart'
import {
  useDeleteDigitalCard,
  useDeletePanelLayout,
  useFlags,
  useInfiniteLayouts,
  useLayouts,
  useMarketingContent,
  useStickerCategories,
  useStickers,
  useUpdateAccount,
} from 'src/react_query'
import { factoryFBForNewCardEditor } from 'src/editor/EditorCard'
import useScreenOrientation from 'src/hooks/useScreenOrientation'
import { SendButton } from 'src/editor/components/SendButton/SendButton'
import { SaveActionZone } from '@sendoutcards/editor/dist/containers/GlobalEditor/GlobalEditor'
import { useFeatureAccess } from 'src/hooks/useFeatureAccess'
import { LockedFeatureModal } from 'src/LockedFeatureModal/LockedFeatureModal'
import { Dialog as SytemDialog } from 'src/design_system/molecules/dialog/Dialog'
import formatMarketingContent from 'src/helpers/formatMarketingContent'
import ActCardGraphic from 'src/act/components/ActCardGraphic/ActCardGraphic'

const hasSpanView = (cardType: CardType): boolean => {
  switch (cardType) {
    case CardType.TwoPanel:
    case CardType.ThreePanel:
    case CardType.TwoPanelBig:
      return true
    default:
      return false
  }
}

type leaveOptions = 'catalog' | 'cardHistory' | 'myAccount'
type Status = 'none' | 'loading' | 'error' | 'success'

const Editor: React.FC<Props> = props => {
  const api = apiFactory(props)

  const {
    context,
    saveButtonLabel,
    saveButtonBackground,
    saveButtonColor,
    onBackToCatalog,
    orderApi,
    handleAddToOrder,
    onReload,
  } = props
  const {
    step,
    isMobile,
    resolveLocation,
    card,
    fullBleeds,
    setFullBleeds,
    fonts,
    isSaving,
    setIsSaving,
    panelView,
    setPanelView,
    createOrUpdateBackPanel,
    handleSave,
    handleSaveAndSend,
    selectedBackLayoutId,
    setSelectedBackLayoutId,
    toasterNotification,
    setToasterNotification,
    canShowNewCardEditor,
    errorState,
    setErrorState,
  } = api

  const hasContextBar = !!context?.lines

  // TODO: React Query Refactor, not sure if this is what we want to do
  // but RQ returns potentially undefined results
  if (!card) throw Error('No card provided!')

  const [shouldShowNewCardEditor] = useState(card.isNewEditorCard)
  const [showConfirmVisible, setShowConfirmVisible] = useState(false)
  const [selectedVariationID] = useState(
    getSelectedVariationId(card, fullBleeds),
  )
  const [selectedStickerCategory, setSelectedStickerCategory] = useState<
    string | undefined
  >()
  const [shouldShowFeatureAccessModal, setShouldShowFeatureAccessModal] =
    useState(false)
  const allFonts = fonts.all ?? []

  const { data: marketingContent } = useMarketingContent()
  const updateLayoutTitle = marketingContent?.backPanelUpdateLayoutTitle.content
  const updateLayoutBody = marketingContent?.backPanelUpdateLayoutBody.content
  const editorConfirmActDialogTitle =
    marketingContent?.editorConfirmActDialogTitle.content ?? ''
  const editorConfirmActDialogDescription =
    marketingContent?.editorConfirmActDialogDescription.content ?? ''
  const editorConfirmActDialogPrintButtonTitle =
    marketingContent?.editorConfirmActDialogPrintButtonTitle.content ?? ''
  const editorConfirmActDialogActButtonTitle =
    marketingContent?.editorConfirmActDialogActButtonTitle.content ?? ''

  const featureAccessArray = [FullAccessFeatureType.CardCustomBacks]
  const { lazyFeatureAccess } = useFeatureAccess(featureAccessArray)
  // NOTE: This is a temporary tool for local test of the new card editor
  // Switch this to true when using a local build of the current soc-editor
  const shouldUseNewWIPCardEditor = true

  const [newCard, setNewCard] = useState<Card>(
    newEditorCard(
      card,
      shouldUseNewWIPCardEditor ? factoryFBForNewCardEditor(card) : fullBleeds,
      allFonts,
      selectedVariationID ?? null,
    ),
  )

  const [shouldShowConfirmAct, setShouldShowConfirmAct] = useState(false)
  const [shouldShowConfirmLeave, setShouldShowConfirmLeave] = useState(false)
  const [savingTransitionMessage, setSavingTransitionMessage] = useState(
    'Saving your card...',
  )

  const [leaveTo, setLeaveTo] = useState<leaveOptions>('catalog')
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const showNewCardEditor = shouldShowNewCardEditor && canShowNewCardEditor
  const mutations = useMutations()
  const actions = useActions()
  const updateAccountMutation = useUpdateAccount()
  const deletePanelLayoutMutation = useDeletePanelLayout()
  const account = useAccount()
  const { orders } = useSelector(state => state)
  const deviceInfo = useDeviceInfo()
  const { orientation } = useScreenOrientation()
  // Track in memory if they have dismissed the landscape warning. Only show once per editor instance mounted
  const orientationWarningRef = useRef(false)
  const [isLandscapeWarningOpen, setIsLandscapeWarningOpen] = useState(false)
  const shouldShowLandscapeWarning =
    !orientationWarningRef.current && isLandscapeWarningOpen

  const [mainCss, setMainCss] = useState(styles.main)

  const [images] = useQueries(showNewCardEditor ? getImages() : undefined)

  const cardDimensions = getCardDimensions(card)

  const layoutsQuery = useLayouts(
    {
      category: LayoutCategory.BasicLayouts,
      offset: 0,
      limit: 100,
      dimensions: cardDimensions,
    },
    { enabled: showNewCardEditor },
  )
  const layouts = layoutsQuery.data

  const canHaveHorizontalBackPanels =
    cardDimensions === LayoutDimensions.Horizontal && showNewCardEditor
  const flags = useFlags()

  const hasHorizontalBackPanels =
    canHaveHorizontalBackPanels &&
    flags.data?.some(flag => flag.name === 'horizontal_back_panels')

  const hasActDisplay = flags.data?.some(flag => flag.name === 'ActDisplay')

  const systemBackPanelsQuery = useInfiniteLayouts({
    category: LayoutCategory.BackPanels,
    owner: Owner.System,
    dimensions: hasHorizontalBackPanels ? undefined : LayoutDimensions.Vertical,
  })
  const systemBackPanels = systemBackPanelsQuery.data

  const backPanelsQuery = useInfiniteLayouts({
    category: LayoutCategory.BackPanels,
    owner: Owner.User,
    dimensions: hasHorizontalBackPanels ? undefined : LayoutDimensions.Vertical,
  })
  const backPanels = backPanelsQuery.data

  const deleteDigitalCard = useDeleteDigitalCard()

  const stickerCategoriesQuery = useStickerCategories()

  const {
    data: stickers,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useStickers({
    categories: selectedStickerCategory
      ? [{ name: selectedStickerCategory }]
      : undefined,
  })

  const handleShowAct = async () => {
    await handleSave()
    actions.openActConfiguration(card.id)
  }

  useEffect(() => {
    lazyFeatureAccess.enable()
  }, [lazyFeatureAccess])

  // TODO: We were using the act context before, now we can grab it straight from the card
  // Do we want to switch or keep using redux for this?
  // const isDigitalSend =
  //   actDataStore.kind === 'ACT_CONTEXT' ? actDataStore.isDigitalCard : false
  const isDigitalSend = !!card.digitalCard && hasActDisplay
  const isCampaign = context?.lines && context.lines.length > 0

  const miniCartProps: MiniCartProps = {
    orderId: orders.order ? orders.order.id : '',
    editorCard: api.card,
    containerId: 'editor_context_bar',
    button: {
      count: orders.order && countLineItems(orders.order.lines),
      shouldBypassDrawer: isDigitalSend ?? false,
    },
    checkoutAction: {
      onCheckout: () => {
        if (isDigitalSend) {
          handleShowAct()
        } else {
          handleSaveAndSend().catch(error => console.error(error))
        }
      },
      title: 'Proceed to Cart',
    },
    previewZIndex: 1002,
    orderPreviewActionOverride: {
      onAddGift: {
        title: 'How would you like to proceed?',
        description:
          'Would you like to add this card to your order and also add a gift or discard this card and proceed to the gift store?',
        accept: {
          title: 'Add card to order and proceed to add a gift',
          shouldUseDefaultAction: true,
          onClick: async () => {
            setIsSaving(true)
            try {
              await handleSave()
              setIsSaving(true)
              setSavingTransitionMessage('Saving your card...')
              await handleAddToOrder?.(card.id)
              setIsSaving(true)
              setSavingTransitionMessage('Adding your card to your order...')
              await onReload?.()
            } catch (error) {
              console.error(error)
            } finally {
              setIsSaving(false)
            }
          },
        },
        decline: {
          title: 'Discard current card and continue to gift store',
          shouldUseDefaultAction: true,
        },
      },
    },
    orderApi: orderApi,
  }

  const [isReadyToSend, setIsReadyToSend] = useState(false)
  const [contextZoneOptionsIsLoadingMap, setContextZoneOptionsIsLoadingMap] =
    useState<{ [id: string]: Status }>({})
  const saveChangesTitle = 'Save Changes'
  const saveExistingBackPanelTitle = 'Save Back Panel'
  const saveNewBackPanelTitle = 'Save as New Back Panel'
  const saveAndCloseCardEditorTitle = 'Save & Close Card Editor'

  useEffect(() => {
    setNewCard(
      newEditorCard(
        card,
        shouldUseNewWIPCardEditor
          ? factoryFBForNewCardEditor(card)
          : fullBleeds,
        allFonts,
        selectedVariationID ?? null,
      ),
    )
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (deviceInfo.device !== 'Desktop' && deviceInfo.device !== 'Other') {
      if (/landscape/i.test(orientation)) {
        setIsLandscapeWarningOpen(true)
      } else {
        setIsLandscapeWarningOpen(false)
      }
    }
  }, [orientation, deviceInfo.device])

  // Add/Removed toast notification clear
  useEffect(() => {
    if (toasterNotification) {
      setTimeout(() => {
        setToasterNotification(null)
      }, 2800)
    }
  }, [toasterNotification, setToasterNotification])

  const updateIosHeight = useCallback(() => {
    setMainCss({
      ...(styles.main as Record<string, unknown>),
      height: `${window.innerHeight}px`,
    })
  }, [])

  useEffect(() => {
    if (deviceInfo.os === 'iOS' && !CSS.supports('height', '100dvh')) {
      updateIosHeight()
      window.addEventListener('resize', updateIosHeight)
      return () => {
        window.removeEventListener('resize', updateIosHeight)
      }
    } else if (
      (deviceInfo.os === 'MacOS' && deviceInfo.browser === 'Safari') ||
      !CSS.supports('height', '100dvh')
    ) {
      setMainCss({
        ...(styles.main as Record<string, unknown>),
        height: '100vh',
      })
    }
    return
  }, [deviceInfo.os, deviceInfo.browser, updateIosHeight])

  const landscapeWarning = (
    <Portal attachToContainerId="app">
      <Dialog
        isOpen={shouldShowLandscapeWarning}
        onClose={() => {
          setIsLandscapeWarningOpen(false)
          orientationWarningRef.current = true
        }}
        zIndex={10001}
        insetOverride="x4"
      >
        <Flex justifyContent="center">
          <Icon name="imageSwap" size={50} primaryColor="primaryBodyText" />
        </Flex>
        <Spacer space="x2" />
        <Text
          type="subtitle"
          weight="bold"
          color="primaryHeading"
          alignment="center"
        >
          Rotate for a Better View
        </Text>
        <Spacer space="x2" />
        <Text type="body" alignment="center">
          For the best experience, please hold your device vertically (portrait
          mode). This way you'll see all the content just as it was
          designed.{' '}
        </Text>
        <Spacer space="x2" />
        <QDButton
          type="primary"
          onClick={() => setIsLandscapeWarningOpen(false)}
          fullWidth={true}
        >
          Ok
        </QDButton>
      </Dialog>
    </Portal>
  )

  const errorDialog = errorState && (
    <Dialog isOpen={true} onClose={() => setErrorState(undefined)}>
      <Flex flexDirection="column" alignItems="center" rowGap="x2">
        <Text type="subtitle" alignment="center">
          {errorState.title}
        </Text>
        <Text type="body" alignment="center">
          For further assistance, contact the Customer Success team at (801)
          463-3800 to resolve this issue.
        </Text>
        <Text type="body">Report error code: {`${errorState.error}`}</Text>
        <Button title="Ok" onClick={() => setErrorState(undefined)} />
      </Flex>
    </Dialog>
  )

  const [hasUpdateSuccess, setHasUpdateSuccess] = useState(false)

  const [shouldShowSaveAndClose, setShouldShowSaveAndClose] = useState(false)

  if (showNewCardEditor) {
    if (
      (!layouts && layoutsQuery.isLoading) ||
      (!backPanels && backPanelsQuery.isLoading) ||
      (!systemBackPanels && systemBackPanelsQuery.isLoading)
    ) {
      return <Transition message="Loading editor" />
    }
    const mutableContextZoneOptions: SaveActionZone[] = [
      {
        onClick: async () => {
          setContextZoneOptionsIsLoadingMap(prevState => ({
            ...prevState,
            [saveChangesTitle]: 'loading',
          }))
          try {
            await handleSave()
            setContextZoneOptionsIsLoadingMap(prevState => ({
              ...prevState,
              [saveChangesTitle]: 'success',
            }))
            setToasterNotification({
              iconName: 'check',
              content: 'Saved changes',
              type: 'success',
            })
          } catch (error) {
            console.error(error)
            setToasterNotification({
              iconName: 'close',
              content: 'Failed to save changes',
              type: 'danger',
            })
            setContextZoneOptionsIsLoadingMap(prevState => ({
              ...prevState,
              [saveChangesTitle]: 'error',
            }))
          } finally {
            window.setTimeout(() => {
              setContextZoneOptionsIsLoadingMap(prevState => ({
                ...prevState,
                [saveChangesTitle]: 'none',
              }))
            }, 2000)
          }
        },
        title: saveChangesTitle,
        status: contextZoneOptionsIsLoadingMap[saveChangesTitle] ?? 'none',
        isActive:
          contextZoneOptionsIsLoadingMap[saveChangesTitle] &&
          contextZoneOptionsIsLoadingMap[saveChangesTitle] !== 'none',
      },
      {
        onClick: () => setShouldShowSaveAndClose(true),
        title: saveAndCloseCardEditorTitle,
        status:
          contextZoneOptionsIsLoadingMap[saveAndCloseCardEditorTitle] ?? 'none',
      },
      ...(selectedBackLayoutId
        ? [
            {
              title: saveExistingBackPanelTitle,
              onClick: async () => {
                setContextZoneOptionsIsLoadingMap(prevState => ({
                  ...prevState,
                  [saveExistingBackPanelTitle]: 'loading',
                }))

                try {
                  await createOrUpdateBackPanel()
                } catch (error) {
                  console.error(error)
                  setToasterNotification({
                    iconName: 'close',
                    content: 'Failed to save changes',
                    type: 'danger',
                  })
                  setContextZoneOptionsIsLoadingMap(prevState => ({
                    ...prevState,
                    [saveExistingBackPanelTitle]: 'error',
                  }))
                } finally {
                  window.setTimeout(() => {
                    setContextZoneOptionsIsLoadingMap(prevState => ({
                      ...prevState,
                      [saveExistingBackPanelTitle]: 'none',
                    }))
                  }, 2000)
                }
              },
              status:
                contextZoneOptionsIsLoadingMap[saveExistingBackPanelTitle] ??
                'none',
              isActive:
                contextZoneOptionsIsLoadingMap[saveExistingBackPanelTitle] &&
                contextZoneOptionsIsLoadingMap[saveExistingBackPanelTitle] !==
                  'none',
              isBackPanelOnly: true as const,
            },
            {
              title: saveNewBackPanelTitle,
              onClick: async () => {
                setContextZoneOptionsIsLoadingMap(prevState => ({
                  ...prevState,
                  [saveNewBackPanelTitle]: 'loading',
                }))

                try {
                  await createOrUpdateBackPanel(true)
                } catch (error) {
                  console.error(error)
                  setToasterNotification({
                    iconName: 'close',
                    content: 'Failed to save changes',
                    type: 'danger',
                  })
                  setContextZoneOptionsIsLoadingMap(prevState => ({
                    ...prevState,
                    [saveNewBackPanelTitle]: 'error',
                  }))
                } finally {
                  window.setTimeout(() => {
                    setContextZoneOptionsIsLoadingMap(prevState => ({
                      ...prevState,
                      [saveNewBackPanelTitle]: 'none',
                    }))
                  }, 2000)
                }
              },
              status:
                contextZoneOptionsIsLoadingMap[saveNewBackPanelTitle] ?? 'none',
              isActive:
                contextZoneOptionsIsLoadingMap[saveNewBackPanelTitle] &&
                contextZoneOptionsIsLoadingMap[saveNewBackPanelTitle] !==
                  'none',
              isBackPanelOnly: true as const,
            },
          ]
        : []),
    ]
    // tslint:disable-next-line
    const fullName = `${account.firstName} ${account.lastName}`

    const setBackPanelDefaultLayout = async (id: string) => {
      const result = await updateAccountMutation.mutateAsync({
        account: { settings: { defaultBackPanelId: id } },
      })
      actions.updatedAccount(result.updateAccount.account)
    }

    const confirmedLeave = () => {
      setShouldShowConfirmLeave(false)
      api.onClose()
      switch (leaveTo) {
        case 'cardHistory':
          actions.openCardHistory()
          return
        case 'myAccount':
          actions.openAccount()
          return
        case 'catalog':
          actions.openCatalog()
          return
        default:
          return
      }
    }

    const handleSelectStickerCategory = (stickerCategoryName: string) => {
      if (selectedStickerCategory !== stickerCategoryName) {
        setSelectedStickerCategory(stickerCategoryName)
      } else {
        setSelectedStickerCategory(undefined)
      }
    }

    const getSaveAndCloseTitle = () => {
      const saveStatus =
        contextZoneOptionsIsLoadingMap[saveAndCloseCardEditorTitle] ?? 'none'
      switch (saveStatus) {
        case 'loading':
          return 'Saving...'
        case 'success':
          return 'Saved!'
        case 'error':
          return 'Failed to save'
        default:
          return 'Continue and navigate away'
      }
    }

    const handleOnConfirmPrint = async () => {
      try {
        setIsReadyToSend(true)
        setShouldShowConfirmAct(false)
        if (card.digitalCard) {
          await deleteDigitalCard.mutateAsync({ id: card.digitalCard?.pk })
        }
        handleSaveAndSend()
      } catch (err) {
        setIsReadyToSend(false)
        setErrorState({
          title: "Could't create your shipped card order",
          error: err,
        })
      }
    }

    const handleOnConfirmAct = () => {
      setShouldShowConfirmAct(false)
      handleShowAct()
    }

    return (
      <GlobalEditorProvider>
        <style>{'html { overscroll-behavior: none }'}</style>
        {landscapeWarning}
        {errorDialog}
        {shouldShowConfirmLeave && (
          <ConfirmDialog
            title="You have made unsaved changes to this card"
            description="This card will be available in your Account under 'Drafted Cards'. Any unsaved changes will be lost."
            primaryAction="decline"
            isOpen={shouldShowConfirmLeave}
            accept={{
              title: 'Continue without saving',
              onClick: confirmedLeave,
            }}
            decline={{
              title: 'Return to card editor',
              onClick: () => {
                setShouldShowConfirmLeave(false)
              },
            }}
            onClose={() => {
              setShouldShowConfirmLeave(false)
            }}
          />
        )}
        {shouldShowConfirmAct && (
          <SytemDialog
            isOpen={shouldShowConfirmAct}
            onClose={() => {
              setShouldShowConfirmAct(false)
            }}
            sheetBgColorOverride="#fff"
          >
            <Flex
              flexDirection="column"
              rowGap="x2"
              justifyContent="center"
              alignItems="center"
            >
              <ActCardGraphic
                isHorizontal={card.isHorizontal}
                cardPreviewUrl={card.frontPreviewUrl}
                shouldShowGradientBackground={true}
                shouldShowEnvelope={true}
              />
              <Text
                type="body"
                style={{
                  fontFamily: 'Montserrat, sans-serif',
                  fontSize: '22px',
                  fontWeight: 700,
                  color: '#252525',
                }}
                content={editorConfirmActDialogTitle}
              />
              <Text
                type="body"
                style={{
                  fontFamily: 'Montserrat, sans-serif',
                  fontSize: '12px',
                  fontWeight: 600,
                  color: '#404040',
                }}
                content={editorConfirmActDialogDescription}
              />
              <div
                onClick={handleOnConfirmPrint}
                style={{
                  borderRadius: '8px',
                  display: 'flex',
                  justifyContent: 'center',
                  boxShadow: '0px 4px 32px 0px rgba(0, 0, 0, 0.08)',
                  padding: '8px 20px',
                  margin: '0px 8px',
                  fontFamily: 'Montserrat, sans-serif',
                  alignItems: 'center',
                  fontWeight: 600,
                  color: '#fff',
                  fontSize: '16px',
                  backgroundColor: '#404040',
                  width: '100%',
                  cursor: 'pointer',
                }}
              >
                {formatMarketingContent(
                  editorConfirmActDialogPrintButtonTitle,
                  { amount: card.cost.total[0].asString },
                )}
              </div>
              <div
                onClick={handleOnConfirmAct}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '0px 8px',
                  width: '100%',
                  fontFamily: 'Montserrat, sans-serif',
                  alignItems: 'center',
                  fontWeight: 600,
                  color: '#404040',
                  fontSize: '16px',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
              >
                {editorConfirmActDialogActButtonTitle}
              </div>
            </Flex>
          </SytemDialog>
        )}
        {shouldShowSaveAndClose && (
          <ConfirmDialog
            title="Are you sure you want to leave?"
            description="This card will be available in your Account under 'Drafted Cards'."
            primaryAction="accept"
            isOpen={shouldShowSaveAndClose}
            accept={{
              title: getSaveAndCloseTitle(),
              onClick: async () => {
                setContextZoneOptionsIsLoadingMap(prevState => ({
                  ...prevState,
                  [saveAndCloseCardEditorTitle]: 'loading',
                }))
                try {
                  await handleSave()
                  setContextZoneOptionsIsLoadingMap(prevState => ({
                    ...prevState,
                    [saveAndCloseCardEditorTitle]: 'success',
                  }))
                  setTimeout(() => {
                    setShouldShowSaveAndClose(false)
                    api.onClose()
                  }, 1500)
                } catch (error) {
                  console.error(error)
                  setContextZoneOptionsIsLoadingMap(prevState => ({
                    ...prevState,
                    [saveAndCloseCardEditorTitle]: 'error',
                  }))
                } finally {
                  setTimeout(() => {
                    setContextZoneOptionsIsLoadingMap(prevState => ({
                      ...prevState,
                      [saveAndCloseCardEditorTitle]: 'none',
                    }))
                  }, 2000)
                }
              },
            }}
            decline={{
              title: 'Cancel and stay on this page',
              onClick: () => {
                setShouldShowSaveAndClose(false)
              },
            }}
            onClose={() => {
              setShouldShowSaveAndClose(false)
            }}
          />
        )}
        <GlobalEditor
          colorPalettes={{
            palette: api.designColors?.palette?.map(color =>
              newEditorColor(color),
            ),
            isLoading: api.designColors.isLoading,
          }}
          defaultBackPanel={[
            account.settings?.defaultBackPanel?.id ?? '',
            setBackPanelDefaultLayout,
          ]}
          userInfo={{
            id: account.id,
            name: fullName,
            email: account.email,
          }}
          card={newCard}
          fonts={fonts.fonts}
          signatures={fonts.signatures}
          placeholders={
            !card.digitalCard
              ? [
                  'First Name',
                  'Last Name',
                  'Spouse Name',
                  'First Name & Spouse Name',
                ]
              : undefined
          }
          layouts={
            layouts?.map(layout => newEditorLayout(allFonts)(layout)) ?? []
          }
          defaultBackPanels={{
            isLoadingMore: systemBackPanelsQuery.isFetchingNextPage,
            hasMore: !!systemBackPanelsQuery.hasNextPage,
            loadMore: systemBackPanelsQuery.fetchNextPage,
            results:
              systemBackPanels?.pages
                .flatMap(p => p.layouts)
                .map(layout => newEditorLayout(allFonts)(layout)) ?? [],
          }}
          customBackPanels={{
            isLoadingMore: backPanelsQuery.isFetchingNextPage,
            hasMore: !!backPanelsQuery.hasNextPage,
            loadMore: backPanelsQuery.fetchNextPage,
            results:
              backPanels?.pages
                .flatMap(p => p.layouts)
                .map(layout => newEditorLayout(allFonts)(layout)) ?? [],
          }}
          images={{
            ...images,
            hasMore: images?.hasMore ?? false,
            loadMore: images?.loadMore ?? (() => {}),
            isLoadingMore: images?.isLoadingMore ?? false,
            results:
              images?.results.map(result =>
                userImageFragmentToImageType(result),
              ) ?? [],
          }}
          stickers={{
            hasMore: hasNextPage ?? false,
            isLoadingMore: isFetchingNextPage,
            loadMore: fetchNextPage,
            results:
              stickers?.pages
                .flatMap(page => page.results)
                .map(result => stickerFragmentToImageType(result)) ?? [],
          }}
          onDeleteBackPanel={id =>
            deletePanelLayoutMutation.mutateAsync({ id })
          }
          isLoading={isSaving}
          onSelectBackLayout={setSelectedBackLayoutId}
          onUploadImages={files =>
            range(files.length)
              .map(index => files.item(index))
              .filter(isNotNullOrUndefined)
              .forEach(image_file =>
                mutations.uploadImage({
                  image_file,
                  tags: ['prompt-editor'],
                }),
              )
          }
          onDeleteImage={id => mutations.deleteImage({ id })}
          onSave={({ fullBleeds }) => {
            if (allFonts.length === 0) return
            setFullBleeds(fullBleeds.map(editorFullBleed(allFonts)))
          }}
          contextZones={{
            save: {
              isLoading: isSaving,
              loadingMessage: 'Saving Changes',
              options: mutableContextZoneOptions,
            },
            clientNavigation: [
              {
                section: {
                  title: 'Menu',
                  description: '',
                  links: [
                    {
                      title: 'Back To Catalog',
                      icon: 'catalog',
                      onClick: () => {
                        setShouldShowConfirmLeave(true)
                        setLeaveTo('catalog')
                      },
                      key: 'catalog',
                    },
                    {
                      title: 'Card History',
                      icon: 'envelope',
                      onClick: () => {
                        setShouldShowConfirmLeave(true)
                        setLeaveTo('cardHistory')
                      },
                      key: 'history',
                    },
                    {
                      title: 'My Account',
                      icon: 'user',
                      onClick: () => {
                        setShouldShowConfirmLeave(true)
                        setLeaveTo('myAccount')
                      },
                      key: 'account',
                    },
                  ],
                },
              },
            ],
            primary: (
              <SendButton
                onClick={async () => {
                  if (!isDigitalSend) {
                    setIsReadyToSend(true)
                    try {
                      await handleSaveAndSend()
                    } catch (e) {
                      console.error(e)
                    } finally {
                      setIsReadyToSend(false)
                    }
                  } else {
                    // Return this
                    // setShouldShowConfirmAct(true)
                    // and remove the below if we need to return the ACT confirm dialog
                    handleShowAct()
                  }
                }}
                title={
                  isMobile
                    ? undefined
                    : isCampaign
                      ? 'Save'
                      : isDigitalSend
                        ? 'Share'
                        : 'Send'
                }
                isLoading={isReadyToSend}
              />
            ),
            externalActions: context?.headerText && context.lines && (
              <div style={{ position: 'relative' }}>
                <Div
                  cursor="pointer"
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  backgroundColor={'foreground'}
                  borderRadius="default"
                  display={'flex'}
                  alignItems="center"
                  inset={{ horizontal: 'x2' }}
                  justifyContent={'center'}
                  boxShadow="mediumLight"
                  height="50px"
                  style={{ gap: '4px' }}
                >
                  <Icon
                    name="campaigns"
                    size={20}
                    primaryColor="primaryBodyText"
                  />

                  {!isMobile && (
                    <>
                      {context.headerSubText ? (
                        <Text
                          type="caption"
                          weight="semiBold"
                          color="primaryHeading"
                        >
                          {context.headerSubText}
                        </Text>
                      ) : (
                        <>
                          <Text
                            type="caption"
                            weight="semiBold"
                            color="primaryHeading"
                          >
                            Campaign Cards:
                            {context.headerSubText}
                          </Text>
                          <Text type="caption" color="primaryHeading">
                            {context.lines.length}
                          </Text>
                        </>
                      )}
                    </>
                  )}
                </Div>
                <AnimatePresence>
                  {isDropdownOpen && (
                    <motion.div
                      style={{ position: 'relative', width: '100%' }}
                      initial={{ height: 0, opacity: 0 }}
                      animate={{ height: 'auto', opacity: 1 }}
                      exit={{ height: 0, opacity: 0 }}
                      transition={{ duration: 0.3 }}
                    >
                      <Div
                        display={'flex'}
                        backgroundColor="foreground"
                        boxShadow={'mediumLight'}
                        borderRadius="default"
                        position="absolute"
                        top="8px"
                        left={isMobile ? undefined : '0px'}
                        right={isMobile ? '0px' : undefined}
                        flexDirection="column"
                        width="100%"
                        minWidth="fit-content"
                        inset={{ horizontal: 'x2', vertical: 'x2' }}
                        style={{ gap: '16px' }}
                      >
                        {context.lines.map((line, index) => {
                          return (
                            <Div
                              key={line.card?.id ?? index}
                              display="flex"
                              alignItems="center"
                              style={{ gap: '8px' }}
                            >
                              <Image
                                borderRadius="none"
                                image={{ url: line.card?.frontPreviewUrl }}
                                isActive
                                width={'39px'}
                                height={
                                  line.card?.isHorizontal ? '28px' : '54px'
                                }
                              />
                              <div>
                                <Text
                                  type="caption"
                                  weight="semiBold"
                                  color="primaryHeading"
                                  lineHeight={1}
                                  whiteSpace="nowrap"
                                >
                                  Send Delay:
                                </Text>
                                <Text type="caption">
                                  {line.sendDelay !== undefined
                                    ? SendDelayOptions.ribbonDescription(
                                        line.sendDelay,
                                      )
                                    : 'None'}
                                </Text>
                              </div>
                            </Div>
                          )
                        })}
                      </Div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            ),
            templateUpdate: {
              modalTitle: updateLayoutTitle ?? '',
              modalBody: updateLayoutBody ?? '',
              hasUpdateSuccess: hasUpdateSuccess,
              onCreateNewTemplate: async () => {
                setHasUpdateSuccess(false)
                setContextZoneOptionsIsLoadingMap(prevState => ({
                  ...prevState,
                  [saveNewBackPanelTitle]: 'loading',
                }))

                try {
                  await createOrUpdateBackPanel(true)
                  setHasUpdateSuccess(true)
                } catch (error) {
                  console.error(error)
                  setToasterNotification({
                    iconName: 'close',
                    content: 'Failed to save changes',
                    type: 'danger',
                  })
                  setContextZoneOptionsIsLoadingMap(prevState => ({
                    ...prevState,
                    [saveNewBackPanelTitle]: 'error',
                  }))
                } finally {
                  window.setTimeout(() => {
                    setContextZoneOptionsIsLoadingMap(prevState => ({
                      ...prevState,
                      [saveNewBackPanelTitle]: 'none',
                    }))
                  }, 2000)
                }
              },
              onOverwriteTemplate: async () => {
                setHasUpdateSuccess(false)
                setContextZoneOptionsIsLoadingMap(prevState => ({
                  ...prevState,
                  [saveExistingBackPanelTitle]: 'loading',
                }))

                try {
                  await createOrUpdateBackPanel()
                  setHasUpdateSuccess(true)
                } catch (error) {
                  console.error(error)
                  setToasterNotification({
                    iconName: 'close',
                    content: 'Failed to save changes',
                    type: 'danger',
                  })
                  setContextZoneOptionsIsLoadingMap(prevState => ({
                    ...prevState,
                    [saveExistingBackPanelTitle]: 'error',
                  }))
                } finally {
                  window.setTimeout(() => {
                    setContextZoneOptionsIsLoadingMap(prevState => ({
                      ...prevState,
                      [saveExistingBackPanelTitle]: 'none',
                    }))
                  }, 2000)
                }
              },
            },
            backPanelFeatureAccess: {
              hasFeatureAccess: lazyFeatureAccess.hasAccess ?? false,
              shouldShowFeatureModal: shouldShowFeatureAccessModal,
              setShouldShowFeatureModal: () => {
                setShouldShowFeatureAccessModal(true)
              },
              featureAccessModal: (
                <LockedFeatureModal
                  onClose={() => setShouldShowFeatureAccessModal(false)}
                  actions={{
                    mainAction: {
                      execute: () => console.log('clicked main action'),
                      title: 'Unlock This & More Features',
                    },
                    secondaryAction: {
                      execute: () => setShouldShowFeatureAccessModal(false),
                      title: 'Not Now',
                    },
                  }}
                  textContent={{
                    title: 'Unlock Me!',
                    subTitle: 'Custom Back Panels are a Full System Feature.',
                    description:
                      'Upgrade your account from a Basic account to a Monthly Membership or any of our other plans to access this great feature!',
                  }}
                  image={{
                    url: 'https://soc-website-public.s3.us-west-2.amazonaws.com/static/soc-website/images/locked-feature-modal/image-1.png',
                    height: '100%',
                    width: '100%',
                    top: '38px',
                    left: '0px',
                  }}
                  featureAccessToGrant={featureAccessArray}
                />
              ),
            },
          }}
          textScaleRatio={1.25}
          isUsingOldFlattener={false}
          stickerCategories={{
            results:
              stickerCategoriesQuery.data?.pages
                .flatMap(page => page.results)
                .map(stickerCategory => stickerCategory.name) ?? [],
            onSelect: handleSelectStickerCategory,
          }}
        />
        <Div
          borderRadius={{ left: { xSmall: 'large' }, xLarge: 'circle' }}
        ></Div>
      </GlobalEditorProvider>
    )
  }

  return (
    <div
      className="order-editor"
      css={() => styles.container(step.type !== Steps.Idle)}
    >
      {landscapeWarning}
      {errorDialog}
      <FontFace fonts={api.fonts} />
      {isSaving && <Transition message={savingTransitionMessage} />}
      <div css={styles.sidebar}>
        <EditorToolBar
          api={api}
          setShowConfirmVisible={setShowConfirmVisible}
          showConfirmVisible={showConfirmVisible}
          onBackToCatalog={onBackToCatalog}
        />
      </div>
      <div css={styles.drawerContainer}>
        {step.type !== Steps.Idle && <EditorDrawer api={api} />}
      </div>
      <div id={'main'} css={mainCss}>
        {!isMobile && context?.headerText && (
          <ContextBar
            lines={context?.lines}
            title={context?.headerText}
            subtitle={context?.headerSubText}
            activeCardId={card.id}
          />
        )}
        <>
          <div css={styles.headerContainer} id="promo-modal-container">
            {isMobile && (
              <MobileContextBar
                miniCartProps={miniCartProps}
                onExit={() => setShowConfirmVisible(true)}
                cardType={card.type}
                panelLocation={resolveLocation(card.type)}
                isFullBleed={panelView === 'fullbleed'}
                onChangePanelView={() =>
                  setPanelView(panelView === 'panel' ? 'fullbleed' : 'panel')
                }
              />
            )}
            {!isMobile && hasSpanView(card.type) && (
              <Button
                title={panelView === 'fullbleed' ? 'Panel View' : 'Span View'}
                onClick={() =>
                  setPanelView(
                    panelView === 'fullbleed' ? 'panel' : 'fullbleed',
                  )
                }
                style={{
                  position: 'absolute',
                  right: 15,
                  zIndex: 102,
                  ...(hasContextBar || context?.headerText ? { top: 90 } : {}),
                }}
              />
            )}
          </div>
          <EditorTabs
            saveButtonLabel={saveButtonLabel}
            api={api}
            swipeableViewWidth={
              isMobile
                ? '100%'
                : step.type !== Steps.Idle
                  ? 'calc(100vw - 380px)'
                  : 'calc(100vw - 65px)'
            }
          />
        </>
        {!isMobile && card && (
          <Footer css={styles.footer}>
            <EditorFooterContents
              label={context?.footerLabel(card)}
              saveButtonLabel={saveButtonLabel}
              saveButtonBackground={saveButtonBackground}
              saveButtonColor={saveButtonColor}
              onSave={() => {
                handleSaveAndSend().catch(error => console.error(error))
              }}
              miniCartProps={miniCartProps}
            />
          </Footer>
        )}
      </div>

      <AnimatePresence>
        {isMobile && toasterNotification && (
          <ToasterNotification
            backgroundColor={{
              swatch: toasterNotification.type,
              shade: '_500',
            }}
            icon={{
              size: 'xSmall',
              name: toasterNotification.iconName,
              primaryColor: 'inverseHeadingText',
              iconContainerColor: { swatch: 'success', shade: '_400' },
            }}
            label={{
              color: 'inverseHeading',
              type: 'footnote',
              content: toasterNotification.content,
            }}
          />
        )}
      </AnimatePresence>
    </div>
  )
}

export default Editor
