import { Operation } from '../../../__generated__/globalTypes'

import {
  MembershipInviteFragment,
  membershipInviteFragmentString,
} from '../../fragments/__generated__/MembershipInviteFragment'

import getReceivedPendingMembershipInvitesRawString from '../GetReceivedPendingMembershipInvites.graphql'

const getReceivedPendingMembershipInvitesString = [
  getReceivedPendingMembershipInvitesRawString,
  membershipInviteFragmentString,
].join('\n\n')

export type GetReceivedPendingMembershipInvitesData = {
  receivedPendingMembershipInvites: MembershipInviteFragment[]
}

export type GetReceivedPendingMembershipInvites =
  Operation<GetReceivedPendingMembershipInvitesData>

export const GetReceivedPendingMembershipInvites =
  (): GetReceivedPendingMembershipInvites => ({
    query: getReceivedPendingMembershipInvitesString,
    variables: undefined,
  })
