import React from 'react'
import { Flex, Transition } from '@sendoutcards/quantum-design-ui'
import {
  useActions,
  useFlag,
  useMutations,
  usePersistedUserData,
  useQueryParams,
  useRedirectUriBuilder,
  useState,
} from 'src/hooks'

import { AsYouType, CountryCode, formatNumber } from 'libphonenumber-js'
import VerifyPhoneNumberSuccess from './VerifyPhoneNumberSuccess'
import CodeVerification from './CodeVerification'
import PhoneNumberEntry from './PhoneNumberEntry'
import IncorrectCode from './IncorrectCode'
import FinishMyAccount from './FinishMyAccount'
import Result from 'src/utils/Result'
import TimedSuccess from './TimedSuccess'
import { PhoneNumberInput } from 'src/legacy_graphql'
import {
  VerifyPhoneNumberFailureFragment,
  VerifyPhoneNumberSuccessFragment,
} from 'src/graphql/generated/graphql'
import { useFlags, useUpdateAccount } from 'src/react_query'

export const getFormattedNumber = (countryCode: string, text: string) => {
  const formatter = new AsYouType(countryCode as CountryCode)
  const preFormattedNumber = formatter.input(text)

  const finalFormattedNumber = formatter.isValid()
    ? formatNumber(
        formatter.getNationalNumber(),
        countryCode as CountryCode,
        'NATIONAL',
      )
    : preFormattedNumber

  return finalFormattedNumber.endsWith(')')
    ? finalFormattedNumber.slice(0, -1)
    : finalFormattedNumber
}

type Step =
  | { type: 'entry'; phoneNumber?: PhoneNumberInput; failureMessage?: string }
  | { type: 'verification'; phoneNumber: PhoneNumberInput }
  | { type: 'incorrectCode'; phoneNumber: PhoneNumberInput }
  | { type: 'success'; success: VerifyPhoneNumberSuccessFragment }
  | { type: 'finishAccount'; success: VerifyPhoneNumberSuccessFragment }
  | { type: 'timedSuccess'; success: VerifyPhoneNumberSuccessFragment }

const PhoneNumberVerification = (props: {
  loginPhoneNumber: PhoneNumberInput | null
  sponsorGenealogyIdOrSlug?: string
  isCheckingOut?: boolean
  onSuccess: (success: VerifyPhoneNumberSuccessFragment) => void
  onDeclinePhoneNumber?: () => void
}) => {
  const {
    loginPhoneNumber,
    sponsorGenealogyIdOrSlug,
    isCheckingOut,
    onDeclinePhoneNumber,
  } = props

  const actions = useActions()
  const queryParams = useQueryParams()
  const { controlData } = usePersistedUserData()
  const { data: flags } = useFlags()
  const [step, setStep] = useState<Step>(
    loginPhoneNumber && !controlData.isLoggedIn
      ? { type: 'verification', phoneNumber: loginPhoneNumber }
      : {
          type: 'entry',
          phoneNumber: loginPhoneNumber ? loginPhoneNumber : undefined,
        },
  )
  const [formattedNumber, setFormattedNumber] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)
  const [phoneVerificationError, setPhoneVerificationError] = useState<
    VerifyPhoneNumberFailureFragment | undefined
  >(undefined)
  const redirectUriBuilder = useRedirectUriBuilder()
  const handleFailureMessage = (
    failureMessage: string,
    phoneNumberInput: PhoneNumberInput,
  ) => {
    setStep({
      type: 'entry',
      phoneNumber: loginPhoneNumber ?? phoneNumberInput,
      failureMessage,
    })
  }
  const mutations = useMutations()
  const updateAccountMutation = useUpdateAccount()
  const onVerificationRequested = (phoneNumber: PhoneNumberInput) =>
    setStep({ type: 'verification', phoneNumber })

  const getFlags = useFlag(flags ?? [])

  const onRequestVerificationCode = async (
    phoneNumberInput: PhoneNumberInput,
    event?: React.FormEvent<HTMLFormElement>,
  ) => {
    try {
      setFormattedNumber(
        getFormattedNumber(
          phoneNumberInput.countryIsoCode,
          phoneNumberInput.nationalNumber,
        ),
      )
      setIsLoading(true)
      const { requestPhoneNumberVerification: result } =
        await mutations.requestPhoneNumberVerification({
          phoneNumber: phoneNumberInput,
          doNotShowDefaultTransition: true,
        })
      if (result.__typename === 'RequestPhoneNumberVerificationSuccess') {
        onVerificationRequested(phoneNumberInput)
      } else {
        handleFailureMessage(result.failureMessage, phoneNumberInput)
      }
    } catch (error) {
      handleFailureMessage(
        error?.toString() ?? 'Failed to request phone number verification.',
        phoneNumberInput,
      )
    } finally {
      setIsLoading(false)
    }
  }

  const onFailure = (failure: VerifyPhoneNumberFailureFragment) => {
    if (step.type === 'verification') {
      setPhoneVerificationError(failure)
      setStep({
        type: 'incorrectCode',
        phoneNumber: step.phoneNumber,
      })
    }
  }

  const finishAccount = async (
    success: VerifyPhoneNumberSuccessFragment,
    email: string,
    username: string,
    password: string,
    sponsorId: string,
  ) => {
    try {
      setIsLoading(true)
      actions.loginDone(Result(success.verifiedAccount))
      const { updateAccount } = await updateAccountMutation.mutateAsync({
        account: { email, username, password, sponsor: sponsorId },
      })
      actions.updatedAccount(updateAccount.account)
    } catch (error) {
      console.log(error ?? 'Failed to update account.')
    } finally {
      setIsLoading(false)
      actions.openCatalog()
    }
  }

  const onSuccess = (
    success: VerifyPhoneNumberSuccessFragment,
    possibleRedirectUri: string | null,
  ) => {
    if (
      success.verifiedAccount.username &&
      loginPhoneNumber &&
      !controlData.isLoggedIn
    ) {
      if (queryParams.isJoiningAffiliate) {
        actions.loadedUser(Result(success.verifiedAccount))
        return
      }
      actions.loginDone(Result(success.verifiedAccount))
      const redirectUri =
        possibleRedirectUri || redirectUriBuilder(success.verifiedAccount)
      if (redirectUri) {
        window.location.href = redirectUri
      } else {
        actions.openAccount()
      }
    }
    if (isCheckingOut) {
      actions.loadedUser(Result(success.verifiedAccount))
      setStep({ type: 'timedSuccess', success })
    } else if (
      !success.verifiedAccount.isQualifiedForFirstFreeCard &&
      !success.verifiedAccount.username
    ) {
      const { actDisplay: hasAct, disableActDemo: isDemoDisabled } = getFlags
      if (hasAct && isDemoDisabled) {
        actions.loginDone(Result(success.verifiedAccount))
        actions.openAccount()
      }
      setStep({ type: 'finishAccount', success })
    } else if (queryParams.postcardPromo) {
      props.onSuccess(success)
      setStep({ type: 'success', success })
    } else {
      setStep({ type: 'success', success })
    }
  }

  return (
    <Flex
      height="100%"
      width="100%"
      minHeight="500px"
      justifyContent="center"
      alignItems="center"
    >
      {isLoading && (
        <Transition
          isLoading={isLoading}
          title="Sending Code To:"
          subtitle={formattedNumber}
        />
      )}
      {!isLoading && step.type === 'entry' && (
        <PhoneNumberEntry
          requestVerificationCode={onRequestVerificationCode}
          onVerificationRequested={onVerificationRequested}
          phoneNumber={step.phoneNumber}
          failureMessage={step.failureMessage}
          onDeclinePhoneNumber={onDeclinePhoneNumber}
        />
      )}
      {!isLoading && step.type === 'verification' && (
        <CodeVerification
          phoneNumber={step.phoneNumber}
          onSuccess={onSuccess}
          onFailure={onFailure}
          sponsorGenealogyIdOrSlug={sponsorGenealogyIdOrSlug}
          onNeedNewCode={(phoneNumber: PhoneNumberInput) => {
            if (loginPhoneNumber) {
              actions.openPhoneNumberVerification(phoneNumber, true)
            } else {
              setStep({
                type: 'entry',
                phoneNumber,
              })
            }
          }}
        />
      )}
      {step.type === 'incorrectCode' && (
        <IncorrectCode
          phoneNumber={step.phoneNumber}
          getNewCode={phoneNumber =>
            setStep({ type: 'entry', phoneNumber: phoneNumber ?? undefined })
          }
          tryAgain={() =>
            setStep({ type: 'verification', phoneNumber: step.phoneNumber })
          }
          failure={phoneVerificationError}
        />
      )}
      {step.type === 'finishAccount' && (
        <FinishMyAccount finishAccount={finishAccount} success={step.success} />
      )}
      {step.type === 'success' && (
        <VerifyPhoneNumberSuccess
          success={step.success}
          onCompletion={props.onSuccess}
          didVerifyExistingAccount={controlData.isLoggedIn}
        />
      )}
      {step.type === 'timedSuccess' && (
        <TimedSuccess success={step.success} onCompletion={props.onSuccess} />
      )}
    </Flex>
  )
}

export default PhoneNumberVerification
