import React from 'react'
import { LoginForm } from 'src/user/components'
import CreateAccountForm from 'src/dashboard/components/CreateAccountForm'
import { useSelector, useState } from 'src/hooks'
import { Flex, Sheet } from '@sendoutcards/quantum-design-ui'
import FeaturedCardsGrid from 'src/catalog/components/FeaturedCardsGrid/FeaturedCardsGrid'

interface JoinModalProps {
  onAfterSave?: () => void
  onLogin?: () => void
  onClose?: () => void
  initialAction: LoginJoinForgot
  isJoiningAffiliate?: boolean
}

export type LoginJoinForgot = 'login' | 'join' | 'forgot'

const JoinModal: React.FC<JoinModalProps> = props => {
  const { initialAction, onAfterSave, onLogin, onClose, isJoiningAffiliate } =
    props

  const [isJoining, setIsJoining] = useState(initialAction === 'join')

  const [didForgetPassword, setDidForgetPassword] = useState(false)

  const setIsOpen = onClose
    ? (isOpen: boolean) => !isOpen && onClose()
    : undefined

  const isMobile = useSelector(state => state.window.width <= 474)

  return (
    <Flex
      flexDirection="column"
      inset={isMobile ? { horizontal: 'x1', vertical: 'x4' } : 'x4'}
    >
      <Sheet
        inset="x0"
        setIsOpen={setIsOpen}
        isOpen={true}
        backgroundElement={<FeaturedCardsGrid />}
      >
        <div
          style={{ height: '100%', width: '100%' }}
          id="join_modal_container"
        >
          {isJoining ? (
            <CreateAccountForm
              onAfterSave={onAfterSave}
              onToggleJoinLogin={() => setIsJoining(!isJoining)}
              isAffiliateJoining={!!isJoiningAffiliate}
            />
          ) : (
            <Flex
              flexDirection="column"
              inset={{
                horizontal: isMobile ? 'x1' : 'x3',
                vertical: isMobile ? 'x5' : 'x3',
              }}
            >
              <LoginForm
                title="Welcome to SendOutCards"
                didForgetLoginInfo={didForgetPassword}
                setForgotPassword={setDidForgetPassword}
                onCreateAccount={() => setIsJoining(true)}
                isAffiliateJoining={!!isJoiningAffiliate}
                onComplete={onLogin}
              />
            </Flex>
          )}
        </div>
      </Sheet>
    </Flex>
  )
}

export default JoinModal
