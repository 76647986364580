import React from 'react'

import {
  Banner,
  Div,
  Flex,
  Icon,
  useColorSwatch,
} from '@sendoutcards/quantum-design-ui'
import { useActions, useSelector, useState } from 'src/hooks'
import { DefaultError, SuspenseBoundary } from 'src/chrome'
import DropDownBanner from 'src/dashboard/components/DropDownBanner/DropDownBanner'
import { useMarketingBanners } from 'src/react_query'

interface IAnnouncementBannersProps {}

const AnnouncementBanners: React.FC<IAnnouncementBannersProps> = () => {
  return (
    <SuspenseBoundary unresolved={false} failure={DefaultError}>
      <GenerateBannerComponents />
    </SuspenseBoundary>
  )
}

const GenerateBannerComponents: React.FC = () => {
  const account = useSelector(state => state.user.account)
  const actions = useActions()
  const isMobile = useSelector(state => state.window.isMobile)

  const bannersQuery = useMarketingBanners()
  const banners = bannersQuery.data
  const [showPaymentHoldBanner, setShowPaymentHoldBanner] = useState(
    account?.settings?.paymentHold,
  )
  const { grayScale } = useColorSwatch()
  if (!banners || banners.length === 0) {
    return null
  }

  const textStyles = {
    fontFamily: 'Montserrat',
    fontSize: isMobile ? '.75rem' : '0.875rem',
    lineHeight: '1.5',
  }

  const styles = {
    innerBanner: (textColor: string) => ({
      maxHeight: '150px',
      overflow: 'auto',
      p: {
        ...textStyles,
        color: textColor,
      },
      a: {
        ...textStyles,
      },
    }),
  }

  return (
    <div
      style={{
        paddingLeft: isMobile ? '8px' : '12px',
        paddingRight: isMobile ? '8px' : '12px',
        paddingTop: '4px',
        paddingBottom: '4px',
        position: 'relative',
      }}
    >
      <Flex
        position="absolute"
        top="2px"
        left="2px"
        height="24px"
        width="24px"
        justifyContent="center"
        alignItems="center"
        zIndex={1}
        borderRadius="circle"
        style={{ backgroundColor: '#fff', border: '2px solid #44D5A4' }}
      >
        <Icon name="exclamation" primaryColor="#44D5A4" size="small" />
      </Flex>
      {showPaymentHoldBanner && (
        <DropDownBanner
          timeout={30000}
          zIndex={6}
          actionButton={{
            title: 'Update',
            onClick: () => {
              setShowPaymentHoldBanner(false)
              actions.openPaymentSettings()
            },
          }}
          dismissButton={{
            title: 'Dismiss',
            onClick: () => setShowPaymentHoldBanner(false),
          }}
          title="Hello! A recent payment was unsuccessful. Please update your payment settings now to ensure your cards and gifts are sent when expected."
          icon="exclamation"
          status="danger"
          setShowPaymentHoldBanner={setShowPaymentHoldBanner}
        />
      )}
      {banners.map((banner, index) => (
        <Div
          key={banner.title}
          inset={{ vertical: isMobile ? 'x_5' : 'x1' }}
          position="relative"
        >
          <Banner status={'success'}>
            <Flex justifyContent="flex-start" alignItems="center" width="100%">
              <div
                css={styles.innerBanner(grayScale.base)}
                dangerouslySetInnerHTML={{ __html: banner.htmlContent }}
              />
            </Flex>
          </Banner>
        </Div>
      ))}
    </div>
  )
}

export default AnnouncementBanners
