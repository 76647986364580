import { Operation } from '../../../__generated__/globalTypes'

import {
  MarketingParagraphFragment,
  marketingParagraphFragmentString,
} from '../../fragments/__generated__/MarketingParagraphFragment'

import getAffiliateUpsaleMarketingContentRawString from '../GetAffiliateUpsaleMarketingContent.graphql'

const getAffiliateUpsaleMarketingContentString = [
  getAffiliateUpsaleMarketingContentRawString,
  marketingParagraphFragmentString,
].join('\n\n')

export type GetAffiliateUpsaleMarketingContentData = {
  compPlanTrainingLink: {
    __typename: 'MarketingURL'
    url: string
  }
  affiliateUpsellCRCContent: MarketingParagraphFragment
  affiliateUpsellSOCAffiliateContent: MarketingParagraphFragment
  affiliateUpsellSOCAffiliateContentFreeAffiliate: MarketingParagraphFragment
  affiliateUpsellFGSAContent: MarketingParagraphFragment
  affiliateUpsellGSAContent: MarketingParagraphFragment
  affiliateUpsellCRCTitle: MarketingParagraphFragment
  affiliateUpsellSOCAffiliateTitle: MarketingParagraphFragment
  affiliateUpsellFGSATitle: MarketingParagraphFragment
  affiliateUpsellGSATitle: MarketingParagraphFragment
  affiliateUpsellCRCButtonTitle: MarketingParagraphFragment
  affiliateUpsellFGSADButtonTitle: MarketingParagraphFragment
  affiliateUpsellGSAButtonTitle: MarketingParagraphFragment
}

export type GetAffiliateUpsaleMarketingContent =
  Operation<GetAffiliateUpsaleMarketingContentData>

export const GetAffiliateUpsaleMarketingContent =
  (): GetAffiliateUpsaleMarketingContent => ({
    query: getAffiliateUpsaleMarketingContentString,
    variables: undefined,
  })
