import React, { FC } from 'react'
import { useCallback } from 'src/hooks'
import { Div, Flex, Icon, Span } from '@sendoutcards/quantum-design-ui'
import {
  animate,
  AnimatePresence,
  PanInfo,
  useDragControls,
  useMotionValue,
} from 'framer-motion'

export type DuvetProps = {
  onClose: () => void
  openValue: number
  isOpen: boolean
  directionArrow?: {
    direction: 'up' | 'down'
    onClick: () => void
  }
  backgroundColor?: string
  headerOffset?: string
}

export const Duvet: FC<DuvetProps> = ({
  onClose,
  children,
  isOpen,
  openValue,
  directionArrow,
  backgroundColor,
  headerOffset,
}) => {
  const y = useMotionValue(0)
  const safeOpenValue =
    openValue > window.innerHeight ? window.innerHeight : openValue
  const openOffset = window.innerHeight - safeOpenValue

  const dragControls = useDragControls()

  const startDrag = (event: MouseEvent | TouchEvent | PointerEvent) => {
    dragControls.start(event)
  }

  const handleDrag = useCallback(
    (_: MouseEvent, { delta }: PanInfo) => {
      y.set(Math.max(y.get() + delta.y, openOffset))
    },
    [y, openOffset],
  )

  const handleDragEnd = useCallback(
    (_, { velocity }: PanInfo) => {
      if (velocity.y > 500) {
        onClose()
      } else {
        const yOffset = window.innerHeight - y.get()

        const snapTo =
          yOffset / safeOpenValue > 0.4 ? openOffset : window.innerHeight

        animate(y, snapTo, {
          type: 'spring',
          stiffness: 300,
          damping: 30,
          mass: 0.2,
        })
      }
    },
    [y, safeOpenValue, openOffset], // eslint-disable-line
  )

  return (
    <AnimatePresence>
      {isOpen && (
        <Div
          boxShadow="0px 8px 16px 0px rgb(0 0 0 / 10%);"
          borderRadius={{ top: 'large' }}
          key="duvet"
          width={'100%'}
          drag="y"
          transition={{
            stiffness: 300,
            damping: 30,
            mass: 0.2,
          }}
          maxHeight="calc(100dvh - 25px)"
          height="100dvh"
          backgroundColor={backgroundColor ?? 'foreground'}
          dragConstraints={{ top: 0, bottom: window.innerHeight }}
          dragMomentum={false}
          dragElastic={0}
          style={{ y }}
          initial={{ y: window.innerHeight }}
          animate={{ y: openOffset }}
          dragControls={dragControls}
          onDrag={handleDrag}
          onDragEnd={handleDragEnd}
          position="fixed"
          bottom="20px"
          overflow="hidden"
          data-should-drag="true"
          dragListener={false}
        >
          <Flex
            position="fixed"
            top="x2"
            right="x2"
            onClick={onClose}
            justifyContent="center"
            alignItems="center"
            backgroundColor={backgroundColor ?? '#f5f5f5'}
            borderRadius="circle"
            inset=".5rem"
            zIndex={2}
          >
            <Icon name="close" size={14} primaryColor="primaryBodyText" />
          </Flex>
          <Div
            position="absolute"
            top="16px"
            left="50%"
            transform="translateX(-50%)"
            width="100%"
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            zIndex={1}
            data-should-drag="true"
            onTapStart={startDrag}
          >
            <Div
              width="50px"
              height="4px"
              borderRadius="large"
              backgroundColor={{ swatch: 'grayScale', shade: '_1000' }}
              data-should-drag="true"
            />
            {directionArrow && (
              <Span
                cursor="pointer"
                onClick={directionArrow.onClick}
                inset={{ top: 'x_5' }}
                transform={`rotate(${
                  directionArrow.direction === 'up' ? '0deg' : '180deg'
                })`}
              >
                <Icon
                  name="chevron"
                  size="small"
                  primaryColor="default"
                  orientation={'up'}
                />
              </Span>
            )}
          </Div>
          <Div
            position="absolute"
            top={headerOffset}
            left="0px"
            inset={{ horizontal: 'x2' }}
            width="100%"
            height={`${openValue}px`}
            overflow="hidden"
            data-should-drag="true"
          >
            {children}
          </Div>
        </Div>
      )}
    </AnimatePresence>
  )
}
