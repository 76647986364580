import { Operation } from '../../../__generated__/globalTypes'

import shouldDisplayUpsaleTriggerString from '../ShouldDisplayUpsaleTrigger.graphql'

export type ShouldDisplayUpsaleTriggerData = {
  shouldDisplayUpsaleTrigger: {
    shouldDisplayUpsale: boolean
  }
}

export type ShouldDisplayUpsaleTrigger =
  Operation<ShouldDisplayUpsaleTriggerData>

export const ShouldDisplayUpsaleTrigger = (): ShouldDisplayUpsaleTrigger => ({
  query: shouldDisplayUpsaleTriggerString,
  variables: undefined,
})
