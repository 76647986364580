import React from 'react'
import {
  Div,
  LoadingSpinner,
  Text,
  UserCard,
} from '@sendoutcards/quantum-design-ui'
import { AnimatePresence } from 'framer-motion'
import AddressForm from 'src/contacts/components/AddressForm/AddressForm'
import { Dialog } from 'src/design_system/molecules/dialog/Dialog'
import { ToasterNotification } from 'src/editor/components/MobileEditorToolbar/components/ToasterNotification'
import {
  ContactRequestStatusEnum,
  UpdateRequestedContactInput,
} from 'src/graphql/generated/graphql'
import { useEffect, useSelector, useState } from 'src/hooks'
import { CreateContactInput } from 'src/legacy_graphql'
import {
  useContactRequestByToken,
  useGetActRecipientActionScreenContent,
  useRequestPhysicalCard,
  useUpdateRequestedContact,
} from 'src/react_query'
import styles from './actRequestCard.module.scss'
import { Button } from 'src/design_system/components/Button/Button'
import formatLocation from 'src/helpers/formatLocation'

enum Step {
  ContactRequest,
  ContactConfirmation,
  Success,
  Fail,
  Demo,
}

type ActRequestCardProps = {
  digitalCardToken: string
  contactRequestToken: string
  cardImgUrl: string
  onBack: () => void
  isDemo?: boolean
}

// TODO: What if the contact request expired?

const ActRequestCard: React.FC<ActRequestCardProps> = props => {
  const { digitalCardToken, contactRequestToken, cardImgUrl, onBack, isDemo } =
    props

  const { data: recipientActionScreenContent } =
    useGetActRecipientActionScreenContent()
  const marketingData =
    recipientActionScreenContent?.requestPrintedVersionContent

  const isMobile = useSelector(state => state.window.width <= 520)

  const contactRequestQuery = useContactRequestByToken(
    { token: contactRequestToken ?? '' },
    { enabled: !!contactRequestToken },
  )

  const updateRequestedContactMutation = useUpdateRequestedContact()
  const requestPhysicalCardMutation = useRequestPhysicalCard()

  const [headerTitle, setHeaderTitle] = useState(
    marketingData?.title ?? 'Request a Printed Copy',
  )
  const [step, setStep] = useState<Step>(
    isDemo ? Step.Demo : Step.ContactRequest,
  )

  const shouldShowContactRequestError =
    step === Step.ContactRequest &&
    (!contactRequestToken || contactRequestQuery.isError)

  const firstName =
    contactRequestQuery.data?.contact?.firstName ??
    contactRequestQuery.data?.firstName ??
    ''
  const lastName =
    contactRequestQuery.data?.contact?.lastName ??
    contactRequestQuery.data?.lastName ??
    ''

  const initialContact =
    firstName || lastName
      ? {
          firstName,
          lastName,
          address1: null,
          address2: null,
          company: null,
          city: null,
          state: null,
          country: null,
          postalCode: null,
        }
      : undefined

  const onSubmit = async (normalContactInput: CreateContactInput) => {
    const requestedContactInput: UpdateRequestedContactInput = {
      firstName: normalContactInput.firstName,
      lastName: normalContactInput.lastName,
      companyName: normalContactInput.companyName,
      address1: normalContactInput.address1,
      address2: normalContactInput.address2,
      city: normalContactInput.city,
      state: normalContactInput.state,
      postalCode: normalContactInput.postalCode,
      country: normalContactInput.country,
    }

    try {
      await updateRequestedContactMutation.mutateAsync({
        token: contactRequestToken,
        contact: requestedContactInput,
      })
      onSendRequest()
    } catch (e) {
      console.error('Error updating requested contact', e)
      setStep(Step.Fail)
      return
    }
  }

  const onSendRequest = async () => {
    const isRequested = await requestPhysicalCardMutation.mutateAsync({
      digitalCardToken: digitalCardToken,
    })
    if (isRequested) {
      setHeaderTitle('Printed Copy Request Successfully Sent')
      setStep(Step.Success)
    } else {
      console.error('Something went wrong!')
    }
  }

  useEffect(() => {
    if (
      step === Step.ContactRequest &&
      !contactRequestQuery.isLoading &&
      contactRequestQuery.data?.status === ContactRequestStatusEnum.Completed
    ) {
      setStep(Step.ContactConfirmation)
    }
  }, [contactRequestQuery.isLoading, contactRequestQuery.data, step])

  return (
    <Dialog
      isOpen={true}
      onClose={onBack}
      sheetBgColorOverride="#fff"
      mobileOpenHeight={
        step === Step.Success
          ? 'calc(100dvh - 600px)'
          : step === Step.ContactConfirmation
            ? 'calc(100dvh - 700px)'
            : undefined
      }
      wrapperStyle={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <div
        className={styles.dialogInnerWrapper}
        style={{ maxWidth: !isMobile ? '500px' : undefined }}
      >
        <div>
          <div className={styles.cardImgWrapper}>
            <img
              src={cardImgUrl}
              className={styles.cardImg}
              alt={'Sending Cards is AwEsOmE!'}
            />
          </div>
          <div className={styles.title}>
            <Text
              type="subtitle"
              color="primaryHeading"
              alignment="center"
              weight="bold"
            >
              {headerTitle}
            </Text>
          </div>
          <div className={styles.message}>
            <Text type="body" alignment="center">
              {marketingData?.content ??
                'We print and send cards, anywhere in the world, simply provide your address and we do the rest.'}
            </Text>
          </div>
        </div>
        {shouldShowContactRequestError && (
          <Text type="title">{'There was an error loading this page.'}</Text>
        )}
        {step === Step.Demo && (
          <Div
            display="flex"
            flexDirection="column"
            maxWidth="650px"
            zIndex={1}
          >
            <AddressForm
              shouldShowDates={false}
              isTitleVisible={false}
              contact={initialContact}
              confirmButtonText="Send Request"
              onSubmit={onSubmit}
              isSaving={false}
              submitButtonStyles={{
                alignAction: 'flex-end',
                backgroundColorOverride: '#404040',
                isFullWidth: true,
              }}
            />
          </Div>
        )}
        {step === Step.ContactRequest && !shouldShowContactRequestError && (
          <>
            {contactRequestQuery.isLoading ? (
              <LoadingSpinner size="large" />
            ) : (
              <Div
                display="flex"
                flexDirection="column"
                maxWidth="650px"
                zIndex={1}
              >
                <AddressForm
                  shouldShowDates={false}
                  isTitleVisible={false}
                  contact={initialContact}
                  confirmButtonText="Send Request"
                  onSubmit={onSubmit}
                  isSaving={updateRequestedContactMutation.isLoading}
                  submitButtonStyles={{
                    alignAction: 'flex-end',
                    backgroundColorOverride: '#404040',
                    isFullWidth: true,
                  }}
                />
              </Div>
            )}
          </>
        )}
        {step === Step.ContactConfirmation && (
          <div>
            <div className={styles.contactCard}>
              <UserCard
                profileImage={''}
                firstName={firstName}
                lastName={lastName}
                caption={
                  contactRequestQuery.data?.contact
                    ? formatLocation(
                        contactRequestQuery.data?.contact.city,
                        contactRequestQuery.data?.contact.state,
                      )
                    : ''
                }
                size="small"
                active={true}
              />
            </div>
            <Button
              isDisabled={isDemo}
              title={isDemo ? 'Coming Soon' : 'Send Request'}
              backgroundColor="#404040"
              width="100%"
              borderRadius="16px"
              height="54px"
              onClick={onSendRequest}
            />
          </div>
        )}
        {step === Step.Success && (
          <div className={styles.success}>
            <Button title="Done" backgroundColor="#059669" />
          </div>
        )}
        {updateRequestedContactMutation.error && (
          <AnimatePresence>
            <ToasterNotification
              backgroundColor={{
                swatch: 'danger',
                shade: '_500',
              }}
              icon={{
                size: 'xSmall',
                name: 'information',
                primaryColor: 'inverseHeadingText',
                iconContainerColor: { swatch: 'success', shade: '_400' },
              }}
              label={{
                color: 'inverseHeading',
                type: 'footnote',
                content:
                  'There was an error saving your address. Please contact support.',
              }}
            />
          </AnimatePresence>
        )}
      </div>
    </Dialog>
  )
}

export default ActRequestCard
