import React from 'react'
import { CardCategoryFragment } from 'src/legacy_graphql'
import { Div, Flex, Text } from '@sendoutcards/quantum-design-ui'
import { buildYourOwnCatImg64 } from './BuildYourOwnCatImg'

type AllCategoriesMobileProps = {
  categories: (
    | CardCategoryFragment
    | {
        __typename: string
        id: string
        description: string
        cards: never[]
        thumbnail: string
      }
  )[]
  handleBuildYourOwn: () => void
  handleCategory: (id: string) => void
  handleCustomCategory: () => void
}

export const AllCategoriesMobile: React.FC<AllCategoriesMobileProps> = ({
  categories,
  handleBuildYourOwn,
  handleCategory,
  handleCustomCategory,
}) => {
  const sharedStyles = {
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    height: '100%',
    maxHeight: '250px',
  }
  return (
    <Flex flexDirection="row" flexWrap="wrap">
      <Flex width="100%" inset={{ bottom: 'x_75', top: 'x1', left: 'x1' }}>
        <Text content="Cards" weight="bold" type="subtitle" />
      </Flex>
      <Div
        onClick={handleBuildYourOwn}
        style={{
          ...sharedStyles,
          backgroundImage: `url(${buildYourOwnCatImg64})`,
          width: '100%',
          aspectRatio: '2/1',
        }}
      />
      {categories.map(category => {
        const handleOnClick =
          category.id === '3'
            ? handleCustomCategory
            : () => handleCategory(category.id)
        const resolvedCategoryThumbnailUrl = `${category.thumbnail}`
        return (
          <Div
            key={category.id}
            onClick={handleOnClick}
            style={{
              ...sharedStyles,
              backgroundImage: `url(${resolvedCategoryThumbnailUrl})`,
              width: '50%',
              aspectRatio: '1/1',
            }}
          />
        )
      })}
    </Flex>
  )
}
