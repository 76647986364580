import marketingMultiParagraphFragmentRawString from '../MarketingMultiParagraphFragment.graphql'

export const marketingMultiParagraphFragmentString =
  marketingMultiParagraphFragmentRawString

export type MarketingMultiParagraphFragment = {
  __typename: 'MarketingMultiParagraph'
  createdAt: string
  updatedAt: string
  id: string
  title: string
  content: string
}

export const isMarketingMultiParagraphFragment = (
  fragment: any,
): fragment is MarketingMultiParagraphFragment =>
  fragment &&
  fragment.__typename === 'MarketingMultiParagraph' &&
  typeof fragment.createdAt === 'string' &&
  typeof fragment.updatedAt === 'string' &&
  typeof fragment.id === 'string' &&
  typeof fragment.title === 'string' &&
  typeof fragment.content === 'string'
