import { Dialog, Div, Flex, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { cardTypes, getPaperTypes } from 'src/app/constants'
import { useSelector, useState } from 'src/hooks'
import { CardPaperType, CardType } from 'src/graphql/generated/graphql'
import {
  getRouteCardType,
  getRoutePaperType,
} from 'src/redux/selectors/catalog'
import { CardPreviewHeader } from './CardPreview/components/CardPreviewHeader'
import { CardPreviewFooter } from './CardPreview/components/CardPreviewFooter'
import { SendTypeCard } from './CardPreview/components/SendTypeCard'

export type CardPreviewSteps = 'SelectSendType' | 'CardDetails'

type SendTypeToggleProps = {
  onToggle: (isSelected: boolean) => void
  isSelected: boolean
}

const SendTypeToggle = (props: SendTypeToggleProps) => {
  const { isSelected, onToggle } = props

  const handleToggle = () => {
    onToggle(!isSelected)
  }

  return (
    <Flex
      backgroundColor="#d0d0d029"
      justifyContent="space-between"
      alignItems="center"
      borderRadius="circle"
      inset="x_5"
      width="100%"
      maxWidth="150px"
      onClick={handleToggle}
    >
      <Flex
        justifyContent="center"
        alignItems="center"
        backgroundColor={isSelected ? '#fff' : 'transparent'}
        inset={{ vertical: 'x_75', horizontal: 'x1_5' }}
        borderRadius="circle"
        cursor="pointer"
        opacity={isSelected ? 1 : 0.5}
        style={{
          transition: 'background-color 0.3s ease',
          backgroundColor: isSelected ? '#fff' : 'transparent',
        }}
      >
        <Text
          type="footnote"
          content="Instant"
          weight="semiBold"
          style={{ fontFamily: 'Montserrat, san-seriff' }}
        />
      </Flex>
      <Flex
        justifyContent="center"
        alignItems="center"
        backgroundColor={!isSelected ? '#fff' : 'transparent'}
        inset={{ vertical: 'x_75', horizontal: 'x1_5' }}
        borderRadius="circle"
        cursor="pointer"
        opacity={!isSelected ? 1 : 0.5}
        style={{
          transition: 'background-color 0.3s ease',
          backgroundColor: !isSelected ? '#fff' : 'transparent',
        }}
      >
        <Text
          type="footnote"
          content="Printed"
          weight="semiBold"
          style={{ fontFamily: 'Montserrat, san-seriff' }}
        />
      </Flex>
    </Flex>
  )
}

type SendTypeScreenProps = {
  title: string
  imageUrl: string
  cost: string
  isSelected: boolean
  onSelect: (isSelected: boolean) => void
}

const SendTypeScreen = (props: SendTypeScreenProps) => {
  const { title, imageUrl, cost, isSelected, onSelect } = props
  const primaryFont = 'Montserrat, san serif'

  const handleToggle = () => {
    onSelect(!isSelected)
  }

  return (
    <Flex
      position="relative"
      cursor="pointer"
      alignItems="center"
      justifyContent="center"
      inset="1rem"
      rowGap="x1_5"
      backgroundColor={isSelected ? '#fff' : '#c4c4c4d9'}
      flexDirection="column"
      style={{
        borderRadius: '.5rem',
        boxShadow: '0px 4px 28px 0px rgba(0, 0, 0, 0.08)',
        border: '2px solid rgba(233, 233, 233, 0.70)',
        opacity: isSelected ? 1 : 0.6,
      }}
      onClick={handleToggle}
    >
      <Flex
        position="absolute"
        width="fit-content"
        height="fit-content"
        right="6px"
        top="6px"
        backgroundColor={isSelected ? '#f2f2f2' : '#c4c4c4d9'}
        inset={{ horizontal: '.5rem' }}
        style={{ borderRadius: '30px' }}
      >
        <Text
          content={cost}
          type="body"
          style={{
            fontFamily: primaryFont,
            fontSize: '.625rem',
            fontWeight: 700,
            color: '#6B7280',
          }}
        />
      </Flex>
      <img
        src={imageUrl}
        alt="#"
        style={{
          width: '3.25rem',
          height: '4rem',
          boxShadow: '0px 4px 28px 0px rgba(0, 0, 0, 0.08)',
        }}
      />
      <Text
        content={title}
        type="body"
        whiteSpace="pre"
        alignment="center"
        lineHeight={1.8}
        style={{
          fontFamily: primaryFont,
          fontSize: '.625rem',
          fontWeight: 700,
          color: '#404040',
        }}
      />
    </Flex>
  )
}

type PaperTypeTileProps = {
  title: string
  imageUrl: string
  price: string
  onSelect: (isSelected: boolean) => void
  isSelected: boolean
}

const PaperTypeTile = (props: PaperTypeTileProps) => {
  const { title, imageUrl, price, onSelect, isSelected } = props

  const primaryFont = 'Montserrat, san serif'

  const handleSelect = () => {
    onSelect(isSelected)
  }

  return (
    <Div
      cursor="pointer"
      position="relative"
      height="5.25rem"
      width="5.25rem"
      onClick={handleSelect}
      opacity={isSelected ? 1 : 0.7}
      style={{
        backgroundImage: `url(${imageUrl})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        borderRadius: '.25rem',
        boxShadow: '0px 4px 28px 0px rgba(0, 0, 0, 0.08)',
      }}
    >
      <Flex
        position="absolute"
        width="fit-content"
        height="fit-content"
        right="6px"
        top="6px"
        backgroundColor={isSelected ? '#fff' : '#c4c4c4d9'}
        inset={{ horizontal: '.5rem' }}
        style={{ borderRadius: '30px' }}
      >
        <Text
          type="body"
          content={price}
          lineHeight={1.5}
          style={{
            fontFamily: primaryFont,
            fontSize: '.625rem',
            fontWeight: 700,
            color: '#404040',
          }}
        />
      </Flex>
      <Flex
        position="absolute"
        backgroundColor={isSelected ? '#fff' : '#c4c4c4d9'}
        justifyContent="center"
        bottom="0px"
        left="0px"
        right="0px"
        style={{
          margin: 'auto',
          borderBottomLeftRadius: '.25rem',
          borderBottomRightRadius: '.25rem',
        }}
      >
        <Text
          type="body"
          content={title}
          lineHeight={2.5}
          style={{
            fontFamily: primaryFont,
            fontSize: '.625rem',
            fontWeight: 700,
            color: '#404040',
          }}
        />
      </Flex>
    </Div>
  )
}

type CardTypeTileProps = {
  title: string
  isSelected: boolean
  imageUrl: string
  onSelect: (isSelected: boolean) => void
}

const CardTypeTile = (props: CardTypeTileProps) => {
  const { title, isSelected, imageUrl, onSelect } = props
  const primaryFont = 'Montserrat, san serif'

  const handleSelect = () => {
    onSelect(isSelected)
  }

  return (
    <Flex
      cursor="pointer"
      width="fit-content"
      height="fit-content"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      backgroundColor={isSelected ? '#fff' : '#0000000D'}
      inset={{ vertical: '.75rem', horizontal: '1rem' }}
      onClick={handleSelect}
      style={{
        borderRadius: '.25rem',
        boxShadow: isSelected
          ? '0px 4px 28px 0px rgba(0, 0, 0, 0.08)'
          : '0px 0px 34px 0px rgba(0, 0, 0, 0.08) inset',
        border: isSelected ? '2px solid rgba(233, 233, 233, 0.50)' : undefined,
      }}
    >
      <img
        src={imageUrl}
        alt="#"
        style={{
          width: '3.25rem',
          height: '4rem',
          boxShadow: '0px 4px 28px 0px rgba(0, 0, 0, 0.08)',
        }}
      />
      <Text
        type="body"
        content={title}
        style={{
          fontFamily: primaryFont,
          fontSize: '.625rem',
          fontWeight: 700,
          color: '#6B7280',
        }}
      />
    </Flex>
  )
}

type CardPreviewProps = {
  initialStep: CardPreviewSteps
  onCardTypeSelect?: (selectedCardType: CardType) => void
  onPaperTypeSelect?: (selectedPaperType: CardPaperType) => void
  onStepChange?: (nextStep: CardPreviewSteps) => void
  cardImageUrl: string
  onClose: () => void
  onComplete: () => void
  onChangeSendType: () => void
  isDigitalSend: boolean
  isDemo?: boolean
}

export const DemoCardPreviewFlow = (props: CardPreviewProps) => {
  const {
    initialStep = 'SelectSendType',
    onCardTypeSelect,
    onPaperTypeSelect,
    onStepChange,
    cardImageUrl,
    onComplete,
    onClose,
    onChangeSendType,
    isDigitalSend,
    isDemo,
  } = props
  const primaryFont = 'Montserrat, san serif'

  const routeCardType = useSelector(getRouteCardType)
  const routePaperType = useSelector(getRoutePaperType)

  const [selectedCardType, setSelectedCardType] = useState(
    routeCardType ?? CardType.TwoPanel,
  )
  const [selectedPaperType, setSelectedPaperType] = useState(
    routePaperType ?? CardPaperType.Std,
  )
  const [currentStep, setCurrentStep] = useState<CardPreviewSteps>(initialStep)

  const handleSelectCardType = (value: CardType) => {
    setSelectedCardType(value)
    onCardTypeSelect?.(value)
  }

  const handleSelectPaperType = (value: CardPaperType) => {
    setSelectedPaperType(value)
    onPaperTypeSelect?.(value)
  }

  const goToNextStep = () => {
    if (isDemo) {
      onClose()
      return
    }
    if (currentStep === 'CardDetails') {
      if (onComplete) {
        onComplete()
      }
    } else {
      const nextStep = 'CardDetails'
      setCurrentStep(nextStep)
      onStepChange?.(nextStep)
    }
  }

  const goBackStep = () => {
    switch (currentStep) {
      case 'CardDetails':
        setCurrentStep('SelectSendType')
        onStepChange?.('SelectSendType')
        break
      default:
        setCurrentStep('SelectSendType')
        onStepChange?.('SelectSendType')
    }
  }

  const cardTypeData = cardTypes

  const paperUpgradeCost = selectedCardType === CardType.TwoPanelBig ? 150 : 50

  const formattedPaperUpgrade = (() => {
    const paperCostString = paperUpgradeCost.toString()

    return paperUpgradeCost < 100
      ? `${paperUpgradeCost}¢`
      : `$${paperCostString.slice(0, 1)}.${paperCostString.slice(1)}`
  })()

  const paperTypes = getPaperTypes(selectedCardType).map(type => ({
    ...type,
    upgrade: type.upgrade ? formattedPaperUpgrade : type.upgrade,
  }))

  const filteredCardTypeData = isDigitalSend
    ? cardTypeData.filter(data =>
        ['Flat Card', '2-Panel', '3-Panel'].includes(data.label),
      )
    : cardTypeData

  const sendType = isDigitalSend
    ? {
        title: 'Immediate Delivery\nDigital Card',
        cost: '$1',
        imageUrl: cardImageUrl,
      }
    : {
        title: 'Standard Physical\nShipping Card',
        cost: '$2.50',
        imageUrl: cardImageUrl,
      }

  const stepContent = {
    SelectSendType: (
      <Flex flexDirection="column">
        <Text
          type="body"
          content="Select Send Type:"
          style={{
            fontFamily: primaryFont,
            fontSize: '.625rem',
            fontWeight: 500,
            color: '#6B7280',
          }}
        />
        <Div display="grid" style={{ gridTemplateColumns: 'repeat(2, 150px)' }}>
          <SendTypeScreen
            onSelect={onChangeSendType}
            title={'Immediate Delivery\nDigital Card'}
            imageUrl={cardImageUrl}
            isSelected={isDigitalSend}
            cost="$1"
          />
          <SendTypeScreen
            onSelect={onChangeSendType}
            title={'Standard Physical\nShipping Card'}
            imageUrl={cardImageUrl}
            isSelected={!isDigitalSend}
            cost="$2.50"
          />
        </Div>
      </Flex>
    ),
    CardDetails: (
      <Flex width="100%" flexDirection="column" rowGap="x2">
        <Flex width="100%" justifyContent="center">
          <SendTypeToggle
            onToggle={onChangeSendType}
            isSelected={isDigitalSend}
          />
        </Flex>
        <Flex flexDirection="column">
          <Text
            type="body"
            content="Send Type:"
            style={{
              fontFamily: primaryFont,
              fontSize: '.625rem',
              fontWeight: 500,
              color: '#6B7280',
            }}
          />
          <SendTypeCard {...sendType} />
        </Flex>
        <Flex flexDirection="column">
          <Text
            type="body"
            content="Card Type:"
            style={{
              fontFamily: primaryFont,
              fontSize: '.625rem',
              fontWeight: 500,
              color: '#6B7280',
            }}
          />
          <Div
            display="grid"
            justifyItems="center"
            width="100%"
            rowGap="x1"
            style={{ gridTemplateColumns: 'repeat(3, 100px)' }}
          >
            {filteredCardTypeData.map(cardType => (
              <CardTypeTile
                key={cardType.value}
                title={cardType.label}
                imageUrl={cardImageUrl}
                isSelected={selectedCardType === cardType.value}
                onSelect={() => handleSelectCardType(cardType.value)}
              />
            ))}
          </Div>
        </Flex>
        {!isDigitalSend && (
          <Flex flexDirection="column">
            <Text
              type="body"
              content="Choose Paper Type"
              style={{
                fontFamily: primaryFont,
                fontSize: '.625rem',
                fontWeight: 500,
                color: '#6B7280',
              }}
            />
            <Div
              display="grid"
              justifyItems="center"
              width="100%"
              style={{ gridTemplateColumns: 'repeat(3, 100px)' }}
            >
              {paperTypes.map(paperType => (
                <PaperTypeTile
                  key={paperType.value}
                  title={paperType.label}
                  price={paperType.upgrade ?? ''}
                  imageUrl={cardImageUrl}
                  isSelected={selectedPaperType === paperType.value}
                  onSelect={() => handleSelectPaperType(paperType.value)}
                />
              ))}
            </Div>
          </Flex>
        )}
      </Flex>
    ),
  }

  return (
    <Dialog isOpen={true}>
      <Flex
        position="absolute"
        top="50%"
        left="50%"
        width="345px"
        inset={'1rem'}
        backgroundColor="#fff"
        flexDirection="column"
        rowGap="x2"
        style={{
          borderRadius: '1rem',
          boxShadow: '0px 4px 28px 0px rgba(0, 0, 0, 0.08)',
          transform: 'translate(-50%,-50%)',
        }}
      >
        <CardPreviewHeader title="Customize Card Options" onClose={onClose} />
        {stepContent[currentStep]}
        <CardPreviewFooter
          primaryAction={{
            title: isDemo ? 'Coming Soon' : 'Continue',
            onClick: goToNextStep,
          }}
          secondaryAction={{
            title: 'Back',
            onClick: goBackStep,
          }}
        />
      </Flex>
    </Dialog>
  )
}
