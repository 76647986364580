import React, { FC, useRef } from 'react'
import {
  AccountInput,
  StripeSourceFragment,
} from 'src/graphql/generated/graphql'
import {
  Div,
  Flex,
  LoadingSpinner,
  Icon as QIcon,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import { MethodCard } from 'src/upsale/components/checkout/payment/MethodCard'
import { useAccount, useActions, useState } from 'src/hooks'
import { creditCardBrand } from 'src/payments/components/CardLogo/CreditCardLogo'
import { PaymentFormDisplay } from 'src/orders/components/OrderPaymentMethod/OrderPaymentMethod'
import CreditCardForm from 'src/payments/components/CreditCardForm/CreditCardForm'
import { noop } from 'src/helpers/appHelpers'
import { useShouldVerifyEmail, useUpdateAccount } from 'src/react_query'
import { parseError } from 'src/utils/parseError'
import { Token } from '@stripe/stripe-js'
import { Button } from 'src/design_system/molecules/button/Button'

export type PaymentMethodProps = {
  cardHolderName: string
  handlePaymentMethod: () => void
  paymentMethod?: StripeSourceFragment
  paymentFormDisplay?: PaymentFormDisplay
}

export const PaymentMethod: FC<PaymentMethodProps> = ({
  cardHolderName,
  paymentFormDisplay,
}) => {
  const submitCardRef = useRef<HTMLButtonElement>(null)

  const [isLoading, setIsLoading] = useState(false)
  const [isFormComplete, setIsFormComplete] = useState(false)
  const [CCFormError, setCCFormError] = useState<string | undefined>()
  const [isRemovingCard, setIsRemovingCard] = useState(false)

  const updateAccountMutation = useUpdateAccount()
  const actions = useActions()
  const account = useAccount()

  const { data: shouldVerifyEmail } = useShouldVerifyEmail()

  const stripeSource = account.stripeSource
  const shouldVerifyUser =
    !account.isEmailVerified &&
    !account.verifiedPhoneNumber &&
    shouldVerifyEmail

  const updateAccount = async (
    data: AccountInput,
    success: () => void = noop,
    error: (error: Error | string) => void = noop,
  ) => {
    try {
      const {
        updateAccount: { account },
      } = await updateAccountMutation.mutateAsync({
        account: { ...data },
      })
      actions.updatedAccount(account)
      success()
    } catch (err) {
      error(parseError(err))
    }
  }

  const setCard = (stripeToken: Token) => {
    setIsLoading(true)
    updateAccount({ stripeToken: stripeToken.id }, () => {
      setIsLoading(false)
      paymentFormDisplay?.setShouldShowPaymentMethodForm(false)
    })
  }

  const addCard = () => {
    submitCardRef.current?.click()
  }

  const removeCard = () => {
    setIsRemovingCard(true)
    updateAccount({ stripeToken: null }, () => {
      setIsRemovingCard(false)
      paymentFormDisplay?.setShouldShowPaymentMethodForm(true)
    })
  }
  return (
    <>
      {stripeSource && stripeSource.__typename === 'CreditCard' && (
        <>
          {isRemovingCard ? (
            <>
              <Div display="flex" justifyContent="center">
                <LoadingSpinner size="medium" />
              </Div>
              <Spacer space="x1" />
              <Text type="body" alignment="center">
                Removing Card
              </Text>
            </>
          ) : (
            <>
              <MethodCard
                title="Payment"
                last4={stripeSource.last4}
                type={creditCardBrand(stripeSource.brand)}
                name={cardHolderName}
                exp={`${stripeSource.expMonth}/${stripeSource.expYear}`}
              />
              <Div outset={{ left: 'auto' }}>
                <Button
                  fill="#f5f6f7"
                  gap="x_5"
                  padding={'12px 16px'}
                  borderRadius="small"
                  title={{ content: 'Update Card', fontSize: '12px' }}
                  onClick={removeCard}
                />
              </Div>
            </>
          )}
        </>
      )}
      {paymentFormDisplay?.shouldShowPaymentForm && !stripeSource && (
        <>
          <Spacer orientation="vertical" space="x2" />
          <CreditCardForm
            onSuccess={token => setCard(token)}
            onError={err => setCCFormError(err)}
            isCCFormComplete={isComplete => {
              setIsFormComplete(isComplete)
            }}
            isLoading={isLoading}
            shouldValidateEmail={shouldVerifyUser}
          >
            <button
              form="addCreditCardForm"
              type="submit"
              ref={submitCardRef}
              style={{ display: 'none' }}
            />
            <Button
              fill="#f5f6f7"
              gap="x_5"
              padding={'12px 16px'}
              borderRadius="small"
              onClick={addCard}
              isDisabled={!isFormComplete}
              title={{ content: 'Add Card', fontSize: '12px' }}
            />
          </CreditCardForm>
          {CCFormError && (
            <Text
              type="caption"
              color="danger"
              content={CCFormError}
              inset={{ top: 'x_5' }}
            />
          )}
        </>
      )}
      {!paymentFormDisplay?.shouldShowPaymentForm && !stripeSource && (
        <Flex
          backgroundColor="foreground"
          flexDirection="row"
          width="100%"
          borderRadius="default"
          justifyContent="center"
          alignItems="center"
          inset="x3"
          style={{ outline: '2px solid black' }}
          onClick={() =>
            paymentFormDisplay?.setShouldShowPaymentMethodForm(
              !paymentFormDisplay.shouldShowPaymentForm,
            )
          }
          cursor="pointer"
        >
          <QIcon
            size="small"
            name="creditCard"
            primaryColor="primaryHeadingText"
          />
          <Spacer space="x1" orientation="horizontal" />
          <Text
            type="body"
            weight="semiBold"
            content="Add Payment Method"
            color="primaryHeading"
            style={{ textDecoration: 'underline' }}
          />
        </Flex>
      )}
    </>
  )
}
