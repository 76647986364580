import { ConfirmDialog, Flex, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { CardLineItemCallbacksType } from 'src/orders/components/CardLineItem/CardLineItem'
import { PartialLine } from 'src/helpers/multitouch'
import { useActions, useSelector, useState } from 'src/hooks'
import OrderItem from '../OrderItem/OrderItem'
import { Portal } from 'src/portal/portal'
import {
  HOCSpacingKeys,
  LiteralUnion,
  SpacingDirection,
} from '@sendoutcards/quantum-design-ui/dist/src/helpers/hoc-types/entityValueTypes'
import { RemoveOrderItemDialog } from 'src/contacts/components/RemoveOrderItemDialog/RemoveOrderItemDialog'
import { OrderApiType } from 'src/orders/api'
import { Button } from 'src/design_system/molecules/button/Button'
import OrderTotal from '../OrderTotal/OrderTotal'
import { useFeatureAccess } from 'src/hooks/useFeatureAccess'
import { LockedFeatureModal } from 'src/LockedFeatureModal/LockedFeatureModal'
import {
  CardFragment,
  CardPaperType,
  FullAccessFeatureType,
} from 'src/graphql/generated/graphql'

type GiftItemCallbacks = {
  onAddGift: (line: PartialLine, index: number) => void
  onChangeGift: (line: PartialLine, index: number) => void
  onRemoveGift: (line: PartialLine) => void
}

export type OrdersSectionPropType = {
  onAddAnotherCard?: () => void
  cardItem: CardLineItemCallbacksType
  giftItem: GiftItemCallbacks
  onAddCard: (line: PartialLine, index: number) => void
  onRemoveCard: (line: PartialLine) => void
  onRemoveLine: (line: PartialLine) => void
  inset?: SpacingDirection | LiteralUnion<HOCSpacingKeys>
  onChangeCard: (card: CardFragment) => void
  onConvertToDigital: () => void
  saveAs?: () => void
  isSaving?: boolean
  api?: OrderApiType
}

export type RemoveItemType = 'card' | 'gift' | undefined

export const OrdersSection: React.FC<OrdersSectionPropType> = ({
  cardItem,
  giftItem,
  onAddAnotherCard,
  onAddCard,
  onRemoveCard,
  onRemoveLine,
  onConvertToDigital,
  saveAs,
  isSaving,
  api,
}) => {
  const { order, activeLine } = useSelector(state => state.orders)
  const account = useSelector(state => state.user.account)
  const actions = useActions()

  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false)
  const [isRemoveCardAlertVisible, setIsRemoveCardAlertVisible] =
    useState(false)
  const [removeItemType, setRemoveItemType] =
    useState<RemoveItemType>(undefined)
  const [lineToRemove, setLineToRemove] = useState<PartialLine | null>(null)

  const tabletBreakpoint = 840

  const isMobile = useSelector(state => state.window.width < 625)
  const isSmallMobile = useSelector(state => state.window.width < 426)
  const isTablet = useSelector(state => state.window.width < tabletBreakpoint)
  const campaignStoreFeatureArray = [FullAccessFeatureType.CampaignStoreAccess]
  const scheduleSendsFeatureArray = [FullAccessFeatureType.CardScheduling]
  const multiCardSendFeatureArray = [FullAccessFeatureType.CardMultiSends]
  const { lazyFeatureAccess: lazyCampaignAccess } = useFeatureAccess(
    campaignStoreFeatureArray,
  )
  const { lazyFeatureAccess: lazyScheduledSendsAccess } = useFeatureAccess(
    scheduleSendsFeatureArray,
  )
  const { lazyFeatureAccess: lazyMultiCardSendAccess } = useFeatureAccess(
    multiCardSendFeatureArray,
  )

  const hasFulfilledAllLineItemRequirements = order?.lines.every(
    line => line.card,
  )

  const isInvalidCampaign =
    !order?.returnAddress ||
    !order.lines.length ||
    !hasFulfilledAllLineItemRequirements ||
    (!order.isBulk && order.invalidSendDelayContacts.length > 0) ||
    (order.isBulk && !order.bulkUpgradeShippingAddress)

  const toggleRemoveAlert = (event?: React.MouseEvent, line?: PartialLine) => {
    if (event) {
      event.stopPropagation()
    }

    setIsRemoveCardAlertVisible(!isRemoveCardAlertVisible)
    setLineToRemove(line ?? null)
  }

  const handleRemoveLine = () => {
    if (!order) {
      return
    }

    if (order.lines.length > 1) {
      if (lineToRemove) {
        onRemoveLine(lineToRemove)
      }
    } else {
      actions.clearOrder()
      actions.openCatalog()
    }

    setIsRemoveCardAlertVisible(false)
    setLineToRemove(null)
  }

  const handleRemoveItemFromLine = (
    removeItemType: RemoveItemType,
    line: PartialLine,
    index: number,
  ) => {
    setRemoveItemType(removeItemType)
    actions.setActiveLine(line, index)
    setIsRemoveModalOpen(true)
  }

  // You may not want to block the entire UI, this is just a placeholder
  // If needed add a logical check inside the container to render a modal or other UI
  // EG !lazyFeatureAccess.hasAccess && <BlockingUI/>
  if (
    !lazyCampaignAccess.hasAccess ||
    !lazyScheduledSendsAccess.hasAccess ||
    !lazyMultiCardSendAccess.hasAccess
  ) {
    return (
      <LockedFeatureModal
        onClose={() =>
          !lazyCampaignAccess.hasAccess
            ? lazyCampaignAccess.disable()
            : !lazyScheduledSendsAccess.hasAccess
              ? lazyScheduledSendsAccess.disable()
              : !lazyMultiCardSendAccess.hasAccess
                ? lazyMultiCardSendAccess.disable()
                : undefined
        }
        actions={{
          mainAction: {
            execute: () => console.log('clicked main action'),
            title: 'Unlock This & More Features',
          },
          secondaryAction: !lazyCampaignAccess.hasAccess
            ? {
                execute: () => lazyCampaignAccess.disable(),
                title: 'Not Now',
              }
            : !lazyScheduledSendsAccess.hasAccess
              ? {
                  execute: () => lazyScheduledSendsAccess.disable(),
                  title: 'Not Now',
                }
              : !lazyMultiCardSendAccess.hasAccess
                ? {
                    execute: () => lazyMultiCardSendAccess.disable(),
                    title: 'Not Now',
                  }
                : undefined,
        }}
        textContent={{
          title: 'Unlock Me!',
          subTitle: !lazyCampaignAccess.hasAccess
            ? 'The Campaign Manager is a Full System Feature.'
            : !lazyScheduledSendsAccess.hasAccess
              ? 'The Card Schedule Manager is a Full System Feature.'
              : !lazyMultiCardSendAccess.hasAccess
                ? 'The Multiple Card Send Manager is a Full System Feature.'
                : undefined,
          description:
            'Upgrade your account from a Basic account to a Monthly Membership or any of our other plans to access this great feature!',
        }}
        image={{
          url: 'https://soc-website-public.s3.us-west-2.amazonaws.com/static/soc-website/images/locked-feature-modal/image-1.png',
          height: '100%',
          width: '100%',
          top: '38px',
          left: '0px',
        }}
        featureAccessToGrant={
          !lazyCampaignAccess.hasAccess
            ? campaignStoreFeatureArray
            : !lazyScheduledSendsAccess.hasAccess
              ? scheduleSendsFeatureArray
              : !lazyMultiCardSendAccess.hasAccess
                ? multiCardSendFeatureArray
                : []
        }
      />
    )
  }

  return (
    <Flex
      borderRadius="large"
      flexDirection="column"
      width="100%"
      backgroundColor="#f5f6f7"
    >
      <Text
        inset={{ left: 'x3', top: 'x3' }}
        type={isMobile ? 'largeBody' : 'subtitle'}
        weight="bold"
        content="Your Card(s) & Gift(s)"
        color="primaryHeading"
      />
      {isRemoveModalOpen && activeLine && (
        <Portal>
          <RemoveOrderItemDialog
            itemType={removeItemType}
            setIsOpen={() => setIsRemoveModalOpen(false)}
            isOpen={isRemoveModalOpen}
            image={
              removeItemType === 'card'
                ? activeLine.card?.frontPreviewUrl
                  ? {
                      url: activeLine.card.frontPreviewUrl,
                      isHorizontal: activeLine.card?.isHorizontal,
                    }
                  : undefined
                : activeLine.giftVariation?.defaultImageUrl
                  ? { url: activeLine.giftVariation?.defaultImageUrl }
                  : undefined
            }
            title={`Remove ${removeItemType} from order?`}
            subtitle={`Are you sure you want to remove this ${removeItemType} item from your order?`}
            onConfirm={() => {
              if (removeItemType === 'card') onRemoveCard(activeLine)
              else if (removeItemType === 'gift')
                giftItem.onRemoveGift(activeLine)

              setIsRemoveModalOpen(false)
              setRemoveItemType(undefined)
            }}
            onCancel={() => {
              setIsRemoveModalOpen(false)
            }}
          />
        </Portal>
      )}
      {order &&
        order.lines.map((line, index) => {
          return (
            <>
              <OrderItem
                id={`order_line_${index + 1}`}
                orderLine={line}
                lineIndex={index}
                cardItem={{
                  onEditCard: () => {
                    actions.setActiveLine(line, index)
                    api?.setIsShowingCardPreview(true)
                  },
                  onUpdatePaperType: (
                    paperType: CardPaperType,
                    card: CardFragment,
                  ) => {
                    actions.setActiveLine(line, index)
                    cardItem.onUpdatePaperType?.(paperType, card)
                  },
                  onToggleSendDelay: () => {
                    const hasAccess = lazyScheduledSendsAccess.enable()
                    if (hasAccess) {
                      actions.setActiveLine(line, index)
                      cardItem.onToggleSendDelay?.()
                    }
                  },
                  onRemoveCard: () =>
                    handleRemoveItemFromLine('card', line, index),
                  onReplaceCard: cardItem.onReplaceCard,
                }}
                giftItem={{
                  lineIndex: index,
                  onChangeGift: () => {
                    actions.setActiveLine(line, index)
                    giftItem.onChangeGift(line, index)
                  },
                  onRemoveGift: () =>
                    handleRemoveItemFromLine('gift', line, index),
                }}
                title={`Card ${index + 1}`}
                onAddCard={() => onAddCard(line, index)}
                onAddGift={() => giftItem.onAddGift(line, index)}
                onRemoveLineAlert={e => toggleRemoveAlert(e, line)}
                order={order}
                shouldNotAllowRemoval={order.lines.length < 2}
              />
            </>
          )
        })}
      <Flex
        width={isMobile ? '100%' : 'unset'}
        alignItems={isMobile ? 'center' : undefined}
        flexDirection={isMobile ? 'column' : 'row'}
        columnGap="x2"
        rowGap="x2"
        justifyContent={isTablet ? 'center' : 'flex-start'}
        inset={{ left: 'x3', top: 'x3', bottom: 'x4' }}
        backgroundColor="#f5f6f7"
      >
        <Button
          id="add_another_card_btn"
          title={{
            content: 'Add Another Card',
            fontSize: isSmallMobile ? '13px' : undefined,
            fontColor: '#fff',
          }}
          onClick={() => {
            const hasAccess = lazyMultiCardSendAccess.enable()

            if (hasAccess) {
              onAddAnotherCard?.()
            }
          }}
          fill="#323334"
          padding="16px 36px"
          borderRadius="small"
        />
        {!isInvalidCampaign && account?.username && (
          <Button
            id={'save_as_campaign_btn'}
            title={{
              content: 'Save as a Campaign',
              fontSize: isSmallMobile ? '13px' : undefined,
            }}
            onClick={() => {
              const hasAccess = lazyCampaignAccess.enable()

              if (hasAccess) {
                saveAs?.()
              }
            }}
            fill="#000"
            padding="14px 36px"
            borderRadius="small"
            isOutlined={true}
            isDisabled={isSaving}
          />
        )}
        {api?.canShowDigitalOrderOptions && (
          <Button
            id={'convert_to_digital_btn'}
            title={{
              content: 'Convert to Digital Card',
              fontSize: isSmallMobile ? '13px' : undefined,
            }}
            onClick={() => onConvertToDigital()}
            fill="#000"
            padding="14px 36px"
            borderRadius="small"
            isOutlined={true}
            isDisabled={isSaving}
          />
        )}
      </Flex>
      <OrderTotal order={order} api={api} />
      {isRemoveCardAlertVisible && lineToRemove && (
        <Portal>
          <ConfirmDialog
            title={'Remove card from order?'}
            description={
              'Are you sure you want to remove this item from your order?'
            }
            isOpen={isRemoveCardAlertVisible}
            accept={{
              title: 'Yes',
              onClick: () => {
                handleRemoveLine()
                toggleRemoveAlert()
              },
            }}
            decline={{
              title: 'No',
              onClick: () => toggleRemoveAlert(),
            }}
          />
        </Portal>
      )}
    </Flex>
  )
}
