import React from 'react'
import { useState } from 'src/hooks'
import { Div, Flex, Text } from '@sendoutcards/quantum-design-ui'
import { DemoCardPreviewFlow } from './ThrowOutDemoCardPreview'

const demoCards = [
  'https://www.sendoutcards.com/thumbnail/ML7oBhwi4fwpxDnbNAfH6E7IP5k=/fit-in/700x0/system_images/2024/8/8/486d0ecc-ea1e-4dee-9f1c-a14a2c756a14.png',
  'https://www.sendoutcards.com/thumbnail/ulKra0LiPlvzhHStODKuIKbMfY8=/fit-in/700x0/system_images/2022/6/29/ea1ed404-e402-443d-9fbb-b15dcd08d23c.png',
  'https://www.sendoutcards.com/thumbnail/lzrbZDQwXPg_d8WM_HytYhUlrks=/fit-in/700x0/system_images/2022/6/29/d6897a48-af78-4a92-8cc6-1a8bd638c351.png',
  'https://www.sendoutcards.com/thumbnail/93MqyC8wPcy0zweYjGTSkHM-wrY=/fit-in/700x0/system_images/2025/1/24/2f8c7d35-d9b5-499c-a036-afface653810.png',
  'https://www.sendoutcards.com/thumbnail/U3x7H-sVb_UNjY_9c9JWfeQl4l0=/fit-in/700x0/system_images/2022/2/10/25c3e25a-4c00-480c-b791-0d979e5822dd.png',
  'https://www.sendoutcards.com/thumbnail/umFOAvrD-WNACOAUs45RlKZtZcQ=/fit-in/700x0/system_images/2024/8/8/c8ea132e-2773-48e5-b2d3-99438980d6e6.png',
  'https://www.sendoutcards.com/thumbnail/yFY0N5n9NW6Z5owXv9IYqk_tYVE=/fit-in/320x0/system_images/2023/2/15/a8d2a441-10e8-4201-a846-0772ee0abd71.png',
  'https://www.sendoutcards.com/thumbnail/mRpuocny-swq8ra1Ix0Spd1COt4=/fit-in/700x0/system_images/2020/6/25/87c4be8f-6248-41c5-af6d-3762af8d9362.png',
  'https://www.sendoutcards.com/thumbnail/ipL2GYZW8A3kcLyp-IszMhnJecQ=/fit-in/700x0/high_res_catalog_image/2016/8/30/0/type_love_front.png',
  'https://www.sendoutcards.com/thumbnail/s0t-1v_dnw5lBcmbap_N2tPOnzY=/fit-in/700x0/system_images/2024/8/8/ccc27971-fa5b-42de-8874-238f4dd1bacb.png',
  'https://www.sendoutcards.com/thumbnail/broaImC_1fTk_yWPe3u6rYFJqPE=/fit-in/700x0/system_images/2022/9/28/02a2fc96-0dd8-452b-9418-7df1f1407694.png',
  'https://www.sendoutcards.com/thumbnail/o7iZT3dL24W-QwWn0_W-DdQCCVM=/fit-in/700x0/system_images/2019/12/4/d7df5858-049c-46a5-be22-7d89a40a76fc.png',
  'https://www.sendoutcards.com/thumbnail/sPFD8ufPzbrJMN56hS40zYMQAyM=/fit-in/700x0/system_images/2019/12/4/e0ddd26f-f318-4c79-b5b0-b82b395581f5.png',
]

type DemoCatalogType = {
  onClose: () => void
}

export const ThrowOutDemoCatalog = ({ onClose }: DemoCatalogType) => {
  const [selectedCard, setSelectedCard] = useState('')
  const [isDigitalCard, setIsDigitalCard] = useState(true)
  const handleCloseCatalog = () => {
    setSelectedCard('')
    onClose()
  }
  return (
    <Flex
      zIndex={1000}
      position="absolute"
      top="0"
      left="0"
      width="100vw"
      height="100dvh"
      backgroundColor="foreground"
      justifyContent="center"
      alignItems="center"
      overflowY="auto" // Enable scrolling on the main container
      flexDirection={'column'}
      style={{ background: '#f6f6f6', paddingTop: '90px' }}
    >
      <Flex
        justifyContent="space-between"
        inset={{ horizontal: 'x2' }}
        alignItems="center"
        width="100%"
        height="60px"
        backgroundColor="foreground"
        position="absolute"
        top="0"
      >
        <Flex
          style={{ padding: '4px 16px' }}
          backgroundColor={'background'}
          borderRadius="circle"
          onClick={handleCloseCatalog}
          cursor="pointer"
        >
          <Text type="caption" weight="bold" style={{ color: '#404040' }}>
            Back
          </Text>
        </Flex>
        <Flex>
          <Text type="body" weight="bold" style={{ color: '#404040' }}>
            Choose a Card
          </Text>
        </Flex>
        <Div width="50px"></Div>
      </Flex>
      <Div
        style={{
          display: 'grid',
          gap: '10px',
          gridTemplateColumns: 'repeat(auto-fit, minmax(120px, 1fr))',
          overflowY: 'auto',
          maxHeight: '90vh', // Limit height to allow scrolling
        }}
        width="95%"
        maxWidth="800px"
      >
        {demoCards.map((image, index) => (
          <Div
            key={index}
            onClick={() => setSelectedCard(image)}
            style={{
              position: 'relative',
              width: '100%',
              paddingBottom: '140%', // 5:7 Aspect Ratio
              backgroundImage: `url(${image})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              cursor: 'pointer',
              borderRadius: '8px',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              transition: 'transform 0.2s ease-in-out',
            }}
          />
        ))}
      </Div>

      {selectedCard && (
        <DemoCardPreviewFlow
          isDemo
          initialStep="SelectSendType"
          cardImageUrl={selectedCard}
          onClose={handleCloseCatalog}
          onComplete={() => alert('coming soon!')}
          onChangeSendType={() => setIsDigitalCard(!isDigitalCard)}
          isDigitalSend={isDigitalCard}
        />
      )}
    </Flex>
  )
}
