import { Div } from '@sendoutcards/quantum-design-ui'
import React from 'react'

type EditorCardColorBackgroundProps = {
  startColor?: string
  endColor?: string
  deg?: number
  colorOpacity?: number
}

export const EditorCardColorBackground = ({
  startColor = '#F1E9F9',
  endColor = '#FDA092',
  deg = 135,
  colorOpacity = 0.5,
}: EditorCardColorBackgroundProps) => {
  const hexToRgba = (hex: string, opacity: number) => {
    const stripped = hex.replace('#', '')

    // parse 2 chars each for R, G, B
    const r = parseInt(stripped.slice(0, 2), 16)
    const g = parseInt(stripped.slice(2, 4), 16)
    const b = parseInt(stripped.slice(4, 6), 16)

    return `rgba(${r}, ${g}, ${b}, ${opacity})`
  }

  const rgbToRgba = (rgb: string, opacity: number) => {
    const rgbValues = rgb.match(/\d+/g) // Extracts the numeric values from the rgb string
    if (rgbValues && rgbValues.length === 3) {
      return `rgba(${rgbValues[0]}, ${rgbValues[1]}, ${rgbValues[2]}, ${opacity})`
    }
    return rgb // Return the original string if it's not in the expected format
  }

  // Check if the endColor is a hex or rgb value and call the appropriate function
  const renderedOpacityEndColor = endColor.startsWith('#')
    ? hexToRgba(endColor, colorOpacity)
    : rgbToRgba(endColor, colorOpacity)
  const gradientStyle = {
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    background: `linear-gradient(${deg}deg, ${startColor}, ${renderedOpacityEndColor})`,
  }

  return (
    <Div position="absolute" top="0" left="0" width="100%" height="100%">
      <Div
        id="image-overlay"
        position="absolute"
        style={{
          background:
            'url(https://soc-website-public.s3.us-west-2.amazonaws.com/static/soc-editor/editor-background-overlay.png) lightgray 50% / cover no-repeat',
          backgroundSize: 'cover',
          opacity: '0.5',
          width: '100%',
          height: '100%',
          mixBlendMode: 'overlay',
        }}
      />
      <Div
        width="100%"
        height="100%"
        id="background-gradient"
        style={gradientStyle}
      />
    </Div>
  )
}
