import React from 'react'
import Carousel from 'nuka-carousel'

import { Icon, Search } from 'src/chrome'
import { useActions, useSelector } from 'src/hooks'

import { Text } from '@sendoutcards/quantum-design-ui'

import { FilterButton } from '../../types'
import { Api } from '../../api'
import styles from '../../containers/catalog.module.scss'
import CatalogHeader from '../Headers/CatalogHeader'
import CatalogRibbon from '../CatalogRibbon/CatalogRibbon'
import { AllCategoriesMobile } from './AllCategoriesMobile'

type Props = {
  api: Api
}

const AllCategories: React.FC<Props> = props => {
  const { api } = props
  const { cardCatalog, myCardsCategory } = api
  const isMobile = useSelector(state => state.window.isMobile)
  const actions = useActions()

  const specialCardCollections = cardCatalog.collections.filter(
    collection => collection.reasonToSend,
  )
  const usualCardCollections = cardCatalog.collections.filter(
    collection => !collection.reasonToSend,
  )

  const filterButtons = (api: Api): FilterButton[] => [
    {
      __typename: 'FilterButton',
      name: 'Photo Drop',
      onClick: api.handleShowPremium,
      id: 'photo_drop_filter_btn',
      icon: {
        icon: 'PHOTODROP',
        color: '#6f8394',
        viewBox: '0 0 19 32',
        size: 60,
      },
      uniqueHandler: false,
    },
    {
      __typename: 'FilterButton',
      name: 'Catalog',
      id: 'catalog_filter_btn',
      onClick: api.handleShowCatalogCards,
      icon: {
        icon: 'CATALOGCARD',
        color: '#6f8394',
        viewBox: '0 0 19 32',
        size: 60,
      },
      uniqueHandler: false,
    },
    {
      __typename: 'FilterButton',
      name: 'Build Your Own',
      id: 'create_your_own_filter_btn',
      onClick: () => actions.toggleIsBlankCardModalOpen(),
      icon: {
        icon: 'CAMERA1',
        color: '#6f8394',
        viewBox: '0 0 1224 1024',
        size: 45,
      },
      uniqueHandler: true,
    },
  ]

  const mobileCategoryList = [myCardsCategory, ...cardCatalog.cardCategories]
  return !isMobile ? (
    <>
      {(!api.searchResults && (
        <div style={isMobile ? { overflow: 'scroll' } : { overflow: 'auto' }}>
          <div className={styles.searchHeader}>
            {specialCardCollections.length > 0 &&
              (isMobile ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    padding: '10px 0',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    zIndex: 10,
                    backgroundColor: '#f9fafc',
                  }}
                >
                  <Icon
                    icon="SOCLOGO"
                    color="violet"
                    size={35}
                    style={{ margin: '15px 15px 15px 15px' }}
                    isCentered={true}
                  />
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Text type={'subtitle'} weight="semiBold">
                      Card Catalog
                    </Text>
                    <Text type={'caption'}>
                      Scroll down to browse through our cards
                    </Text>
                  </div>
                </div>
              ) : (
                <Carousel
                  initialSlideHeight={500}
                  dragging={true}
                  easing={'easeCubicIn'}
                >
                  {specialCardCollections.map(reason => {
                    return (
                      <CatalogHeader
                        key={reason.id}
                        info={{
                          title: reason.title,
                          description: reason.description,
                        }}
                        button={{
                          id: reason.id,
                          onClick: api.handleShowCollection,
                        }}
                        textColor={reason.textColor}
                        backgroundStartColor={reason.backgroundStartColor}
                        backgroundEndColor={reason.backgroundEndColor}
                        card={reason.cards[0]}
                        cardPreview={api.handlePreview}
                        handleFavoriteCard={api.handleFavoriteCard}
                      />
                    )
                  })}
                </Carousel>
              ))}

            <Search
              id={'search_bar'}
              categories={cardCatalog.cardCategories}
              handleCategory={category => api.handleShowCategory(category.id)}
              hint={'What are you looking for?'}
              autocomplete={'off'}
              onSearch={api.handleSearch}
              clearSearch={api.handleClearSearch}
              style={
                isMobile
                  ? { marginBottom: 0, marginTop: '30px' }
                  : { marginBottom: 60 }
              }
              className={styles.catalogSearchBar}
              inputClassName={styles.catalogSearchField}
              tagsClassName={styles.catalogSearchTags}
              buttonClick={() => api.setShowBuildYourOwnModal(true)}
            />
          </div>
          <CatalogRibbon
            title={'Filter By Card Type'}
            items={filterButtons(api)}
          />
          <CatalogRibbon
            type={'collections'}
            title={'Card Collections'}
            items={usualCardCollections}
            canFavorite={true}
            onClick={api.handleShowCollection}
            handleFavoriteCard={api.handleFavoriteCard}
          />
          {cardCatalog.favoriteCards.length > 0 && (
            <CatalogRibbon
              key={'favorites'}
              type={'favorites'}
              title={'My Favorites'}
              isScrollBarEnabled={!api.shouldDisableScroll}
              items={cardCatalog.favoriteCards}
              onClick={api.handlePreview}
              isCategory={true}
              canFavorite={true}
              handleCategory={api.handleShowFavorites}
              handleFavoriteCard={api.handleFavoriteCard}
            />
          )}
          {cardCatalog.customCards.length > 0 && (
            <CatalogRibbon
              key={'myCards'}
              type={'drafts'}
              title={'My Custom Cards'}
              isScrollBarEnabled={!api.shouldDisableScroll}
              items={cardCatalog.customCards}
              isCategory={true}
              handleCategory={api.handleShowCustom}
              onClick={api.handlePreview}
            />
          )}
          {cardCatalog.legacyPicturePlusCards.length > 0 && (
            <CatalogRibbon
              key={'legacyPicturePlus'}
              title={'Legacy Picture Plus Cards'}
              isScrollBarEnabled={!api.shouldDisableScroll}
              items={cardCatalog.legacyPicturePlusCards}
              isCategory={true}
              handleCategory={api.handleShowLegacyPicturePlus}
              onClick={api.handlePreview}
            />
          )}
          <CatalogRibbon
            type={'featured'}
            title={'Featured'}
            isScrollBarEnabled={!api.shouldDisableScroll}
            items={cardCatalog.featuredCards}
            canFavorite={true}
            onClick={api.handlePreview}
            handleFavoriteCard={api.handleFavoriteCard}
          />
          {cardCatalog.cardCategories
            .filter(category => category.cards.length > 0)
            .map(category => {
              return (
                <CatalogRibbon
                  type={category.id}
                  key={category.id}
                  isScrollBarEnabled={!api.shouldDisableScroll}
                  title={category.description}
                  items={category.cards}
                  onClick={api.handlePreview}
                  handleCategory={() => api.handleShowCategory(category.id)}
                  isCategory={true}
                  canFavorite={true}
                  handleFavoriteCard={api.handleFavoriteCard}
                />
              )
            })}
        </div>
      )) ||
        // Made a search on catalog
        (api.searchResults && (
          <div>
            <CatalogHeader
              info={{
                title: 'Search Cards',
                description: `Search results for '${api.search}'.`,
              }}
              textColor="#fff"
              backgroundStartColor="#e687ff"
              backgroundEndColor="#ff5689"
              card={api.searchResults.results[0]}
              cardPreview={api.handlePreview}
              onBack={api.handleClearSearch}
              handleFavoriteCard={api.handleFavoriteCard}
            />
            <CatalogRibbon
              type={'search_results'}
              items={api.searchResults.results}
              isSearchResultEmpty={
                api.search !== '' &&
                api.searchResults.hasMore &&
                api.searchResults.results.length <= 0
              }
              search={api.search}
              isWrapContentEnabled={true}
              canFavorite={true}
              onClick={api.handlePreview}
              onClose={api.handleClearSearch}
              handleSearchAll={api.handleClearSearch}
              isLoadingMore={api.searchResults.isLoadingMore}
              hasMore={api.searchResults.hasMore}
              loadMore={
                api.searchResults.hasMore
                  ? api.searchResults.loadMore
                  : undefined
              }
              handleFavoriteCard={api.handleFavoriteCard}
            />
          </div>
        ))}
    </>
  ) : (
    <AllCategoriesMobile
      categories={mobileCategoryList}
      handleBuildYourOwn={() => actions.toggleIsBlankCardModalOpen()}
      handleCategory={(id: string) => api.handleShowCategory(id)}
      handleCustomCategory={api.handleShowCustom}
    />
  )
}

export default AllCategories
