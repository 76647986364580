import {
  CardFragment,
  ElementInput,
  ElementPictureInput,
  ElementStickerInput,
  PanelInput,
  UpdateCardInput,
} from 'src/graphql/generated/graphql'
import {
  EditorElement,
  EditorFullBleed,
  EditorImage,
  EditorPanel,
  EditorSticker,
} from './EditorCard'
import paragraphInputs from './text/paragraphInputs'
import omit from 'lodash/omit'

const ElementImageUpdate = ({
  image,
  position,
  scale,
  filter,
}: EditorImage): ElementPictureInput =>
  !image
    ? { image: null }
    : {
        image: image.id,
        position,
        scale,
        filter: filter ? filter.name : null,
      }

const ElementStickerUpdate = ({
  sticker,
  position,
  scale,
}: EditorSticker): ElementStickerInput =>
  !sticker
    ? { sticker: null }
    : {
        sticker: sticker.id,
        position,
        scale,
      }

export const createCleanBackPanel = (
  backFullBleedPanel: EditorFullBleed,
): PanelInput => {
  const panel = backFullBleedPanel.panels[0]
  return {
    backgroundColorObject: panel.backgroundColor
      ? omit(panel.backgroundColor, '__typename')
      : undefined,
    location: 0,
    isFullBleed: false,
    elements: panel.elements.map(ElementUpdate),
  }
}

const ElementUpdate = (element: EditorElement): ElementInput => {
  return {
    sticker: element.sticker ? ElementStickerUpdate(element.sticker) : null,
    image: element.image ? ElementImageUpdate(element.image) : null,
    text: element.text ? { paragraphs: paragraphInputs(element.text) } : null,
    draggable: element.draggable,
    height: element.height,
    width: element.width,
    locked: element.locked,
    rotation: element.rotation,
    x: element.x,
    y: element.y,
    z: element.z,
  }
}

const legacyFullBleedBackgroundPanels = (
  fullBleed: EditorFullBleed,
  fullBleedLocation: number,
) =>
  fullBleed.backgroundElement
    ? [
        {
          location: fullBleedLocation,
          isFullBleed: true,
          backgroundColor: null,
          elements: fullBleed.backgroundElement
            ? [ElementUpdate(fullBleed.backgroundElement)]
            : [],
        },
      ]
    : []

const fullBleedBackgroundPanels = (
  fullBleed: EditorFullBleed,
  fullBleedLocation: number,
) =>
  fullBleed.name === 'FBBackground'
    ? [
        {
          location: fullBleedLocation,
          isFullBleed: true,
          backgroundColor: null,
          elements: fullBleed.panels[0].elements[0]
            ? [ElementUpdate(fullBleed.panels[0].elements[0])]
            : [],
        },
      ]
    : []

export const panelInput = (panel: EditorPanel): PanelInput => ({
  backgroundColor:
    panel.backgroundColor && omit(panel.backgroundColor, '__typename'),
  elements: panel.elements.map(ElementUpdate),
  isFullBleed: false,
  location: 0,
})

const legacyPanels = (fullBleeds: EditorFullBleed[]): PanelInput[] =>
  fullBleeds
    .flatMap((fullBleed, fullBleedLocation) => [
      ...legacyFullBleedBackgroundPanels(fullBleed, fullBleedLocation),
      ...fullBleed.panels.map(panel => ({
        location: undefined,
        isFullBleed: false,
        backgroundColor: panel.backgroundColor,
        elements: panel.elements.map(ElementUpdate),
      })),
    ])
    .reduce(
      (
        { location, panels }: { location: number; panels: PanelInput[] },
        panel,
      ) => ({
        location: panel.location === undefined ? location + 1 : location,
        panels: [
          ...panels,
          {
            ...panel,
            backgroundColor: undefined,
            backgroundColorObject: panel.backgroundColor
              ? omit(panel.backgroundColor, '__typename')
              : undefined,
            location: panel.location === undefined ? location : panel.location,
          },
        ],
      }),
      { location: 0, panels: Array<PanelInput>() },
    ).panels

const panels = (fullBleeds: EditorFullBleed[]): PanelInput[] =>
  fullBleeds
    .flatMap((fullBleed, fullBleedLocation) => [
      ...fullBleedBackgroundPanels(fullBleed, fullBleedLocation),
      ...fullBleed.panels
        .filter(fb => fb.name !== 'FBBackground')
        .map(panel => ({
          location: undefined,
          isFullBleed: false,
          backgroundColor: panel.backgroundColor,
          elements: panel.elements.map(ElementUpdate),
        })),
    ])
    .reduce(
      (
        { location, panels }: { location: number; panels: PanelInput[] },
        panel,
      ) => ({
        location: panel.location === undefined ? location + 1 : location,
        panels: [
          ...panels,
          {
            ...panel,
            backgroundColor: undefined,
            backgroundColorObject: panel.backgroundColor
              ? omit(panel.backgroundColor, '__typename')
              : undefined,
            location: panel.location === undefined ? location : panel.location,
          },
        ],
      }),
      { location: 0, panels: Array<PanelInput>() },
    ).panels

const prepareFb = (fullBleeds: EditorFullBleed[]): EditorFullBleed[] => {
  const fbBackIndex = fullBleeds.findIndex(fb =>
    fb.panels.some(panel => panel.name === 'FBBackground'),
  )
  if (fbBackIndex !== -1) {
    const fbBackElement = fullBleeds.splice(fbBackIndex, 1)[0]
    fullBleeds.splice(1, 0, fbBackElement)
    return fullBleeds
  }
  return fullBleeds
}

const isFullBleedHorizontal = (fullBleed?: EditorFullBleed) =>
  fullBleed ? fullBleed.width > fullBleed.height : undefined

export const cardUpdate = (
  card: CardFragment,
  fullBleeds: EditorFullBleed[],
  recipient: string | null = null,
): UpdateCardInput => ({
  id: card.id,
  panels: card.isNewEditorCard
    ? panels(prepareFb(fullBleeds))
    : legacyPanels(fullBleeds),
  recipient,
  isBackPanelHorizontal: card.isHorizontal
    ? isFullBleedHorizontal(
        fullBleeds.find(fullBleed => fullBleed.name === 'Back'),
      )
    : undefined,
})
