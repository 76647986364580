import React from 'react'
import { Dialog } from 'src/design_system/molecules/dialog/Dialog'
import {
  Anchor,
  Button,
  ConfirmDialog,
  Div,
  Flex,
  Icon,
  IconLink,
  Input,
  PhoneInput,
  PinInput,
  Spacer,
  Text,
  Transition,
} from '@sendoutcards/quantum-design-ui'
import { PhoneNumberInput } from 'src/legacy_graphql'
import {
  useActions,
  useEffect,
  useFlag,
  useMutations,
  useQueryParams,
  useState,
} from 'src/hooks'
import {
  VerifyPhoneNumberFailureFragment,
  VerifyPhoneNumberFailureType,
  VerifyPhoneNumberSuccessFragment,
  VerticalId,
} from 'src/graphql/generated/graphql'
import {
  useFlags,
  useMarketingContent,
  usePhoneNumberVerification,
  useUpdateAccount,
} from 'src/react_query'
import { getFormattedNumber } from 'src/onboarding/components/PhoneNumberVerification'
import Result from 'src/utils/Result'
import usePhoneNumberCountries from 'src/hooks/usePhoneNumberCountries'
import {
  createPhoneNumberInput,
  getValidatedNumber,
} from 'src/helpers/createPhoneNumberInput'
import { getMachineCountryCode } from 'src/helpers'
import { PhoneVerificationTerms } from 'src/dashboard/components/PhoneVerificationTerms'
import { useSocPublicImageBucketUrl } from 'src/hooks/useSocPublicImageBucketUrl'
import FinishMyAccount from 'src/onboarding/components/FinishMyAccount'

type Step =
  | { type: 'entry'; phoneNumber?: PhoneNumberInput; failureMessage?: string }
  | { type: 'verification'; phoneNumber: PhoneNumberInput }
  | { type: 'incorrectCode'; phoneNumber: PhoneNumberInput }
  | { type: 'finishAccount'; success: VerifyPhoneNumberSuccessFragment }

type ActSendFreeCardDialogProps = {
  isOpen: boolean
  onClose: () => void
  onFinished: () => void
}

const FONT_FAMILY = 'Montserrat, sans-serif'

export const ActSendFreeCardDialog = (props: ActSendFreeCardDialogProps) => {
  const { isOpen, onClose, onFinished } = props

  const actions = useActions()
  const queryParams = useQueryParams()
  const { data: flags } = useFlags()

  const [step, setStep] = useState<Step>({
    type: 'entry',
    phoneNumber: undefined,
  })
  const [firstName, setFirstName] = useState<string | undefined>()
  const [lastName, setLastName] = useState<string | undefined>()
  const [formattedNumber, setFormattedNumber] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)
  const [phoneVerificationError, setPhoneVerificationError] = useState<
    VerifyPhoneNumberFailureFragment | undefined
  >(undefined)
  const freeCardHeroImage = useSocPublicImageBucketUrl('freecardhero.png')

  const handleFailureMessage = (
    failureMessage: string,
    phoneNumberInput: PhoneNumberInput,
  ) => {
    setStep({
      type: 'entry',
      phoneNumber: phoneNumberInput,
      failureMessage,
    })
  }
  const mutations = useMutations()
  const updateAccountMutation = useUpdateAccount()

  const getFlags = useFlag(flags ?? [])

  const onRequestVerificationCode = async (
    firstName: string,
    lastName: string,
    phoneNumberInput: PhoneNumberInput,
  ) => {
    try {
      setFirstName(firstName)
      setLastName(lastName)
      setFormattedNumber(
        getFormattedNumber(
          phoneNumberInput.countryIsoCode,
          phoneNumberInput.nationalNumber,
        ),
      )
      setIsLoading(true)
      const { requestPhoneNumberVerification: result } =
        await mutations.requestPhoneNumberVerification({
          phoneNumber: phoneNumberInput,
          doNotShowDefaultTransition: true,
        })
      if (result.__typename === 'RequestPhoneNumberVerificationSuccess') {
        setStep({ type: 'verification', phoneNumber: phoneNumberInput })
      } else {
        handleFailureMessage(result.failureMessage, phoneNumberInput)
      }
    } catch (error) {
      handleFailureMessage(
        error?.toString() ?? 'Failed to request phone number verification.',
        phoneNumberInput,
      )
    } finally {
      setIsLoading(false)
    }
  }

  const onFailure = (failure: VerifyPhoneNumberFailureFragment) => {
    if (step.type === 'verification') {
      setPhoneVerificationError(failure)
      setStep({
        type: 'incorrectCode',
        phoneNumber: step.phoneNumber,
      })
    }
  }

  const finishJoin = async (
    success: VerifyPhoneNumberSuccessFragment,
    email: string,
    username: string,
    password: string,
    sponsorId: string,
  ) => {
    try {
      setIsLoading(true)
      actions.loginDone(Result(success.verifiedAccount))
      const { updateAccount } = await updateAccountMutation.mutateAsync({
        account: { email, username, password, sponsor: sponsorId },
      })
      actions.updatedAccount(updateAccount.account)
    } catch (error) {
      console.log(error ?? 'Failed to update account.')
    } finally {
      setIsLoading(false)
      onFinished()
    }
  }

  const setUpAccount = async (
    success: VerifyPhoneNumberSuccessFragment,
    firstName?: string,
    lastName?: string,
    sponsorId?: string,
  ) => {
    try {
      setIsLoading(true)
      actions.loginDone(Result(success.verifiedAccount))
      const { updateAccount } = await updateAccountMutation.mutateAsync({
        account: {
          firstName,
          lastName,
          sponsor: sponsorId,
        },
      })
      actions.updatedAccount(updateAccount.account)
    } catch (error) {
      console.log(error ?? 'Failed to update account.')
    } finally {
      setIsLoading(false)
      onFinished()
    }
  }

  const onSuccess = (success: VerifyPhoneNumberSuccessFragment) => {
    if (success.verifiedAccount.username) {
      actions.loginDone(Result(success.verifiedAccount))
      onFinished()
    } else if (
      !success.verifiedAccount.isQualifiedForFirstFreeCard &&
      !success.verifiedAccount.username
    ) {
      const { actDisplay: hasAct, disableActDemo: isDemoDisabled } = getFlags
      if (hasAct && isDemoDisabled) {
        actions.clearRouteArgs()
        actions.loginDone(Result(success.verifiedAccount))
        actions.openAccount()
      } else {
        setStep({ type: 'finishAccount', success })
      }
    } else {
      setUpAccount(success, firstName, lastName, queryParams.sponsor)
    }
  }

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      padding="0px 20px 40px 20px"
      sheetBgColorOverride="#FFFFFF"
      maxWidth="590px"
    >
      {isLoading && (
        <Transition
          isLoading={isLoading}
          title="Sending Code To:"
          subtitle={formattedNumber}
        />
      )}
      {!isLoading && step.type === 'entry' && (
        <Flex flexDirection={'column'} justifyContent={'center'} inset={'x1'}>
          <Flex
            flexDirection="column"
            rowGap="x0"
            justifyContent="center"
            alignItems="center"
            css={{
              aspectRatio: '1/1',
              background:
                'linear-gradient(to bottom, #F6F6F600, #F6F6F600, #F6F6F600, #FFFFFF),linear-gradient(to top, #F6F6F600, #F6F6F600, #F6F6F600, #FFFFFF),linear-gradient(to right, #FFFFFF, #F6F6F600, #FFFFFF),linear-gradient(to right, #FFDFE7, #E498FD)',
            }}
            maxHeight={'30dvh'}
          >
            <div
              style={{
                backgroundImage: `url(${freeCardHeroImage})`,
                backgroundPosition: 'center',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                height: '100%',
                aspectRatio: '1/1',
              }}
            />
          </Flex>
          <PhoneNumberEntry
            requestVerificationCode={onRequestVerificationCode}
            phoneNumber={step.phoneNumber}
            failureMessage={step.failureMessage}
            onDeclinePhoneNumber={() => {
              actions.clearRouteArgs()
              actions.openAccount()
            }}
          />
        </Flex>
      )}
      {!isLoading && step.type === 'verification' && (
        <CodeVerification
          phoneNumber={step.phoneNumber}
          onSuccess={onSuccess}
          onFailure={onFailure}
          sponsorGenealogyIdOrSlug={queryParams.sponsor}
          onNeedNewCode={(phoneNumber: PhoneNumberInput) => {
            setStep({
              type: 'entry',
              phoneNumber,
            })
          }}
        />
      )}
      {step.type === 'incorrectCode' && (
        <IncorrectCode
          phoneNumber={step.phoneNumber}
          getNewCode={phoneNumber =>
            setStep({ type: 'entry', phoneNumber: phoneNumber ?? undefined })
          }
          tryAgain={() =>
            setStep({ type: 'verification', phoneNumber: step.phoneNumber })
          }
          failure={phoneVerificationError}
        />
      )}
      {step.type === 'finishAccount' && (
        <>
          <Spacer space="x2" />
          <FinishMyAccount finishAccount={finishJoin} success={step.success} />
        </>
      )}
    </Dialog>
  )
}

const PhoneNumberEntry = (props: {
  requestVerificationCode: (
    firstName: string,
    lastName: string,
    phoneNumber: PhoneNumberInput,
  ) => void
  onDeclinePhoneNumber: () => void
  phoneNumber?: PhoneNumberInput
  failureMessage?: string
}) => {
  const { requestVerificationCode, failureMessage, onDeclinePhoneNumber } =
    props
  const [isDialogOpen, setDialogIsOpen] = useState(false)

  const phoneNumberCountries = usePhoneNumberCountries()

  const [selectedCountryIndex, setSelectedCountryIndex] = useState(
    phoneNumberCountries.findIndex(
      country =>
        country.isoCode === (props.phoneNumber?.countryIsoCode ?? 'US'),
    ),
  )
  const [firstName, setFirstName] = useState<string | undefined>()
  const [lastName, setLastName] = useState<string | undefined>()
  const [phoneNumber, setPhoneNumber] = useState(
    props.phoneNumber?.nationalNumber ?? '',
  )
  const [isCountrySelectedManually, setIsCountrySelectedManually] =
    useState<boolean>(false)

  const selectedCountry = phoneNumberCountries[selectedCountryIndex]
  const selectedCountryCode = selectedCountry.isoCode
  const formattedNumber = getFormattedNumber(selectedCountryCode, phoneNumber)
  const validatedNumber = getValidatedNumber(selectedCountryCode, phoneNumber)

  const onSubmit = async (event?: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault()

    if (!firstName || !lastName) {
      // this makes the warning show up
      !firstName && setFirstName('')
      !lastName && setLastName('')
      return
    }

    if (!validatedNumber) {
      return
    }

    const phoneNumberInput = createPhoneNumberInput(
      phoneNumber,
      selectedCountry,
    )

    requestVerificationCode(firstName, lastName, phoneNumberInput)
  }

  useEffect(() => {
    const updateCountryCode = async () => {
      for await (const countryCode of getMachineCountryCode()) {
        const index = phoneNumberCountries.findIndex(country => {
          return country.isoCode === countryCode
        })
        if (index !== -1 && !isCountrySelectedManually) {
          setSelectedCountryIndex(index)
        }
      }
    }
    if (!isCountrySelectedManually) {
      updateCountryCode()
    }
  }, [phoneNumberCountries, isCountrySelectedManually])

  return (
    <form onSubmit={onSubmit} autoComplete="on">
      <Flex flexDirection="column">
        <Text type="subtitle" weight="bold" alignment="center">
          Let's get started with your contact information.
        </Text>
        <Spacer space="x4" />
        <Div
          justifyContent="space-between"
          display="flex"
          flexDirection={'row'}
          outset={{ vertical: 'x1_5' }}
          width="100%"
        >
          <Div width={'49%'}>
            <Input
              id={'first_name_field'}
              onChange={e => setFirstName(e)}
              value={firstName ?? ''}
              placeholder={'First Name'}
              type="text"
              message={
                firstName !== ''
                  ? undefined
                  : {
                      type: 'danger',
                      content: 'this field is required',
                    }
              }
              isFullWidth={true}
            />
          </Div>
          <Div width={'49%'}>
            <Input
              id={'last_name_field'}
              onChange={e => {
                setLastName(e)
              }}
              value={lastName ?? ''}
              name="lastName"
              message={
                lastName !== ''
                  ? undefined
                  : {
                      type: 'danger',
                      content: 'this field is required',
                    }
              }
              placeholder={'Last Name'}
              type="text"
              isFullWidth={true}
            />
          </Div>
        </Div>
        <Div width={'100%'}>
          <PhoneInput
            countries={phoneNumberCountries}
            selectedCountryIndex={selectedCountryIndex}
            onSelectedCountryIndexChange={index => {
              setIsCountrySelectedManually(true)
              setSelectedCountryIndex(index)
            }}
            phoneNumber={formattedNumber}
            onPhoneNumberChange={setPhoneNumber}
            maxHeight="125px"
            message={
              failureMessage
                ? {
                    type: 'danger',
                    content: failureMessage,
                  }
                : undefined
            }
          />
        </Div>
        <Spacer space="x2" />
        <Div outset={failureMessage ? { top: 'x6' } : undefined}>
          <IconLink
            icon={'information'}
            title={'Why we ask for your phone number...'}
            fill="primaryBodyText"
            onClick={() => setDialogIsOpen(true)}
            size="small"
          />
        </Div>
        <Spacer space="x2" />
        <Flex flexDirection="column" alignItems="center">
          <Div width={'230px'} id="enter_phone_number_btn">
            <button
              style={{
                borderRadius: '16px',
                display: 'flex',
                justifyContent: 'center',
                boxShadow: '0px 4px 32px 0px rgba(0, 0, 0, 0.08)',
                padding: '8px 20px',
                fontFamily: FONT_FAMILY,
                alignItems: 'center',
                fontWeight: 600,
                color: '#fff',
                fontSize: '16px',
                backgroundColor: '#404040',
                cursor: 'pointer',
                width: '100%',
                height: '48px',
              }}
            >
              Validate
            </button>
          </Div>
          <Spacer space="x2" />
          <Anchor
            size={'small'}
            isDecorated={true}
            onClick={() => {
              onDeclinePhoneNumber()
            }}
          >
            Already have an account?
          </Anchor>
        </Flex>
        <PhoneVerificationTerms />
        {isDialogOpen && (
          <WhyMyPhoneNumberDialog
            onAccept={() => setDialogIsOpen(false)}
            onClose={() => setDialogIsOpen(false)}
          />
        )}
      </Flex>
    </form>
  )
}

const WhyMyPhoneNumberDialog = (props: {
  onAccept: () => void
  onClose: () => void
}) => {
  const { onAccept, onClose } = props
  const { data: marketingContent } = useMarketingContent({ suspense: true })

  if (!marketingContent) {
    throw Error("Couldn't get Marketing Content")
  }

  const { whyMyPhoneNumberMessage } = marketingContent

  return (
    <ConfirmDialog
      isOpen={true}
      title={whyMyPhoneNumberMessage.title}
      description={whyMyPhoneNumberMessage.content}
      accept={{ title: 'Got it', onClick: () => onAccept() }}
      primaryAction={'accept'}
      onClose={() => onClose()}
      data-mktcontent="whyMyPhoneNumberMessage"
    />
  )
}

const CodeVerification = (props: {
  phoneNumber: PhoneNumberInput
  sponsorGenealogyIdOrSlug?: string
  onSuccess: (
    success: VerifyPhoneNumberSuccessFragment,
    possibleRedirectUri: string | null,
  ) => void
  onFailure: (failure: VerifyPhoneNumberFailureFragment) => void
  onNeedNewCode: (phoneNumber: PhoneNumberInput) => void
}) => {
  const { phoneNumber, sponsorGenealogyIdOrSlug, onNeedNewCode } = props

  const [verificationCode, setVerificationCode] = useState('')
  const [failureMessage, setFailureMessage] = useState<string>()
  const [isLoading, setIsLoading] = useState(false)
  const verifyPhoneNumberMutation = usePhoneNumberVerification()

  const formattedNumber = getFormattedNumber(
    phoneNumber.countryIsoCode,
    phoneNumber.nationalNumber,
  )

  const verifyPhoneNumber = async (verificationCode: string) => {
    if (verificationCode.length !== 6) {
      return setFailureMessage('Verification code must 6 digits.')
    }
    setIsLoading(true)
    try {
      const {
        verifyPhoneNumber: result,
        createUserLoginLink: { link },
      } = await verifyPhoneNumberMutation.mutateAsync({
        phoneNumber,
        verificationCode,
        sponsorGenealogyIdOrSlug,
        verticalId: VerticalId.Sendoutcards,
        queryParams: {},
      })
      if (result.__typename === 'VerifyPhoneNumberSuccess') {
        props.onSuccess(result, link ?? null)
      } else {
        props.onFailure(result)
      }
    } catch (error) {
      setFailureMessage(error?.toString() ?? 'Failed to verify phone number.')
    } finally {
      setIsLoading(false)
    }
  }
  if (isLoading) {
    return <Transition isLoading={isLoading} title="Verifying Code..." />
  }
  return (
    <form
      onSubmit={event => {
        event.preventDefault()
        verifyPhoneNumber(verificationCode)
      }}
      autoComplete="on"
    >
      <Spacer space="x2" />
      <Text type="subtitle" weight="bold" alignment="center">
        {'Enter your code'}
      </Text>
      <Spacer space="x2" />
      <Text type="caption" alignment="center">
        <>
          Verify it's you. Enter the code sent to
          <Text type="subtitle" weight="semiBold" alignment="center">
            {` ${formattedNumber} `}
          </Text>
          Your free card is one click away.
        </>
      </Text>
      <Spacer space="x4" />
      <PinInput
        length={6}
        hasFocus={true}
        onChange={setVerificationCode}
        onComplete={verifyPhoneNumber}
        message={
          failureMessage
            ? {
                type: 'danger',
                content: failureMessage,
              }
            : undefined
        }
      />
      <Spacer space="x4" />
      <Flex flexDirection="column" alignItems="center">
        <button
          style={{
            borderRadius: '16px',
            display: 'flex',
            justifyContent: 'center',
            boxShadow: '0px 4px 32px 0px rgba(0, 0, 0, 0.08)',
            padding: '8px 20px',
            fontFamily: FONT_FAMILY,
            alignItems: 'center',
            fontWeight: 600,
            color: '#fff',
            fontSize: '16px',
            backgroundColor: '#404040',
            cursor: 'pointer',
            width: '100%',
            maxWidth: '230px',
            height: '48px',
          }}
        >
          Verify Code
        </button>
        <Spacer space="x2" />
        <Anchor
          title="I didn't receive a code"
          isDecorated={true}
          onClick={() => onNeedNewCode(phoneNumber)}
        />
      </Flex>
    </form>
  )
}

const IncorrectCode = (props: {
  phoneNumber: PhoneNumberInput
  failure?: VerifyPhoneNumberFailureFragment
  getNewCode: (phoneNumber?: PhoneNumberInput) => void
  tryAgain: () => void
}) => {
  const { getNewCode, tryAgain, phoneNumber, failure } = props

  const formattedNumber = getFormattedNumber(
    phoneNumber.countryIsoCode,
    phoneNumber.nationalNumber,
  )

  const actions = useActions()

  const content =
    failure?.failureType === VerifyPhoneNumberFailureType.AlreadyVerified ? (
      <>
        <Text type="caption" alignment="left">
          The phone number {formattedNumber} has been found on an existing
          account. You may have:
        </Text>
        <Spacer space="x2" />
        <Text type="caption" alignment="left">
          <ul
            style={{
              fontFamily: 'Montserrat, sans-serif',
              listStylePosition: 'inside',
            }}
          >
            <li style={{ fontFamily: 'Montserrat, sans-serif' }}>
              sent a FREE card using this phone number
            </li>
            <li style={{ fontFamily: 'Montserrat, sans-serif' }}>
              created multiple accounts using this phone number
            </li>
          </ul>
        </Text>
        <Spacer space="x2" />
        <Text type="caption" alignment="left">
          This number can only be linked to one account. Would you like to
          reassign this number to this account? To reassign this number, you
          will need to complete the phone verification one more time.
        </Text>
      </>
    ) : (
      <>
        <Spacer space="x3" />
        <Text type="subtitle" weight="bold">
          Code Not Found
        </Text>
        <Spacer space="x2" />
        <Text type="caption" alignment={'center'}>
          Looks like the code you entered does not match the token we sent to:
          <Text type={'subtitle'} alignment="center">
            <b>{` ${formattedNumber} `}</b>
          </Text>
        </Text>
      </>
    )

  const buttons =
    failure?.failureType === VerifyPhoneNumberFailureType.AlreadyVerified ? (
      <>
        <Flex flexDirection={'row'} justifyContent="space-between">
          <Button
            title={"No, don't do anything"}
            onClick={() => {
              actions.closePhoneNumberVerification()
              actions.openAccount()
            }}
            size="small"
            outlined={true}
            type="danger"
            hover={true}
          />
          <Spacer space="x4" orientation="horizontal" />
          <Button
            title={'Yes, reassign this number'}
            onClick={() => {
              actions.openPhoneNumberVerification(
                phoneNumber,
                false,
                false,
                true,
              )
              getNewCode(phoneNumber)
            }}
            size="small"
            outlined={true}
            type="danger"
            hover={true}
          />
        </Flex>
        <Spacer space="x3" />
        <Text type="caption" alignment="left">
          For further assistance, contact the Customer Success team at (801)
          463-3800 to resolve this issue.
        </Text>
      </>
    ) : (
      <>
        <Button
          title={'Get a new code'}
          onClick={() => getNewCode(undefined)}
          outlined={true}
          type="shadow"
          hover={true}
        />
        <Spacer space="x2" />
        <Anchor
          title="Try my code again"
          size="small"
          onClick={tryAgain}
          isDecorated={true}
        />
      </>
    )

  return (
    <div>
      <Spacer space="x4" />
      <Flex
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
      >
        <Icon name="exclamation" size="xLarge" primaryColor="danger" />
        {content}
      </Flex>
      <Spacer space="x2" />
      <Flex justifyContent="center" flexDirection="column" alignItems="center">
        {buttons}
      </Flex>
    </div>
  )
}
