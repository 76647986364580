import { drawerState } from '../../orders/drawerState'
import { OrderTypes } from '../../orders/orderType'
import { OrderUiContext } from '../../orders/types'
import { isCardCostFree, recipientCount, validateFreeSend } from 'src/helpers'
import {
  changeFreeStatus,
  clearOrder,
  closeDrawer,
  closeGroupModal,
  createdGroup,
  createdOrder,
  deleteOrder,
  loadedOrder,
  loadedOrderError,
  openAddressDrawer,
  openCreateAddress,
  removeCardFromLine,
  removeContact,
  removeLine,
  savedOrder,
  savedOrderError,
  saveOrder,
  setActiveLine,
  setIsUpdatingOrder,
  setOrderUiContext,
  setShowSendDelayError,
  updateOrderPaperType,
  updateRecipients,
  updateReturnAddress,
  updateSendDelay,
} from 'src/redux/actions/orders'
import * as giftActions from 'src/redux/actions/giftstore'
import * as MultiTouch from 'src/helpers/multitouch'
import Action from 'src/redux/action'
import {
  DetailedOrderFragment,
  UpdateOrderInput,
} from 'src/graphql/generated/graphql'
import omit from 'lodash/omit'

export type OrderWithPartialLines = Omit<DetailedOrderFragment, 'lines'> & {
  lines: MultiTouch.PartialLine[]
}

export const buildUpdateOrderInput = (
  order: OrderWithPartialLines,
): UpdateOrderInput => ({
  id: order.id,
  lines: order.lines.map(line => ({
    card: line.card?.id,
    giftVariation: line.giftVariation?.id,
    sendDelay: {
      ...line.sendDelay,
      __typename: undefined,
    },
  })),
  contacts: order.contacts.map(contact => contact.id),
  groups: order.groups.map(group => group.id),
  returnAddress: order.returnAddress && {
    ...omit(order.returnAddress, '__typename'),
    state: order.returnAddress.state || '',
  },
})

type OrdersState = {
  order?: OrderWithPartialLines
  isLoading: boolean
  loadError?: Error
  isFree: boolean
  isSaved: boolean
  isSaving: boolean
  drawerState: string
  groupMembers: {
    __typename: 'Contact'
    id: string
  }[]
  isGroupModalOpen: boolean
  orderType: 'singleCard' | 'multiLine'
  freeStatusChanged?: string[]
  activeLine?: MultiTouch.PartialLine
  activeLineIndex?: number
  isCardAdded: boolean
  isGiftAdded: boolean
  isUpdatingOrder: boolean
  showSendDelayError: boolean
  uiContext: OrderUiContext
}

const initialOrdersState: OrdersState = {
  order: undefined,
  isLoading: false,
  isFree: false,
  isSaved: true,
  isSaving: false,
  drawerState: drawerState.closed,
  groupMembers: [],
  isGroupModalOpen: false,
  orderType: OrderTypes.singleCard,
  isCardAdded: false,
  isGiftAdded: false,
  isUpdatingOrder: false,
  showSendDelayError: false,
  uiContext: null,
}

const orders = (
  state: OrdersState = initialOrdersState,
  action: Action,
): OrdersState => {
  switch (action.type) {
    case loadedOrder.type:
      const isCardFree = isCardCostFree(action.order.cost.entries)
      return {
        ...state,
        isLoading: false,
        order: action.order,
        activeLine: undefined,
        activeLineIndex: undefined,
        isFree: isCardFree,
        freeStatusChanged:
          state.isFree && !isCardFree && recipientCount(action.order) > 0
            ? validateFreeSend(action.order)
            : undefined,
        orderType:
          action.order.lines.length > 1
            ? OrderTypes.multiLine
            : OrderTypes.singleCard,
        isCardAdded: action.order.lines.some(line => line.card),
      }
    case loadedOrderError.type:
      return {
        ...state,
        isLoading: false,
        loadError: action.error,
      }
    case changeFreeStatus.type:
      return {
        ...state,
        freeStatusChanged: undefined,
      }
    case closeGroupModal.type:
      return {
        ...state,
        isGroupModalOpen: false,
        groupMembers: [],
      }
    case createdGroup.type:
      return {
        ...state,
        isGroupModalOpen: false,
        drawerState: drawerState.closed,
        isSaving: true,
      }
    case createdOrder.type:
      return {
        ...state,
        order: action.order,
        activeLine: action.order.lines[0],
        orderType:
          action.order.lines.length > 1
            ? OrderTypes.multiLine
            : OrderTypes.singleCard,
      }
    case deleteOrder.type:
      return {
        ...state,
        ...initialOrdersState,
        isLoading: false,
      }
    case saveOrder.type:
      return {
        ...state,
        isSaved: false,
        isSaving: true,
      }
    case savedOrder.type:
      const isFree = isCardCostFree(action.order.cost.entries)
      return {
        ...state,
        isSaved: true,
        isSaving: false,
        order: action.order,
        activeLine: state.activeLine?.card
          ? action.order.lines[
              MultiTouch.findActiveIndex(action.order, state.activeLine)
            ]
          : undefined,
        activeLineIndex: state.activeLine?.card
          ? MultiTouch.findActiveIndex(action.order, state.activeLine)
          : undefined,
        isFree: isFree,
        freeStatusChanged: !!state.freeStatusChanged
          ? state.freeStatusChanged
          : state.isFree && !isFree && recipientCount(action.order) > 0
            ? validateFreeSend(action.order)
            : undefined,
        orderType:
          action.order.lines.length > 1
            ? OrderTypes.multiLine
            : OrderTypes.singleCard,
      }
    case savedOrderError.type:
      return {
        ...state,
        isSaved: false,
        isSaving: false,
      }
    case openAddressDrawer.type:
      return {
        ...state,
        drawerState: drawerState.addressDrawer,
      }
    case openCreateAddress.type:
      return {
        ...state,
        drawerState: drawerState.createAddress,
      }
    case closeDrawer.type:
      return {
        ...state,
        drawerState: drawerState.closed,
      }
    case giftActions.updateGift.type:
      return {
        ...state,
        isSaved: false,
        isSaving: true,
        isGiftAdded: true,
        order:
          state.order &&
          state.activeLine &&
          MultiTouch.mapLines(state.order, state.activeLine, line => ({
            ...line,
            giftVariation: action.giftVariation,
          })),
      }
    case giftActions.deselectGift.type:
      return {
        ...state,
        isSaved: false,
        isSaving: true,
        order:
          state.order &&
          state.activeLine &&
          MultiTouch.mapLines(state.order, state.activeLine, line => ({
            ...line,
            giftVariation: null,
          })),
      }
    case giftActions.deselectGiftFromLine.type:
      if (!state.order) {
        return state
      }
      const lineToRemoveGift = state.order.lines.indexOf(action.line)
      return {
        ...state,
        isSaved: false,
        isSaving: true,
        order: {
          ...state.order,
          lines: state.order.lines.map((line, index) => {
            if (index === lineToRemoveGift) {
              return {
                ...line,
                giftVariation: null,
              }
            } else {
              return line
            }
          }),
        },
      }
    case removeCardFromLine.type:
      if (!state.order) {
        return state
      }
      const lineToRemoveCard = state.order.lines.indexOf(action.line)
      return {
        ...state,
        isSaved: false,
        isSaving: true,
        order: {
          ...state.order,
          lines: state.order.lines.map((line, index) => {
            if (index === lineToRemoveCard) {
              return {
                ...line,
                card: null,
              }
            } else {
              return line
            }
          }),
        },
      }
    case removeContact.type:
      if (!state.order) {
        return state
      }
      return {
        ...state,
        isSaved: false,
        isSaving: true,
        order: {
          ...state.order,
          contacts: state.order.contacts.filter(
            contact => contact.id !== action.contact.id,
          ),
        },
      }
    case removeLine.type:
      if (!state.order) {
        return state
      }
      const cardIndex = state.order.lines.indexOf(action.line)
      return {
        ...state,
        isSaved: false,
        isSaving: true,
        activeLine: state.order.lines[0],
        activeLineIndex: 0,
        order: {
          ...state.order,
          lines: state.order.lines.filter(
            (_line, index) => index !== cardIndex,
          ),
        },
      }
    case setActiveLine.type:
      return {
        ...state,
        activeLine: action.line,
        activeLineIndex: action.index,
        isCardAdded: false,
        isGiftAdded: false,
      }
    case setIsUpdatingOrder.type:
      return {
        ...state,
        isUpdatingOrder: action.isUpdatingOrder,
      }
    case setOrderUiContext.type:
      return {
        ...state,
        uiContext: action.context,
      }
    case setShowSendDelayError.type:
      return {
        ...state,
        showSendDelayError: action.showSendDelayError,
      }
    case updateRecipients.type:
      if (!state.order) {
        return state
      }
      return {
        ...state,
        isSaved: false,
        isSaving: true,
        order: {
          ...state.order,
          contacts: action.contacts,
          groups: action.groups,
        },
      }
    case updateReturnAddress.type:
      if (!state.order) {
        return state
      }
      return {
        ...state,
        isSaving: true,
        order: {
          ...state.order,
          returnAddress: action.returnAddress,
        },
      }
    case updateOrderPaperType.type:
      if (!state.activeLine || !state.activeLine.card) {
        return state
      }
      return {
        ...state,
        isSaving: true,
        activeLine: {
          ...state.activeLine,
          card: {
            ...state.activeLine.card,
            paperType: action.paperType,
          },
        },
        freeStatusChanged:
          (action.paperType === 'HVY' || action.paperType === 'PRE') &&
          state.activeLine.card.paperType !== action.paperType
            ? ['Paper upgrades to free card sends will be charged at checkout']
            : undefined,
      }
    case updateSendDelay.type:
      return {
        ...state,
        isSaved: false,
        isSaving: true,
        order:
          state.order &&
          state.activeLine &&
          MultiTouch.mapLines(state.order, state.activeLine, line => ({
            ...line,
            sendDelay: action.sendDelay,
          })),
      }
    case clearOrder.type:
      return {
        ...initialOrdersState,
        isUpdatingOrder: state.isUpdatingOrder,
      }
    default:
      return state
  }
}

export default orders
