import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import { Flex, Sheet, Text } from '@sendoutcards/quantum-design-ui'
// @src imports
import { Confirm, DefaultError, Search, Transition } from 'src/chrome'
import { ContextFooter } from 'src/orders/components'
import { GiftRoute } from 'src/gift_store/routes/GiftRoute'
import { GiftCategoryRoute } from 'src/gift_store/routes/GiftCategoryRoute'
import suspenseBoundary from 'src/chrome/SuspenseBoundary/suspenseBoundaryHOC'
import { getGiftCategories, GiftFragment } from 'src/legacy_graphql'
import { GiftStoreRoute } from 'src/gift_store/routes/GiftStoreRoute'
import { CAMPAIGN_CONTEXT } from 'src/context/types'
import { GiftModal, GiftRibbon } from 'src/gift_store/components'
// relative imports
import styles from './giftStore.module.scss'
import {
  useActions,
  useAppcues,
  useEffect,
  useQueries,
  useSelector,
  useState,
} from 'src/hooks'
import { GiftTabs } from '../components/GiftTabs/GiftTabs'
import AnnouncementBanners from 'src/marketing/AnnouncementBanners/AnnouncementBanners'
import { GuestOrLoginForm } from '../components/GuestOrLoginForm'
import OrderContextBar from 'src/orders/components/OrderContextBar/OrderContextBar'
import { AddGiftToCardRoute } from 'src/orders/routes/AddGiftToCardRoute'
import { AddOrderGiftRoute } from 'src/orders/routes/AddOrderGiftRoute'
import { countLineItems } from 'src/orders/helpers'
import { OrderApiType } from 'src/orders/api'
import {
  buildUpdateOrderInput,
  OrderWithPartialLines,
} from 'src/redux/reducers/orders'
import ReplaceOrderItemDialog from 'src/orders/components/ReplaceOrderItemDialog/ReplaceOrderItemDialog'
import { Portal } from 'src/portal/portal'
import {
  useCreateOrder,
  useUpdateCampaign,
  useUpdateOrder,
} from 'src/react_query'
import {
  CampaignFragment,
  GiftVariationFragment,
  UpdateOrderInput,
} from 'src/graphql/generated/graphql'
import { giftVariationFragmentFromLegacy } from 'src/graphql/compat'

type GiftStoreProps = {
  route: GiftStoreRoute | AddGiftToCardRoute | AddOrderGiftRoute
  orderApi?: OrderApiType
}

enum Steps {
  Idle,
  ViewGift,
}

interface IdleStep {
  type: Steps.Idle
}

interface ViewGiftStep {
  type: Steps.ViewGift
  slug: string
}

type Step = IdleStep | ViewGiftStep

// eslint-disable-next-line
const isOnAddGiftToCardRoute = (route: any): route is AddGiftToCardRoute =>
  route.lineIndex

const GiftStore: React.FC<GiftStoreProps> = props => {
  const { route, orderApi } = props
  const actions = useActions()
  const updateCampaignMutation = useUpdateCampaign()
  const createOrderMutation = useCreateOrder()
  const updateOrderMutation = useUpdateOrder()
  const initialGiftSlug =
    route.subroute?.path === GiftRoute.path ? route.subroute.giftId : undefined
  const [chamberedGift, setChamberedGift] = useState<GiftFragment | null>(null)

  const applyChanges = (
    orderInput: UpdateOrderInput,
    giftVariation: string,
  ) => ({
    ...orderInput,
    lines: (orderInput.lines || []).concat([
      {
        card: undefined,
        giftVariation,
        sendDelay: null,
      },
    ]),
  })

  const addNewGiftLineItem = async (
    order: OrderWithPartialLines,
    giftVariation: string,
  ) => {
    const orderInput = buildUpdateOrderInput(order)
    const { updateOrder } = await updateOrderMutation.mutateAsync({
      order: applyChanges(orderInput, giftVariation),
    })
    actions.savedOrder(updateOrder.order)
  }

  const [categories] = useQueries(getGiftCategories())
  const {
    orders,
    user: { account },
  } = useSelector(state => state)
  const { activeLine, activeLineIndex } = orders

  const activeLineIndexFromRoute = isOnAddGiftToCardRoute(route)
    ? typeof route.lineIndex === 'string'
      ? parseInt(route.lineIndex, 10)
      : undefined
    : undefined

  const isChangingGift =
    !!activeLine?.giftVariation &&
    !!(activeLineIndexFromRoute === activeLineIndex)

  const highLevelCategory = route.highLevelCategory ?? categories[0].id

  const [searchTerm, setSearchTerm] = useState<string>()

  const [guestOrLoginState, setGuestOrLoginState] = useState<{
    isOpen: boolean
    gift: GiftFragment | null
  }>({
    isOpen: false,
    gift: null,
  })

  const [step, setStep] = useState<Step>(
    initialGiftSlug
      ? { type: Steps.ViewGift, slug: initialGiftSlug }
      : { type: Steps.Idle },
  )

  const resetStep = () => setStep({ type: Steps.Idle })
  const context = useSelector(state => state.context)

  const { account: isAuthenticated } = useSelector(state => state.user)
  const isSparse = !account?.username

  const [addingGift, setAddingGift] = useState<{
    status: boolean
    message: string
  }>({ status: false, message: '' })

  const [confirm, setConfirm] = useState<{
    title: string
    message: string
    action: () => void
  }>()

  useAppcues('-L8hSjiwftl1lOHA3uw7')

  const closeConfirmModal = () => setConfirm(undefined)

  const createGiftOrder = async (giftId: string) => {
    /* create order and go to the order page */
    const {
      createOrder: { order },
    } = await createOrderMutation.mutateAsync({
      order: { lines: [{ giftVariation: giftId }] },
    })
    actions.createdOrder(order)
    actions.openOrder(order.id)
  }

  const viewGift = (slug: string) => setStep({ type: Steps.ViewGift, slug })

  // for now this is used to update the URL
  useEffect(() => {
    if (step.type === Steps.ViewGift) {
      actions.selectGiftToView(step.slug)
      return
    }
    actions.closeGiftBeingViewed()
  }, [step, actions])

  const invalidOrderState = () =>
    orders.order &&
    orders.activeLine &&
    orders.activeLine.card &&
    orders.activeLine.card.type === 'POSTCARD'

  const addGift = (gift: GiftFragment) => {
    const selectedGift = gift && gift.defaultVariation

    if (!selectedGift) {
      return
    }

    if (!invalidOrderState()) {
      setAddingGift({ status: true, message: 'Adding gift to order..' })
      localSelectGift(giftVariationFragmentFromLegacy(selectedGift))
    } else {
      setConfirm({
        title: "Can't add gift",
        message:
          'Cannot send a gift with a postcard. Would you like to create a new order for this gift?',
        action: () => {
          actions.clearOrder()
          createGiftOrder(selectedGift.id)
        },
      })
    }
  }

  const handleAddGift = (gift: GiftFragment) => {
    if (isAuthenticated) {
      if (isChangingGift) {
        resetStep()
        setChamberedGift(gift)
      } else {
        addGift(gift)
      }
    } else {
      setGuestOrLoginState({
        isOpen: true,
        gift,
      })
    }
  }

  const updateCampaign = async (campaign: CampaignFragment) => {
    await updateCampaignMutation.mutateAsync({
      campaign: {
        id: campaign.id,
        name: campaign.name,
        lines: campaign.lines?.map(line => {
          return {
            card: line.card?.id,
            giftVariation: line.giftVariation?.id,
            sendDelay: {
              ...line.sendDelay,
              __typename: undefined,
            },
          }
        }),
      },
    })
    actions.editedCampaign(campaign.id)
    actions.openCampaign(campaign.id)
  }

  const localSelectGift = (gift: GiftVariationFragment) => {
    actions.selectGift(gift.id)

    if (context.kind === CAMPAIGN_CONTEXT && context.line !== undefined) {
      updateCampaign({
        ...context.campaign,
        lines: context.campaign.lines.map((line, index) => {
          if (index === context.line) {
            return {
              ...line,
              giftVariation: gift,
            }
          }
          return line
        }),
      })
    } else if (orders.order) {
      const containerId = orders.order.id
      containerId && typeof activeLineIndexFromRoute === 'number'
        ? actions.updateGift(gift)
        : addNewGiftLineItem(orders.order, gift.id)
      setTimeout(() => actions.openOrder(containerId), 500)
    } else {
      createGiftOrder(gift.id)
    }
  }

  const onCloseGiftModal = () => resetStep()

  const PAGE_ID = 'gift_store_page'

  return (
    <div className={styles.mainContainer} id={PAGE_ID}>
      {account && <AnnouncementBanners />}
      <Flex maxWidth="520px">
        <Sheet
          zIndex={4001}
          isOpen={guestOrLoginState.isOpen}
          setIsOpen={isOpen =>
            setGuestOrLoginState({ ...guestOrLoginState, isOpen })
          }
        >
          <GuestOrLoginForm
            onComplete={() => {
              guestOrLoginState.gift && addGift(guestOrLoginState.gift)
            }}
            asset={{
              image: guestOrLoginState.gift?.defaultImageUrl ?? '',
              title: `Add Gift: ${guestOrLoginState.gift?.name}` ?? '',
            }}
          />
        </Sheet>
      </Flex>
      <div
        className={styles.header}
        style={{ marginTop: isSparse ? '60px' : 0 }}
      >
        <Search
          id="gift_store_search"
          style={{ top: 0, paddingBottom: 0 }}
          onSearch={setSearchTerm}
          value={searchTerm}
          autocomplete={'off'}
          clearSearch={() => setSearchTerm('')}
        />
        <GiftTabs
          value={highLevelCategory}
          onChange={categoryId => {
            if (highLevelCategory !== categoryId) {
              actions.selectHighLevelCategory(categoryId)
              setSearchTerm(undefined)
            }
          }}
          selectChildren={categories.map(category => {
            return (
              <MenuItem value={category.id} key={category.id}>
                <Text type="body">{category.name}</Text>
              </MenuItem>
            )
          })}
        />
      </div>
      {addingGift.status && <Transition message={addingGift.message} />}
      {!addingGift.status && (
        <div className={styles.scrollableContainer}>
          {!searchTerm &&
            highLevelCategory === '1' &&
            account &&
            account.giftSends > 0 && (
              <>
                <GiftRibbon
                  isChangingGift={isChangingGift}
                  id={99999}
                  shouldWrapContent={
                    route.subroute &&
                    route.subroute.path === GiftCategoryRoute.path
                  }
                  name="Heartfelt Plus Gifts - Best Sellers"
                  isEligibleFreeGift={true}
                  onSelect={() => actions.selectChildCategory('freegift')}
                  key={'freegift'}
                  viewGift={viewGift}
                  addGift={handleAddGift}
                  onBack={actions.dismissChildCategory}
                />
              </>
            )}
          {searchTerm ? (
            <GiftRibbon
              isChangingGift={isChangingGift}
              shouldWrapContent={true}
              name="Search Results"
              search={searchTerm}
              viewGift={viewGift}
              addGift={handleAddGift}
            />
          ) : (
            categories.map(category => {
              if (highLevelCategory === category.id) {
                return (category.children ?? [])
                  .filter(
                    child =>
                      !route.subroute ||
                      route.subroute.path === GiftRoute.path ||
                      (route.subroute.path === GiftCategoryRoute.path &&
                        child.id === route.subroute.giftCategoryId),
                  )
                  .map((child, index) => (
                    <GiftRibbon
                      isChangingGift={isChangingGift}
                      id={index}
                      shouldWrapContent={
                        route.subroute &&
                        route.subroute.path === GiftCategoryRoute.path
                      }
                      name={child.name}
                      categoryId={child.id}
                      onSelect={() => actions.selectChildCategory(child.id)}
                      key={child.id}
                      viewGift={viewGift}
                      addGift={handleAddGift}
                      onBack={actions.dismissChildCategory}
                    />
                  ))
              } else {
                return null
              }
            })
          )}
          {orders.order && orderApi && (
            <OrderContextBar
              miniCart={{
                orderId: orders.order.id,
                containerId: PAGE_ID,
                checkoutAction: {
                  title: isChangingGift
                    ? 'Cancel & Go to Cart'
                    : 'Send A Card Only',
                },
                button: {
                  count: countLineItems(orders.order.lines),
                  shouldBypassDrawer: false,
                },
                previewZIndex: 400,
                orderApi: orderApi,
              }}
              label={{ title: 'Cards', count: orders.order.lines.length }}
            />
          )}
          {context.kind === 'CAMPAIGN_CONTEXT' && (
            <ContextFooter
              cards={context.campaign.lines.length}
              total={null}
              isInCatalog={true}
              footerType={'campaign'}
              name={context.campaign.name}
              cancelContext={() => actions.openCampaign(context.campaign.id)}
            />
          )}
        </div>
      )}
      {step.type === Steps.ViewGift && (
        <GiftModal
          key={'gift_modal'}
          slug={step.slug}
          onClose={onCloseGiftModal}
          addGift={gift => {
            resetStep()
            handleAddGift(gift)
          }}
          isChangingGift={isChangingGift}
        />
      )}
      {confirm && (
        <Confirm
          title={confirm.title}
          message={confirm.message}
          onDecline={closeConfirmModal}
          onConfirm={confirm.action}
        />
      )}
      {isChangingGift && typeof activeLineIndex === 'number' && chamberedGift && (
        <Portal attachToContainerId="app">
          <ReplaceOrderItemDialog
            title={`Card ${activeLineIndex + 1}: Replace Gift?`}
            subtitle={`Confirm and continue to replace the current gift selected for Card  ${
              activeLineIndex + 1
            } with the new gift chosen above.`}
            onCancel={() => setChamberedGift(null)}
            onConfirm={() => {
              addGift(chamberedGift)
              setChamberedGift(null)
            }}
            onClose={() => setChamberedGift(null)}
            isOpen={!!chamberedGift}
            currentItem={{
              ImageURL: activeLine?.giftVariation?.defaultImageUrl,
              title: activeLine?.giftVariation?.name,
              price: activeLine?.giftVariation?.price.asString,
            }}
            swapToItem={{
              ImageURL: chamberedGift.defaultImageUrl,
              title: chamberedGift.name,
              price: chamberedGift.defaultVariation?.price.asString,
            }}
          />
        </Portal>
      )}
    </div>
  )
}

export default suspenseBoundary({
  component: GiftStore,
  failure: DefaultError,
  unresolved: <Transition message="Loading gift store..." />,
})
