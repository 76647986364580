import React from 'react'
import { Flex, Text } from '@sendoutcards/quantum-design-ui'

type CardPanelsNavigatorProps = {
  onChangePosition: (position: string) => void
  currentPosition: string
  positions: string[]
}

export const CardPanelsNavigator = (props: CardPanelsNavigatorProps) => {
  const { positions, currentPosition, onChangePosition } = props

  return (
    <Flex
      backgroundColor="#d0d0d029"
      justifyContent="space-between"
      alignItems="center"
      borderRadius="circle"
      inset="x_5"
      width="100%"
    >
      {positions.map(position => {
        const isSelected = position === currentPosition
        return (
          <Flex
            justifyContent="center"
            alignItems="center"
            backgroundColor={isSelected ? '#fff' : 'transparent'}
            inset={{ vertical: 'x_75', horizontal: 'x1_5' }}
            borderRadius="circle"
            cursor="pointer"
            opacity={isSelected ? 1 : 0.5}
            style={{
              transition: 'background-color 0.3s ease',
              backgroundColor: isSelected ? '#fff' : 'transparent',
            }}
            onClick={() => {
              onChangePosition(position)
            }}
          >
            <Text
              type="footnote"
              content={position}
              weight="semiBold"
              style={{ fontFamily: 'Montserrat, san-seriff' }}
            />
          </Flex>
        )
      })}
    </Flex>
  )
}
