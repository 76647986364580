import { Maybe, Operation } from '../../../__generated__/globalTypes'

import {
  TraineeFragment,
  traineeFragmentString,
} from '../../fragments/__generated__/TraineeFragment'

import { storefrontFragmentString } from '../../fragments/__generated__/StorefrontFragment'

import getPrompitingsCoachTraineesRawString from '../GetPrompitingsCoachTrainees.graphql'

const getPrompitingsCoachTraineesString = [
  getPrompitingsCoachTraineesRawString,
  traineeFragmentString,
  storefrontFragmentString,
].join('\n\n')

export type GetPrompitingsCoachTraineesData = {
  account: {
    promptingsCoach: Maybe<{
      trainees: TraineeFragment[]
    }>
  }
}

export type GetPrompitingsCoachTrainees =
  Operation<GetPrompitingsCoachTraineesData>

export const GetPrompitingsCoachTrainees = (): GetPrompitingsCoachTrainees => ({
  query: getPrompitingsCoachTraineesString,
  variables: undefined,
})
