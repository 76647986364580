import React, { FC } from 'react'
import { SVGProps } from './types'

export const Crown: FC<SVGProps> = ({ size, onClick, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_2135_13407)">
        <path
          d="M8.23524 11.9937C7.02604 11.9937 5.81685 11.9785 4.60808 11.9996C3.90085 12.0118 3.41785 11.5499 3.27581 10.9472C2.92599 9.4628 2.54119 7.98681 2.17367 6.50661C1.99918 5.8036 2.55004 5.25148 3.25263 5.42302C3.82794 5.56337 4.40324 5.70456 4.97686 5.85249C5.11805 5.88874 5.21626 5.86008 5.30687 5.74586C5.94118 4.9476 6.58097 4.15398 7.21528 3.35571C7.76319 2.66661 8.7018 2.65902 9.25013 3.34433C9.88402 4.13627 10.5188 4.92737 11.1484 5.72268C11.2496 5.85039 11.3516 5.89127 11.5147 5.8487C12.0824 5.69908 12.6535 5.56168 13.2237 5.42217C13.9255 5.25064 14.4742 5.8036 14.2998 6.50872C13.9293 8.00452 13.5563 9.49989 13.1829 10.9953C13.0214 11.641 12.5743 11.992 11.91 11.9933C10.6852 11.995 9.46045 11.9937 8.23524 11.9937ZM3.21934 6.46025C3.22229 6.50535 3.2206 6.526 3.22566 6.54539C3.57379 7.93877 3.92192 9.33214 4.2709 10.7251C4.32738 10.951 4.37247 10.9835 4.61103 10.9835C7.02394 10.9835 9.43642 10.9835 11.8493 10.9835C12.1069 10.9835 12.1477 10.9527 12.2088 10.7078C12.546 9.36038 12.8828 8.01294 13.2191 6.66509C13.2347 6.60271 13.2423 6.53865 13.2566 6.46025C12.7509 6.58585 12.2662 6.6988 11.7853 6.82693C11.1969 6.9833 10.7257 6.82482 10.3481 6.34688C9.71965 5.55072 9.08323 4.76131 8.44976 3.96895C8.27612 3.75147 8.19899 3.75232 8.02408 3.97064C7.3708 4.78702 6.72006 5.60594 6.06214 6.41895C5.7334 6.82482 5.30223 6.96644 4.79521 6.84885C4.27512 6.72831 3.75839 6.59344 3.21934 6.45983V6.46025Z"
          fill={color}
        />
        <path
          d="M9.24596 1.01785C9.24385 1.57757 8.7933 2.02053 8.22769 2.01842C7.6684 2.01631 7.22586 1.56576 7.22754 1.00015C7.23007 0.440443 7.6802 -0.00209985 8.24581 7.49493e-06C8.80595 0.00211484 9.24807 0.452244 9.24596 1.01785Z"
          fill={color}
        />
        <path
          d="M14.4604 2.86011C15.021 2.85843 15.4724 3.30729 15.4737 3.86827C15.4749 4.42671 15.0227 4.88401 14.4663 4.88653C13.9163 4.88864 13.4506 4.42545 13.4485 3.87375C13.4464 3.31825 13.902 2.8618 14.4604 2.86011Z"
          fill={color}
        />
        <path
          d="M1.00032 3.85014C1.01465 3.2858 1.47278 2.8462 2.03081 2.86096C2.59305 2.87571 3.03896 3.33764 3.02463 3.89145C3.0103 4.44568 2.53741 4.90002 1.98908 4.88569C1.43148 4.87136 0.986409 4.40564 1.00032 3.85014Z"
          fill={color}
        />
      </g>
      <defs>
        <filter
          id="filter0_d_2135_13407"
          x={0.5}
          y={0}
          width={14.9736}
          height={12.4999}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx={-0.5} dy={0.5} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2135_13407"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2135_13407"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}
