import React, { FC } from 'react'

export const DuvetHandleArrow: FC<{}> = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="20px"
      height="9px"
      viewBox="0 0 8 3"
      enableBackground="new 0 0 8 3"
      xmlSpace="preserve"
    >
      <path
        opacity="1"
        fill="#F9FAFB"
        enableBackground="new    "
        d="M1.42,0.105c0.116-0.116,0.303-0.116,0.419,0L4,2.266
              l2.161-2.161c0.116-0.116,0.303-0.116,0.419,0c0.116,0.116,0.116,0.303,0,0.419l-2.37,2.37c-0.116,0.116-0.303,0.116-0.419,0
              l-2.37-2.37C1.304,0.409,1.304,0.221,1.42,0.105z"
      />
    </svg>
  )
}
