import React from 'react'

export type Variation = {
  id: string
  color: string
}

type VariationToggleProps = {
  variations: Variation[]
  selectedVariationId?: string
  selectVariation: (id: string) => void
  className?: string
}

export const VariationToggle: React.FC<VariationToggleProps> = props => {
  const { variations, selectedVariationId, selectVariation } = props
  return (
    <div
      id="variation_toggle"
      style={{
        display: 'flex',
        minWidth: 'auto',
        height: '28px',
        background: 'rgba(238, 236, 236, 0.7)',
        borderRadius: '100px',
        position: 'absolute',
        zIndex: '1',
        bottom: '0',
        transform: 'translateX(-50%)',
        padding: '2px 4px',
        boxShadow: `0 15px 20px -15px rgba(125, 124, 124, 0.3),
        0 55px 50px -35px rgba(156, 156, 156, 0.3),
        0 85px 60px -25px rgba(255, 255, 255, 0.1)`,
      }}
    >
      {variations.map(variation => {
        const hasHex = new RegExp('#')
        const isHexValue = hasHex.test(variation.color)
        const variationColor = isHexValue
          ? variation.color
          : `#${variation.color}`
        const activeBorder: React.CSSProperties = {
          borderColor:
            variation.id === selectedVariationId
              ? variationColor
              : 'transparent',
        }
        return (
          <div
            id={`variation_toggle_${variation.id}`}
            style={{
              position: 'relative',
              width: '24px',
              height: '24px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={() => selectVariation(variation.id)}
            key={variation.id}
          >
            <div
              style={{
                position: 'relative',
                width: '22px',
                height: '22px',
                display: 'inline-block',
                border: '4px solid transparent', // border color set in variationToggle.js
                borderRadius: '100%',
                ...activeBorder,
              }}
            >
              <div
                style={{
                  pointerEvents: 'none',
                  width: '15px',
                  height: '15px',
                  borderRadius: '100%',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  backgroundColor: variationColor,
                }}
              />
            </div>
          </div>
        )
      })}
    </div>
  )
}
