import React, { FC } from 'react'

import styles from './styles.module.scss'
import { DuvetHandleArrow } from '../HandleArrow'

export type DuvetHandleDirection = 'up' | 'down'
export type DuvetHandleProps = {
  direction?: DuvetHandleDirection
}

export const DuvetHandle: FC<DuvetHandleProps> = ({ direction }) => {
  return (
    <>
      <div
        className={`${styles.wrapper} ${
          direction
            ? direction === 'up'
              ? styles.shouldAnimateUp
              : styles.shouldAnimateDown
            : ''
        }`}
      >
        <div className={styles.icon}>
          <div
            className={styles.arrows}
            style={{ transform: direction === 'up' ? 'rotateZ(180deg)' : '' }}
          >
            <div className={styles.arrow}>
              <DuvetHandleArrow />
            </div>
            <div className={styles.arrow}>
              <DuvetHandleArrow />
            </div>
          </div>
        </div>
        <div className={styles.title}>
          {direction === 'up' ? 'SWIPE UP' : 'SWIPE DOWN'}
        </div>
      </div>
    </>
  )
}
