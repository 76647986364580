import React, { FC } from 'react'
import { SVGProps } from './types'

export const Stars: FC<SVGProps> = ({ size, color, onClick }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <g clipPath="url(#clip0_4824_4210)">
        <path
          d="M10.9229 10.0544C11.2934 9.98952 11.5726 10.5112 11.1996 10.7065C10.6927 10.9718 10.3639 10.1525 10.9229 10.0544Z"
          fill={color ? color : '#FECD39'}
        />
        <path
          d="M9.81247 8.53391L9.32248 10.0286C8.86758 11.0401 7.77266 11.1306 6.79238 11.4665C7.3967 11.718 8.1338 11.7886 8.67521 12.159C9.45103 12.6896 9.51303 13.6033 9.78313 14.3993C9.8288 14.3993 9.83909 14.315 9.85119 14.2807C10.1164 13.5328 10.1558 12.7456 10.8557 12.2113C11.3956 11.7992 12.1844 11.7467 12.8026 11.4665C12.482 11.3175 11.4343 11.2464 11.7443 10.7073C11.8438 10.5345 12.0132 10.535 12.2001 10.5671C12.7948 10.6688 13.5189 11.0367 14.1319 11.1576C14.425 11.3206 14.4293 11.6362 14.1129 11.7857C13.1024 12.2641 11.3097 12.2236 10.892 13.3394C10.5949 14.1328 10.428 15.005 10.1301 15.8029C9.96915 16.0752 9.59954 16.0668 9.46585 15.7742C9.1168 15.0103 9.01064 13.9859 8.66583 13.2059C8.50704 12.847 8.26537 12.6699 7.88729 12.526C7.11026 12.2304 6.22889 12.0547 5.44248 11.7666C5.19628 11.6331 5.18993 11.3273 5.43159 11.1837C6.21558 10.8852 7.09756 10.7149 7.87882 10.4266C8.21032 10.304 8.47951 10.112 8.63105 9.80626C9.01094 9.03869 9.10108 7.94988 9.45708 7.17782C9.60952 6.84729 10.0172 6.88102 10.16 7.21463C10.4443 7.8799 10.5632 8.71744 10.8269 9.40182C10.8381 9.52323 10.7577 9.68063 10.6403 9.73628C10.0136 10.0334 9.97429 8.8383 9.81338 8.53335L9.81247 8.53391Z"
          fill={color ? color : '#FECD39'}
        />
        <path
          d="M12.7948 0.00809005C12.9603 -0.0233883 13.1194 0.0465948 13.2016 0.17841C13.433 0.71298 13.512 1.37178 13.7376 1.89763C13.7733 1.98083 13.8232 2.05643 13.8894 2.12136C14.1989 2.42518 15.3407 2.56037 15.7943 2.7928C16.0832 2.94064 16.056 3.25514 15.7838 3.40326C15.3582 3.59775 14.2137 3.75851 13.904 4.0421C13.5809 4.33749 13.4318 5.56233 13.2189 5.98757C13.0634 6.23518 12.7125 6.25823 12.555 5.99966C12.3311 5.46621 12.2507 4.80095 12.0184 4.281C11.9113 4.04097 11.7873 3.97577 11.5417 3.88218C11.0832 3.70708 10.3573 3.591 9.9556 3.39145C9.6855 3.25711 9.72815 2.89061 10.0031 2.77537C10.4946 2.56908 11.2096 2.48251 11.6854 2.26188C11.8496 2.18572 11.9757 2.0154 12.039 1.86082C12.2389 1.37374 12.3251 0.621637 12.5595 0.183469C12.6043 0.0997146 12.6947 0.027764 12.7945 0.00893322L12.7948 0.00809005ZM14.4638 3.08932C13.8761 2.93754 13.3852 2.77369 13.1091 2.229C13.0065 2.02636 12.9745 1.80432 12.8783 1.60112C12.7271 2.12079 12.5804 2.56992 12.0378 2.84114C11.8251 2.94738 11.6007 2.97324 11.3971 3.0598C11.3672 3.07245 11.3264 3.07695 11.3227 3.11658C11.8251 3.22872 12.2967 3.39258 12.5737 3.82062C12.731 4.06346 12.7912 4.33665 12.8783 4.60449C13.0268 4.10281 13.1868 3.62417 13.7083 3.35491L14.4635 3.08903L14.4638 3.08932Z"
          fill={color ? color : '#FECD39'}
        />
        <path
          d="M3.00829 2.702C3.18069 2.6649 3.341 2.7228 3.42629 2.86305C3.63046 3.19835 3.76687 3.99065 3.91507 4.40155C4.02487 4.7065 4.06963 4.84562 4.40688 4.98278C4.91048 5.18795 5.5263 5.28098 6.03897 5.48671C6.28518 5.62218 6.29304 5.94596 6.03897 6.07918C5.64637 6.28491 4.9123 6.40324 4.45951 6.57609C4.09292 6.71605 4.04786 6.80515 3.92264 7.14382C3.75749 7.59014 3.65375 8.2762 3.452 8.67109C3.32164 8.92657 2.95052 8.95186 2.79959 8.69357C2.60238 8.35658 2.47444 7.58199 2.32321 7.17165C2.19799 6.83241 2.16049 6.72224 1.78694 6.57553C1.29665 6.383 0.719249 6.27339 0.217462 6.09745C-0.0644335 5.9558 -0.0759271 5.63174 0.207178 5.48671C0.592517 5.28941 1.28939 5.17249 1.72675 5.01819C2.04162 4.90717 2.16291 4.82707 2.28419 4.52606C2.49078 4.01398 2.58938 3.40802 2.78386 2.88553C2.81683 2.82201 2.9354 2.71746 3.00859 2.70172L3.00829 2.702ZM4.67819 5.7554C3.7708 5.62499 3.26478 5.09604 3.12262 4.26749C2.94387 4.90015 2.7757 5.34028 2.0885 5.60841C1.9406 5.66603 1.74944 5.69385 1.6094 5.75259C1.57401 5.76749 1.55495 5.7391 1.56705 5.81105C2.1257 5.926 2.65713 6.15141 2.90152 6.66237C2.99891 6.86586 3.02946 7.09492 3.12262 7.29925C3.26569 6.77985 3.39635 6.33438 3.93292 6.05923C4.16551 5.94006 4.41293 5.90802 4.63554 5.81414C4.67093 5.79925 4.68999 5.82763 4.67789 5.75568L4.67819 5.7554Z"
          fill={color ? color : '#FECD39'}
        />
      </g>
      <defs>
        <clipPath id="clip0_4824_4210">
          <rect
            width={16}
            height={16}
            fill="white"
            transform="translate(0 0.000488281)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
