import React from 'react'
import UserAvatar from 'react-user-avatar'
import { useGetDetailedCountries, usePaginatedContacts } from 'src/react_query'
import styles from './contactSelector.module.scss'
import { Text } from '@sendoutcards/quantum-design-ui'
import { Contact } from 'src/graphql/generated/graphql'
import ReactCountryFlag from 'react-country-flag'
import { Icon as ChromeIcon } from 'src/chrome'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'

type ContactSelectorProps = {
  search: string
  shouldShowSelector: boolean
  onContactSelect: (contact: Contact) => void
  onSelectNewContact: () => void
  currentlySelectedContactId?: string
}

const ContactSelector: React.FC<ContactSelectorProps> = props => {
  const {
    search,
    shouldShowSelector,
    onContactSelect,
    onSelectNewContact,
    currentlySelectedContactId,
  } = props

  const countriesList = useGetDetailedCountries()

  const contactsQuery = usePaginatedContacts(
    {
      search: search ?? '',
      showSecondaryContacts: true,
      page: 1,
    },
    { enabled: search !== '' },
  )
  return (
    <>
      {(shouldShowSelector && contactsQuery.isFetching) ||
        (shouldShowSelector && contactsQuery.data && (
          <div className={styles.wrapper}>
            {contactsQuery.isFetching && <> LOADING... </>}
            {!contactsQuery.isFetching && contactsQuery.data && (
              <>
                {contactsQuery.data.paginatedContacts.contacts.map(contact => {
                  const normCountry =
                    contact.country === 'SendOutCards'
                      ? 'United States'
                      : contact.country
                  const countryIso = countriesList.data?.find(
                    p => p.postalName === normCountry,
                  )?.alpha2
                  return (
                    <div
                      className={styles.contact}
                      onClick={() =>
                        onContactSelect(contact as unknown as Contact)
                      }
                    >
                      <div className={styles.leftColumn}>
                        <div className={styles.avatar}>
                          <UserAvatar
                            name={`${contact.firstName} ${contact.lastName}`}
                            size={50}
                            src=""
                            style={{
                              color: 'white',
                              fontSize: '1.1rem',
                              letterSpacing: 1,
                            }}
                          />
                        </div>
                        <div className={styles.info}>
                          <Text
                            type="body"
                            weight="semiBold"
                            content={`${contact.firstName} ${contact.lastName}`}
                          />
                          <div className={styles.location}>
                            {countryIso && (
                              <ReactCountryFlag
                                countryCode={countryIso}
                                svg={true}
                                style={{
                                  width: '24px',
                                  height: '14px',
                                  borderRadius: '1px',
                                  marginRight: '4px',
                                }}
                              />
                            )}
                            <Text
                              type="footnote"
                              content={`${contact.state ?? ''},`}
                            />
                            <Text
                              type="footnote"
                              content={contact.city ?? ''}
                            />
                          </div>
                        </div>
                      </div>
                      {contact.id === currentlySelectedContactId ? (
                        <div className={styles.badge}>
                          <div className={styles.badgeCircle}>
                            <Icon
                              name="check"
                              size={20}
                              color="#65ad66"
                              height={20}
                              width={20}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className={styles.plus}>
                          <ChromeIcon
                            icon={'PLUS'}
                            height={20}
                            style={{
                              display: 'flex',
                              aspectRatio: '1/1',
                              alignItems: 'center',
                            }}
                          />
                        </div>
                      )}
                    </div>
                  )
                })}
                <div className={styles.addContact} onClick={onSelectNewContact}>
                  <div className={styles.addContactLeftColumn}>
                    <ChromeIcon icon={'ADDUSER'} />
                    <Text type="body" weight="semiBold" content={search} />
                  </div>
                  <div className={styles.addContactLabel}>
                    <Text type="footnote" content="Add Contact" />
                  </div>
                </div>
              </>
            )}
          </div>
        ))}
    </>
  )
}

export default ContactSelector
