import React from 'react'
import { Div, Flex, Icon, Text } from '@sendoutcards/quantum-design-ui'
import { RightArrowIcon } from 'src/editor/components/RightArrowIcon'

type CartWithCounterProps = {
  count?: number
  isActive: boolean
  isMobile?: boolean
  backgroundColor?: string
  actionType?: 'mobile' | 'desktop'
}

export const CartWithCounter = (props: CartWithCounterProps) => {
  const {
    isActive,
    count,
    isMobile = false,
    backgroundColor,
    actionType = 'desktop',
  } = props
  return (
    <Flex
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="relative"
      outset={{ top: '6px', left: '6px' }}
      backgroundColor={backgroundColor}
      flexWrap="wrap"
    >
      {actionType === 'mobile' ? (
        <>
          <Flex backgroundColor={backgroundColor}>
            <RightArrowIcon />
          </Flex>
          <Text
            content="Proceed"
            type="footnote"
            weight="semiBold"
            color="inverseHeading"
            style={{ fontSize: '.7rem' }}
          />
        </>
      ) : (
        <Icon
          size="small"
          name="bag"
          primaryColor={
            isMobile
              ? 'primaryHeadingText'
              : isActive
              ? 'inverseHeadingText'
              : 'primaryHeadingText'
          }
        />
      )}
      {count !== undefined && count > 0 && actionType !== 'mobile' && (
        <Div
          height="11px"
          width="11px"
          borderRadius="circle"
          backgroundColor="#ff1f93"
          position="absolute"
          display="flex"
          justifyContent="center"
          alignItems="center"
          top="-2px"
          left="10px"
          zIndex={1}
        >
          <p style={{ fontSize: '8px', color: '#fff', fontWeight: 700 }}>
            {count}
          </p>
        </Div>
      )}
    </Flex>
  )
}
