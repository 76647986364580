import React, { FC } from 'react'
import { SVGProps } from './types'

export const CardWHeart: FC<SVGProps> = ({ size, color, onClick }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M14.455 2.673a.367.367 0 0 1 .36.373m-.36-.373.26 2.088h.1m-.36-2.088h-.005c-.016 0-.045 0-.076.008l-9.18 1.836a.37.37 0 0 0-.294.365v13.625c0 .202.165.366.367.366H15.93a.367.367 0 0 0 .366-.366V5.127a.367.367 0 0 0-.366-.366h-1.116m-.36-2.088.36 2.188v-.1m0-1.715v.001l-.1-.002h.1zm0 0v1.715m-7.086 0 6.353-1.268V4.76zm7.835 13.38h-9.93V5.493h9.93z"
        fill={color}
        stroke="#F9FAFB"
        strokeWidth={0.2}
      />
      <path
        d="M7.186 11.153c.072-1.136.8-1.733 1.464-1.807.114-.014.226-.022.322-.024.275-.016.55.034.8.146m-2.586 1.685v.427m0-.427.1.006v-.003m-.1-.003h.1v.003m-.1.424c.096.78.597 1.474 1.728 2.38m-1.728-2.38h.1v-.007m-.1.007.1-.013v.006m1.628 2.387c.495.397 1.117.85 1.948 1.384M8.914 13.96l.062-.078m-.062.078.062-.078m1.886 1.462c.736-.525 1.317-.97 1.773-1.354m-1.773 1.354.054-.085-.057-.036m.003.12-.058-.08.055-.04m1.776-1.234c1.186-1.01 1.695-1.762 1.749-2.583m-1.749 2.583-.064-.076m.064.076-.065-.076m1.814-2.507-.006-.418m.006.418-.1-.006v.004m.1.002-.1.002v-.004m.094-.416c-.117-1.112-.877-1.68-1.535-1.728m1.535 1.728-.1.001v.005m.1-.006-.099.01v-.004M12.843 9.26a3 3 0 0 0-.325 0c-.733.014-1.072.275-1.56.856m1.885-.856-.007.1m.007-.1-.005.1h-.001m-1.879.756a.1.1 0 0 1-.026.027m.026-.027.077.064.001-.001m-.078-.063.08.06-.002.003m-.104-.036a.266.266 0 0 1-.376-.027 2.4 2.4 0 0 0-.784-.648m1.16.675.06.08.002-.002m-.062-.078.065.076-.003.002m-1.222-.753-.046.09h.003m.043-.09-.04.092q-.003 0-.003-.002m0 0c.29.151.544.363.748.62l.003.003a.366.366 0 0 0 .514.04M9.73 9.558a1.6 1.6 0 0 0-.751-.136h-.003c-.093.002-.202.01-.314.023-.611.069-1.305.622-1.375 1.711m3.708-.935a.2.2 0 0 0 .042-.042m0 0c.242-.288.44-.487.66-.617s.47-.195.824-.202h.003a3 3 0 0 1 .314 0m0 0c.606.045 1.328.571 1.441 1.634m0 0 .006.41m0 0c-.052.776-.534 1.504-1.714 2.51m0 0c-.441.371-1.002.802-1.71 1.309m0 0a23 23 0 0 1-1.884-1.34m0 0c-1.122-.9-1.597-1.571-1.69-2.31m0 0v-.417m5.215-2.529a2.32 2.32 0 0 0-1.766.772 2.7 2.7 0 0 0-.703-.498l-.004-.001a2.4 2.4 0 0 0-1.085-.203h-.001c-.111 0-.246 0-.383.027-.917.107-1.923.909-2.02 2.42v.516l.001.012c.123.972.704 1.782 1.962 2.79.537.435 1.225.917 2.14 1.517l.002.001a.365.365 0 0 0 .41-.008c.819-.58 1.461-1.07 1.963-1.493 1.324-1.126 1.898-2.01 1.973-3.036v-.489c-.14-1.485-1.184-2.248-2.1-2.313a4 4 0 0 0-.389-.014Z"
        fill={color}
        stroke="#F9FAFB"
        strokeWidth={0.2}
      />
    </svg>
  )
}
