import { Div, Flex, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { IconTypeOrIconString } from 'src/app/types'
import { Icon } from 'src/chrome'
import { CardType } from 'src/graphql/generated/graphql'

type CardTypeTileProps = {
  title: string
  cardType: CardType
  icon?: {
    icon?: IconTypeOrIconString
    size?: number
    viewBox?: string
  }
  upgrade?: string
  isSelected: boolean
  isHorizontal: boolean
  isDigital?: boolean
  imageUrl: string
  size?: string
  onSelect: (isSelected: boolean) => void
}

export const CardTypeTile = (props: CardTypeTileProps) => {
  const {
    title,
    cardType,
    icon,
    upgrade,
    isSelected,
    isHorizontal,
    isDigital = false,
    imageUrl,
    size = '3.25rem',
    onSelect,
  } = props
  const primaryFont = 'Montserrat, san serif'

  const handleSelect = () => {
    onSelect(isSelected)
  }

  return (
    <Flex
      cursor="pointer"
      width="100%"
      height="fit-content"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      backgroundColor={'background'}
      inset={{ top: '.75rem', bottom: '.25rem', horizontal: '1rem' }}
      onClick={handleSelect}
      style={{
        position: 'relative',
        borderRadius: '.25rem',

        border: isSelected
          ? '3px solid #8fc3ff'
          : '2px solid rgba(233, 233, 233, 0.70)',
      }}
    >
      <Div position="relative" width="100%">
        <CardTypeView
          cardImageUrl={imageUrl}
          cardType={cardType}
          size={size}
          isHorizontal={isHorizontal}
          isDigital={isDigital}
        />

        {icon && icon.icon && (
          <Icon
            icon={icon.icon}
            color={'#6F8394'}
            size={icon.size ?? 30}
            viewBox={icon.viewBox ?? '0 0 25 32'}
            style={{
              position: 'absolute',
              right: 0,
              bottom: 0,
            }}
          />
        )}
      </Div>

      <Text
        type="body"
        content={title}
        style={{
          fontFamily: primaryFont,
          fontSize: '.625rem',
          fontWeight: 700,
          color: '#6B7280',
        }}
      />
      {upgrade && (
        <Flex
          position="absolute"
          width="fit-content"
          height="fit-content"
          right="6px"
          top="6px"
          backgroundColor={isSelected ? '#fff' : '#c4c4c4d9'}
          inset={{ horizontal: '.5rem' }}
          style={{ borderRadius: '30px' }}
        >
          <Text
            type="body"
            content={`+${upgrade}`}
            lineHeight={1.5}
            style={{
              fontFamily: primaryFont,
              fontSize: '.625rem',
              fontWeight: 700,
              color: '#404040',
            }}
          />
        </Flex>
      )}
    </Flex>
  )
}

export const CardTypeView = (props: {
  cardImageUrl: string
  cardType: CardType
  size?: string
  isHorizontal?: boolean
  isDigital?: boolean
}) => {
  const {
    cardImageUrl: frontPanelImageUrl,
    cardType,
    size = '8rem',
    isHorizontal = false,
    isDigital = false,
  } = props
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
      }}
    >
      {!isDigital && cardType !== CardType.Postcard && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            width: size,
            aspectRatio: '5/7',
            backgroundImage: `url(https://www.sendoutcards.com/media/images/TileEnvelope.png)`, // envelope image
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            transform: isHorizontal
              ? 'rotate(96deg)translate(-1%, 0%)scale(1.02)'
              : 'rotate(-5deg)translate(-6%, 1%)scale(1.02)',
          }}
        />
      )}
      {isDigital && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            width: size,
            aspectRatio: '5/7',
            backgroundImage: `url(https://www.sendoutcards.com/media/images/iPhone.png)`, // iPhone image
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            transform: !isHorizontal
              ? 'translate(-18%, 0)scale(0.9)'
              : 'translate(-32%, 0)scale(0.9)',
          }}
        />
      )}
      <div
        style={{
          position: 'relative',
          backgroundImage: `url(${frontPanelImageUrl})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          aspectRatio: isHorizontal ? '7/5' : '5/7',
          ...(isHorizontal
            ? {
                height: size,
              }
            : {
                width: size,
              }),
        }}
      />
    </div>
  )
}
