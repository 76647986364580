import { Div, Flex, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { IconTypeOrIconString } from 'src/app/types'
import { Icon } from 'src/chrome'

type PaperTypeTileProps = {
  title: string
  imageUrl: string
  price: string
  icon?: IconTypeOrIconString
  size?: string
  onSelect: (isSelected: boolean) => void
  isSelected: boolean
}

export const PaperTypeTile = (props: PaperTypeTileProps) => {
  const {
    title,
    // imageUrl,
    price,
    icon,
    size = '5.25rem',
    onSelect,
    isSelected,
  } = props

  const primaryFont = 'Montserrat, san serif'

  const handleSelect = () => {
    onSelect(isSelected)
  }

  return (
    <Div
      cursor="pointer"
      position="relative"
      width={size}
      onClick={handleSelect}
      opacity={isSelected ? 1 : 0.7}
      style={{
        aspectRatio: '5/7',
        // backgroundImage: icon ? 'unset' : `url(${imageUrl})`,
        // backgroundImage: `url(${imageUrl})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        borderRadius: '.25rem',
        boxShadow: '0px 4px 28px 0px rgba(0, 0, 0, 0.2)',
        border: isSelected
          ? '3px solid #8fc3ff'
          : '2px solid rgba(233, 233, 233, 0.70)',
      }}
    >
      {icon && (
        <Icon
          icon={icon}
          // color={isSelected ? '#404040' : '#6F8394'}
          // size={70}
          // viewBox={'3 2 19 20'}
          color={'#6F8394'}
          size={60}
          viewBox="0 0 25 32"
          style={{
            position: 'absolute',
            right: 6,
            bottom: '30%',
            background: 'rgba(0, 0, 0, 0.03)',
          }}
        />
      )}

      <Flex
        position="absolute"
        width="fit-content"
        height="fit-content"
        right="6px"
        top="6px"
        backgroundColor={isSelected ? '#fff' : '#c4c4c4d9'}
        inset={{ horizontal: '.5rem' }}
        style={{ borderRadius: '30px' }}
      >
        <Text
          type="body"
          content={price}
          lineHeight={1.5}
          style={{
            fontFamily: primaryFont,
            fontSize: '.625rem',
            fontWeight: 700,
            color: '#404040',
          }}
        />
      </Flex>
      <Flex
        position="absolute"
        backgroundColor={isSelected ? '#fff' : '#c4c4c4d9'}
        justifyContent="center"
        bottom="0px"
        left="0px"
        right="0px"
        style={{
          margin: 'auto',
          borderBottomLeftRadius: '.25rem',
          borderBottomRightRadius: '.25rem',
        }}
      >
        <Text
          type="body"
          content={title}
          lineHeight={2.5}
          style={{
            fontFamily: primaryFont,
            fontSize: '.625rem',
            fontWeight: 700,
            color: '#404040',
          }}
        />
      </Flex>
    </Div>
  )
}
