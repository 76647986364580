import React, { FC } from 'react'
import { SVGProps } from './types'

export const CopyLink: FC<SVGProps> = ({ size, onClick, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M13.874 4.34c-.052.272-.102.543-.194.806-.183.522-.478.97-.863 1.361q-.988.998-1.986 1.987c-.371.369-.902.33-1.178-.082a.74.74 0 0 1 .034-.841q.076-.097.165-.183c.624-.626 1.246-1.255 1.877-1.876.485-.478.71-1.05.681-1.732-.039-.937-.862-1.837-1.79-1.96-.76-.1-1.398.122-1.934.666-.63.639-1.27 1.27-1.903 1.905-.179.18-.382.294-.646.273a.7.7 0 0 1-.603-.41.69.69 0 0 1 .054-.728q.062-.09.142-.169.964-.967 1.93-1.932C8.268.821 8.988.448 9.844.348c.017-.002.033-.01.05-.015h.754c.307.052.612.107.904.222q1.492.59 2.095 2.076c.118.29.174.596.228.903z"
        fill="#4B4E53"
      />
      <path
        d="M.548 10.047c-.003-.994.354-1.845 1.046-2.551.661-.675 1.336-1.337 2.003-2.007.467-.47 1.126-.213 1.26.283a.7.7 0 0 1-.124.651 2 2 0 0 1-.156.174q-.95.953-1.904 1.904a2.15 2.15 0 0 0-.656 1.74c.057.852.708 1.65 1.542 1.87.817.214 1.535.026 2.138-.57q.979-.973 1.95-1.95c.214-.216.463-.32.764-.25.49.115.713.703.429 1.119a1.3 1.3 0 0 1-.151.177L6.758 12.57c-.634.635-1.395 1.017-2.288 1.085-1.18.089-2.19-.303-2.998-1.179-.541-.587-.835-1.288-.922-2.078-.013-.115-.002-.234-.002-.351"
        fill={color}
      />
      <path
        d="M9.979 4.947a.7.7 0 0 1-.22.54l-.561.561Q7.467 7.776 5.74 9.508c-.218.219-.469.308-.768.228a.732.732 0 0 1-.38-1.151 2 2 0 0 1 .115-.125q1.988-1.985 3.972-3.975c.207-.208.443-.303.73-.244.345.07.571.364.57.706"
        fill={color}
      />
    </svg>
  )
}
