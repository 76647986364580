import { Div, Flex, Text } from '@sendoutcards/quantum-design-ui'
import React, { FC } from 'react'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'

import { CheckoutLineFragment } from 'src/saleor_graphql/generated/graphql'

type PreSelectedSubscriptionTileType = {
  product: CheckoutLineFragment
  onDelete: () => void
}

const PreSelectedSubscriptionTile: FC<PreSelectedSubscriptionTileType> = ({
  product,
  onDelete,
}) => {
  const hasDiscount =
    product.totalPrice.net.amount !== product.undiscountedTotalPrice.amount

  const handleTruncateTitle = (title?: string) => {
    const MAX_TITLE_LENGTH = 25
    if (!title) return

    return title.length >= MAX_TITLE_LENGTH
      ? title.substring(0, MAX_TITLE_LENGTH) + '...'
      : title
  }
  return (
    <Flex width="100%" columnGap="x2" inset={{ bottom: 'x4' }}>
      <Flex
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        backgroundColor="#404040"
        inset="x1_5"
        borderRadius="medium"
      >
        <Flex alignItems="center">
          <Div
            minWidth="3px"
            height="35px"
            style={{
              background: 'linear-gradient(161deg, #4ace9e, #67e5dd, #31d3a0)',
              borderRadius: '2px',
            }}
            outset={{ right: 'x1_5' }}
          />
          <Text
            content={handleTruncateTitle(product.variant.name)}
            type="body"
            weight="semiBold"
            style={{ color: '#fff' }}
          />
        </Flex>
        <Flex flexDirection="row" columnGap="x3" alignItems="center">
          <Flex
            justifyContent="center"
            width="4rem"
            style={{
              flex: 'none',
              flexDirection: 'column',
            }}
          >
            {hasDiscount && (
              <Text
                type="body"
                content={`$${product.undiscountedTotalPrice.amount.toFixed(2)}`}
                style={{
                  fontSize: '1rem',
                  fontWeight: 700,
                  lineHeight: 1,
                  color: '#000',
                }}
                isStrikeThrough={true}
              />
            )}
            <Flex>
              <Text
                type="body"
                content={`$${product.totalPrice.net.amount.toFixed(2)}`}
                style={{
                  fontSize: '1rem',
                  fontWeight: 700,
                  color: '#fff',
                  lineHeight: 1,
                }}
              />
              <Text
                style={{ color: '#fff', paddingLeft: '2px' }}
                type="footnote"
                content="/mo"
              />
            </Flex>
          </Flex>
          <Div
            display="flex"
            height="fit-content"
            justifyContent="center"
            alignItems="center"
            backgroundColor="#F87171"
            inset=".625rem"
            style={{ borderRadius: '.65rem', cursor: 'pointer' }}
            onClick={onDelete}
          >
            <Icon color="#fff" name="trash" size={16} />
          </Div>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default PreSelectedSubscriptionTile
