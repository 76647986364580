import React, { useRef } from 'react'
import {
  Button,
  Flex,
  LoadingSpinner,
  Text,
} from '@sendoutcards/quantum-design-ui'
import { useState } from 'src/hooks/useState'
import useActions from 'src/hooks/useActions'
import {
  useCallback,
  useDebounce,
  useEmailValidation,
  usePersistedUserData,
  useSponsorFromQueryParam,
} from 'src/hooks'
import { parseError } from 'src/utils/parseError'
import { RawCreateAccountForm } from './RawCreateAccountForm'
import ReCAPTCHA from 'react-google-recaptcha'
import useUsernameValidation from 'src/hooks/useUsernameValidation'
import { VerifyClient } from './VerifyClient'
import { Portal } from 'src/portal/portal'
import { CheckoutFindMySponsor } from '../../checkout_find_my_sponsor/CheckoutFindMySponsor'
import { sendoutcardsPromotionsGenealogyId } from 'src/app/constants'
import { ValidationError } from 'src/email_verification/VerifyEmail/ValidationFailureEmailVerification'
import {
  useShouldVerifyEmail,
  useUpdateAccount,
  useValidateEmailVerification,
} from 'src/react_query'
import { AccountFragment } from 'src/graphql/generated/graphql'

type InputType = {
  error: string | null | undefined
  value: string
}

type UpdateAccountFormState = {
  email: InputType
  username: InputType
  password: InputType
  firstName: InputType
  lastName: InputType
}

interface Props {
  shouldNotAutoComplete?: boolean
  handleSuccessfullAccUpdate: (account: AccountFragment) => void
  shouldStackForm?: boolean
  isSponsorExpanded: boolean
  handleIsSponsorExpanded: (isExpanded: boolean) => void
}

export const FinishSparseAccountSetupForm: React.FC<Props> = props => {
  const {
    shouldNotAutoComplete,
    handleSuccessfullAccUpdate,
    shouldStackForm = false,
    isSponsorExpanded,
    handleIsSponsorExpanded,
  } = props
  const [createAccountFormState, setCreateAccountFormState] =
    useState<UpdateAccountFormState>({
      email: { error: undefined, value: '' },
      username: { error: null, value: '' },
      password: { error: null, value: '' },
      firstName: { error: null, value: '' },
      lastName: { error: null, value: '' },
    })

  const { account } = usePersistedUserData()

  const shouldRequireUsernamePass = account && !account.username
  const shouldRequireName =
    (account && !account.firstName) || (account && !account.lastName)

  const { hasSponsorQP, fetchSponsor, sponsor } = useSponsorFromQueryParam()

  const [universalError, setUniversalError] = useState<string | null>(null)

  const [isLoading, setIsLoading] = useState(false)
  const [shouldVerifyAccount, setShouldVerifyAccount] = useState(false)
  const [isPhoneNumberTaken, setIsPhoneNumberTaken] = useState(false)
  const [isPhoneNumberInvalid, setIsPhoneNumberInvalid] = useState(false)

  const debouncedUsername = useDebounce(createAccountFormState.username, 500)
  const usernameValidation = useUsernameValidation(debouncedUsername.value)

  const debouncedEmail = useDebounce(createAccountFormState.email, 500)
  const emailValidation = useEmailValidation(debouncedEmail.value)

  const hasRequiredFields = (
    username: string,
    password: string,
    email: string,
    firstName: string,
    lastName: string,
  ) => {
    if (
      (shouldRequireUsernamePass && !username) ||
      (shouldRequireUsernamePass && !password) ||
      !email ||
      (shouldRequireName && !firstName) ||
      (shouldRequireName && !lastName)
    ) {
      setCreateAccountFormState({
        ...createAccountFormState,
        password: {
          ...createAccountFormState.password,
          error: !password ? 'Password is required' : null,
        },
        email: {
          ...createAccountFormState.email,
          error: !email ? 'Email is required' : null,
        },
        username: {
          ...createAccountFormState.username,
          error: !username ? 'Username is required' : null,
        },
        firstName: {
          ...createAccountFormState.firstName,
          error: !firstName ? 'First Name is required' : null,
        },
        lastName: {
          ...createAccountFormState.lastName,
          error: !lastName ? 'Last Name is required' : null,
        },
      })
      return false
    }
    return true
  }

  const updateAccountMutation = useUpdateAccount()
  const validateEmailVerificationMutation = useValidateEmailVerification()
  const { data: shouldVerifyEmail } = useShouldVerifyEmail()
  const actions = useActions()
  const recaptchaRef = useRef<ReCAPTCHA>(null)
  const [isCheckingPhoneNumber, setIsCheckingPhoneNumber] = useState(false)

  const [sponsorGenealogyId, setSponsorGenealogyId] = useState<string>()

  const [validationFailure, setValidationFailure] = useState<
    ValidationError | undefined
  >()

  const handleIsCheckingPhoneNumber = useCallback((isChecking: boolean) => {
    setIsCheckingPhoneNumber(isChecking)
  }, [])

  const handleIsPhoneNumberTaken = useCallback((isTaken: boolean) => {
    setIsPhoneNumberTaken(isTaken)
  }, [])

  const handleIsPhoneNumberInvalid = useCallback((isInvalid: boolean) => {
    setIsPhoneNumberInvalid(isInvalid)
  }, [])

  const handleSelectedSponsor = (id: string) => {
    setSponsorGenealogyId(id)
  }

  const onSubmit = async () => {
    if (shouldVerifyEmail) {
      setShouldVerifyAccount(true)
    } else {
      await onSubmitUpdateAccount('')
    }
  }

  const onUpdateAccount = async (
    username: string,
    password: string,
    email: string,
    firstName: string,
    lastName: string,
    code: string,
  ) => {
    if (!hasRequiredFields(username, password, email, firstName, lastName))
      return
    try {
      setIsLoading(true)
      setShouldVerifyAccount(false)

      try {
        if (shouldVerifyEmail) {
          const { validateEmailVerificationCode } =
            await validateEmailVerificationMutation.mutateAsync({
              email: email,
              code: code,
            })

          if (
            validateEmailVerificationCode &&
            validateEmailVerificationCode.__typename ===
              'ValidateEmailVerificationCodeFailure'
          ) {
            setValidationFailure({
              type: validateEmailVerificationCode.failureType,
              message: validateEmailVerificationCode.message,
            })
            return
          }
        }

        const { account } = (
          await updateAccountMutation.mutateAsync({
            account: {
              username: shouldRequireUsernamePass ? username : undefined,
              password: shouldRequireUsernamePass ? password : undefined,
              email: email,
              firstName: shouldRequireName ? firstName : undefined,
              lastName: shouldRequireName ? lastName : undefined,
              sponsorGenealogyId: sponsorGenealogyId
                ? sponsorGenealogyId
                : sponsor
                  ? sponsor.genealogyId.toString()
                  : String(sendoutcardsPromotionsGenealogyId),
            },
          })
        ).updateAccount

        if (account) actions.updatedAccount(account)

        handleSuccessfullAccUpdate(account)
      } catch (error) {
        setUniversalError(parseError(error))
      }
    } catch (error) {
      setUniversalError(parseError(error))
    } finally {
      recaptchaRef.current?.reset()
      setIsLoading(false)
    }
  }

  const onSubmitUpdateAccount = async (code: string) => {
    onUpdateAccount(
      createAccountFormState.username.value,
      createAccountFormState.password.value,
      createAccountFormState.email.value,
      createAccountFormState.firstName.value,
      createAccountFormState.lastName.value,
      code,
    )
  }

  return (
    <>
      {!shouldVerifyAccount && (
        <Flex
          backgroundColor="foreground"
          borderRadius="large"
          width="100%"
          flexDirection="column"
        >
          <div style={{ display: 'flex', marginBottom: '1rem' }}>
            <Text
              type="largeBody"
              color="primaryBody"
              content="Step 1: "
              outset={{ right: 'x1' }}
            />
            <Text
              type="largeBody"
              color="primaryHeading"
              content="Account Information"
              weight="bold"
            />
          </div>
          <Text
            type="caption"
            color="primaryHeading"
            content="Please update your account information before continuing"
            weight="bold"
          />
          <RawCreateAccountForm
            data={{
              email: createAccountFormState.email.value,
              username: createAccountFormState.username.value,
              password: createAccountFormState.password.value,
              firstName: createAccountFormState.firstName.value,
              lastName: createAccountFormState.lastName.value,
              phoneNumber: '',
            }}
            displayFields={{
              username: shouldRequireUsernamePass,
              password: shouldRequireUsernamePass,
              name: true,
              email: true,
            }}
            isLoading={isLoading}
            setData={input => {
              if (isLoading) return
              setCreateAccountFormState({
                ...createAccountFormState,
                email: { value: input.email, error: null },
                username: {
                  value: input.username,
                  error: null,
                },
                password: {
                  value: input.password,
                  error: null,
                },
                firstName: {
                  value: input.firstName,
                  error: null,
                },
                lastName: {
                  value: input.lastName,
                  error: null,
                },
              })
            }}
            errors={{
              email: emailValidation.errorMessage,
              username:
                !usernameValidation.isValid &&
                createAccountFormState.username.value !== ''
                  ? 'This username is taken'
                  : undefined,
              password: createAccountFormState.password.error,
              firstName: createAccountFormState.firstName.error,
              lastName: createAccountFormState.lastName.error,
              universal: universalError,
              phoneNumber: undefined,
            }}
            shouldNotAutoComplete={shouldNotAutoComplete}
            shouldStackForm={shouldStackForm}
            isLoadingEmailValidation={emailValidation.isLoading}
            isLoadingUsernameValidation={usernameValidation.isLoading}
            handleIsCheckingPhoneNumber={handleIsCheckingPhoneNumber}
            handleIsPhoneNumberTaken={handleIsPhoneNumberTaken}
            handleIsPhoneNumberInvalid={handleIsPhoneNumberInvalid}
          />
          <Portal
            attachToContainerId="checkout-find-my-sponsor-container"
            wrapperStyles={{ width: '100%' }}
          >
            <>
              {((hasSponsorQP && fetchSponsor && !fetchSponsor.isLoading) ||
                !hasSponsorQP) && (
                <CheckoutFindMySponsor
                  isExpanded={isSponsorExpanded}
                  setIsExpanded={handleIsSponsorExpanded}
                  onSelectedSponsor={handleSelectedSponsor}
                  presetSponsorId={sponsor?.id}
                />
              )}
              {hasSponsorQP && fetchSponsor.isLoading && (
                <LoadingSpinner size="large" />
              )}
            </>
          </Portal>
          <Flex
            outset={{ top: 'auto' }}
            inset={{ top: 'x3' }}
            justifyContent="flex-end"
            alignItems="center"
            columnGap="x2"
          >
            <div style={{ width: '50%' }}>
              {process.env.REACT_APP_RECAPTCHA_KEY && (
                <ReCAPTCHA
                  ref={recaptchaRef}
                  size="invisible"
                  sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                />
              )}
              <Button
                fullWidth={true}
                disabled={
                  isLoading ||
                  !emailValidation.isValid ||
                  emailValidation.isLoading ||
                  !usernameValidation.isValid ||
                  usernameValidation.isLoading ||
                  isCheckingPhoneNumber ||
                  isPhoneNumberTaken ||
                  isPhoneNumberInvalid ||
                  !createAccountFormState.firstName.value ||
                  !createAccountFormState.lastName.value ||
                  !createAccountFormState.email.value ||
                  !createAccountFormState.username.value ||
                  !createAccountFormState.password.value
                }
                size={shouldStackForm ? 'medium' : 'small'}
                title={isLoading ? 'Updating Account' : 'Update Account'}
                backgroundColorOverride="#00aeef"
                onClick={onSubmit}
              />
            </div>
          </Flex>
        </Flex>
      )}
      {shouldVerifyAccount && (
        <VerifyClient
          onClose={() => {
            setShouldVerifyAccount(false)
          }}
          email={createAccountFormState.email.value}
          confirm={{
            onSubmit: onSubmitUpdateAccount,
          }}
          validationFailure={validationFailure}
        />
      )}
    </>
  )
}
