import { Flex, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'

type SendTypeCardProps = {
  title: string
  cost: string
  imageUrl: string
}

export const SendTypeCard = (props: SendTypeCardProps) => {
  const { title, cost, imageUrl } = props
  const primaryFont = 'Montserrat, san serif'

  return (
    <Flex
      width="100%"
      inset={'1rem'}
      justifyContent="space-between"
      style={{
        borderRadius: '.5rem',
        border: '2px solid rgba(233, 233, 233, 0.70)',
        boxShadow: '0px 4px 28px 0px rgba(0, 0, 0, 0.08)',
      }}
    >
      <Flex flexDirection="row" alignItems="center" columnGap="x1">
        <img
          src={imageUrl}
          alt="#"
          style={{
            width: '3.25rem',
            height: '4rem',
            boxShadow: '0px 4px 28px 0px rgba(0, 0, 0, 0.08)',
          }}
        />
        <Text
          content={title}
          whiteSpace="pre"
          lineHeight={1.6}
          type="body"
          style={{
            fontFamily: primaryFont,
            fontSize: '.75rem',
            fontWeight: 700,
            color: '#404040',
          }}
        />
      </Flex>
      <Text
        content={cost}
        type="body"
        style={{
          fontFamily: primaryFont,
          fontSize: '.625rem',
          fontWeight: 700,
          color: '#6B7280',
        }}
      />
    </Flex>
  )
}
