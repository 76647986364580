import { ActionCreator } from '@sendoutcards/core'
import { OrderUiContext } from 'src/orders/types'
import {
  AddressFragment,
  ContactFragment,
  DetailedGroupFragment,
} from 'src/legacy_graphql'
import {
  CardPaperType,
  DetailedOrderFragment,
  SendDelayFragment,
} from 'src/graphql/generated/graphql'
import { PartialLine } from 'src/helpers/multitouch'

export const setActiveLine = ActionCreator(
  'SET_ACTIVE_LINE',
  (line: PartialLine, index: number) => ({ line, index }),
)

export const updateRecipients = ActionCreator(
  'UPDATE_RECIPIENTS',
  (
    contacts: {
      __typename: 'Contact'
      id: string
    }[],
    groups: {
      __typename: 'Group'
      id: string
      name: string
      members: {
        __typename: 'Contact'
        id: string
      }[]
    }[],
  ) => ({ contacts, groups }),
)

export const saveOrder = ActionCreator('SAVE_ORDER')

export const clearOrder = ActionCreator('CLEAR_ORDER')

export const loadedOrderError = ActionCreator(
  'LOADED_ORDER_ERROR',
  (error: Error) => ({ error }),
)

export const createdGroup = ActionCreator(
  'CREATED_GROUP',
  (group: DetailedGroupFragment) => ({
    group,
  }),
)

export const loadedOrder = ActionCreator(
  'LOADED_ORDER',
  (order: DetailedOrderFragment) => ({
    order,
  }),
)

export const closeGroupModal = ActionCreator('CLOSE_GROUP_MODAL')

export const removeCardFromLine = ActionCreator(
  'REMOVE_CARD_FROM_LINE',
  (line: PartialLine) => ({
    line,
  }),
)

export const removeLine = ActionCreator('REMOVE_LINE', (line: PartialLine) => ({
  line,
}))

export const openAddressDrawer = ActionCreator('OPEN_ADDRESS_DRAWER')

export const removeContact = ActionCreator(
  'REMOVE_CONTACT',
  (contact: ContactFragment) => ({ contact }),
)

export const savedOrder = ActionCreator(
  'SAVED_ORDER',
  (order: DetailedOrderFragment) => ({
    order,
  }),
)

export const closeDrawer = ActionCreator('CLOSE_DRAWER')

export const openCreateAddress = ActionCreator('OPEN_CREATE_ADDRESS')

export const deleteOrder = ActionCreator(
  'DELETE_ORDER',
  (deleted: boolean) => ({ deleted }),
)

export const setShowSendDelayError = ActionCreator(
  'SET_SHOW_SEND_DELAY_ERROR',
  (showSendDelayError: boolean) => ({ showSendDelayError }),
)

export const setIsUpdatingOrder = ActionCreator(
  'SET_IS_UPDATING_ORDER',
  (isUpdatingOrder: boolean) => ({ isUpdatingOrder }),
)

export const updateOrderPaperType = ActionCreator(
  'UPDATE_ORDER_PAPER_TYPE',
  (paperType: CardPaperType) => ({ paperType }),
)

export const createdOrder = ActionCreator(
  'CREATED_ORDER',
  (order: DetailedOrderFragment) => ({
    order,
  }),
)

export const changeFreeStatus = ActionCreator('CHANGE_FREE_STATUS')

export const savedOrderError = ActionCreator(
  'SAVED_ORDER_ERROR',
  (error: Error) => ({ error }),
)

export const updateReturnAddress = ActionCreator(
  'UPDATE_RETURN_ADDRESS',
  (returnAddress: AddressFragment) => ({ returnAddress }),
)

export const updateSendDelay = ActionCreator(
  'UPDATE_SEND_DELAY',
  (sendDelay: SendDelayFragment) => ({ sendDelay }),
)

export const setOrderUiContext = ActionCreator(
  'SET_ORDER_UI_CONTEXT',
  (context: OrderUiContext) => ({ context }),
)
