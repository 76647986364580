import React from 'react'
import { GiftCardType } from '../GiftCard/GiftCard'
import {
  Button,
  Flex,
  Spacer,
  LoadingSpinner,
  Text,
  Div,
} from '@sendoutcards/quantum-design-ui'
import { TotalsTable } from 'src/upsale/components/checkout/TotalsTable'
import { PaymentMethod } from 'src/upsale/components/checkout/payment/PaymentMethod'
import { useAccount, useSelector } from 'src/hooks'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
import { PaymentFormDisplay } from 'src/orders/components/OrderPaymentMethod/OrderPaymentMethod'
import { DigitalCardOrder } from 'src/graphql/generated/graphql'
import { formatAmount } from 'src/helpers/payments'
import CardCostTag from '../PrepareAct/CardCostTag'
import ActCardGraphic from '../ActCardGraphic/ActCardGraphic'

type ItemType = {
  title: string
  price: number
  imageUrl: string
  isHorizontal: boolean
  discount?: {
    name: string
    sku: string
    amount: number
  } | null
  upgrade?: {
    name: string
    sku: string
    amount: number
  } | null
}
type AddonType = {
  title: string
  item: GiftCardType
}

type ActCheckoutProps = {
  item: ItemType
  addon?: AddonType
  onChangeAddon: () => void
  onChangeCard: () => void
  onSubmit: () => void
  paymentFormDisplay: PaymentFormDisplay
  order?: DigitalCardOrder
  isProcessingPayment: boolean
}

export const ActCheckout: React.FC<ActCheckoutProps> = ({
  item,
  addon,
  onChangeAddon,
  onChangeCard,
  onSubmit,
  paymentFormDisplay,
  order,
  isProcessingPayment,
}) => {
  const account = useAccount()
  const paymentMethod = account.stripeSource
  const isMobile = useSelector(state => state.window.width < 799)

  //  Component State
  const { tax, total } = order?.data ?? {}
  const formattedSubtotal = formatAmount(item.price) //subtotal is coming back as 0
  const formattedTotal = total !== undefined ? formatAmount(total) : ''
  const formattedTaxTotal =
    tax !== undefined && tax > 0 ? formatAmount(tax) : ''
  const hasTax = formattedTaxTotal && formattedTaxTotal !== '0.00'
  const shouldShowPayment = formattedTotal !== '0.00'
  return (
    <>
      <Text
        type="largeBody"
        content="Checkout"
        weight="extraBold"
        alignment="center"
      />
      <Flex
        width="100%"
        flexDirection={isMobile ? 'column' : 'row'}
        outset={{ vertical: 'x3' }}
        columnGap={!isMobile ? '2rem' : undefined}
      >
        {!isMobile && (
          <Flex flexDirection="column">
            <CardCostTag
              title={item.title}
              cardCost={`$${formatAmount(item.price)}`}
            />
            <Spacer space="x5" />
            <ActCardGraphic
              isHorizontal={item.isHorizontal}
              cardPreviewUrl={item.imageUrl}
              shouldShowGradientBackground={false}
              shouldShowCellphone={true}
              shouldShowEnvelope={false}
              height="350px"
            />
          </Flex>
        )}
        <Flex flexDirection="column" width="100%">
          <Flex inset="x2" flexDirection="column">
            {isMobile && (
              <Flex width="100%" outset={{ bottom: 'x3' }}>
                <CardCostTag
                  title={item.title}
                  cardCost={`$${formatAmount(item.price)}`}
                  cardPreviewUrl={item.imageUrl}
                />
              </Flex>
            )}
            <Flex width="100%" outset={{ bottom: 'x2' }}>
              <TotalsTable
                discounts={
                  item.discount
                    ? {
                        name: item.discount.name,
                        sku: item.discount.sku,
                        amount: formatAmount(item.discount.amount),
                      }
                    : undefined
                }
                upgrades={
                  item.upgrade
                    ? {
                        name: item.upgrade.name,
                        sku: item.upgrade.sku,
                        amount: formatAmount(item.upgrade.amount),
                      }
                    : undefined
                }
                subtotal={formattedSubtotal}
                orderTotal={formattedTotal}
                taxTotal={hasTax ? formattedTaxTotal : undefined}
              />
            </Flex>
            {shouldShowPayment && (
              <>
                <Flex
                  flexDirection="row"
                  alignItems="center"
                  outset={{ bottom: 'x4' }}
                >
                  <Text
                    type="body"
                    weight="bold"
                    content="Payment Method"
                    color="primaryHeading"
                  />
                  <Flex
                    alignItems="center"
                    style={{ marginLeft: 'auto', marginRight: '8px' }}
                    onClick={() =>
                      paymentFormDisplay.setShouldShowPaymentMethodForm(
                        !paymentFormDisplay.shouldShowPaymentForm,
                      )
                    }
                    cursor="pointer"
                  >
                    <Icon name="edit" size={16} />
                    <Spacer space="x1" orientation="horizontal" />
                    <Text
                      type="caption"
                      weight="bold"
                      color="primaryHeading"
                      content="Edit"
                      style={{ textDecoration: 'underline' }}
                    />
                  </Flex>
                </Flex>

                <PaymentMethod
                  paymentFormDisplay={paymentFormDisplay}
                  cardHolderName={`${account.firstName} ${account.lastName}`}
                  paymentMethod={paymentMethod ?? undefined}
                  handlePaymentMethod={() =>
                    paymentFormDisplay.setShouldShowPaymentMethodForm(true)
                  }
                />
              </>
            )}
          </Flex>
          <Spacer space="x3" />
          <Div
            inset={{ horizontal: 'x3' }}
            style={
              isMobile
                ? {
                    position: 'fixed',
                    bottom: '24px',
                    left: '0px',
                    width: '100%',
                  }
                : {}
            }
          >
            <Button
              fullWidth={true}
              disabled={
                isProcessingPayment ||
                (!account.stripeSource &&
                  paymentFormDisplay.shouldShowPaymentForm &&
                  !!total &&
                  total > 0)
              }
              size="large"
              backgroundColorOverride="#404040"
              onClick={
                !account.stripeSource && !!total && total > 0
                  ? () => {
                      paymentFormDisplay.setShouldShowPaymentMethodForm(true)
                    }
                  : onSubmit
              }
            >
              {isProcessingPayment ? (
                <div
                  style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
                >
                  <LoadingSpinner size="small" />
                  <Text type="largeBody" content="Processing Payment..." />
                </div>
              ) : !account.stripeSource && !!total && total > 0 ? (
                'Please add a payment source'
              ) : !shouldShowPayment ? (
                'Confirm & Send'
              ) : (
                'Purchase Act'
              )}
            </Button>
          </Div>
        </Flex>
      </Flex>
    </>
  )
}
