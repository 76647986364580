import React from 'react'
import { Dialog } from 'src/design_system/molecules/dialog/Dialog'
import { Flex, Text } from '@sendoutcards/quantum-design-ui'

type UpsaleDialogProps = {
  isOpen: boolean
  title: string
  heroImage: string
  description: string
  onClose: () => void
  primaryAction?: {
    title: string
    onClick: () => void
  }
  secondaryAction?: {
    title: string
    onClick: () => void
  }
  actionsPosition?: 'column' | 'row'
  background?: string
}

export const UpsaleDialog = (props: UpsaleDialogProps) => {
  const {
    isOpen,
    primaryAction,
    secondaryAction,
    actionsPosition = 'column',
    background = 'linear-gradient(to bottom, #F6F6F600, #F6F6F6, #F6F6F6),linear-gradient(to right, #FFDFE7, #E498FD)',
    title,
    heroImage,
    description,
    onClose,
  } = props
  const fontFamily = 'Montserrat, sans-serif'

  const titleStyle: React.CSSProperties = {
    fontFamily: fontFamily,
    fontSize: '28px',
    fontWeight: 700,
    color: '#252525',
  }

  const descriptionStyles: React.CSSProperties = {
    fontFamily: fontFamily,
    fontSize: '14px',
    fontWeight: 600,
    color: '#404040',
  }

  const primaryButtonStyles: React.CSSProperties = {
    borderRadius: '16px',
    display: 'flex',
    justifyContent: 'center',
    boxShadow: '0px 4px 32px 0px rgba(0, 0, 0, 0.08)',
    padding: '8px 20px',
    fontFamily: fontFamily,
    alignItems: 'center',
    fontWeight: 600,
    color: '#fff',
    fontSize: '16px',
    backgroundColor: '#404040',
    cursor: 'pointer',
  }

  const secondaryButtonStyles: React.CSSProperties = {
    display: 'flex',
    fontFamily: fontFamily,
    fontWeight: 600,
    color: '#404040',
    fontSize: '16px',
    textDecoration: 'underline',
    cursor: 'pointer',
  }

  return (
    <Dialog isOpen={isOpen} onClose={onClose} sheetBgColorOverride={background}>
      <Flex
        flexDirection="column"
        rowGap="x2"
        justifyContent="center"
        alignItems="center"
      >
        <div
          style={{
            backgroundImage: `url(${heroImage})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            maxWidth: '600px',
            minWidth: '300px',
            aspectRatio: '1/1',
          }}
        />
        <Text type="body" style={{ ...titleStyle }} content={title} />
        <Text
          type="body"
          style={{ ...descriptionStyles }}
          content={description}
        />
        <Flex
          flexDirection={actionsPosition}
          rowGap={actionsPosition === 'column' ? 'x2' : undefined}
          columnGap={actionsPosition === 'row' ? 'x2' : undefined}
        >
          {primaryAction && (
            <div
              onClick={primaryAction.onClick}
              style={{ ...primaryButtonStyles }}
            >
              {primaryAction.title}
            </div>
          )}
          {secondaryAction && (
            <div
              onClick={secondaryAction.onClick}
              style={
                actionsPosition === 'row'
                  ? { ...primaryButtonStyles }
                  : { ...secondaryButtonStyles }
              }
            >
              {secondaryAction.title}
            </div>
          )}
        </Flex>
      </Flex>
    </Dialog>
  )
}
