import React, { FC } from 'react'
import { SVGProps } from './types'

export const Revert: FC<SVGProps> = ({ size, color, onClick }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M13.61 17.9997C13.21 17.8297 13.06 17.5497 13.18 17.2097C13.22 17.0997 13.29 17.0197 13.37 16.9497C13.79 16.5297 14.21 16.1097 14.64 15.6797C14.57 15.6297 14.5 15.6497 14.43 15.6497C11.58 15.6497 8.72999 15.6297 5.87999 15.6497C4.44999 15.6697 3.17999 14.6297 3.00999 13.2097C3.00999 13.1897 2.99999 13.1797 2.98999 13.1597C2.98999 12.1897 2.98999 11.2097 2.98999 10.2397C3.07999 10.0097 3.22999 9.83971 3.48999 9.80971C3.86999 9.76971 4.13999 10.0297 4.13999 10.4497C4.13999 11.2497 4.13999 12.0497 4.13999 12.8497C4.13999 13.6397 4.65999 14.2697 5.41999 14.4497C5.56999 14.4897 5.72999 14.4797 5.88999 14.4797C8.72999 14.4797 11.57 14.4797 14.41 14.4797C14.47 14.4797 14.53 14.4897 14.58 14.4597C14.58 14.3797 14.52 14.3597 14.48 14.3097C14.09 13.9197 13.7 13.5297 13.31 13.1397C13.15 12.9697 13.09 12.7797 13.15 12.5497C13.22 12.3197 13.38 12.1897 13.61 12.1497C13.79 12.1097 13.96 12.1697 14.09 12.2997C14.88 13.0797 15.66 13.8597 16.44 14.6397C16.71 14.9097 16.69 15.2397 16.42 15.4997C15.67 16.2497 14.93 16.9897 14.18 17.7397C14.07 17.8497 13.95 17.9497 13.79 17.9897H13.58L13.61 17.9997Z"
        fill={color}
      />
      <path
        d="M3 4.78043C3.04 4.68043 3.09 4.58043 3.17 4.50043C3.94 3.73043 4.71 2.96043 5.49 2.19043C5.83 1.86043 6.35 1.98043 6.47 2.42043C6.53 2.65043 6.47 2.84043 6.3 3.01043C5.89 3.42043 5.48 3.83043 5.07 4.24043C5.05 4.26043 5.02 4.29043 4.99 4.32043C5.06 4.37043 5.13 4.35043 5.19 4.35043C6.96 4.35043 8.74 4.35043 10.51 4.35043C10.9 4.35043 11.15 4.61043 11.12 4.99043C11.1 5.24043 10.91 5.45043 10.66 5.49043C10.55 5.51043 10.43 5.51043 10.31 5.51043C8.62 5.51043 6.93 5.51043 5.23 5.51043C5.16 5.51043 5.09 5.50043 5.03 5.53043C5.03 5.61043 5.1 5.64043 5.14 5.68043C5.53 6.07043 5.92 6.46043 6.31 6.85043C6.47 7.02043 6.53 7.22043 6.46 7.44043C6.39 7.67043 6.22 7.79043 6 7.84043C5.79 7.88043 5.62 7.79043 5.47 7.64043C4.74 6.90043 4 6.17043 3.27 5.44043C3.16 5.33043 3.05 5.22043 3 5.06043L3 4.78043Z"
        fill={color}
      />
      <path
        d="M16.6401 8.20029C16.6401 8.72029 16.6401 9.14029 16.6401 9.56029C16.6401 9.93029 16.4001 10.1803 16.0601 10.1803C15.7201 10.1803 15.4801 9.93029 15.4801 9.56029C15.4801 8.75029 15.4801 7.94029 15.4801 7.13029C15.4801 6.34029 14.9301 5.69029 14.1601 5.53029C14.0201 5.50029 13.8701 5.50029 13.7301 5.48029C13.4601 5.44029 13.2401 5.16029 13.2601 4.90029C13.2801 4.60029 13.5101 4.36029 13.8101 4.35029C15.1901 4.30029 16.4501 5.39029 16.6201 6.76029C16.6801 7.27029 16.6201 7.79029 16.6501 8.20029H16.6401Z"
        fill={color}
      />
    </svg>
  )
}
