import React from 'react'
import { useDebounce, useState } from 'src/hooks'
import styles from './contactSearch.module.scss'
import ContactSelector from './ContactSelector'
import { Contact } from 'src/graphql/generated/graphql'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
import { Text } from '@sendoutcards/quantum-design-ui'

type ContactSearchProps = {
  onContactSelect: (contact?: Contact) => void
  onChange: (value: string) => void
  currentlySelectedContactId?: string
}

const ContactSearch: React.FC<ContactSearchProps> = props => {
  const { onContactSelect, onChange, currentlySelectedContactId } = props

  const [recipientSearch, setRecipientSearch] = useState('')

  const [shouldShowContactSelector, setShouldShowContactSelector] =
    useState(false)

  const debouncedSearchTerm = useDebounce(recipientSearch, 300)

  const shouldShowContactSelectorDebounced = useDebounce(
    shouldShowContactSelector,
    100,
  )

  const handleOnFocus = () => {
    setShouldShowContactSelector(true)
  }

  const handleOnBlur = () => {
    setShouldShowContactSelector(false)
  }

  const handleContactSelect = (selectedContact: Contact) => {
    setRecipientSearch(
      `${selectedContact.firstName} ${selectedContact.lastName}`,
    )
    onContactSelect(selectedContact)
  }

  const handleSelectNewContact = () => {
    onContactSelect(undefined)
  }

  return (
    <div style={{ position: 'relative' }}>
      <input
        type="text"
        id="data_input_cs"
        className={styles.mainInput}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        value={recipientSearch}
        onChange={e => {
          setRecipientSearch(e.target.value)
          onChange(e.target.value)
        }}
        autoCorrect="off"
        spellCheck="false"
        autoComplete="off"
      />
      <ContactSelector
        search={debouncedSearchTerm}
        onContactSelect={handleContactSelect}
        shouldShowSelector={shouldShowContactSelectorDebounced}
        currentlySelectedContactId={currentlySelectedContactId}
        onSelectNewContact={handleSelectNewContact}
      />
      {(currentlySelectedContactId || recipientSearch !== '') && (
        <div className={styles.badge}>
          <div
            className={`${styles.badgeWrapper} ${currentlySelectedContactId ? styles.selected : ''}`}
          >
            <div className={`${styles.badgeItem} ${styles.badgeCheck}`}>
              <div className={styles.badgeCircle}>
                <Icon
                  name="check"
                  size={20}
                  color="#65ad66"
                  height={20}
                  width={20}
                />
              </div>
            </div>
            <div className={`${styles.badgeItem} ${styles.badgeNewContact}`}>
              <div className={styles.newContact}>
                <Text type="footnote" content="New Contact" whiteSpace="pre" />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ContactSearch
