import { NO_STATE_SELECTION } from 'src/contacts/components/AddressForm/constants'
import { getDetailedCountries } from 'src/legacy_graphql/queryDeclarations'
import { formatCountriesAndRegions } from 'src/helpers/formatCountriesAndRegions'
import { useMemo, useQueryFutures } from '.'

const useCountryAndRegions = (country: string) => {
  const [detailedCountries] = useQueryFutures(getDetailedCountries())
  const { countries, regions, isRegionsUnavailable } = detailedCountries.value
    ? formatCountriesAndRegions(detailedCountries.value, country)
    : { countries: [], regions: undefined, isRegionsUnavailable: false }

  const generatedStateOptions = useMemo(() => {
    return (
      regions ? [NO_STATE_SELECTION, ...regions] : [NO_STATE_SELECTION]
    ).map(region => {
      return {
        value: region,
        label: region,
      }
    })
  }, [regions])

  const generatedCountryOptions = useMemo(() => {
    return (countries || ['United States']).map(country => ({
      value: country,
      label: country,
    }))
  }, [countries])

  return {
    countries,
    regions,
    isRegionsUnavailable,
    generatedStateOptions,
    generatedCountryOptions,
  }
}

export default useCountryAndRegions
