import { Dialog, Div } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { AccountFragment, AddressInput } from 'src/graphql/generated/graphql'
import { Portal } from 'src/portal/portal'
import ShippingAddress from '../ShippingAddress/ShippingAddress'

type ValidateAddressProps = {
  isOpen: boolean
  onClose: () => void
  account: AccountFragment
  onSubmit: (updatedAddress?: AddressInput) => void
}

const ValidateAddress: React.FC<ValidateAddressProps> = props => {
  const { isOpen, onClose, account, onSubmit } = props

  const validationNotification =
    'The State entered/format is invalid. Before continuing, please select a valid State from the drop down and click “Save address” when done.'
  return (
    <Portal>
      <Dialog
        isOpen={isOpen}
        onClose={onClose}
        maxHeight={{ xSmall: '95vh', medium: '85vh' }}
        maxWidth={{ xSmall: '95vw', medium: '60vw' }}
      >
        <Div
          overflow="auto"
          maxWidth={{
            xSmall: 'calc(95vw - 4rem)',
            medium: 'calc(60vw - 4rem)',
          }}
        >
          <ShippingAddress
            account={account}
            title="My Address"
            isEditable={true}
            showAddressForm={true}
            onSubmit={updatedAddress => onSubmit(updatedAddress ?? undefined)}
            notification={validationNotification}
          />
        </Div>
      </Dialog>
    </Portal>
  )
}

export default ValidateAddress
