import { Flex } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { Variation, VariationToggle } from './VariationToggle'
import { FavoriteButton } from './FavoriteButton'
import { SendTypeCardView } from './SendTypeCardView'
import { CardPanelsNavigator } from './CardPanelsNavigator'
import {
  CustomCardPanelsPreviews,
  CustomCardPanelsPreviewsPositions,
} from '../CardPreview'
import PremiumBadge from 'src/chrome/PremiumBadge/PremiumBadge'

export const CardPreviewCardView = (props: {
  size?: string
  cardImageUrl: string
  isHorizontal: boolean
  isCardDigitalSend: boolean
  currentPanelPreview: CustomCardPanelsPreviewsPositions
  cardId: string
  isPremium: boolean
  isCardFavorite: boolean
  variations: Variation[]
  selectedVariationId: string | undefined
  handleVariation: (variationId: string) => void
  cardPanelsPreviews: CustomCardPanelsPreviews | undefined
  panelsPositions: CustomCardPanelsPreviewsPositions[]
  setCurrentPanelPreview: (value: CustomCardPanelsPreviewsPositions) => void
}) => {
  const {
    cardImageUrl,
    size = '12rem',
    isHorizontal,
    isCardDigitalSend,
    currentPanelPreview,
    cardId,
    isPremium,
    isCardFavorite,
    variations,
    selectedVariationId,
    handleVariation,
    cardPanelsPreviews,
    panelsPositions,
    setCurrentPanelPreview,
  } = props
  return (
    <Flex flexDirection="column" alignItems={'center'} width={'100%'}>
      <div
        style={{
          position: 'relative',
        }}
      >
        <SendTypeCardView
          cardImageUrl={cardImageUrl}
          size={size}
          isHorizontal={isHorizontal}
          isDigital={isCardDigitalSend}
          isCustom={cardPanelsPreviews !== undefined}
          panelPosition={currentPanelPreview}
        />
        {isPremium && (
          <div style={{ position: 'absolute', top: '10px', left: '10px' }}>
            <PremiumBadge />
          </div>
        )}
        {!cardPanelsPreviews && (
          <FavoriteButton
            cardId={cardId}
            isFavorite={isCardFavorite}
            style={{
              display: 'flex',
              width: 30,
              height: 30,
              position: 'absolute',
              borderRadius: '50%',
              background: 'rgba(243, 243, 243, 0.8)',
              boxShadow:
                '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)',
              right: '1rem',
              top: '1rem',
            }}
          />
        )}
      </div>
      <div
        style={
          {
            position: 'relative',
            bottom: 8,
          } as React.CSSProperties
        }
      >
        {variations && variations.length > 1 && (
          <VariationToggle
            variations={variations}
            selectedVariationId={selectedVariationId}
            selectVariation={handleVariation}
          />
        )}
        {cardPanelsPreviews && (
          <CardPanelsNavigator
            positions={panelsPositions}
            currentPosition={currentPanelPreview}
            onChangePosition={position =>
              setCurrentPanelPreview(
                position as CustomCardPanelsPreviewsPositions,
              )
            }
          />
        )}
      </div>
    </Flex>
  )
}
