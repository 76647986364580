import { Div, Flex, Spacer, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { SendTypeScreen } from './components/SendTypeScreen'
import { SendTypeCardView } from './components/SendTypeCardView'
import { SendType } from 'src/catalog/components/CardOptions/CardOptions'
import { useMeasurements } from 'src/hooks'

export const SelectSendType = (props: {
  shouldShowAct: boolean
  cardFrontPanelImageUrl: string
  cardInsideRightImagePreview?: string
  isHorizontal: boolean
  cardTitle?: string
  cardSubtitle?: string
  primaryFont: string
  onSelectSendType: (sendType: SendType) => void
  selectedSendType: SendType
  isDisplayingInsideRightImagePreview: boolean
  onSeeInside: () => void
  onEditCustomCard?: () => void
}) => {
  const {
    shouldShowAct,
    cardFrontPanelImageUrl,
    cardInsideRightImagePreview,
    isHorizontal,
    cardTitle,
    cardSubtitle,
    primaryFont,
    isDisplayingInsideRightImagePreview,
    onSeeInside,
    onSelectSendType,
    selectedSendType,
    onEditCustomCard,
  } = props

  const { windowWidth } = useMeasurements()
  const isScreenTooSmall = windowWidth <= 360

  return (
    <Flex
      flexDirection="column"
      inset={'x1'}
      width={'100%'}
      overflowY={'auto'}
      overflowX={'hidden'}
    >
      <Flex flexDirection="column" alignItems={'center'} width={'100%'}>
        {cardTitle && (
          <Text
            type="title"
            content={cardTitle}
            style={{
              fontFamily: primaryFont,
              fontSize: '1rem',
              fontWeight: 800,
              color: '#000',
              width: '100%',
              textAlign: 'center',
              minHeight: '1.5rem',
            }}
          />
        )}
        {cardSubtitle && (
          <Text
            type="subtitle"
            content={cardSubtitle}
            style={{
              fontFamily: primaryFont,
              fontSize: '.8rem',
              fontWeight: 500,
              color: '#000',
              width: '100%',
              textAlign: 'center',
              minHeight: '1rem',
            }}
          />
        )}
      </Flex>

      {onEditCustomCard && (
        <div
          onClick={onEditCustomCard}
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '12px',
            width: '100%',
            fontFamily: 'Montserrat, sans-serif',
            alignItems: 'center',
            fontWeight: 600,
            color: '#404040',
            fontSize: '16px',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
        >
          Edit & Save
        </div>
      )}
      <Spacer space="x3" />
      {shouldShowAct && (
        <>
          <Text
            type="body"
            content="Select Send Type:"
            style={{
              fontFamily: primaryFont,
              fontSize: '.625rem',
              fontWeight: 700,
              color: '#000',
              width: '100%',
              textAlign: 'start',
            }}
          />
          <Div
            display="grid"
            justifyContent={'center'}
            style={{
              gridTemplateColumns: isScreenTooSmall
                ? 'repeat(2, 9rem)'
                : 'repeat(2, 10rem)',
              gap: 12,
            }}
          >
            <SendTypeScreen
              isSelected={selectedSendType === SendType.Digital}
              onClick={() => onSelectSendType(SendType.Digital)}
              title={'Immediate Delivery\nDigital Card'}
              cardView={
                <SendTypeCardView
                  cardImageUrl={cardFrontPanelImageUrl}
                  size="2rem"
                  isHorizontal={isHorizontal}
                  isDigital={true}
                />
              }
            />
            <SendTypeScreen
              isSelected={selectedSendType === SendType.Print}
              onClick={() => onSelectSendType(SendType.Print)}
              title={'Printed Card\nDelivered By Mail'}
              cardView={
                <SendTypeCardView
                  cardImageUrl={cardFrontPanelImageUrl}
                  size="2rem"
                  isHorizontal={isHorizontal}
                  isDigital={false}
                />
              }
            />
          </Div>
        </>
      )}
      {cardInsideRightImagePreview && (
        <div
          onClick={onSeeInside}
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '32px',
            width: '100%',
            fontFamily: 'Montserrat, sans-serif',
            alignItems: 'center',
            fontWeight: 600,
            color: '#404040',
            fontSize: '16px',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
        >
          {isDisplayingInsideRightImagePreview ? 'See Front' : 'See Inside'}
        </div>
      )}
    </Flex>
  )
}
