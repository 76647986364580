import { useMeasurements } from 'src/hooks'
import { CustomCardPanelsPreviewsPositions } from '../CardPreview'

export const SendTypeCardView = (props: {
  cardImageUrl: string
  size?: string
  isHorizontal?: boolean
  isDigital?: boolean
  isCustom?: boolean
  panelPosition?: CustomCardPanelsPreviewsPositions
}) => {
  const {
    cardImageUrl: frontPanelImageUrl,
    size = '8rem',
    isHorizontal = false,
    isDigital = false,
    isCustom = false,
    panelPosition = 'front',
  } = props

  const { windowWidth } = useMeasurements()
  const isScreenTooSmall = windowWidth < 960

  const isInsidePanel = panelPosition === 'inside'
  const isBackPanel = panelPosition === 'back'
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        padding: '24px',
        ...(isScreenTooSmall && isCustom
          ? {
              aspectRatio: '5/7',
              width: size,
            }
          : {}),
      }}
    >
      {!isDigital && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            backgroundImage: `url(https://www.sendoutcards.com/media/images/TileEnvelope.png)`, // envelope image
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            aspectRatio: '5/7',
            width: size,
            transform: isHorizontal
              ? 'rotate(96deg)translate(-1%, 0%)scale(1.02)'
              : 'rotate(-5deg)translate(-6%, 1%)scale(1.02)',
          }}
        />
      )}
      {isDigital && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            width: size,
            aspectRatio: '5/7',
            backgroundImage: `url(https://www.sendoutcards.com/media/images/iPhone.png)`, // iPhone image
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            transform: !isHorizontal
              ? 'translate(-18%, 0)scale(0.9)'
              : 'translate(-32%, 0)scale(0.9)',
          }}
        />
      )}
      {!isInsidePanel ? (
        <div
          style={{
            position: 'relative',
            backgroundColor: 'white',
            backgroundImage: `url(${frontPanelImageUrl})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            boxShadow: '0 8px 22px 0 rgba(0, 0, 0, 0.35)',
            aspectRatio: isHorizontal && !isBackPanel ? '7/5' : '5/7',
            ...(isHorizontal && !isBackPanel
              ? {
                  height: size,
                }
              : {
                  width: size,
                }),
          }}
        />
      ) : (
        <div
          style={{
            position: 'relative',
          }}
        >
          <div
            style={{
              position: 'absolute',
              backgroundColor: 'white',
              backgroundImage: `url(${frontPanelImageUrl})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              boxShadow: '0 8px 22px 0 rgba(0, 0, 0, 0.35)',
              aspectRatio: !isHorizontal ? '7/5' : '5/7',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)scale(0.9)',
              ...(!isHorizontal
                ? {
                    width: `calc(2 * ${size})`,
                  }
                : {
                    height: `calc(1.6 * ${size})`,
                  }),
            }}
          >
            <div
              style={{
                position: 'absolute',
                height: '100%',
                width: '100%',
                ...(!isHorizontal
                  ? {
                      background:
                        'linear-gradient(to right, rgba(0, 0, 0, 0.3) 0%, rgba(255, 255, 255, 0.3) 48%, rgba(0, 0, 0, 0.5) 50%, rgba(255, 255, 255, 0.3) 52%, rgba(0, 0, 0, 0.3) 100%)',
                    }
                  : {
                      background:
                        'linear-gradient(rgba(0, 0, 0, 0.3) 0%, rgba(255, 255, 255, 0.3) 48%, rgba(0, 0, 0, 0.5) 50%, rgba(255, 255, 255, 0.3) 52%, rgba(0, 0, 0, 0.3) 100%)',
                    }),
              }}
            />
          </div>
        </div>
      )}
    </div>
  )
}
