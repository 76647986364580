import { Operation } from '../../../__generated__/globalTypes'

import {
  MarketingParagraphFragment,
  marketingParagraphFragmentString,
} from '../../fragments/__generated__/MarketingParagraphFragment'

import getPaymentSettingsMarketingContentRawString from '../GetPaymentSettingsMarketingContent.graphql'

const getPaymentSettingsMarketingContentString = [
  getPaymentSettingsMarketingContentRawString,
  marketingParagraphFragmentString,
].join('\n\n')

export type GetPaymentSettingsMarketingContentData = {
  paymentSettingsCardFooter: MarketingParagraphFragment
}

export type GetPaymentSettingsMarketingContent =
  Operation<GetPaymentSettingsMarketingContentData>

export const GetPaymentSettingsMarketingContent =
  (): GetPaymentSettingsMarketingContent => ({
    query: getPaymentSettingsMarketingContentString,
    variables: undefined,
  })
