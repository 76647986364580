import React, { FC } from 'react'
import { SVGProps } from './types'

export const CopyRequest: FC<SVGProps> = ({ size, onClick, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M14.845 16.773H2.154c-.024-.01-.047-.025-.072-.03-.89-.155-1.54-.85-1.566-1.715-.023-.76-.01-1.52-.01-2.28 0-1.953.004-3.906-.006-5.859a.59.59 0 0 1 .252-.52c.6-.464 1.19-.943 1.788-1.413a.25.25 0 0 0 .108-.224q-.004-1.039-.002-2.078c0-.38.13-.506.525-.506.921 0 1.842.002 2.763-.004.087 0 .19-.04.26-.092.353-.27.695-.553 1.047-.824.798-.615 1.77-.604 2.556.022.335.267.665.538 1.006.798a.5.5 0 0 0 .274.096c.921.007 1.842.003 2.763.005.33 0 .479.144.479.457 0 .698.002 1.396-.002 2.093 0 .1.031.162.11.225.592.467 1.175.946 1.774 1.405.216.165.3.339.299.608-.012 2.609-.007 5.218-.007 7.826q.002.149-.008.297c-.057.688-.408 1.197-1.046 1.516-.184.091-.395.133-.594.197m.885-9.281c-.072.048-.117.076-.16.106q-2.918 2.086-5.84 4.167a2.3 2.3 0 0 1-.432.25c-.725.303-1.4.198-2.028-.25L1.43 7.599c-.045-.032-.092-.063-.154-.105-.003.058-.005.089-.005.12v7.28c.001.688.486 1.148 1.22 1.149q6.009.004 12.018-.004c.195 0 .407-.04.582-.12.46-.21.642-.59.642-1.07q-.004-3.585-.002-7.17zM3.411 2.887v.18q.002 2.438-.002 4.873c0 .121.034.198.139.272 1.397.987 2.79 1.98 4.183 2.97.519.369 1.025.368 1.546-.002l4.144-2.943c.092-.064.133-.13.132-.244q-.005-2.467-.003-4.935-.002-.081-.01-.171zm10.923 2.91v1.788l1.187-.844zm-12.854.94 1.151.819V5.828zm8.217-4.595c-1.015-.985-1.676-.7-2.376 0z"
        fill={color}
      />
      <path
        d="M8.482 8.364H6.244a1 1 0 0 1-.225-.026.35.35 0 0 1-.268-.35.36.36 0 0 1 .302-.34q.114-.016.227-.014h4.444q.106-.002.211.012a.356.356 0 0 1 .313.33.35.35 0 0 1-.27.363 1 1 0 0 1-.225.025zM7.146 6.523c-.354 0-.708.004-1.062-.002-.15-.003-.25-.095-.306-.224-.056-.133-.04-.27.07-.36a.56.56 0 0 1 .316-.13 85 85 0 0 1 1.993-.005c.246.002.408.15.413.356.005.215-.154.366-.412.37-.338.007-.675.003-1.013.003z"
        fill={color}
      />
    </svg>
  )
}
