import { Operation } from '../../../__generated__/globalTypes'

import {
  MarketingParagraphFragment,
  marketingParagraphFragmentString,
} from '../../fragments/__generated__/MarketingParagraphFragment'

import getCreditCardManagerMarketingContentRawString from '../GetCreditCardManagerMarketingContent.graphql'

const getCreditCardManagerMarketingContentString = [
  getCreditCardManagerMarketingContentRawString,
  marketingParagraphFragmentString,
].join('\n\n')

export type GetCreditCardManagerMarketingContentData = {
  creditCardUpdateRateLimitReached: MarketingParagraphFragment
  lastCardRemovalDisclaimerTitle: MarketingParagraphFragment
  lastCardRemovalDisclaimerMessage: MarketingParagraphFragment
  lastCardRemovalDisclaimerDecline: MarketingParagraphFragment
  lastCardRemovalDisclaimerAccept: MarketingParagraphFragment
}

export type GetCreditCardManagerMarketingContent =
  Operation<GetCreditCardManagerMarketingContentData>

export const GetCreditCardManagerMarketingContent =
  (): GetCreditCardManagerMarketingContent => ({
    query: getCreditCardManagerMarketingContentString,
    variables: undefined,
  })
