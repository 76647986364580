import React from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Paper from '@material-ui/core/Paper'

import { Icon } from 'src/chrome'
import { useEffect, useRef, useState } from 'src/hooks'
import { CardCategoryFragment } from 'src/graphql/generated/graphql'

import SearchTags from './SearchTags'
import styles from './search.module.scss'
import { Text } from '@sendoutcards/quantum-design-ui'

interface SearchProps {
  activeCategory?: CardCategoryFragment
  buttonClick?: () => void
  categories?: CardCategoryFragment[]
  className?: string
  clearSearch?: () => void
  handleCategory?: (category: CardCategoryFragment) => void
  hint?: string
  id?: string
  inputClassName?: string
  onClear?: () => void
  onSearch: (search: string) => void
  style?: React.CSSProperties
  tagsClassName?: string
  value?: string
  autocomplete?: 'on' | 'off'
}

const Search: React.FC<SearchProps> = props => {
  const {
    activeCategory,
    value,
    buttonClick,
    categories,
    className,
    handleCategory,
    hint,
    id,
    inputClassName,
    style,
    tagsClassName,
    onSearch,
    onClear,
    clearSearch,
    autocomplete,
  } = props

  const inputRef = useRef<HTMLInputElement>(null)

  const [searchValue, setSearchValue] = useState(value ?? '')
  const [isTooltipVisible, setIsTooltipVisible] = useState(false)

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.currentTarget.value)
    setIsTooltipVisible(!!event.currentTarget.value)
  }

  const handleEnter = (keyEvent: React.KeyboardEvent<HTMLInputElement>) => {
    if (keyEvent.key === 'Enter') {
      onSearch(searchValue)
      setIsTooltipVisible(false)
      inputRef.current && inputRef.current.focus()
    }
  }

  const resetSearch = () => {
    if (clearSearch) {
      clearSearch()
    } else if (onClear) {
      onClear()
      setSearchValue('')
    } else {
      setSearchValue('')
      onSearch('')
    }
  }

  const handleBlur = () => {
    setIsTooltipVisible(false)
  }

  useEffect(() => {
    setSearchValue(value ?? '')
    inputRef.current && inputRef.current.focus()
  }, [value])

  return (
    <div className={`${styles.searchField} ${className}`} style={style}>
      <div
        className={
          inputClassName ? `${styles.search} ${inputClassName}` : styles.search
        }
      >
        <Icon icon={'SEARCH'} size={18} color={'#ff5689'} />
        <div className={styles.selectedTag}>
          <Text type="caption" color="accent">
            {activeCategory ? activeCategory.description : 'Search'}:
          </Text>
        </div>
        <input
          id={id}
          ref={inputRef}
          type="text"
          value={searchValue}
          onChange={handleSearch}
          onKeyPress={handleEnter}
          onBlur={handleBlur}
          className={styles.searchInput}
          placeholder={hint}
          autoComplete={autocomplete}
        />
        {(activeCategory || value) && (
          <div onClick={resetSearch} style={{ cursor: 'pointer' }}>
            <Icon icon={'CLOSECIRCLE'} size={18} color={'#ff5689'} />
          </div>
        )}
        {isTooltipVisible && (
          <Paper
            style={{
              position: 'absolute',
              bottom: '-97%',
              left: 83,
              zIndex: 30,
            }}
          >
            <ListItem>
              <ListItemText
                primary={
                  <Text type="caption" color="primaryHeading">
                    Press Enter key to search
                  </Text>
                }
                disableTypography={true}
              />
            </ListItem>
          </Paper>
        )}
      </div>
      {categories && (
        <SearchTags
          className={tagsClassName ?? ''}
          categories={categories}
          selectedTag={activeCategory}
          onClick={handleCategory}
          buttonClick={buttonClick}
        />
      )}
    </div>
  )
}

export default Search
