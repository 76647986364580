import React from 'react'
import Button from '@material-ui/core/Button'
// @src imports
import { Button as ChromeButton, Transition } from 'src/chrome'
import { AddressForm } from 'src/contacts/components'
import { useState } from 'src/hooks'

import styles from './shippingAddress.module.scss'
import { Div, Spacer, Text } from '@sendoutcards/quantum-design-ui'
import { emptyShippingAddress } from 'src/app/constants'
import { AccountFragment, AddressInput } from 'src/graphql/generated/graphql'
import { useUpdateAccount } from 'src/react_query'
import omit from 'lodash/omit'

interface Props {
  account: Pick<AccountFragment, 'firstName' | 'lastName' | 'shippingAddress'>
  title: string
  isEditable?: boolean
  titleClassName?: string
  showAddressForm?: boolean
  onSubmit?: (updatedAddress?: AddressInput) => void
  notification?: string
}

const ShippingAddress: React.FC<Props> = props => {
  const {
    account,
    isEditable,
    title,
    titleClassName,
    showAddressForm,
    onSubmit,
    notification,
  } = props

  const updateAccountMutation = useUpdateAccount()

  const [isAddressVisible, setIsAddressVisible] = useState(
    !account.shippingAddress ||
      !account.shippingAddress.address1 ||
      showAddressForm,
  )

  const [isSavingAddress, setIsSavingAddress] = useState(false)

  const [shippingAddress, setShippingAddress] = useState(
    account.shippingAddress || emptyShippingAddress,
  )

  const changeAddress = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => setAddress(name, value)

  const changeCountry = (value: string) => setAddress('country', value)
  const changeState = (value: string) => setAddress('state', value)

  const setAddress = (name: string, value: string) => {
    setShippingAddress({
      ...shippingAddress,
      [name]: value,
    })
  }

  const handleShowAddress = () => setIsAddressVisible(a => !a)

  const submitAddress = async () => {
    const user = {
      firstName: account.firstName || shippingAddress.firstName,
      lastName: account.lastName || shippingAddress.lastName,
      shippingAddress: omit(shippingAddress, '__typename'),
    }

    setIsSavingAddress(true)
    try {
      await updateAccountMutation.mutateAsync({ account: user })
      setIsAddressVisible(false)
    } finally {
      onSubmit?.(shippingAddress)
      setIsSavingAddress(false)
    }
  }

  return (
    <Div position="relative" className={styles.componentContainer}>
      <Div position="sticky" top="0" zIndex={10} backgroundColor="foreground">
        {title && (
          <div
            className={
              titleClassName
                ? `${styles.sectionTitle} ${titleClassName}`
                : styles.sectionTitle
            }
          >
            <Text type="body">{title}</Text>
            {isEditable && (
              <Button
                onClick={handleShowAddress}
                style={{
                  marginLeft: 'auto',
                  width: 105,
                  padding: 0,
                  height: '26px',
                  lineHeight: '20px',
                }}
                classes={{
                  label: styles.buttonLabel,
                }}
              >
                {isAddressVisible ? 'Cancel' : 'Edit Address'}
              </Button>
            )}
          </div>
        )}
        {!title && (
          <ChromeButton
            title={isAddressVisible ? 'Cancel' : 'Edit Address'}
            buttonColor={isAddressVisible ? undefined : 'pink'}
            onClick={handleShowAddress}
            style={{ marginLeft: 'auto', width: 126 }}
          />
        )}
        {notification && (
          <Div
            borderColor="rgba(255, 0, 0, 0.4)"
            borderWidth="default"
            borderStyle="solid"
            borderRadius="medium"
            width="95%"
            inset={{ horizontal: 'x2', vertical: 'x1' }}
            backgroundColor="rgba(255, 0, 0, 0.15)"
          >
            <Text type="footnote" color="danger">
              {notification}
            </Text>
          </Div>
        )}
        <Spacer space="x3" />
      </Div>
      <Div overflow="auto">
        {isAddressVisible ? (
          <AddressForm
            contact={shippingAddress}
            onChange={changeAddress}
            onCountryChange={changeCountry}
            onStateChange={changeState}
            shouldShowDates={false}
            isTitleVisible={false}
            isSaving={isSavingAddress}
            onSubmit={submitAddress}
            style={{
              paddingRight: 30,
            }}
          />
        ) : shippingAddress.address1 ? (
          <div>
            <Text type="caption">{`${shippingAddress.firstName} ${shippingAddress.lastName}`}</Text>
            {shippingAddress.company ? (
              <Text type="caption">{`${shippingAddress.company}`}</Text>
            ) : null}
            {shippingAddress.address1 ? (
              <Text type="caption">{`${shippingAddress.address1}`}</Text>
            ) : null}
            {shippingAddress.address2 ? (
              <Text type="caption">{`${shippingAddress.address2}`}</Text>
            ) : null}
            <Text type="caption">{`${shippingAddress.city}, ${shippingAddress.state} ${shippingAddress.postalCode}`}</Text>
            {shippingAddress.country ? (
              <Text type="caption">{`${shippingAddress.country}`}</Text>
            ) : null}
          </div>
        ) : (
          <Text type="caption">No return address set</Text>
        )}
        {isSavingAddress && <Transition message="Saving address..." />}
      </Div>
    </Div>
  )
}

export default ShippingAddress
