import { GraphQLClient } from 'graphql-request'
import { baseURL } from 'src/app/api'
import LogRocket from 'src/logrocket/logrocket'
import { Store } from 'src/redux/store'

// graphql-request 7 will require an absolute URL.
// Note that document.baseURI is only used if the provided URL is relative.
const graphqlUrl = new URL(`${baseURL}/graphql`, document.baseURI).href
export const graphqlClient = new GraphQLClient(graphqlUrl, {
  headers: () => {
    const token = Store.getState().user.controlData.token
    const sessionURL =
      process.env.NODE_ENV === 'production' && LogRocket.sessionURL
    return {
      Authorization: `Token ${token}`,
      ...(sessionURL ? { 'X-LogRocket-URL': sessionURL } : {}),
    }
  },
  requestMiddleware: request => {
    if (
      process.env.NODE_ENV === 'development' &&
      process.env.REACT_APP_USE_GQL_DEBUG === 'true'
    ) {
      const regex = /www(?!\.prod)/
      const url = request.url
        .replace(regex, 'www.prod')
        .replace('graphql', 'graphiql')
      const hash = Object.entries({
        query: JSON.parse(request.body as string).query,
        variables: request.variables,
        headers: request.headers,
      })
        .filter(([, value]) => value)
        .map(
          ([key, value]) =>
            `${key}=${encodeURIComponent(
              typeof value === 'string' ? value : JSON.stringify(value),
            )}`,
        )
        .join('&')
      console.log(
        '[soc-graphql-debugger-request]',
        request.operationName,
        `variables=${JSON.stringify(request.variables)}`,
        `${url}#${hash}`,
      )
    }

    return request
  },
  responseMiddleware: response => {
    if (response instanceof Error) {
      console.error('[soc-graphql-error]', `${response.message}\n`)
      LogRocket.captureException(response)
    } else {
      if (response.errors !== undefined && response.errors.length !== 0) {
        console.error('[soc-graphql-errors]', response.errors)
      }
      if (
        process.env.NODE_ENV === 'development' &&
        process.env.REACT_APP_USE_GQL_RESPONSE_DEBUG === 'true'
      ) {
        console.log('[soc-graphql-debugger-response]', response.data)
      }
    }
  },
})
