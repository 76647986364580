import { Text, TextColor, WeightType } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
import styles from './premiumBadge.module.scss'
import { useFlags } from 'src/react_query/queries/hooks'

type PremiumBadgeProps = {
  title?: string
  titleWeight?: WeightType
  color?: TextColor
  shouldAnimate?: boolean
  fontSize?: string
  shouldHideBgColor?: boolean
}

const PremiumBadge: React.FC<PremiumBadgeProps> = props => {
  const {
    title = 'Premium',
    titleWeight = 'semiBold',
    fontSize = '11px',
    color = 'accent',
    shouldAnimate,
    shouldHideBgColor,
  } = props

  // We will prevent display of the badge based on flags
  const flags = useFlags()
  const hasPremiumDisplay = flags.data?.some(
    flag => flag.name === 'premium_cards',
  )
  if (!hasPremiumDisplay) return <></>

  return (
    <div
      className={`${styles.wrapper} ${shouldHideBgColor ? styles.noBgColor : ''}`}
    >
      {shouldAnimate && (
        <>
          <div className={styles.flash} />
          <div className={`${styles.flash} ${styles.miniFlash}`} />
        </>
      )}
      <div className={styles.icon}>
        <Icon name="premium_crown" size={14} color="#fecd38" />
      </div>
      <Text
        type="footnote"
        content={title}
        style={{ fontSize: fontSize }}
        color={color}
        weight={titleWeight}
      />
    </div>
  )
}

export default PremiumBadge
