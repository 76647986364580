import { Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { AddressInput } from 'src/graphql/generated/graphql'

interface AddressProps {
  address: AddressInput
}

const Address: React.FC<AddressProps> = props => {
  const { address, children, ...restProps } = props
  return (
    <div {...restProps}>
      <Text
        type="body"
        color="primaryHeading"
      >{`${address.firstName} ${address.lastName}`}</Text>
      {address.company ? (
        <Text type="body" color="primaryHeading">{`${address.company}`}</Text>
      ) : null}
      {address.address1 ? (
        <Text type="body" color="primaryHeading">{`${address.address1}`}</Text>
      ) : null}
      {address.address2 ? (
        <Text type="body" color="primaryHeading">{`${address.address2}`}</Text>
      ) : null}
      <Text
        type="body"
        color="primaryHeading"
      >{`${address.city}, ${address.state} ${address.postalCode}`}</Text>
      {address.country ? (
        <Text type="body" color="primaryHeading">{`${address.country}`}</Text>
      ) : null}
    </div>
  )
}

export default Address
