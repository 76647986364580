import React from 'react'
import { Text } from '@sendoutcards/quantum-design-ui'
import styles from './cardCostTag.module.scss'

type CardCostTagProps = {
  cardCost?: string
  title?: string
  cardPreviewUrl?: string
}

const CardCostTag: React.FC<CardCostTagProps> = props => {
  const { cardPreviewUrl, title, cardCost } = props
  return (
    <div className={styles.cardPreview}>
      <div className={styles.cardPreviewInnerLeft}>
        {cardPreviewUrl && (
          <img
            src={cardPreviewUrl}
            alt="Card Preview"
            className={styles.cardPreviewUrl}
          />
        )}
        <div className={styles.titleContainer}>
          <Text
            type="body"
            content={title ?? 'Custom Card'}
            weight="bold"
            color="primaryHeading"
            style={{
              minWidth: 0,
              whiteSpace: 'nowrap',
              overflowX: 'hidden',
              textOverflow: 'ellipsis',
              display: 'block',
            }}
          />
          <div className={styles.sendType}>
            <Text
              type="footnote"
              content={'Immediate Delivery Digital Card'}
              weight="extraBold"
              whiteSpace="normal"
            />
          </div>
        </div>
      </div>
      <div className={styles.cost}>
        <Text
          type="caption"
          content={cardCost}
          weight="extraBold"
          color="primaryHeading"
        />
      </div>
    </div>
  )
}

export default CardCostTag
