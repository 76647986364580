import sendableCardImageFragmentRawString from '../SendableCardImageFragment.graphql'

export const sendableCardImageFragmentString =
  sendableCardImageFragmentRawString

export type SendableCardImageFragment = {
  __typename: 'Image'
  id: string
  url: string
  width: number
  height: number
  smallThumb: string
  mediumThumb: string
}

export const isSendableCardImageFragment = (
  fragment: any,
): fragment is SendableCardImageFragment =>
  fragment &&
  fragment.__typename === 'Image' &&
  typeof fragment.id === 'string' &&
  typeof fragment.url === 'string' &&
  typeof fragment.width === 'number' &&
  typeof fragment.height === 'number' &&
  typeof fragment.smallThumb === 'string' &&
  typeof fragment.mediumThumb === 'string'
