import React from 'react'

import { Div, Flex, Text } from '@sendoutcards/quantum-design-ui'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'

import { IconType } from 'src/design_system/atoms/icons/types'

type SummaryItemProps = {
  title: string
  description?: string
  icon: IconType
  itemColor: string
  price: string
  perType: string
}

export const SummaryItem: React.FC<SummaryItemProps> = ({
  title,
  description,
  icon,
  itemColor,
  price,
  perType,
}) => {
  return (
    <Flex
      alignItems="center"
      borderRadius="medium"
      justifyContent="space-between"
      width="100%"
      backgroundColor="foreground"
      inset="x2"
      position="relative"
      overflow="hidden"
    >
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: '4px',
          backgroundColor: itemColor,
        }}
      />
      <Flex alignItems="center" columnGap="x2">
        <Div
          height="35px"
          width="35px"
          justifyContent="center"
          alignItems="center"
        >
          <Icon name={icon} color={itemColor} />
        </Div>
        <Div>
          <Text type="caption" content={title} weight="semiBold" />
          {description && (
            <Text type="footnote" content={description} weight="regular" />
          )}
        </Div>
      </Flex>
      <Flex justifyContent="flex-end">
        <Text type="caption" content={price} />
        <Text type="caption" content={perType} style={{ color: '#D1D5DB' }} />
      </Flex>
    </Flex>
  )
}
