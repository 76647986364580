import { Div, Flex, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
import { Dialog } from 'src/design_system/molecules/dialog/Dialog'
import { useSelector, useState } from 'src/hooks'
import { LinkCopiedToaster } from './components/LinkCopiedToaster'
import {
  CardFragment,
  DigitalcardDigitalCardShareStatusChoices,
  ShareStatusType,
} from 'src/graphql/generated/graphql'
import { AnimatePresence } from 'framer-motion'
import { useDigitalCardUpdate } from 'src/react_query'

type ShareScreenProps = {
  recipientName: string
  card?: CardFragment
  baseURL: string
  token?: string
  onCopyToClipboard?: () => boolean
  onFinish: () => void
  isSparse: boolean
}

export const ShareScreen = (props: ShareScreenProps) => {
  const {
    recipientName,
    card,
    baseURL,
    token,
    onCopyToClipboard,
    onFinish,
    isSparse,
  } = props
  const [hasCopiedShareLink, setHasCopiedShareLink] = useState(false)
  const [shouldShowCopyMessage, setShouldShowCopyMessage] = useState(false)
  const [status, setStatus] = useState<
    DigitalcardDigitalCardShareStatusChoices | undefined
  >(card?.digitalCard?.shareStatus)
  const isCreatedStatus = status === 'CREATED'

  const updateDigitalCardStatus = useDigitalCardUpdate()

  function toShareStatusChoices(
    status: ShareStatusType,
  ): DigitalcardDigitalCardShareStatusChoices {
    return status as unknown as DigitalcardDigitalCardShareStatusChoices
  }

  const updateStatus = async (newStatus: ShareStatusType) => {
    const pk = card?.digitalCard?.pk

    if (pk && status === 'CREATED') {
      await updateDigitalCardStatus.mutateAsync({
        digitalCardPk: pk,
        input: { shareStatus: newStatus },
      })
      setStatus(toShareStatusChoices(newStatus))
    }
  }

  const isMobile = useSelector(state => state.window.width <= 760)
  const fontFamily = 'Montserrat, sans-serif'
  const boxShadow = '0px 0px 32px 0px rgba(0, 0, 0, 0.08)'

  const getCardDimensions = (
    isMobile: boolean,
    isHorizontal: boolean | undefined,
  ) => {
    if (!isMobile) {
      return isHorizontal
        ? { width: '420px', height: '300px' }
        : { width: '300px', height: '420px' }
    }

    return isHorizontal
      ? { width: '231px', height: '160px' }
      : { width: '160px', height: '231px' }
  }

  const { width, height } = getCardDimensions(isMobile, card?.isHorizontal)

  const backgrounImageCardStyles = {
    backgroundImage: `url(${card?.frontPreviewUrl})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    boxShadow: boxShadow,
    maxWidth: card?.isHorizontal ? '700px' : '500px',
    maxHeight: card?.isHorizontal ? '500px' : '700px',
    width: '100%',
    height: '100%',
    aspectRatio: card?.isHorizontal ? '7/5' : '5/7',
    zIndex: 1,
    margin: '60px 30px auto',
    borderRadius: '2px',
  }

  const cardImgStyles = {
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundImage: `url(${card?.frontPreviewUrl})`,
    width,
    height,
    boxShadow: boxShadow,
    position: isMobile ? 'absolute' : 'relative',
    top: !isMobile ? '' : card?.isHorizontal ? '-110px' : '-175px',
    borderRadius: '2px',
  } as const

  const nameStyles = {
    color: '#000',
    fontWeight: 700,
    fontSize: isMobile ? '14px' : '16px',
    fontFamily: fontFamily,
  }

  const floatingActionStyles = {
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#fff',
    padding: '8px',
    boxShadow: boxShadow,
    cursor: 'pointer',
  }

  const actionStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    borderRadius: '12px',
    boxShadow: boxShadow,
    flexDirecion: 'row',
    columnGap: '6px',
    fontFamily: fontFamily,
    fontSize: '14px',
    fontWeight: 600,
    padding: '8px 0',
    cursor: 'pointer',
  }

  const copyLinkStyles = {
    textDecoration: 'underline',
    fontFamily: fontFamily,
    fontSize: '14px',
    color: '#444',
    textAlign: 'center' as const,
    display: 'flex',
    flexDirection: 'row' as const,
    alignItems: 'center',
    justifyContent: 'center',
    columnGap: '6px',
    cursor: 'pointer',
  }

  const shareTitle = isCreatedStatus
    ? 'Your ACT Link is Ready!'
    : 'Great Job!\nYour link was copied'

  const shareSubTitlePre = (
    <>
      Now its time to send it to{' '}
      <strong style={{ ...nameStyles }}>
        {recipientName.charAt(0).toUpperCase() + recipientName.slice(1)}
      </strong>
    </>
  )

  const shareSubTitleSend = (
    <>
      Your ACT of Gratitude is
      <br />
      sure to brighten{' '}
      <strong style={{ ...nameStyles }}>
        {recipientName.charAt(0).toUpperCase() + recipientName.slice(1)}’s
      </strong>{' '}
      day.
    </>
  )

  const shareSubTitle = isCreatedStatus ? shareSubTitlePre : shareSubTitleSend
  const handleCopyToClipboard = async () => {
    if (onCopyToClipboard) {
      const isSuccessful = onCopyToClipboard()
      setHasCopiedShareLink(isSuccessful)

      if (isSuccessful) {
        setShouldShowCopyMessage(true)
        await updateStatus(ShareStatusType.Shared)
        setTimeout(() => {
          setHasCopiedShareLink(false)
        }, 3000)
      }
    }
  }

  return (
    <Div display="flex" width="100%" justifyContent="center">
      <Div style={{ ...backgrounImageCardStyles }} />
      <Dialog
        isOpen={true}
        onClose={() => {}}
        sheetBgColorOverride="#fff"
        mobileOpenHeight={!isCreatedStatus ? '20vh' : ''}
        overflow="unset"
        shouldHideTitleBar
        mobileBreakPointOverride={760}
        padding={isMobile ? undefined : '40px'}
        shouldHideBlur={isCreatedStatus ? true : false}
      >
        {hasCopiedShareLink && (
          <AnimatePresence>
            <LinkCopiedToaster />
          </AnimatePresence>
        )}
        <Flex
          flexDirection={isMobile || isCreatedStatus ? 'column' : 'row'}
          rowGap="x2"
          columnGap="x2"
          alignItems="center"
          position="relative"
          justifyContent="center"
        >
          <Flex
            flexDirection="column"
            alignItems="center"
            rowGap={isMobile ? 'x1' : 'x2'}
            width={'100%'}
          >
            {!isCreatedStatus && <div style={{ ...cardImgStyles }} />}
            <Text
              type="body"
              alignment="center"
              content={shareTitle}
              whiteSpace="pre"
              outset={!isCreatedStatus && isMobile ? { top: '70px' } : {}}
              style={{
                fontFamily: fontFamily,
                color: '#252525',
                fontSize: isMobile ? '20px' : '24px',
                fontWeight: 700,
              }}
            />
          </Flex>
          <Flex
            flexDirection="column"
            alignItems="center"
            width={'100%'}
            rowGap="x2"
          >
            <Text
              type="body"
              alignment="center"
              whiteSpace="pre"
              lineHeight={1.5}
              style={{
                fontFamily: fontFamily,
                color: '#6B7280',
                fontSize: isMobile ? '14px' : '16px',
                fontWeight: 600,
              }}
            >
              {shareSubTitle}
            </Text>
            {!isCreatedStatus && (
              <Flex
                flexDirection="row"
                columnGap="x1_5"
                alignItems="center"
                justifyContent="center"
              >
                <Div
                  onClick={handleCopyToClipboard}
                  style={{ ...floatingActionStyles }}
                >
                  <Icon color="#000" size={19} name="copy_link" />
                </Div>
                {!isSparse && (
                  <Div
                    style={{ ...floatingActionStyles }}
                    onClick={async () => {
                      if (navigator.share) {
                        try {
                          navigator.share({
                            title: card?.digitalCard?.shareMessageTitle ?? '',
                            text: card?.digitalCard?.shareMessageBody ?? '',
                            url: `${baseURL}/act/${token}`,
                          })
                          await updateStatus(ShareStatusType.Shared)
                        } catch (err) {
                          console.warn('Share failed', err)
                        }
                      } else {
                        alert(
                          'Sharing is not supported on this device/browser.',
                        )
                      }
                    }}
                  >
                    <Icon color="#000" size={25} name="send_two" />
                  </Div>
                )}
              </Flex>
            )}
            <Flex flexDirection="column" width="100%" rowGap="x1_5">
              <Div
                onClick={async () => {
                  if (navigator.share) {
                    try {
                      navigator.share({
                        title: card?.digitalCard?.shareMessageTitle ?? '',
                        text: card?.digitalCard?.shareMessageBody ?? '',
                        url: `${baseURL}/act/${token}`,
                      })
                      await updateStatus(ShareStatusType.Shared)
                    } catch (err) {
                      console.warn('Share failed', err)
                    }
                  } else {
                    alert('Sharing is not supported on this device/browser.')
                  }
                }}
                style={{
                  ...actionStyles,
                  color: !isCreatedStatus ? '#444' : '#fff',
                  backgroundColor: !isCreatedStatus ? '#fff' : '#444',
                }}
              >
                <Icon
                  size={20}
                  name={!isCreatedStatus ? 'upload' : 'send_two'}
                  color={!isCreatedStatus ? '#444' : '#fff'}
                />
                {!isCreatedStatus ? 'Share' : 'Send ACT'}
              </Div>
              {!isCreatedStatus ? (
                <Div
                  onClick={onFinish}
                  style={{
                    ...actionStyles,
                    color: '#fff',
                    backgroundColor: '#444',
                  }}
                >
                  {isSparse ? 'Set up Your Account' : 'Done'}
                </Div>
              ) : (
                <span
                  style={{ ...copyLinkStyles }}
                  onClick={handleCopyToClipboard}
                >
                  <Icon size={12} name="copy_link" color="#444" />
                  Copy Link
                </span>
              )}
            </Flex>
            {shouldShowCopyMessage && (
              <Text
                type="body"
                alignment="center"
                content={
                  !isCreatedStatus
                    ? 'Remember to paste it into your favorite sharing\nservice and send to your recipient.'
                    : 'Click “Send ACT” to open your device sharing options\nto share with your recipient or\n“Copy Link” and paste into the sharing method of your choosing.'
                }
                whiteSpace="pre"
                lineHeight={1.5}
                style={{
                  fontFamily: fontFamily,
                  color: '#6B7280',
                  fontSize: '10px',
                  fontWeight: 600,
                }}
              />
            )}
          </Flex>
        </Flex>
      </Dialog>
    </Div>
  )
}
