import React, { FC } from 'react'
import {
  Button,
  Div,
  Input,
  LoadingSpinner,
  Spacer,
  Text,
  VStack,
} from '@sendoutcards/quantum-design-ui'
import { useAccount, useActions, useQueries, useState } from 'src/hooks'
import {
  EditCard,
  ExpenseReloadForm,
  PaymentTab,
} from 'src/payments/components'
import { getMarketingContent } from 'src/legacy_graphql'
import { noop } from 'src/helpers/appHelpers'
import { creditCardBrand } from 'src/payments/components/CardLogo/CreditCardLogo'
import { emptyShippingAddress } from 'src/app/constants'
import JoinAddressForm from 'src/onboarding/components/JoinAddressForm'
import { UpsaleResponseData } from 'src/onboarding/components/InnerPaymentSheet'
import useSubmitCard from 'src/hooks/useSubmitCard'
import { parseError } from 'src/utils/parseError'
import {
  AccountInput,
  AddressInput,
  ExpenseReloadFragment,
} from 'src/graphql/generated/graphql'
import { useShouldVerifyEmail, useUpdateAccount } from 'src/react_query'
import omit from 'lodash/omit'

type PlanUpsaleInnerPaymentSheetProps = {
  planId: string
  updateUpsaleResponseData: (
    upsaleResponseData: UpsaleResponseData | undefined,
  ) => void
  isAddon?: boolean
}
export const PlanUpsaleInnerPaymentSheet: FC<
  PlanUpsaleInnerPaymentSheetProps
> = props => {
  const { planId, updateUpsaleResponseData, isAddon } = props

  const account = useAccount()
  const updateAccountMutation = useUpdateAccount()
  const actions = useActions()
  const submitCard = useSubmitCard()

  const [marketingContent] = useQueries(getMarketingContent())

  const { data: shouldVerifyEmail } = useShouldVerifyEmail()

  const [errorMessage, setErrorMessage] = useState<string | undefined>()

  const [firstName, setFirstName] = useState(account.firstName)
  const [lastName, setLastName] = useState(account.lastName)

  const hasShippingAddress =
    account.shippingAddress && account.shippingAddress.address1 !== ''
  const hasFirstLastName = account.firstName !== '' && account.lastName !== ''
  const [isFirstNameValid, setIsFirstNameValid] = useState(hasFirstLastName)
  const [isLastNameValid, setIsLastNameValid] = useState(hasFirstLastName)
  const stripeSource = account.stripeSource
  const [isUpdatingAccount, setIsUpdatingAccount] = useState(false)
  const shouldHaveExpenseBucket = planId === '4' || planId === '39'
  const [expenseReload, setExpenseReload] = useState<ExpenseReloadFragment>({
    purchaseAmount: 10,
    threshold: 100,
    isEnabled: false,
    isRequired: true,
    __typename: 'ExpenseReload',
  })

  const [shippingAddress, setShippingAddress] = useState<AddressInput>(
    omit(account.shippingAddress, '__typename') ?? emptyShippingAddress,
  )

  const validateNameFields = (
    value: string,
    setFieldValidation: (value: React.SetStateAction<boolean>) => void,
    setName: (value: React.SetStateAction<string>) => void,
  ) => {
    setName(value)
    value !== '' ? setFieldValidation(true) : setFieldValidation(false)
  }

  const updateAccount = async (
    account: AccountInput,
    success = noop,
    error: (error: Error | string) => void = noop,
  ) => {
    try {
      const {
        updateAccount: { account: updatedAccount },
      } = await updateAccountMutation.mutateAsync({ account })
      actions.updatedAccount(updatedAccount)
      success()
      updateUpsaleResponseData({
        title: 'Your selection was made successfully',
        subTitle: isAddon
          ? marketingContent.cardPackagePurchaseSuccess.content
          : marketingContent.subscriptionPurchaseSuccess.content,
        isLoading: false,
        loadedStatus: 'success',
        dataMktContent: isAddon
          ? 'cardPackagePurchaseSuccess'
          : 'subscriptionPurchaseSuccess',
      })
    } catch (err) {
      error(parseError(err))
      try {
        setErrorMessage(
          err instanceof Error
            ? err.message.split(': ')[1]
            : 'An error occured',
        )
        updateUpsaleResponseData({
          title: 'An error occurred',
          subTitle:
            err instanceof Error ? err.message.split(': ')[1] : parseError(err),
          isLoading: false,
          loadedStatus: 'error',
        })
      } catch {
        updateUpsaleResponseData({
          title: 'An error occurred',
          subTitle: 'Account failed to update. Contact Support for assistance.',
          isLoading: false,
          loadedStatus: 'error',
        })
        setErrorMessage(
          'Account failed to update. Contact Support for assistance.',
        )
      }
    } finally {
      setIsUpdatingAccount(false)
    }
  }

  const purchasePlan = () => {
    const firstLastNameData = !hasFirstLastName
      ? {
          firstName: firstName,
          lastName: lastName,
        }
      : null

    const shippingAddressData = !hasShippingAddress
      ? (shippingAddress as AddressInput)
      : undefined

    const expenseBucket = shouldHaveExpenseBucket
      ? {
          ...expenseReload,
          threshold: expenseReload.threshold / 100,
          isEnabled: true,
        }
      : undefined

    const accountData: AccountInput = {
      shippingAddress: shippingAddressData,
      expenseReload: expenseBucket,
      ...(isAddon ? { planAddon: planId } : { plan: planId }),
      ...(firstLastNameData ?? {}),
    }

    setIsUpdatingAccount(true)

    updateUpsaleResponseData({
      title: 'Making your selection',
      subTitle: 'Thank you for your selection!',
      isLoading: true,
      loadedStatus: undefined,
    })

    updateAccount(accountData)
  }

  const handleChangeExpense = (name: string, value: number) => {
    setExpenseReload(x => ({
      ...x,
      [name]: value,
    }))
  }

  return (
    <VStack gap="x2">
      {isUpdatingAccount ? (
        <Div>
          <Spacer space="x5" />
          <LoadingSpinner size="xLarge" />
          <Spacer space="x4" />
          <Text type="largeBody" alignment="center">
            Please wait while we update your account.
          </Text>
        </Div>
      ) : errorMessage !== undefined ? (
        <Div>
          <Spacer space="x5" />
          <Text type="subtitle">An error occurred</Text>
          <Spacer space="x2_5" />
          <Text type="body">{errorMessage}</Text>
          <Spacer space="x2_5" />
          <Button
            type="secondary"
            title="BACK"
            fullWidth={true}
            onClick={() => setErrorMessage(undefined)}
          />
        </Div>
      ) : (
        <Div
          style={{ maxHeight: '60vh', overflowY: 'auto' }}
          id="user-info-flow"
        >
          <Spacer space="x1" />
          {!hasFirstLastName && (
            <Div id="user-info-form" outset={{ horizontal: '2px' }}>
              <Text
                type="caption"
                content="First Name"
                color={isFirstNameValid ? 'primaryBody' : 'danger'}
              />
              <Input
                type="text"
                placeholder="First Name"
                value={firstName}
                onChange={value =>
                  validateNameFields(value, setIsFirstNameValid, setFirstName)
                }
                isFullWidth={true}
              />
              <Spacer space="x2" />
              <Text
                type="caption"
                content="Last Name"
                color={isLastNameValid ? 'primaryBody' : 'danger'}
              />
              <Input
                type="text"
                placeholder="Last Name"
                value={lastName}
                onChange={value =>
                  validateNameFields(value, setIsLastNameValid, setLastName)
                }
                isFullWidth={true}
              />
              <Spacer space="x2" />
            </Div>
          )}
          {!hasShippingAddress && (
            <>
              <Text type="body">Billing Address</Text>
              <Div outset={{ horizontal: '2px' }}>
                <JoinAddressForm
                  shippingAddress={shippingAddress}
                  onChange={setShippingAddress}
                />
              </Div>
              <Spacer space="x2" />
            </>
          )}
          {shouldHaveExpenseBucket && (
            <>
              <Spacer space="x2" />
              <Text type="body" color="primaryHeading">
                This plan requires an Expense Bucket:
              </Text>
              <Spacer space="x1_5" />
              <ExpenseReloadForm
                expenseReload={expenseReload}
                onChange={handleChangeExpense}
              />
              <Text
                type="footnote"
                inset="x2"
              >{`*We will charge $${expenseReload.purchaseAmount} of expense to your card today`}</Text>
            </>
          )}
          {!stripeSource || stripeSource?.__typename !== 'CreditCard' ? (
            <Div id="add-credit-card-section">
              <Text type="body" content="Attach your credit card to checkout" />
              <EditCard
                onSuccess={submitCard.submitCard}
                onError={submitCard.onError}
                isSubmitButtonVisible={true}
                isAddingCard={submitCard.isAddingCard}
                shouldValidateEmail={shouldVerifyEmail}
              />
              {submitCard.addCCardError !== undefined && (
                <Text type="caption" color="danger">
                  {submitCard.addCCardError}
                </Text>
              )}
            </Div>
          ) : (
            <>
              <Div>
                <PaymentTab
                  paymentType={'card'}
                  brand={creditCardBrand(stripeSource.brand)}
                />
                <Spacer space="x1_5" />
                <Div display="flex" alignItems="center">
                  <Text type="body">Credit Card Information</Text>
                </Div>
                <Spacer space="x2" />
                <Div
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  css={{
                    '@media (max-width: 1200px)': {
                      display: 'block',
                    },
                  }}
                >
                  <Div>
                    <Text type="caption">
                      Card Number: **** **** **** {stripeSource.last4}
                    </Text>
                    <Text type="caption">
                      Exp: {stripeSource.expMonth}/{stripeSource.expYear}
                    </Text>
                  </Div>
                  <Button
                    fullWidth={false}
                    type="secondary"
                    title="Remove Card"
                    onClick={() => submitCard.submitCard()}
                  />
                </Div>
              </Div>
              <Spacer space="x1_5" />
              <Button
                id="submit_btn"
                type="success"
                title="Confirm"
                disabled={
                  (!hasShippingAddress &&
                    (shippingAddress.address1 === '' ||
                      shippingAddress.city === '' ||
                      shippingAddress.state === '' ||
                      shippingAddress.postalCode === '')) ||
                  !isFirstNameValid ||
                  !isLastNameValid
                }
                fullWidth={true}
                onClick={purchasePlan}
              />
            </>
          )}
        </Div>
      )}
    </VStack>
  )
}

export default PlanUpsaleInnerPaymentSheet
