import { Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import {
  CardWithoutCostFragment,
  MinimalCardPreviewFragment,
  MinimalDigitalCardFragment,
} from 'src/graphql/generated/graphql'
import styles from './digitalCardHistoryCell.module.scss'
import { Button } from 'src/design_system/components/Button/Button'
import { useState } from 'src/hooks'
import { LEGACY_SITE_URL } from 'src/app/api'

interface DigitalCardHistoryCellProps {
  digitalCard: MinimalDigitalCardFragment
  onViewCard: (card: MinimalCardPreviewFragment) => void
}

const DigitalCardHistoryCell: React.FunctionComponent<
  DigitalCardHistoryCellProps
> = props => {
  const { digitalCard, onViewCard } = props
  const [shareBtTitle, setShareBtTitle] = useState('Share')

  const createdAtDateFormatted = new Date(digitalCard.createdAt)
  return (
    <div className={styles.digitalCardHistoryRowWrapper}>
      <div className={styles.dchPreviewAndDetailsWrapper}>
        <div
          className={styles.dchFrontImage}
          style={{
            backgroundImage: `url("${digitalCard.card.frontPreviewUrl}")`,
            cursor: 'pointer',
          }}
          onClick={() =>
            onViewCard(digitalCard.card as unknown as CardWithoutCostFragment)
          }
        />
        <div>
          <div className={styles.dchTitle}>
            <Text type="body" weight="bold">
              {digitalCard.shareMessageTitle}
            </Text>
          </div>
          <div className={styles.dchCreatedOn}>
            <Text
              type="footnote"
              isItalic={true}
              weight="thin"
              outset={{ right: 'x1' }}
            >
              Created On:
            </Text>
            <Text type="caption">
              {createdAtDateFormatted.toLocaleDateString('en-US')}
            </Text>
          </div>
          <div className={styles.dchContact}>
            <Text
              type="footnote"
              isItalic={true}
              weight="thin"
              outset={{ right: 'x1' }}
            >
              Sent to:
            </Text>
            <Text type="caption">
              {`${digitalCard.recipients[0].contactRequest?.contact?.firstName ?? digitalCard.recipients[0].contactRequest?.firstName ?? ''} ${digitalCard.recipients[0].contactRequest?.contact?.lastName ?? digitalCard.recipients[0].contactRequest?.lastName ?? ''}`}
            </Text>
          </div>
        </div>
      </div>
      <div className={styles.dchShareLink}>
        <Button
          title={shareBtTitle}
          width="100%"
          onClick={() => {
            const url = `${LEGACY_SITE_URL}/act/${digitalCard.recipients[0].token}`
            navigator.share({
              title: digitalCard.shareMessageTitle,
              text: digitalCard.shareMessageBody,
              url: url,
            })
            navigator.clipboard.writeText(url)
            setShareBtTitle('Link Copied')
          }}
        />
      </div>
    </div>
  )
}

export default DigitalCardHistoryCell
