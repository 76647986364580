import React, { FC } from 'react'
import {
  Button,
  ButtonType,
  Div,
  Flex,
  RadioOptionCardProps,
  Transition,
  UpsaleSheet,
} from '@sendoutcards/quantum-design-ui'
import FeaturedCardsGrid from 'src/catalog/components/FeaturedCardsGrid/FeaturedCardsGrid'
import { useAccount, useActions, useSelector, useState } from 'src/hooks'
import { UpsaleResponseData } from 'src/onboarding/components/InnerPaymentSheet'
import PlanUpsaleInnerPaymentSheet from './PlanUpsaleInnerPaymentSheet'
import SamePlanNotification from './SamePlanNotification'
import { useRemoveSearchParams } from 'src/hooks/useRemoveSearchParams'
import { BasicCreateAccountForm } from 'src/gift_store/components/BasicCreateAccountForm'

const options: RadioOptionCardProps[] = [
  {
    title: '$20.00/10 Card Challenge',
    subtitle: '10 Heartfelt Prompting Cards - stamps included!',
    isFeatured: false,
    optionId: '122',
    isSelected: false,
  },
  {
    title: '$97.00/Month',
    subtitle: 'Unlimited Heartfelt Prompting Cards - stamps included!',
    isFeatured: true,
    optionId: '42',
    isSelected: false,
  },
  {
    title: '$37.00/Month',
    subtitle: '20 Heartfelt Prompting Cards - stamps included!',
    isFeatured: false,
    optionId: '6',
    isSelected: false,
  },
  {
    title: '$147.00/Month',
    subtitle: '',
    isFeatured: false,
    optionId: '4',
    isSelected: false,
  },
  {
    title: '$247.00/Month',
    subtitle: '',
    isFeatured: false,
    optionId: '39',
    isSelected: false,
  },
  {
    title: '$157/100 Cards',
    subtitle:
      'Send 100 cards within six months of purchase, stamps not included.',
    isFeatured: false,
    optionId: '78',
    isSelected: false,
  },
  {
    title: '$347/250 Cards',
    subtitle:
      'Send 250 cards within six months of purchase, stamps not included.',
    isFeatured: false,
    optionId: '44',
    isSelected: false,
  },
  {
    title: '$597/500 Cards',
    subtitle:
      'Send 500 cards within six months of purchase, stamps not included.',
    isFeatured: false,
    optionId: '45',
    isSelected: false,
  },
  {
    title: '$997/1000 Cards',
    subtitle:
      'Send 1000 cards within one year of purchase, stamps not included.',
    isFeatured: false,
    optionId: '46',
    isSelected: false,
  },
  {
    title: '$1797/2000 Cards',
    subtitle:
      'Send 2000 cards within one year of purchase, stamps not included.',
    isFeatured: false,
    optionId: '47',
    isSelected: false,
  },
]

type PlanUpsaleProps = {
  selectedPlanId?: string
  isAddon?: boolean
  onClose?: () => void
}

const getPlan = (planId: string) => {
  if (planId === '3') {
    return '42'
  } else if (planId === '7') {
    return '4'
  } else if (planId === '8') {
    return '39'
  }
  return planId
}

export const PlanUpsale: FC<PlanUpsaleProps> = props => {
  const { selectedPlanId = '', isAddon, onClose } = props

  const { plan, username } = useAccount()
  const clearSearchParams = useRemoveSearchParams()
  const isSparse = !!!username
  const [isOpen, setIsOpen] = useState(true)
  const [planId, setPlanId] = useState(
    selectedPlanId ? getPlan(selectedPlanId) : '',
  )
  const actions = useActions()
  const isSnapPoint = useSelector(state => state.window.width <= 755)

  // Note: With the stripe unlimited plan change, we might still have some users that didn't
  // migrated to the plan 42. Therefore we should not allow users on plan 3 to buy the 42
  const isUserOnSelectedPlan =
    planId === plan.id || (planId === '42' && plan.id === '3')

  const [upsaleResponseData, setUpsaleResponseData] = useState<
    UpsaleResponseData | undefined
  >(undefined)

  const submitAction = {
    title: 'Subscribe Now',
    type: 'secondary' as ButtonType,
    onClick: (optionId: string) => setPlanId(optionId),
  }

  const viewMoreLink = {
    onClick: () => {},
    title: 'View More Options >',
  }

  const handleSetIsOpen = () => {
    setIsOpen(!isOpen)
    if (onClose !== undefined) {
      onClose()
    } else {
      clearSearchParams(['join', 'planSelected', 'promo'])
      actions.clearRouteArgs()
      actions.openAccount()
    }
  }

  const updateUpsaleResponseData = (
    uResponseData: UpsaleResponseData | undefined,
  ) => {
    setUpsaleResponseData(uResponseData)
  }

  return (
    <UpsaleSheet
      setIsOpen={handleSetIsOpen}
      isOpen={isOpen}
      title=""
      description=""
      options={options}
      submitAction={submitAction}
      extraLink={viewMoreLink}
      selectedOptionTitle="Great choice!"
      selectedOptionDescription="We are glad you found the plan that works best for you. Please confirm below."
      backgroundElement={<FeaturedCardsGrid />}
      selectedOption={planId}
      defaultNumOptionsShown={1}
      zIndex={4}
      inset={isSnapPoint ? '' : { left: 'x7' }}
      width={isSnapPoint ? undefined : 'calc(100% - 60px)'}
      successComponent={
        upsaleResponseData ? (
          <Flex
            alignItems={'center'}
            justifyContent={'center'}
            width={'100%'}
            flexDirection={'column'}
            height={'100%'}
            data-mktcontent={upsaleResponseData.dataMktContent}
          >
            <Div outset={{ top: 'auto' }} width={'100%'}>
              <Transition
                title={upsaleResponseData.title}
                isLoading={upsaleResponseData.isLoading}
                subtitle={upsaleResponseData.subTitle}
                loadedStatus={upsaleResponseData.loadedStatus}
              />
            </Div>
            {upsaleResponseData.loadedStatus !== undefined && (
              <Div outset={{ top: 'auto' }} width={'100%'}>
                {upsaleResponseData.loadedStatus === 'success' && (
                  <Button
                    fullWidth={true}
                    title={'Continue to account'}
                    onClick={handleSetIsOpen}
                  />
                )}
                {upsaleResponseData.loadedStatus === 'error' && (
                  <Button
                    fullWidth={true}
                    title={'Go back'}
                    onClick={() => setUpsaleResponseData(undefined)}
                  />
                )}
              </Div>
            )}
          </Flex>
        ) : undefined
      }
    >
      {isSparse ? (
        <>
          <BasicCreateAccountForm
            shouldNotAutoComplete={true}
            shouldStackForm={true}
          />
        </>
      ) : (
        <>
          {isUserOnSelectedPlan ? (
            <SamePlanNotification />
          ) : (
            <PlanUpsaleInnerPaymentSheet
              planId={planId}
              updateUpsaleResponseData={updateUpsaleResponseData}
              isAddon={isAddon}
            />
          )}
        </>
      )}
    </UpsaleSheet>
  )
}

export default PlanUpsale
