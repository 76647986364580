const formatMarketingContent = (
  content: string,
  replacements: {
    [key: string]: string
  },
): string => {
  return Object.entries(replacements).reduce(
    (acc, [key, value]) => acc.replaceAll(`{${key}}`, value),
    content,
  )
}

export default formatMarketingContent
