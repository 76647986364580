import React from 'react'
import { CreateContactInput, getDetailedCountries } from 'src/legacy_graphql'
import { Div, Flex, Input, Select, Text } from '@sendoutcards/quantum-design-ui'
import { useQueries, useState } from 'src/hooks'
import HoverTooltip from 'src/chrome/HoverTooltip/HoverTooltip'
import { OptionItems } from 'src/contacts/components/ContactForm/ContactForm'
import { formatCountriesAndRegions } from 'src/helpers/formatCountriesAndRegions'
import { AddressInput } from 'src/graphql/generated/graphql'

type Props = {
  shippingAddress: AddressInput
  onChange: (update: AddressInput) => void
}

const JoinAddressForm = <T extends CreateContactInput>(props: Props) => {
  const { shippingAddress, onChange } = props
  const { address1, address2, city, state, postalCode, country } =
    shippingAddress

  const [detailedCountries] = useQueries(getDetailedCountries())
  const { countries, regions, isRegionsUnavailable } =
    formatCountriesAndRegions(detailedCountries, country)

  const isInvalidRegion = !isRegionsUnavailable
    ? state
      ? !regions?.includes(state)
      : true
    : false

  const [isAddress1Valid, setIsAddress1Valid] = useState(false)
  const [isCityValid, setIsCityValid] = useState(false)
  const [isStateValid, setIsStateValid] = useState(false)
  const [isPostalCodeValid, setIsPostalCodeValid] = useState(false)

  const validateField = (
    value: string | number,
    setFieldValidation: (value: React.SetStateAction<boolean>) => void,
  ) => {
    value !== '' ? setFieldValidation(true) : setFieldValidation(false)
  }

  const validateFields = () => {
    if (isAddress1Valid && isCityValid && isStateValid && isPostalCodeValid) {
    } else {
    }
  }

  const getIndex = (
    name: string | null | undefined,
    array: string[] | undefined,
  ) => {
    if (array && name) {
      const index = array.indexOf(name)
      if (index >= 0) {
        return index
      }
    }
    return undefined
  }

  const [stateOptionIndex, setStateOptionIndex] = useState<number | undefined>(
    getIndex(state, regions),
  )
  const [countryOptionIndex, setCountryOptionIndex] = useState<
    number | undefined
  >(getIndex(country === '' ? 'United States' : country, countries))

  const handleChange = <K extends keyof T>(key: K, value: T[K]) => {
    onChange({
      ...shippingAddress,
      [key]: value,
    })

    validateFields()
  }

  return (
    <>
      <Flex flexDirection="column" rowGap="x3">
        <Div>
          <Text
            type="caption"
            color={isAddress1Valid ? 'primaryBody' : 'danger'}
          >
            Address 1
          </Text>
          <Input
            type="text"
            value={address1 ? address1 : ''}
            isFullWidth={true}
            placeholder="Address 1"
            onChange={value => {
              validateField(value, setIsAddress1Valid)
              handleChange('address1', value)
            }}
            name="address_1_field"
          />
        </Div>
        <Div>
          <Text type="caption">Address 2</Text>
          <Input
            type="text"
            value={address2 ? address2 : ''}
            isFullWidth={true}
            placeholder="Address 2"
            onChange={value => handleChange('address2', value)}
            name="address_2_field"
          />
        </Div>
        <Flex justifyContent="flex-start" columnGap="x2" flexWrap="wrap">
          <Div>
            <Text type="caption" color={isCityValid ? 'primaryBody' : 'danger'}>
              City
            </Text>
            <Input
              type="text"
              value={city ? city : ''}
              placeholder="City"
              onChange={value => {
                validateField(value, setIsCityValid)
                handleChange('city', value)
              }}
              name="city_field"
            />
          </Div>
          {!isRegionsUnavailable && (
            <Div display="flex" flexDirection="column" flexGrow={1}>
              <HoverTooltip
                title="Please select a region"
                shouldDisplayTooltip={isInvalidRegion ? true : false}
                isFullWidth={true}
                background="dark"
              >
                <Text
                  type="caption"
                  color={
                    isInvalidRegion || !isStateValid ? 'danger' : 'primaryBody'
                  }
                >
                  State
                </Text>
              </HoverTooltip>
              <Select
                selectedOptionIndex={stateOptionIndex}
                onChange={selectedOptionIndex => {
                  setStateOptionIndex(selectedOptionIndex)
                  validateField(selectedOptionIndex, setIsStateValid)
                  handleChange(
                    'state',
                    regions ? regions[selectedOptionIndex] : 'United States',
                  )
                }}
                alignment="center"
                options={(regions || ['United States']).map((region, index) => {
                  const optionItem: OptionItems = {
                    value: `${index}`,
                    label: region,
                  }
                  return optionItem
                })}
                type="text"
                dropDownMaxHeight="250px"
                selectHeight="50px"
              />
            </Div>
          )}
          <Div>
            <Text
              type="caption"
              color={isPostalCodeValid ? 'primaryBody' : 'danger'}
            >
              Postal Code
            </Text>
            <Input
              type="text"
              value={postalCode ? postalCode : ''}
              placeholder="Postal Code"
              onChange={value => {
                validateField(value, setIsPostalCodeValid)
                handleChange('postalCode', value)
              }}
            />
          </Div>
          <Div display="flex" flexDirection="column" flexGrow={1}>
            <HoverTooltip
              title="Please select a country"
              shouldDisplayTooltip={false}
              isFullWidth={true}
              background="dark"
            >
              <Text type="caption">Country</Text>
            </HoverTooltip>
            <Select
              selectedOptionIndex={countryOptionIndex}
              onChange={selectedOptionIndex => {
                setStateOptionIndex(undefined)
                handleChange('state', ' ')
                setCountryOptionIndex(selectedOptionIndex)
                handleChange('country', countries[selectedOptionIndex])
              }}
              alignment="center"
              options={countries.map((country, index) => {
                const optionItem: OptionItems = {
                  value: `${index}`,
                  label: country,
                }
                return optionItem
              })}
              type="text"
              dropDownMaxHeight="250px"
              selectHeight="50px"
            />
          </Div>
        </Flex>
      </Flex>
    </>
  )
}

export default JoinAddressForm
