import { CampaignFragment } from 'src/graphql/generated/graphql'

// Campaign Context
export const CAMPAIGN_CONTEXT = 'CAMPAIGN_CONTEXT'
export type CampaignContext = {
  kind: typeof CAMPAIGN_CONTEXT
  campaign: CampaignFragment
  line?: number
}
export const CampaignContext = (
  campaign: CampaignFragment,
  line?: number,
): CampaignContext => ({
  kind: CAMPAIGN_CONTEXT,
  campaign,
  line,
})

// Order Context
const ORDER_CONTEXT = 'ORDER_CONTEXT'
type OrderContext = {
  kind: typeof ORDER_CONTEXT
}
const OrderContext = (): OrderContext => ({
  kind: ORDER_CONTEXT,
})

// Act context
export const ACT_CONTEXT = 'ACT_CONTEXT'
export type ActContext = {
  kind: typeof ACT_CONTEXT
  isDigitalCard: boolean
}
export const ActContext = (isDigitalCard: boolean): ActContext => ({
  kind: ACT_CONTEXT,
  isDigitalCard,
})

// Priging Page Context
export const PRICING_CONTEXT = 'PRICING_PAGE_CONTEXT'
export type PricingContext = {
  kind: typeof PRICING_CONTEXT
  isCheckoutOpen?: boolean
}
export const PricingContext = (isCheckoutOpen?: boolean): PricingContext => ({
  kind: PRICING_CONTEXT,
  isCheckoutOpen,
})

// No Context
const NO_CONTEXT = 'NO_CONTEXT'
export type NoContext = {
  kind: typeof NO_CONTEXT
}
export const NoContext = (): NoContext => ({ kind: NO_CONTEXT })

export type ContextState =
  | CampaignContext
  | OrderContext
  | PricingContext
  | ActContext
  | NoContext
