import { PanelImages } from '@sendoutcards/editor/dist/components/ThreeDCardPreview/hooks/use3DCCore'
import { PanelName } from '@sendoutcards/editor/dist/types/editorChrome'
import { MinimalPanelFragment } from 'src/graphql/generated/graphql'

/**
 * This helper intakes all the single panel fragments and translate it into a PanelImages obj that
 * will be consumed by the 3D Card Preview
 * Note: as of now, panels preview url don't return images that have bg images across panels consistently
 * @param panels
 * @param isThreePanel
 * @param isHorizontal
 * @returns
 */
export const mapSinglePanelImages = (
  panels: MinimalPanelFragment[],
  isThreePanel: boolean,
  isHorizontal?: boolean,
) => {
  return {
    Front: { url: panels[0].previewUrl },
    InsideLeft: {
      url: panels.find(panel => {
        return isHorizontal
          ? panel.name === 'Inside Top' && !panel.isFullBleed
          : panel.name === 'Inside Left' && !panel.isFullBleed
      })?.previewUrl,
    },
    InsideRight: {
      url: isThreePanel
        ? panels.find(panel => panel.name === 'Inside Middle')?.previewUrl
        : panels.find(panel =>
            isHorizontal
              ? panel.name === 'Inside Bottom'
              : panel.name === 'Inside Right',
          )?.previewUrl,
    },
    Back: { url: panels.find(panel => panel.name === 'Back')?.previewUrl },
    FlapIn: {
      url: isThreePanel
        ? panels.find(panel =>
            isHorizontal
              ? panel.name === 'Inside Bottom'
              : panel.name === 'Inside Right',
          )?.previewUrl
        : '',
    },
    FlapOut: {
      url: isThreePanel
        ? panels.find(panel => panel.name === 'Flap')?.previewUrl
        : '',
    },
  } as PanelImages
}

/**
 * This helper intakes the panels preview urls and translate into a PanelImages obj that
 * will be consumed by the 3D Card Preview
 * @param frontPanelUrl
 * @param insidePanelsUrl The inside panels url consist of an image with all internal panels merged into one
 * image with all its elements in place, if the desired usage requires PanelFragments[], please use
 * the mapSinglePanels() helper
 * @param backUrl
 * @param flapOutUrl
 * @param isThreePanel
 * @param isHorizontal
 * @returns PanelImages
 */
export const mapPanelImages = (
  frontPanelUrl: string | undefined,
  insidePanelsUrl: string | undefined,
  backUrl: string | undefined,
  flapOutUrl: string | undefined,
  isThreePanel: boolean,
  isHorizontal?: boolean,
) => {
  const insideExtension = isThreePanel ? '300%' : '200%'
  const insideDimensions = isHorizontal
    ? { height: insideExtension }
    : { width: insideExtension }

  return {
    Front: {
      url: frontPanelUrl,
    },
    ...(insidePanelsUrl && {
      InsideLeft: { url: insidePanelsUrl, style: { ...insideDimensions } },
    }),
    ...(insidePanelsUrl && {
      InsideRight: {
        url: insidePanelsUrl,
        style: {
          ...insideDimensions,
          ...(isHorizontal ? { top: '-100%' } : { left: '-100%' }),
        },
      },
    }),
    Back: {
      url: backUrl,
    },
    ...(isThreePanel && {
      FlapIn: {
        url: insidePanelsUrl,
        style: {
          ...insideDimensions,
          ...(isHorizontal ? { top: '-200%' } : { left: '-200%' }),
        },
      },
    }),
    ...(isThreePanel && {
      FlapOut: {
        url: flapOutUrl,
      },
    }),
  } as PanelImages
}

export const mapPanelControlNames = (panels: MinimalPanelFragment[]) =>
  panels
    .filter(item => item.name !== 'FBBackground')
    .map((fb, i) => {
      return {
        name: fb.name as PanelName, // Ensure the name is typed as PanelName
        index: i,
      }
    })
