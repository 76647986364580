import { Div, Flex, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
import { Portal } from 'src/portal/portal'

export const LinkCopiedToaster = () => {
  return (
    <Portal>
      <Flex
        justifyContent="center"
        alignItems="center"
        flexDirection="row"
        columnGap="x1"
        position="fixed"
        zIndex={2000}
        style={{
          borderRadius: '22px',
          backgroundColor: '#444',
          boxShadow: '0px 0px 32px 0px rgba(0, 0, 0, 0.16)',
          padding: '8px 12px',
          top: '40px',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      >
        <Div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            backgroundColor: '#059669',
            padding: '6px',
          }}
        >
          <Icon name="check" size={12} color="#fff" />
        </Div>
        <Text
          type="body"
          content="Link Copied!"
          style={{
            color: '#fff',
            fontFamily: 'Montserrat, sans-serif',
            fontSize: '16px',
          }}
        />
      </Flex>
    </Portal>
  )
}
