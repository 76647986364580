import React from 'react'
import {
  Dialog,
  Div,
  Flex,
  Icon as QdsIcon,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import { cardTypes, getPaperTypes } from 'src/app/constants'
import { DIGITAL_CARD_TYPES } from 'src/catalog/components/CardPreview/CardPreviewModal'
import { useMeasurements, useRoute, useSelector, useState } from 'src/hooks'
import {
  CardFragment,
  CardPaperType,
  CardType,
  DetailedSendableCardFragment,
  SendableCardWithTemplateFragment,
} from 'src/graphql/generated/graphql'
import { CardPreviewFooter } from './components/CardPreviewFooter'
import { CardPreviewHeader } from './components/CardPreviewHeader'
import { CardTypeTile } from './components/CardTypeTile'
import { PaperTypeTile } from './components/PaperTypeTile'
import { SendTypeToggle } from './components/SendTypeToggle'
import { SelectSendType } from './SelectSendType'
import { Variation } from './components/VariationToggle'
import { SendType } from 'src/catalog/components/CardOptions/CardOptions'
import { CampaignsRoute } from 'src/campaigns/routes/CampaignsRoute'
import { OrdersRoute } from 'src/orders/routes/OrdersRoute'
import { CardPreviewCardView } from './components/CardPreviewCardView'
import { CardPreviewCustomCardView } from './components/CardPreviewCustomCardView'

export type CustomCardPanelsPreviewsPositions =
  | 'front'
  | 'inside'
  | 'flap'
  | 'back'

export type CustomCardPanelsPreviews = {
  front?: string
  inside?: string
  flap?: string
  back?: string
}

const getVariations = (
  sendableCard: DetailedSendableCardFragment,
): SendableCardWithTemplateFragment[] => {
  return [sendableCard, ...sendableCard.variations]
}

const getSelectedVariation = (
  sendableCard: DetailedSendableCardFragment,
  variationId?: string,
): SendableCardWithTemplateFragment =>
  (variationId &&
    getVariations(sendableCard).find(
      variation => variation.id === variationId,
    )) ||
  sendableCard

export type CardPreviewSteps = 'SelectSendType' | 'CardDetails'

type CardPreviewProps = {
  initialStep: CardPreviewSteps
  shouldShowAct: boolean
  card: DetailedSendableCardFragment | CardFragment
  isFreeSend: boolean
  variationId?: string
  onChangeVariationId: (id?: string) => void
  sendType: SendType
  onChangeSendType: (sendType: SendType) => void
  selectedCardType: CardType
  onCardTypeSelect: (selectedCardType: CardType) => void
  selectedPaperType: CardPaperType
  onPaperTypeSelect: (selectedPaperType: CardPaperType) => void
  onEditCustomCard: () => void
  onStepChange?: (nextStep: CardPreviewSteps) => void
  onClose: () => void
  onComplete: () => void
}

export const CardPreviewFlow = (props: CardPreviewProps) => {
  const {
    initialStep = 'SelectSendType',
    shouldShowAct,
    card,
    isFreeSend,
    variationId,
    onChangeVariationId,
    sendType,
    selectedCardType,
    onCardTypeSelect,
    selectedPaperType,
    onPaperTypeSelect,
    onEditCustomCard,
    onStepChange,
    onComplete,
    onClose,
    onChangeSendType,
  } = props
  const primaryFont = 'Montserrat, san serif'

  const route = useRoute()
  const { windowWidth, windowHeight } = useMeasurements()

  const isScreenTooSmall = windowWidth < 960
  const isScreenTooShort = windowHeight < 630
  const { order } = useSelector(state => state.orders)
  const canSelectPostcardType = !(
    order?.lines.length === 1 && order.lines[0].giftVariation
  )

  const [currentStep, setCurrentStep] = useState<CardPreviewSteps>(initialStep)

  const isDigitalSend = sendType === SendType.Digital

  const handleSelectCardType = (value: CardType) => {
    onCardTypeSelect(value)
  }

  const handleSelectPaperType = (value: CardPaperType) => {
    onPaperTypeSelect(value)
  }

  const goToNextStep = () => {
    if (
      currentStep === 'CardDetails' ||
      (currentStep === 'SelectSendType' && isFreeSend) ||
      (currentStep === 'SelectSendType' &&
        card.__typename === 'Card' &&
        sendType === SendType.Digital)
    ) {
      if (onComplete) {
        onComplete()
      }
    } else {
      const nextStep = 'CardDetails'
      setCurrentStep(nextStep)
      onStepChange?.(nextStep)
    }
  }

  const goBackStep = () => {
    switch (currentStep) {
      case 'CardDetails':
        setCurrentStep('SelectSendType')
        onStepChange?.('SelectSendType')
        break
      default:
        setCurrentStep('SelectSendType')
        onStepChange?.('SelectSendType')
    }
  }

  const isOrderOrCampaignRoute =
    route.subroute.path === CampaignsRoute.path ||
    route.subroute.path === OrdersRoute.path

  const cardVariations =
    card.__typename === 'SendableCard' ? getVariations(card) : undefined
  const selectedCardVariation =
    card.__typename === 'SendableCard'
      ? getSelectedVariation(card, variationId)
      : undefined

  const frontPanelImageUrl =
    card.__typename === 'SendableCard'
      ? selectedCardVariation
        ? { ...card, ...selectedCardVariation }.frontImage.mediumThumb
        : card.frontImage.mediumThumb
      : card.frontPreviewUrl

  const cardPanelsPreviews =
    card.__typename === 'Card'
      ? {
          front: card.frontPreviewUrl,
          inside: card.insidePreviewUrl,
          flap: card.flapPreviewUrl,
          back: card.backPreviewUrl,
        }
      : undefined
  const cardType = card.__typename === 'Card' ? card.type : selectedCardType
  const isFavorite =
    card.__typename === 'SendableCard' ? card.isFavorite : false

  const isHorizontal = card.isHorizontal

  const cardTitle = card.__typename === 'SendableCard' ? card.title : undefined
  const cardSubtitle =
    card.__typename === 'SendableCard' ? card.description : undefined

  const insideRightImagePreview =
    card.__typename === 'SendableCard'
      ? card.insideRightImage?.mediumThumb
      : undefined

  const paperUpgradeCost = selectedCardType === CardType.TwoPanelBig ? 150 : 50

  const formattedPaperUpgrade = (() => {
    const paperCostString = paperUpgradeCost.toString()

    return paperUpgradeCost < 100
      ? `${paperUpgradeCost}¢`
      : `$${paperCostString.slice(0, 1)}.${paperCostString.slice(1)}`
  })()

  const paperTypes = getPaperTypes(selectedCardType).map(type => ({
    ...type,
    upgrade: type.upgrade ? formattedPaperUpgrade : type.upgrade,
  }))

  const filteredCardTypeData = cardTypes
    .filter(type => type.value !== 'POSTCARD' || canSelectPostcardType)
    .filter(type => DIGITAL_CARD_TYPES.includes(type.value) || !isDigitalSend)

  const variations: Variation[] =
    cardVariations?.map(variation => ({
      id: variation.id,
      color: variation.variationColor,
    })) ?? []

  const hasBulkOrderOption = [CardType.Flatcard, CardType.TwoPanel].includes(
    selectedCardType,
  )

  const [
    isDisplayingInsideRightImagePreview,
    setIsDisplayingInsideRightImagePreview,
  ] = useState(false)
  const [currentPanelPreview, setCurrentPanelPreview] =
    useState<CustomCardPanelsPreviewsPositions>('front')

  const handleSeeInside = () => {
    setIsDisplayingInsideRightImagePreview(!isDisplayingInsideRightImagePreview)
  }

  const panelsPositions: CustomCardPanelsPreviewsPositions[] =
    cardType === CardType.Flatcard
      ? ['front', 'inside']
      : cardType === CardType.TwoPanel || cardType === CardType.TwoPanelBig
        ? ['front', 'inside', 'back']
        : cardType === CardType.ThreePanel
          ? ['front', 'inside', 'flap', 'back']
          : ['front', 'back']

  const getCurrentPanelPreview = (): string => {
    if (cardPanelsPreviews) {
      return currentPanelPreview === 'front' && cardPanelsPreviews.front
        ? cardPanelsPreviews.front
        : currentPanelPreview === 'inside' && cardPanelsPreviews.inside
          ? cardPanelsPreviews.inside
          : currentPanelPreview === 'flap' && cardPanelsPreviews.flap
            ? cardPanelsPreviews.flap
            : cardPanelsPreviews.back && cardPanelsPreviews.back
              ? cardPanelsPreviews.back
              : frontPanelImageUrl
    }
    return frontPanelImageUrl
  }

  const cardImageUrl =
    isDisplayingInsideRightImagePreview && insideRightImagePreview
      ? insideRightImagePreview
      : getCurrentPanelPreview()

  const isPremium = card.__typename === 'SendableCard' ? card.isPremium : false

  const stepContent = {
    SelectSendType: isScreenTooSmall ? (
      <>
        <CardPreviewCardView
          cardImageUrl={cardImageUrl}
          isHorizontal={isHorizontal}
          isCardDigitalSend={isDigitalSend}
          currentPanelPreview={currentPanelPreview}
          cardId={card.id}
          isPremium={isPremium}
          isCardFavorite={isFavorite}
          variations={variations}
          selectedVariationId={
            variationId
              ? variationId
              : variations.length > 1
                ? variations[0].id
                : undefined
          }
          handleVariation={onChangeVariationId}
          cardPanelsPreviews={cardPanelsPreviews}
          panelsPositions={panelsPositions}
          setCurrentPanelPreview={setCurrentPanelPreview}
        />
        <SelectSendType
          shouldShowAct={shouldShowAct}
          cardFrontPanelImageUrl={frontPanelImageUrl}
          cardInsideRightImagePreview={insideRightImagePreview}
          isHorizontal={isHorizontal}
          cardTitle={cardTitle}
          cardSubtitle={cardSubtitle}
          primaryFont={primaryFont}
          onSelectSendType={onChangeSendType}
          selectedSendType={sendType}
          isDisplayingInsideRightImagePreview={
            isDisplayingInsideRightImagePreview
          }
          onSeeInside={handleSeeInside}
          onEditCustomCard={
            card.__typename === 'Card' ? onEditCustomCard : undefined
          }
        />
      </>
    ) : (
      <Flex
        flexDirection={'row'}
        justifyContent={'space-evenly'}
        inset={{
          ...(isHorizontal
            ? { left: 'x2', right: 'x2', top: 'x4' }
            : { left: 'x4', right: 'x2' }),
        }}
        columnGap={'x4'}
      >
        {card.__typename === 'Card' ? (
          <Flex>
            <CardPreviewCustomCardView
              size={isScreenTooShort ? '100px' : '20rem'}
              card={card}
            />
          </Flex>
        ) : (
          <Flex>
            <CardPreviewCardView
              size={isScreenTooShort ? '100px' : '17rem'}
              cardImageUrl={cardImageUrl}
              isHorizontal={isHorizontal}
              isCardDigitalSend={isDigitalSend}
              currentPanelPreview={currentPanelPreview}
              cardId={card.id}
              isPremium={isPremium}
              isCardFavorite={isFavorite}
              variations={variations}
              selectedVariationId={
                variationId
                  ? variationId
                  : variations.length > 1
                    ? variations[0].id
                    : undefined
              }
              handleVariation={onChangeVariationId}
              cardPanelsPreviews={cardPanelsPreviews}
              panelsPositions={panelsPositions}
              setCurrentPanelPreview={setCurrentPanelPreview}
            />
          </Flex>
        )}
        <SelectSendType
          shouldShowAct={shouldShowAct}
          cardFrontPanelImageUrl={frontPanelImageUrl}
          cardInsideRightImagePreview={insideRightImagePreview}
          isHorizontal={isHorizontal}
          cardTitle={cardTitle}
          cardSubtitle={cardSubtitle}
          primaryFont={primaryFont}
          onSelectSendType={onChangeSendType}
          isDisplayingInsideRightImagePreview={
            isDisplayingInsideRightImagePreview
          }
          selectedSendType={sendType}
          onSeeInside={handleSeeInside}
          onEditCustomCard={
            card.__typename === 'Card' ? onEditCustomCard : undefined
          }
        />
      </Flex>
    ),
    CardDetails: (
      <Flex width="100%" flexDirection="column" rowGap="x2">
        {shouldShowAct && (
          <Flex width="100%" justifyContent="center">
            <SendTypeToggle onToggle={onChangeSendType} sendType={sendType} />
          </Flex>
        )}
        <Flex flexDirection="column">
          <Text
            type="body"
            content="Card Type:"
            style={{
              fontFamily: primaryFont,
              fontSize: '.625rem',
              fontWeight: 700,
              color: '#6B7280',
            }}
          />
          <Div
            display="grid"
            justifyItems="center"
            width="100%"
            rowGap="x1"
            columnGap="x2"
            style={{
              gridTemplateColumns:
                isDigitalSend && !isScreenTooSmall
                  ? 'repeat(3, 150px)'
                  : isScreenTooSmall
                    ? 'repeat(3, 100px)'
                    : 'repeat(5, 150px)',
            }}
          >
            {filteredCardTypeData.map(cardType => (
              <CardTypeTile
                key={cardType.value}
                title={cardType.label}
                cardType={cardType.value}
                icon={{
                  icon: cardType.icon,
                  size: cardType.iconSize,
                  viewBox: cardType.viewBox,
                }}
                upgrade={cardType.upgrade}
                imageUrl={frontPanelImageUrl}
                isSelected={selectedCardType === cardType.value}
                isHorizontal={isHorizontal}
                isDigital={isDigitalSend}
                size={isScreenTooSmall ? undefined : '5rem'}
                onSelect={() => handleSelectCardType(cardType.value)}
              />
            ))}
          </Div>
        </Flex>
        {!isDigitalSend && !isOrderOrCampaignRoute && (
          <Flex justifyContent="space-around" alignItems="center">
            <Div
              display={'flex'}
              flexDirection={'row'}
              backgroundColor={
                hasBulkOrderOption ? 'rgb(238, 247, 243)' : 'rgb(247, 201, 201)'
              }
              borderRadius="circle"
              boxShadow="light"
              height="fit-content"
              inset={{ horizontal: 'x1_5', vertical: 'x_75' }}
              outset={{ bottom: 'auto' }}
              alignItems={'center'}
            >
              {hasBulkOrderOption ? (
                <QdsIcon primaryColor="success" size="xSmall" name="check" />
              ) : (
                <QdsIcon primaryColor="danger" size="xSmall" name="close" />
              )}
              <Spacer space={'x_25'} orientation={'horizontal'} />
              {hasBulkOrderOption ? (
                <Text weight="semiBold" type="caption" color={'success'}>
                  Eligible for bulk send
                </Text>
              ) : (
                <Text weight="semiBold" type="caption" color={'danger'}>
                  Not eligible for bulk send
                </Text>
              )}
            </Div>
          </Flex>
        )}
        {!isDigitalSend && (
          <Flex flexDirection="column">
            <Text
              type="body"
              content="Choose Paper Type:"
              style={{
                fontFamily: primaryFont,
                fontSize: '.625rem',
                fontWeight: 700,
                color: '#6B7280',
              }}
            />
            <Div
              display="grid"
              justifyItems="center"
              width="100%"
              rowGap="x1"
              columnGap="x1"
              style={{
                gridTemplateColumns: 'repeat(3, 70px)',
              }}
            >
              {paperTypes.map(paperType => (
                <PaperTypeTile
                  key={paperType.value}
                  title={paperType.label}
                  price={paperType.upgrade ?? ''}
                  imageUrl={frontPanelImageUrl}
                  isSelected={selectedPaperType === paperType.value}
                  icon={paperType.icon}
                  size={'70px'}
                  onSelect={() => handleSelectPaperType(paperType.value)}
                />
              ))}
            </Div>
          </Flex>
        )}
        <Spacer space={'x1'} />
      </Flex>
    ),
  }

  const isMobile = useSelector(state => state.window.width <= 474)

  return (
    <Dialog maxWidth="min-content" insetOverride="x2" isOpen={true}>
      <Flex
        position="relative"
        height="100%"
        width="100%"
        inset={isMobile ? undefined : { vertical: 'x1' }}
        backgroundColor="#fff"
        flexWrap="wrap"
        flexDirection="column"
        outset={{ bottom: 'x4', top: 'x2' }}
        rowGap="x2"
        style={{
          borderRadius: '1rem',
        }}
        overflowY="auto"
      >
        <Flex flexDirection="column" flexWrap="wrap">
          <Flex inset={{ bottom: '12px' }}>
            <CardPreviewHeader
              title={
                currentStep === initialStep
                  ? 'Card Preview'
                  : 'Customize Card Options'
              }
              onClose={onClose}
            />
          </Flex>
          <Flex inset={{ bottom: '32px' }} flexWrap="wrap" overflowX="auto">
            {stepContent[currentStep]}
          </Flex>
        </Flex>
      </Flex>
      <Flex width="100%" position="absolute" bottom="0px" right="0px">
        <CardPreviewFooter
          primaryAction={{ title: 'Continue', onClick: goToNextStep }}
          secondaryAction={
            currentStep !== 'SelectSendType'
              ? { title: 'Back', onClick: goBackStep }
              : undefined
          }
        />
      </Flex>
    </Dialog>
  )
}
