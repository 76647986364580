import {
  Div,
  Flex,
  Icon,
  LoadingSpinner,
  Text,
} from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { Button } from 'src/design_system/components/Button/Button'
import { useContainerWidth } from 'src/hooks'
import { useGetSendableCard, useMarketingContent } from 'src/react_query'

export const CardFeatureWidget = () => {
  const [containerRef, containerWidth] = useContainerWidth()
  const shouldShrink = containerWidth <= 163

  const { data: marketingContent } = useMarketingContent()

  const cardId = marketingContent?.cardOfTheMonthId.content

  const { data } = useGetSendableCard({ id: cardId ?? undefined })

  const isHorizontal = data?.sendableCard.isHorizontal
  const cardOfTheMonth = data?.sendableCard.frontImage.url

  return (
    <Flex
      width="100%"
      flexDirection="column"
      backgroundColor="#fff"
      alignItems="center"
      borderRadius="20px"
      minWidth="220px"
      height="inherit"
      inset="x2"
    >
      <Div
        ref={containerRef}
        display="flex"
        width="100%"
        height="100%"
        flexDirection="column"
        justifyContent="start"
        alignItems="start"
      >
        <Text
          type="body"
          content="Card of the Month"
          alignment="left"
          style={{ fontSize: '16px', fontWeight: 600, color: '#404040' }}
        />
        <Flex id="slider-header" width="100%" position="relative" bottom="18px">
          <Flex id="slider-controls" columnGap="x1" display="none">
            <Flex
              alignItems="center"
              justifyContent="center"
              borderRadius="circle"
              cursor="pointer"
              style={{ backgroundColor: '#F9FAFB', padding: 6 }}
              onClick={() => alert('featuredCard_goback')}
            >
              <Icon
                name="leftChevron"
                size="xSmall"
                primaryColor="primaryHeadingText"
              />
            </Flex>
            <Flex
              alignItems="center"
              justifyContent="center"
              borderRadius="circle"
              cursor="pointer"
              style={{ backgroundColor: '#F9FAFB', padding: 6 }}
              onClick={() => alert('featuredCard_goForward')}
            >
              <Icon
                name="rightChevron"
                size="xSmall"
                primaryColor="primaryHeadingText"
              />
            </Flex>
          </Flex>
        </Flex>
        <Flex
          width="100%"
          justifyContent="center"
          alignItems="center"
          height="100%"
          outset={{ vertical: 'x2' }}
        >
          {cardOfTheMonth ? (
            <Div
              style={{
                backgroundRepeat: 'no-repeat',
                height: isHorizontal ? 150 : 210,
                width: isHorizontal ? 210 : 150,
                backgroundSize: 'cover',
                backgroundImage: `url(${cardOfTheMonth})`,
                transform: shouldShrink ? 'scale(.85)' : '',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              }}
            />
          ) : (
            <LoadingSpinner size={'large'} />
          )}
        </Flex>
        <Flex alignItems="flex-end" width="100%" inset={{ vertical: 'x1' }}>
          <Button
            title="Send Card"
            background="linear-gradient(90deg,#E7F1F7,#FDFAF5)"
            textColor="#404040"
            textSize={shouldShrink ? '10px' : '14px'}
            width="100%"
            whiteSpace="nowrap"
            onClick={() => {
              window.location.href = `https://app.sendoutcards.com/catalog/preview/${cardId}`
            }}
          />
        </Flex>
      </Div>
    </Flex>
  )
}
