import React, { PointerEvent } from 'react'
import {
  Div,
  Flex,
  Icon as QDSIcon,
  Span,
  Text,
} from '@sendoutcards/quantum-design-ui'
import {
  animate,
  AnimatePresence,
  motion,
  PanInfo,
  useDragControls,
  useMotionValue,
} from 'framer-motion'
import {
  DuvetHandle,
  DuvetHandleDirection,
} from './components/DuvetHandle/DuvetHandle'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
import { IconType } from 'src/design_system/atoms/icons/types'
import { useCallback } from 'src/hooks'

type DuvetV2Props = {
  onClose: () => void
  openValue: number
  isOpen: boolean
  snapPoints: number[]
  onSnap: (snapValue: number, snapPoints: number[]) => void
  directionArrow?: {
    direction: 'up' | 'down'
    onClick: () => void
  }
  headerOffset?: string
  shouldShowCloseButton?: boolean
  title?: string
  titleIcon?: IconType
  handleDirection?: DuvetHandleDirection
  children: React.ReactNode
}

export const DuvetV2 = (props: DuvetV2Props) => {
  const {
    onClose,
    children,
    isOpen,
    openValue,
    directionArrow,
    headerOffset,
    onSnap,
    snapPoints,
    title,
    titleIcon,
  } = props

  const y = useMotionValue(window.innerHeight)
  const safeOpenValue = Math.min(openValue, window.innerHeight)
  const openOffset = window.innerHeight - safeOpenValue

  const dragControls = useDragControls()

  const startDrag = (event: PointerEvent<HTMLDivElement>) => {
    dragControls.start(event)
  }

  const handleDrag = useCallback(
    (_: MouseEvent, { delta }: PanInfo) => {
      y.set(Math.max(y.get() + delta.y, 0))
    },
    [y],
  )

  const handleDragEnd = useCallback(async () => {
    const currentY = y.get()
    const yOffset = window.innerHeight - currentY

    // Finds the closest snap point
    const closestSnap = snapPoints.reduce((prev, curr) =>
      Math.abs(curr - yOffset) < Math.abs(prev - yOffset) ? curr : prev,
    )

    // Animates to the closest snap point
    animate(y, window.innerHeight - closestSnap, {
      type: 'spring',
      stiffness: 300,
      damping: 30,
      mass: 0.2,
    })

    // Call the onSnap callback after animation
    onSnap(closestSnap, snapPoints)

    // Triggers onClose if dragged downwards
    if (closestSnap === snapPoints[0]) {
      onClose()
    }
  }, [y, snapPoints, onClose, onSnap]) // eslint-disable-line

  const getAnimationDirection = () => {
    const positionIndex = snapPoints.indexOf(openValue)

    if (
      positionIndex === 1 ||
      (positionIndex === 0 && snapPoints[1] === snapPoints[0])
    ) {
      // Check for any bigger value in the array
      const hasBiggerValue = snapPoints.some(
        (num, index) => num > openValue && index > positionIndex,
      )
      return hasBiggerValue ? 'up' : undefined
    }
    if (positionIndex === 0) {
      return undefined
    }
    if (positionIndex === 2 || positionIndex === 3) {
      return 'down'
    }
    return undefined
  }

  return (
    <AnimatePresence>
      {isOpen && (
        <Div
          boxShadow="0px 8px 16px 0px rgb(0 0 0 / 10%);"
          borderRadius={{ top: 'large' }}
          key="duvet"
          width={'100%'}
          drag="y"
          transition={{
            stiffness: 300,
            damping: 30,
            mass: 0.2,
          }}
          height="100dvh"
          backgroundColor={'foreground'}
          dragConstraints={{ top: 0, bottom: window.innerHeight }}
          dragMomentum={false}
          dragElastic={0}
          style={{ y }}
          initial={{ y: window.innerHeight }}
          animate={{ y: openOffset }}
          dragControls={dragControls}
          onDrag={handleDrag}
          onDragEnd={handleDragEnd}
          position="fixed"
          bottom="0px"
          overflow="hidden"
          data-should-drag="true"
          dragListener={false}
          zIndex={8}
        >
          <motion.div
            onPointerDown={startDrag}
            style={{
              touchAction: 'none',
              position: 'relative',
              top: '0px',
              left: '50%',
              transform: 'translateX(-50%)',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              zIndex: 1,
              padding: '24px 16px 8px 16px',
            }}
          >
            <DuvetHandle direction={getAnimationDirection()} />
            {title && titleIcon && (
              <Flex
                justifyContent="space-between"
                width="100%"
                inset={{ horizontal: 'x1', top: 'x_5' }}
              >
                <Div>
                  <Flex flexDirection="row" columnGap="x1" alignItems="center">
                    <Icon name={titleIcon} size={23} fill="#000" />
                    <Text
                      type="largeBody"
                      weight="semiBold"
                      color="primaryHeading"
                      style={{ fontSize: '16px' }}
                    >
                      {title}
                    </Text>
                  </Flex>
                </Div>
                <Flex
                  onClick={onClose}
                  justifyContent="center"
                  alignItems="center"
                  backgroundColor="#F5F5F5"
                  borderRadius="circle"
                  inset=".5rem"
                  zIndex={2}
                >
                  <QDSIcon
                    name="close"
                    size={14}
                    primaryColor="primaryBodyText"
                  />
                </Flex>
              </Flex>
            )}
            {directionArrow && (
              <Span
                cursor="pointer"
                onClick={directionArrow.onClick}
                inset={{ top: 'x_5' }}
                transform={`rotate(${
                  directionArrow.direction === 'up' ? '0deg' : '180deg'
                })`}
              >
                <QDSIcon
                  name="chevron"
                  size="small"
                  primaryColor="default"
                  orientation={'up'}
                />
              </Span>
            )}
          </motion.div>
          <Div
            top={headerOffset}
            left="0px"
            inset={{ horizontal: 'x2' }}
            width="100%"
            overflow="hidden"
            data-should-drag="true"
          >
            {children}
          </Div>
        </Div>
      )}
    </AnimatePresence>
  )
}
