import { Div, Flex, Text } from '@sendoutcards/quantum-design-ui'
import React from 'react'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'

type CardPreviewHeaderProps = {
  title: string
  onClose: () => void
}

export const CardPreviewHeader = (props: CardPreviewHeaderProps) => {
  const { title, onClose } = props
  const primaryFont = 'Montserrat, san serif'

  return (
    <Flex width="100%" justifyContent="space-between" alignItems="center">
      <Text
        type="body"
        content={title}
        style={{
          color: '#404040',
          fontFamily: primaryFont,
          fontSize: '0.875rem',
          fontWeight: 600,
        }}
      />
      <Div
        display="flex"
        justifyContent="center"
        alignItems="center"
        inset=".75rem"
        backgroundColor="#F3F4F6"
        onClick={onClose}
        style={{
          borderRadius: '.5rem',
          boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.02)',
        }}
      >
        <Icon name="x" color="#404040" size={12} />
      </Div>
    </Flex>
  )
}
