import React from 'react'

import LoadingIndicator from '../LoadingIndicator/LoadingIndicator'
import styles from './transition.module.scss'

interface TransitionProps {
  message?: string
  subMessage?: string | string[]
  messageStyle?: React.CSSProperties
  style?: React.CSSProperties
}

const Transition: React.FC<TransitionProps> = props => {
  const { message = 'Loading...', messageStyle, style } = props

  return (
    <div id="transition" className={styles.modalWindow} style={style}>
      <LoadingIndicator message={message} messageStyle={messageStyle} />
    </div>
  )
}

export default Transition
