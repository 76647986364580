import React, { FC } from 'react'
import { SVGProps } from './types'

export const Copy: FC<SVGProps> = ({
  width = 13,
  height = 12,
  onClick,
  color = '#0090D4',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick && (() => onClick())}
    >
      <g clipPath="url(#clip0_4215_43744)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.375 2C1.375 1.24061 1.99061 0.625 2.75 0.625H8.25C9.0094 0.625 9.625 1.24061 9.625 2V3.125H10.75C11.5094 3.125 12.125 3.74061 12.125 4.5V10C12.125 10.7594 11.5094 11.375 10.75 11.375H5.25C4.49061 11.375 3.875 10.7594 3.875 10V8.875H2.75C1.99061 8.875 1.375 8.2594 1.375 7.5V2ZM4.625 8.875V10C4.625 10.3452 4.90482 10.625 5.25 10.625H10.75C11.0952 10.625 11.375 10.3452 11.375 10V4.5C11.375 4.15482 11.0952 3.875 10.75 3.875H9.625V7.5C9.625 8.2594 9.0094 8.875 8.25 8.875H4.625ZM2.75 1.375C2.40482 1.375 2.125 1.65482 2.125 2V7.5C2.125 7.8452 2.40482 8.125 2.75 8.125H8.25C8.5952 8.125 8.875 7.8452 8.875 7.5V2C8.875 1.65482 8.5952 1.375 8.25 1.375H2.75Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_4215_43744">
          <rect
            width={12}
            height={12}
            fill="white"
            transform="translate(0.75)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
